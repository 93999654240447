import React, { createElement } from 'react'

export const List = ({
  classNames, style, title, isVisible, handleClick, titleButton, list
}) => {

  const getContent = (datas) => {
    // datas가 배열인 경우
    if (Array.isArray(datas)) {
      const isAllElements = datas.every(item => React.isValidElement(item));
      // datas 배열의 item이 모두 엘리먼트(태그)인 경우
      if(isAllElements)
        return datas;
      // datas 배열의 item이 데이터인 경우
      else
        return (
          datas.map((item, index) => (
            <li key={index}>{item}</li>
          ))
        );
    // } else if (typeof datas === 'object' && !React.isValidElement(datas)) {  // datas가 json 형식인 경우
    //   let element;
    //   return (
    //     datas.map((item, index) => (
    //       <li key={index} className={item.className} onClick={item.handleClick}>
    //         {
    //           item.data.length > 0 ? 
    //             item.data.map((value) => {
    //               return (
    //                 element = createElement(value.tag.toLowerCase(), {}, value.value)
    //                 // <div className={value.className}>{value.value}</div>
    //               )
    //             }) 
    //           : item.data
    //         }
    //       </li>
    //     ))
    //   );
    } else {
      // data가 다른 형식인 경우
      // return <li>Unsupported data type</li>;
      return (
        datas.map((item, index) => (
          <li key={index}>{item}</li>
        ))
      );
    }
  }

  return (
    <section className={classNames.section}>
      <div className={classNames.div}>
        <div className="title">
          {title}
          {isVisible ? 
            <button className={"btn-secondary " + classNames.button} onClick={handleClick} >{titleButton}</button> 
            : null
          }
        </div>
        <ul className={classNames.ul} style={style}>
            {getContent(list)}
        </ul>
      </div>
    </section>
  )
};