import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';


const RESULT_LOGIN = 'loginActions/RESULT_LOGIN';
const SET_STATES = 'loginActions/SET_STATES';
const INIT_STATES = 'loginActions/INIT_STATES';

export const setResultLogin = createAction(RESULT_LOGIN);
export const setLoginInfo = createAction(SET_STATES);
export const init = createAction(INIT_STATES);


const initialState = Map({   
    userId: '',   
    userName: '',
    message: '',
    result: '',
    sessionID: '',
    logindate: '', 
    companyName: '',
    admin:'',
    companySeq: '',
    companyType: '',    
    userPhoneNum: '',
    userSendOtpType: '',
    deptName: '',
    kindName: ''
});

export default handleActions({    
    [RESULT_LOGIN]: (state, action) => {
        return state.merge(action.payload);
    },    
    [SET_STATES]: (state, action) => {
        return state.merge(action.payload);
    },
    [INIT_STATES]: (state, action) => {
        return state.merge(initialState);
    }
}, initialState);