import React, { forwardRef, useEffect, useRef } from 'react';
import Quill from 'quill';
import './quill.snow.css';

// Editor is an uncontrolled React component
const Editor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        [{'header': 1}, {'header': 2}, 'blockquote', 'code-block'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}, {'indent': '-1'}, {'indent': '+1'}],
        // [{'direction': 'rtl'}, 'align'],
        ['link', 'image', 'video'],
        // ['clean']
    ];

    useEffect(() => {
      const container = containerRef.current;
      const quill = new Quill(container, {
        modules: {
            toolbar: toolbarOptions,
            history: {
                delay: 0,
                maxStack: 500,
                userOnly: true
            },
        },
        placeholder: '내용을 입력해주세요.',
        theme: 'snow',
      });

      ref.current = quill;

      if (defaultValueRef.current && defaultValueRef.current !== '') {
        // const delta = quill.clipboard.convert(defaultValueRef.current);
        // quill.setContents(delta, 'silent');
        quill.setContents(defaultValueRef.current);
      }

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return (<div ref={containerRef}></div>);
  },
);

export default Editor;