import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as loginActions from '../../store/modules/loginActions';
import * as messageActions from '../../store/modules/messageActions';
import { bindActionCreators } from 'redux';
import EmailVerificationPanel from '../LoginPanels/EmailVerificationPanel';

const UserInfo = (props) => {
    const LoginStates = useSelector(state => state.loginActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    const MessageActions = bindActionCreators(messageActions, dispatch);

    const [state, setState] = useState({
        userId: LoginStates.get('userId'),
        userName: LoginStates.get('userName'),
        userCompany: LoginStates.get('companyName'),
        userPhoneNum: '',
        userEmail:'',
        userSendOtpType: null,
        admin: LoginStates.get('admin'),
        sessionID: LoginStates.get('sessionID'),
        userPassword: '',
        newPassword: '',
        passwordCfm: '',
        newPwCheck: 'default',
        reNewPwCheck: 'default',
        phoneCheck: 'default',
        otpTypePhoneNbDisplay: LoginStates.get('userSendOtpType') == '2' ? '' : 'none',
        userEmailDisplay: 'none',
        isUseEmail: false,
        emailCheck: 'default',
        isVerify: false,
    });
    const [prevUserInfo, setPrevUserInfo] = useState();

    useEffect(() => {
        let userId = LoginStates.get('userId');
        if (userId != '') {
            setUserInfo(userId);
        }
    }, []);

    useEffect(() => {
        let userId = LoginStates.get('userId');
        if (userId != '' && prevUserInfo != userId) {
            setUserInfo(userId);
            setPrevUserInfo(userId);
        }
    }, [LoginStates.get('userId')]);

    const setUserInfo = (userId) => {
        let UserInfo = {
            'UserId': userId
        }
        fetch('api/User/GetUserInfo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(UserInfo)
        }).then((response) => response.json())
            .then((data) => {
                setState(prev => { return {
                    ...prev,
                    userId: data.table[0].devUserID,
                    userName: data.table[0].devUserName,
                    userCompany: data.table[0].companyName,
                    userPhoneNum: data.table[0].phoneNumber,
                    userEmail: data.table[0].devUserID != data.table[0].email ? data.table[0].email : '',
                    userSendOtpType: data.table[0].sendOtpType != null ? data.table[0].sendOtpType.toString() : '',
                    admin: data.table[0].admin,
                    otpTypePhoneNbDisplay: data.table[0].sendOtpType == 2 ? '' : 'none',
                    userEmailDisplay: data.table[0].devUserID != data.table[0].email ? '' : 'none',
                    isUseEmail: data.table[0].devUserID != data.table[0].email ? true : false
                }});
            });
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const id = e.target.id;
        let userEmail = state.userEmail;
        let newPassword = state.newPassword;
        let newPasswordCheck = state.passwordCfm;
        let phoneNumber = state.userPhoneNum != undefined ? state.userPhoneNum : '';

        const upperLetters = /[A-Z]/;
        const lowerLetters = /[a-z]/;
        const number = /[0-9]/;
        const special = /[~!@#$%^&*()_+|<>?:{}]/;
        let emailExpression = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        const phoneNumberExpression = /(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;

        setState(prev => {return { ...prev, [name]: value }});   //state값이 업데이트되는 기준은 이벤트가 끝까지 돌고 나서

        if (name == 'userPhoneNum') {
            let newValue = value.replace(/[^0-9]/g, '');
            phoneNumber = newValue;
            setState(prev => {return { ...prev, userPhoneNum: newValue }});
        }
        if (id == 'email') {
            if (e.target.checked) {
                setState(prev => {return { ...prev, isUseEmail: true, userEmailDisplay: '' }});
            } else {
                setState(prev => {return { ...prev, isUseEmail: false, userEmailDisplay: 'none', userEmail: '' }});
            }
        }
        if (name == 'newPassword')
            newPassword = value;
        else if (name == 'passwordCfm')
            newPasswordCheck = value;
        else if (name == 'userEmail')
            userEmail = value;

        //email 체크
        if (userEmail.length == 0)
            setState(prev => {return { ...prev, emailCheck: 'default' }});
        else if (emailExpression.test(userEmail) == true)
            setState(prev => {return { ...prev, emailCheck: 'right' }});
        else if (emailExpression.test(userEmail) == false)
            setState(prev => {return { ...prev, emailCheck: 'wrong' }});

        //새 비밀번호 체크
        if (newPassword.length == 0) {
            newPasswordCheck = '';
            setState(prev => {return { 
                ...prev,
                PasswordCfm: '',
                newPwCheck: 'default'
            }});
        }
        else if (newPassword.length >= 7 && upperLetters.test(newPassword) && lowerLetters.test(newPassword) &&
            special.test(newPassword) && number.test(newPassword))
            setState(prev => {return { ...prev, newPwCheck: 'right' }});
        else
            setState(prev => {return { ...prev, newPwCheck: 'wrong' }});

        //새 비밀번호 다시 체크
        if (newPasswordCheck.length == 0)
            setState(prev => {return { ...prev, reNewPwCheck: 'default' }});
        else if (newPasswordCheck == newPassword)
            setState(prev => {return { ...prev, reNewPwCheck: 'right' }});
        else if (newPasswordCheck != newPassword)
            setState(prev => {return { ...prev, reNewPwCheck: 'wrong' }});

        //핸드폰 체크
        if (name == 'userPhoneNum') {
            if (phoneNumber.length == 0) {
                setState(prev => {return { ...prev, phoneCheck: 'default' }});
            } else if (phoneNumberExpression.test(phoneNumber)) {
                setState(prev => {return { ...prev, phoneCheck: 'right' }});
            } else {
                setState(prev => {return { ...prev, phoneCheck: 'wrong' }});
            }
        }
        setDisplay();
    };

    const setDisplay = () => {
        if (document.querySelector('input[type="radio"][name="userSendOtpType"]:checked') != null) {
            if (document.querySelector('input[type="radio"][name="userSendOtpType"]:checked').value == '2') {
                setState(prev => {return { ...prev, otpTypePhoneNbDisplay: ''}});
            } else {
                setState(prev => {return { ...prev, otpTypePhoneNbDisplay: 'none', phoneCheck: 'default', userPhoneNum: '' }});
            }
        }
    };

    const CheckClass = (num) => {
        if (num == 'default')
            return (null);
        else if (num == 'wrong')
            return (<div className='ico-check wrong'><i className='i-input-wrong'></i></div>);
        else if (num == 'right')
            return (<div className='ico-check'><i className='i-input-ok'></i></div>);
    };

    const ConditionPw = (num) => {
        if (num == 'default' || num == 'right')
            return (<p></p>);
        else
            return (<div className="pw-msg">비밀번호는 대문자, 소문자, 숫자, 특수문자 조합으로 7자 이상이어야 합니다.</div>);
    };

    const ChangePwClass = () => {
        const phoneNumberExpression = /(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
        if (state.newPwCheck != 'wrong' && state.reNewPwCheck != 'wrong' && state.userPassword != '' && state.phoneCheck != 'wrong' && state.emailCheck != 'wrong') {
            if (state.newPwCheck == 'right' && state.passwordCfm == '') {
                return (
                    <button className='btn-primary btn-dis'>저장</button>
                );
            } else if ((state.userSendOtpType == '2' && phoneNumberExpression.test(state.userPhoneNum)) || state.userSendOtpType != '2') {
                return (
                    <button onClick={Submit} className='btn-primary'>저장</button>
                );
            } else {
                return (
                    <button className='btn-primary btn-dis'>저장</button>
                );
            }
        }
        else {
            return (
                <button className='btn-primary btn-dis'>저장</button>
            );
        }
    };

    const Submit = (e) => {
        e.preventDefault();
        if(checkChangePassword() && !state.isVerify) {
            showToast('danger', "메일 인증이 완료되지 않았습니다.", 3000);
            return;
        }
        const userInfo = {
            userId: encodeURI(state.userId),
            userName: encodeURI(state.userName),
            userPassword: encodeURI(state.userPassword),
            companyName: encodeURI(state.userCompany),
            newPassword: encodeURI(state.newPassword),
            userPhoneNum: state.userSendOtpType == '2' ? state.userPhoneNum : '',
            userEmail: state.userEmail == '' ? encodeURI(state.userId) : encodeURI(state.userEmail),
            userSendOtpType: state.userSendOtpType,
            sessionID: state.sessionID,
            admin: state.admin
        };
        fetch('api/User/UpdateInfo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(userInfo)
        }).then(async (response) => { return response.json() })
            .then(async (responseData) => {
                if (responseData.status == "Wrong Current Password") {
                    showToast('danger', responseData.message, 3000);
                    setState(prev => {return { ...prev, currentPassword: '' }});
                }
                else if (responseData.status == "Success Changing Password") {
                    const info = responseData.userInformation;
                    const result = {                        
                        userId: info != null ? info.userId : '',
                        userName: info != null ? info.userName : '',
                        message: responseData.message,
                        result: responseData.status,
                        sessionID: info != null ? info.sessionID : '',
                        companyName: info != null ? info.companyName : '',
                        logindate: new Date().toISOString().slice(0, 10),
                        admin: info != null ? info.admin : ''
                    };
                    await LoginActions.setResultLogin(result);
                    showToast('success', responseData.message, 3000);
                    setState(prev => {return { ...prev, userPassword: '', newPassword: '', passwordCfm: '' }});
                }
            });
    };

    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    };

    const SendOtpTypeClass = () => {
        if (state.otpTypePhoneNbDisplay != 'none') {
            return (
                <dl className="otpTypePhoneNb" id="otpTypePhoneNb">
                    <dt><label htmlFor="otpTypePhoneNb">휴대폰 번호<span className="tiny">(숫자만 입력하세요)</span></label></dt>
                    <dd>
                        <div className="form-input">
                            <input
                                name='userPhoneNum'
                                value={state.userPhoneNum == undefined ? '' : state.userPhoneNum}
                                onChange={e => handleChange(e)}
                                maxLength='11'
                                placeholder='2차인증 카카오톡 선택 시 필수'
                                id="otpTypePhoneNb" />
                            {CheckClass(state.phoneCheck)}
                        </div>
                    </dd>
                </dl>
            );
        }
    };

    const UserEmailClass = () => {
        if (state.userEmailDisplay != 'none') {
            return (
                <div className="form-input">
                    <input
                        name='userEmail'
                        value={state.userEmail}
                        onChange={e => handleChange(e)}
                        placeholder='알림을 수신할 이메일을 입력하세요.'
                    />
                    {CheckClass(state.emailCheck)}
                </div>
                );
        }
    }

    const checkChangePassword = () => {
        return (state.userPassword !== '' &&
            state.newPassword !== '' &&
            state.passwordCfm !== '' && 
            state.newPwCheck === 'right' &&
            state.reNewPwCheck === 'right');
    };

    const AfterCheckAuth = () => {
        setState(prev => { return { 
            ...prev,
            isVerify: true
        }});
    };

    return (
        <div className="content">
            <div className="myinfo">
                <h2>내 정보</h2>
                <section className="my-info-latest">
                    <div className="user-info">
                        <div className="int-ico">{LoginStates.get('userId').substring(0, 1).toUpperCase()}</div>
                        <div className="info-wrap">
                            <p className="email">{LoginStates.get('userId')}</p>
                            <p className="name">{LoginStates.get('userName')}</p>
                            <div className="affl">
                                <p className="name">{LoginStates.get('companyName')}</p>
                                {LoginStates.get('companySeq') == '128' ? <p className="name">{LoginStates.get('deptName')}</p> : ''}
                                <p className="name">{LoginStates.get('kindName')}</p>
                            </div>
                        </div>
                    </div>
                    <dl>
                        <dt>비밀번호&nbsp;<span className="required">*</span></dt>
                        <dd><div className="form-input">
                            <input name="userPassword"
                                type='password'
                                onChange={e => handleChange(e)}
                                placeholder="정보 변경 시 필수로 입력하세요."
                                value={state.userPassword}
                            />
                        </div></dd>
                    </dl>
                    <dl>
                        <dt>새 비밀번호</dt>
                        <dd><div className="form-input">
                            <input name="newPassword"
                                type="password"
                                onChange={e => handleChange(e)}
                                placeholder="새 비밀번호 (대문자,소문자,숫자,특수문자조합 7자 이상)"
                                value={state.newPassword}
                            />
                            {CheckClass(state.newPwCheck)}
                            {ConditionPw(state.newPwCheck)}
                        </div></dd>
                    </dl>
                    <dl>
                        <dt>비밀번호 확인</dt>
                        <dd><div className="form-input">
                            <input name="passwordCfm"
                                type="password"
                                onChange={e => handleChange(e)}
                                placeholder="새 비밀번호 확인"
                                value={state.passwordCfm}
                            />
                            {CheckClass(state.reNewPwCheck)}
                        </div></dd>
                    </dl>
                    {checkChangePassword() &&
                        <dl>
                            <dt>이메일 인증&nbsp;<span className="required">*</span></dt>
                            <dd>
                                <EmailVerificationPanel buttonTitle={'이메일로 인증번호 받기'} state={{
                                    userId: state.userId,
                                    userName: state.userName,
                                    userEmail: state.userEmail !== '' ? state.userEmail : state.userId
                                }} fnAfterCheckAuth={AfterCheckAuth} />
                            </dd>
                        </dl>
                    }
                    <dl className="otpType">
                        <dt>2차 인증</dt>
                        <dd>
                            <div className="select-radio">
                                <input id="otpTypeKakao" name='userSendOtpType' value='2' type='radio' onChange={e => handleChange(e)} checked={state.userSendOtpType == '2'} />
                                <label htmlFor="otpTypeKakao">카카오톡</label>
                                <input id="otpTypeEmail" name='userSendOtpType' value='1' type='radio' onChange={e => handleChange(e)} checked={state.userSendOtpType == '1'} />
                                <label htmlFor="otpTypeEmail">이메일</label>
                                <input id="otpTypeNo" name='userSendOtpType' value='0' type='radio' onChange={e => handleChange(e)} checked={state.userSendOtpType == '0' || state.userSendOtpType == ''} />
                                <label htmlFor="otpTypeNo">사용안함</label>
                            </div>
                            {SendOtpTypeClass()}
                        </dd>
                    </dl>
                    <dl className="user-email" id="userEmail">
                        <dt>메일 주소 추가</dt>
                        <dd>
                            <div className="check-wrap"><input type="checkbox" id="email" checked={state.isUseEmail} onChange={e => handleChange(e)} /><label htmlFor="email" className="toggle"></label><label htmlFor="email" className="txt">아이디 메일주소 외에 다른 메일로 인증 및 알림 메일을 받을래요.</label></div>
                            <p className="info-txt">법인계정 등 아이디 계정에 접근할 수 없는 특수한 경우에만 체크하세요.</p>
                            { UserEmailClass() }
                        </dd>
                    </dl>
                    <div className="btn-wrap">
                        {ChangePwClass()}
                    </div>

                    <div className="info-box-kstudio">
                        K Developers의 계정으로 영림원소프트랩의 개발 툴인 K-Studio를 사용할 수 있습니다.
                        <br /><br /><ol>
                            <li>K-Studio는 라이선스를 구매한 고객사에게만 제공되며, 라이선스를 보유하지 않은 사용자는 사용할 수 없습니다.</li>
                            <li>K-Studio로 생성된 모든 산출물은 라이선스를 구매한 고객사 내부에서만 사용이 허용됩니다.</li>
                            <li> K-Studio로 생성한 산출물을 불법적으로 복제, 배포, 판매하는 것은 엄격히 금지됩니다.</li>
                        </ol>


                    </div>
                </section>
            </div>
        </div>
    );
};

export default UserInfo;