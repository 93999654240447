import React, { useState, useEffect, Fragment, useRef } from 'react'

const Filter = (props) => {
    const [filterOptions, setFilterOptions] = useState([]);
    const [outsideClick, setOutsideClick] = useState(false);

    useEffect(() => {
        let filters = [];
        if (props.comboBox != undefined) {
            filters.push(
                ...props.comboBox.map(x => {
                    return { type: "comboBox", name: x.name, options: x.options, current: x.current ?? "", listVisible: false };
                })
            );
        }
        if (props.multiKeywords != undefined) {
            filters.push(
                ...props.multiKeywords.map(x => {
                    return { type: "multiKeywords", name: x.name, values: [], listVisible: false, title: x.title, result: [] };
                })
            );
        }
        if (props.dateFromTo != undefined) {
            filters.push(
                ...props.dateFromTo.map(x => {
                    return { type: "dateFromTo", name: x.name, options: x.options, current: "", period: { from: "", to: "" }, listVisible: false, inputVisible: false };
                })
            );
        }
        if (props.searchKeyword != undefined) {
            filters.push({ type: "searchKeyword", name: props.searchKeyword.name, value: "", title: props.searchKeyword.title, inputVisible: false });
        }
        setFilterOptions(filters);
    }, []);
    useEffect(() => {
        if (outsideClick) {
            filterOptions.forEach(x => {
                if (x.listVisible != undefined) {
                    x.listVisible = false;
                }
                if (x.inputVisible != undefined) {
                    x.inputVisible = false;
                }
            });
            // setFilterOptions(
            //     filters
            // );
        }
        setOutsideClick(false);
    }, [outsideClick]);
    
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOutsideClick(true);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        props.setListView(getFilteringData());
    }, [props.listData])

    useEffect(() => {
        props.setListView(getFilteringData());
    }, [filterOptions]);

    const getFilteringData = () => {
        let data = props.listData;
        filterOptions.forEach(option => {
            if (option.type == "multiKeywords") {
                if (option.values.length > 0) {
                    data = data.filter(row =>
                        option.values.includes(row[option.name])
                    );
                }
            }
            else if (option.type == "searchKeyword") {
                let result = [];
                if (option.value == '') {
                    return data;
                }
                else {
                    option.name.forEach(name => {
                        result.push(
                            ...data.filter(row => {
                                if (row[name].includes(option.value))
                                    return row;
                            })
                        );
                    });
                }
                data = result;
            }
            else if (option.type == "comboBox") {
                if (option.current != "") {
                    data = data?.filter(row =>
                        option.current == row[option.name]
                    );
                }
            }
            else if (option.type == "dateFromTo") {
                var date = new Date();
                if (option.current != "" && option.current != "searchdate") {
                    date.setDate(date.getDate() - option.current)
                    data = data.filter(row => {
                        return date < new Date(row[option.name])
                    });
                } else if (option.period.from != "" || option.period.to != "") {
                    const fromDate = option.period.from;
                    const toDate = option.period.to;
                    if (fromDate != "")
                        data = data.filter(row => {
                            return new Date(fromDate) < new Date(row[option.name])
                        });
                    if (toDate != "")
                        data = data.filter(row => {
                            return new Date(row[option.name]) < new Date(toDate)
                        });
                }
            }
        });
        return data;
    }
    const matchName = (name, keyword) => {
        if (keyword == "") return false;
        return name?.toLowerCase().includes(keyword.toString().toLowerCase());
    };
    //ComboBox Render (dynamic으로 변경)
    //날짜 유형과 항목 유형으로 분리   
    const renderComboDateFilter = () => {
        let render = [];
        // const [visible, setVisible] = useState(false);
        // const [inputVisible, setInputVisible] = useState(false);

        const select = (e, option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "dateFromTo") {
                    if (f.name == option.name) {
                        f.current = e.target.attributes.applystatus.nodeValue;
                        f.period.from = "";
                        f.period.to = "";
                        f.inputVisible = false;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        const clickSearchdate = (e, option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "dateFromTo") {
                    if (f.name == option.name) {
                        f.current = e.target.attributes.applystatus.nodeValue;
                        f.inputVisible = !f.inputVisible
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        const dateChange = (e, flag, option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "dateFromTo") {
                    if (f.name == option.name) {
                        f.period[flag] = e.target.value;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        const clickList = (option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "dateFromTo") {
                    if (f.name == option.name) {
                        f.listVisible = !f.listVisible;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        render = filterOptions.map((f, i) => {
            if (f.type == "dateFromTo") {
                let selectedName = "";
                if (f.current == "searchdate") {
                    selectedName = "조회";
                } else {
                    const selectedItem = f.options.filter(o => o.key == f.current);
                    selectedName = selectedItem[0].name;
                }
                return (
                    <div key={'combodate' + i}>
                        <div key={'combodateselectbox' + i} className="selectbox" onClick={e => { clickList(f); }}>
                            {selectedName}
                            <i className="i-expand-arrow" onClick={e => { clickList(f); }}></i>
                            <ul className="option-list" style={{ display: f.listVisible ? '' : 'none' }}>
                                {f.options.map((o, j) => {
                                    return (
                                        <li key={'combodateoption' + j} applystatus={o.key} onClick={e => select(e, f)}>{o.name}</li>
                                    );
                                })}
                                <li key={'searchdate' + i} applystatus="searchdate" onClick={e => clickSearchdate(e, f)}>조회</li>
                            </ul>
                        </div>
                        <div key={'dateinput' + i} className="date-input" style={{ display: f.inputVisible ? '' : 'none' }}>
                            <input type="date" max="9999-12-31" onChange={e => dateChange(e, 'from', f)} value={f.period.from} />
                            <span className="txt">~</span>
                            <input type="date" max="9999-12-31" onChange={e => dateChange(e, 'to', f)} value={f.period.to} />
                        </div>
                    </div>
                );
            }
        })
        return render.filter(x => x != undefined);
    }
    const renderComboFilter = () => {
        let render = [];
        const select = (e, option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "comboBox") {
                    if (f.name == option.name) {
                        f.current = e.target.attributes.applystatus.nodeValue;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        const clickList = (option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "comboBox") {
                    if (f.name == option.name) {
                        f.listVisible = !f.listVisible;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        render = filterOptions.map((f, i) => {
            if (f.type == "comboBox") {
                const selectedItem = f.options.filter(o => o.key == f.current);
                return (
                    <div key={'comboselectbox' + i} className="selectbox" onClick={e => { clickList(f); }}>
                        {selectedItem[0].name}
                        <i className="i-expand-arrow" onClick={e => { clickList(f); }}></i>
                        <ul className="option-list" style={{ display: f.listVisible ? '' : 'none' }}>
                            {f.options.map((o, j) => {
                                return (
                                    <li key={'combooption' + j} applystatus={o.key} onClick={e => select(e, f)}>{o.name}</li>
                                );
                            })}
                        </ul>
                    </div>
                );
            }
        })
        return render.filter(x => x != undefined);
    }
    const renderMultiKeywords = () => {
        const deleteKeyword = (option, keyword) => {
            const filter = filterOptions.map(f => {
                if (f.type == "multiKeywords") {
                    if (f.name == option.name) {
                        f.values = f.values.filter(x => x != keyword);
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        const addKeyword = (option, keyword) => {
            const filter = filterOptions.map(f => {
                if (f.type == "multiKeywords") {
                    if (f.name == option.name) {
                        f.values.push(keyword)
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        const clickFilter = (option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "multiKeywords") {
                    if (f.name == option.name) {
                        f.listVisible = !f.listVisible;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }


        const searchKeyword = (e, option) => {
            let arry = [];
            let displayArr = [];
            let results = props.listData.filter(item => matchName(item[option.name], e.target.value.trim()));
            for (let i = 0; i < results.length; i++) {
                if (!arry.includes(results[i][option.name].trim())) {
                    arry.push(results[i][option.name]);
                }
            }
            for (let i = 0; i < arry.length; i++) {
                displayArr.push(
                    <li key={i} onClick={e => addKeyword(option, arry[i])}>
                        <i className="i-company"></i>
                        <span className="cpn-name">{arry[i]}</span>
                    </li>
                );
            }
            const filter = filterOptions.map(f => {
                if (f.type == "multiKeywords") {
                    if (f.name == option.name) {
                        f.result = displayArr;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        let render = [];
        if (filterOptions.length > 0)
            render = filterOptions.map((x, i) => {
                if (x.type == "multiKeywords") {
                    return (
                        <div className="search-cpn-wrap" key={'multikeyword' + i}>
                            <div className={x.values.length > 0 ? "search-cpn open" : "search-cpn"}
                                id={x.name}
                                onClick={e => {
                                    clickFilter(x);
                                }}>
                                <div className="cpn-item">
                                    <i className="i-company"></i>
                                    <span className="cpn-name">{x.values[0]}</span>
                                </div>
                                {x.values.length > 1 ? <span className="count">외 {x.values.length - 1}개</span> : null}
                                <span className="txt">{x.title}필터</span>
                                <i className="i-company"></i>
                            </div>
                            <div className="cpn-list" style={{ display: x.listVisible ? "" : "none" }} >
                                <ul className="selected" style={{ display: x.values.length > 0 ? '' : 'none' }}>
                                    {x.values.map(keyword => {
                                        return (
                                            <li className="cpn-item">
                                                <i className="i-company"></i>
                                                <span className="cpn-name">{keyword}</span>
                                                <button className="btn-delete"
                                                    onClick={() => deleteKeyword(x, keyword)} >
                                                    <i className="i-delete-item"></i>
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className="cpn-search-input">
                                    <input type="text"
                                        onClick={e => { e.target.value = ""; searchKeyword(e, x) }}
                                        onChange={(e) => searchKeyword(e, x)} placeholder={x.title + "검색"} />
                                </div>
                                <ul className="cpn-search-result">
                                    {x.result}
                                </ul>
                            </div>
                        </div>
                    );
                }
            });

        return render.filter(x => x != undefined);
    }
    const renderSearchKeyword = () => {
        let keyword = '';
        const searchOpen = (option) => {
            const filter = filterOptions.map(f => {
                if (f.type == "searchKeyword") {
                    if (f.name == option.name) {
                        f.inputVisible = !f.inputVisible;
                    }
                }
                return f;
            })
            setFilterOptions(
                filter
            );
        }
        const setSearch = (e, option) => {
            keyword = e.target.value;
            let data = props.listData;
            let newdata = [];
            newdata = filterOptions.map(x => {
                if (x.type == "searchKeyword") {
                    if (x.name == option.name) {
                        x.value = keyword;
                    }
                }
                return x;
            });
            setFilterOptions(
                newdata
            );

        }
        let render = [];
        if (filterOptions.length > 0)
            render = filterOptions.map((x, i) => {
                if (x.type == "searchKeyword") {
                    return (
                        <div key={'searchkeyword' + i} className={"search-name " + (x.inputVisible ? "open" : "")}>
                            <input type="text" onChange={e => setSearch(e, x)} placeholder={x.title} />
                            <span className="txt">검색</span>
                            <i className="i-search-filter" onClick={e => searchOpen(x)}></i>
                        </div>
                    );
                }
            });
        return render.filter(x => x != undefined);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    return (
        <div key='filter' className="filter" ref={wrapperRef}>
            {renderComboDateFilter()}
            {renderComboFilter()}
            {renderMultiKeywords()}
            {renderSearchKeyword()}
        </div>
    );
}
export default Filter; 