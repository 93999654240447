import React, { useState, useEffect } from 'react'
import PopUp from '../../Common/PopUp';
import { FileArea, handleUpload } from '../../Common/FileUploader';

export const EditProperty = (props) => {
    const [path, setPath] = useState('');
    const [morePopUp, setMorePopUp] = useState(null);
    const [type, setType] = useState('md');
    const [isUpload, setIsUpload] = useState(false);

    useEffect(() => {
        if (props.currentItem != null)
            if (props.currentItem.path != null) {
                const array = props.currentItem.path.split('/');
                let manualPath = '';
                for (const name of array) {
                    manualPath += ' > ' + name
                }
                setPath(manualPath)
            }
    }, [props.contentInfo])

    useEffect(() => {
        if (props.currentItem != null)
            if (props.currentItem.path != null) {
                const array = props.currentItem.path.split('/');
                let manualPath = '';
                for (let i = 0; i < array.length; i++) {
                    if (i === array.length - 1)
                        manualPath += ' > ' + props.contentInfo?.contentName;
                    else 
                        manualPath += ' > ' + array[i];
                }
                setPath(manualPath)
            }
    }, [props.contentInfo?.contentName]);

    const StartEdit = (e) => {
        e.preventDefault();
        //편집 시작시 편집 데이터(Edit Dump)를 DB에 생성한다.
        // 모든 작업이 성공이면 편집 모드로 변경
        props.EditMode();

    }
    const CancleEditClick = (e) => {
        e.preventDefault();
        const CancleEdit = (e) => {
            props.EditMode()
            setMorePopUp(null);
        }
        setMorePopUp(
            <div className="msg-popup discard-changes">
                <div className="box">
                    <p className="title">편집 취소</p>
                    <p className="single-msg">모든 변경사항이 사라집니다.</p>
                    <div className="btn-wrap">
                        <button className="btn-secondary" onClick={e => setMorePopUp(null)}>취소</button>
                        <button className="btn-primary" onClick={e => CancleEdit(e)}>확인</button>
                    </div>
                </div>
            </div>
        );
        // 모든 작업이 성공이면 뷰 모드로 변경
        //props.EditMode();
    }
    const MoreButtonClick = (e) => {
        e.preventDefault();
        setMorePopUp(
            <div className="layer-list manual-more-list">
                <div className="box">
                    <ul>
                        <li><a onClick={e => CopyToMyDocumentClick(e)}>내 문서로 복제</a></li>
                        <li className="delete-doc"><a onClick={e => DeleteClick(e)}>삭제</a></li>
                    </ul>
                </div>
            </div>
        );
    }
    const CopyToMyDocumentClick = (e) => {
        e.preventDefault();
        setMorePopUp(
            <div className="msg-popup copy-doc">
                <div className="box">
                    <p className="title">내 문서함에 복제</p>
                    <div className="input-wrap doc-title">
                        <label>문서 제목</label>
                        <input type="text" value="현재제목여기에" />
                    </div>
                    <div className="btn-wrap">
                        <button className="btn-secondary" onClick={e => setMorePopUp(null)}>취소</button>
                        <button className="btn-primary">확인</button>
                    </div>
                </div>
            </div>
        );
    }
    const DeleteClick = (e) => {
        e.preventDefault();
        setMorePopUp(
            <div className="msg-popup delete-doc">
                <div className="box">
                    <p className="title">삭제</p>
                    <p className="single-msg">'개발권한 신청방법' 문서를 정말 삭제하시겠습니까?</p>
                    <div className="input-wrap">
                        <label>삭제 사유(필수)</label>
                        <input type="text" />
                    </div>
                    <div className="btn-wrap">
                        <button className="btn-secondary-t3" onClick={e => setMorePopUp(null)}>취소</button>
                        <button className="btn-primary-t3">확인</button>
                    </div>
                </div>
            </div>
        );
    }
    const EditButtons = () => {
        if (props.ViewMode)
            return (
                <div className="btn-wrap" >
                    <button className="btn-edit" onClick={e => StartEdit(e)}>편집 시작</button>
                    <button className="btn-more" onClick={e => MoreButtonClick(e)}><i className="i-btn-more"></i></button>
                </div>
            );
        else
            return (
                <div className="btn-wrap">
                    <span className="txt-saved">{/*10초 전에 자동저장되었습니다.*/}{/*{text}*/}</span>
                    <button className="btn-discard" onClick={e => CancleEditClick(e)} >편집 취소</button>
                    <button className="btn-manual-submit" onClick={e => SubmitClick(e)}>배포하기</button>
                    <button className="btn-more" onClick={e => MoreButtonClick(e)}><i className="i-btn-more"></i></button>
                </div>
            );
    }

    const handleSubmit = () => {
        // const collator = new Intl.Collator("ko");
        const node = props.currentItem;
        const files = props.files;
        const datas = props.data;
        const jsonName = props.path;

        // if (files.files.length > 0) {
        //     for (let i = 0; i < files.files.length; i++) {
        //         const type = files.files[i].type;
        //         const isNode = collator.compare(node.item.module, files.files[i].module);
        //         if (type === 'md' || type === 'pdf') {
        //             if (!node.item.hasOwnProperty('children')) {
        //                 // node.item.module = files.files[i].module;
        //                 if (isNode === 0) continue;
        //                 // node.item.children = [{ module: files.files[i].module, default: files.files[i].module, directory: 'path', type: type === 'pdf' ? type : '' }];
        //                 node.item.children = [{ module: files.files[i].module, directory: 'path', type: type === 'pdf' ? type : '' }];

        //             } else {
        //                 let isExist = false;
                        
        //                 for (let j = 0; j < node.item.children.length; j++) {
        //                     const isChildren = collator.compare(node.item.children[j].module, files.files[i].module);
        //                     if (isNode === 0 || isChildren === 0) isExist = true;
        //                 }
        //                 if (isExist) continue;
        //                 // node.item.children.push({ module: files.files[i].module, default: files.files[i].module, directory: 'path', type: type === 'pdf' ? type : '' });
        //                 node.item.children.push({ module: files.files[i].module, directory: 'path', type: type === 'pdf' ? type : '' });

        //             }
        //         }
        //     }
        // }

        const filePath = path.replaceAll(" > ", "/");
        const result = handleUpload(files, node.item.module, filePath, jsonName, datas);
        if (result) setMorePopUp(null);
    };

    const SubmitClick = (e) => {
        e.preventDefault();
        setMorePopUp(
            <div className="msg-popup update-doc">
                <div className="box">
                    <p className="title">배포하기</p>
                    <div className="info-wrap">
                        <p className="doc-path">ACE  {'>'}  개발자 가이드  {'>'}  권한 및 보안</p>
                        <p className="doc-title">개발권한 신청방법</p>
                        <p className="last-updated">2021.05.31 11:00:22 서길동님이 마지막으로 배포했습니다.</p>
                    </div>
                    <div className="input-wrap">
                        <label>배포 설명</label>
                        <input type="text" />
                    </div>
                    <div className="btn-wrap">
                        <button className="btn-secondary" onClick={e => setMorePopUp(null)}>취소</button>
                        <button className="btn-primary" onClick={handleSubmit}>확인</button>
                    </div>
                </div>
            </div>
        );
    }

    const checkValue = (e) => {
        const txt = e.target.value;
        const check = ['\\', '/', ':', '*', '?', '"', '<', '>', '|'];
        for (let char of check) {
            if (txt.includes(char)) {
                return false;
            }
        }

        const regex = /[\\/:*?"<>|]/g;
        const matches = txt.match(regex);
        if (matches) return false;

        return true;
    }
    const EditTitle = () => {
        if (props.ViewMode)
            return (
                <div className="title">
                    <p className="doc-title">{props.contentInfo.contentName}</p>
                    <p className="doc-id">ID123-2123</p>
                </div>
            );
        else
            return (
                <div className="title edit">
                    <input type="text" value={props.contentInfo.contentName} 
                        onChange={(e) => {
                            if (!checkValue(e)) {
                                alert('이름에는 다음 문자열을 사용할 수 없습니다. \\, /, :, *, ?, ", <, >, |');
                                return;
                            }
                            let node = props.currentItem;
                            node.item.module = e.target.value;
                            props.setContentInfo({...props.contentInfo, contentName: e.target.value});
                            props.setCurrentItem(node);
                 }}/>
                    <p className="doc-id">ID123-2123</p>
                </div>
            );
    };

    useEffect(() => {
        switch(type) {
            case 'md':
                break;
            case 'pdf':
                break;
            default:
                break;
        }
    }, [type]);

    return (
        <div className="property">
            <header>
                <div className="path">{`매뉴얼 관리 ${path}`} </div>
                {EditButtons()}
                <PopUp content={morePopUp} setContent={setMorePopUp} />
            </header>
            {EditTitle()}
            {!props.ViewMode &&
                <div className="type">
                    <div className="radio-item">
                        <input type="radio" id="md" name="content-type" defaultChecked checked={type === 'md'} value={"md"} onChange={() => setType('md')} />
                        <label htmlFor="md">MD</label>
                    </div>
                    <div className="radio-item">
                        <input type="radio" id="pdf" name="content-type" checked={type === 'pdf'} value={"pdf"} onChange={() => setType('pdf')} />
                        <label htmlFor="pdf">PDF</label>
                    </div>
                </div>
            }
        </div>
    );
}

export const Intro = (props) => {   
    useEffect(() => {
        fetch('datas/menu.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Cache-Control': 'no-cache'
            }
        }).then((response) => response.json())
            .then(data => {
                props.setMenuData(data);
            });
    }, []);
    const setNavMenu = () => {
        const data = props.menuData.filter(n => n.type.toLowerCase() === "document");
        return(
            <ul className="select-ctgr">
                {data.map((op, index) => <li key={index}>
                    <button className="item" onClick={(e) => selectCategory(op.menuName, op.path.toLowerCase())}>
                        <div className="icon-wrap"><i className={op.icon}></i></div>
                        <h4 className="title">{op.menuName}</h4>
                        <p className="description" dangerouslySetInnerHTML={{ __html: op.description }}></p>
                    </button>
                </li>
                )}
            </ul>
        );
    }
    const selectCategory = (name, path) => {
        props.setMenuName(path);
        props.setCurrentCategory(name);
    };
    return (
        <>
            {
                props.visible &&
                <div className={`introWrap ${props.isPopUp}`}>
                    <div className="intro">
                        <p className="txt">매뉴얼 카테고리를 선택 해 주세요</p>
                        {setNavMenu()}
                        <div className="my-doc">
                            <a href={()=>{}}>
                                <div className="user-pf">Y</div>
                                내 문서함
                            </a>
                        </div>
                        <p className="txt">또는</p>
                        <button className="btn-primary-t2 btn-new-doc"
                            onClick={(e) => selectCategory(e, "MyDocEdit", "")}
                        >내 문서함에서 새 문서 만들기</button>
                    </div>
                </div >
            }
        </>
    );
}

export const ListHeader = (props) => {
    const handleClick = (e) => {
        e.preventDefault();
        props.ClickCategoryView(e);
    }
    return (
        <header>
            <button className="btn-ctgr" onClick={e => handleClick(e)}><i className="i-manual-ctgr"></i></button>
            <p className="title">{props.currentCategory}</p>
        </header>
    );
}

export const UploadArea = (props) => {
    const [fileList, setFileList] = useState([]);
    useEffect(() => {
        const contentInfo = props.ContentInfo;
        if (contentInfo.type != 'pdf') {
            let body = JSON.stringify({
                ContentType: contentInfo.contentType,
                ContentName: contentInfo.contentName,
                FolderName: contentInfo.folderName
            });
            fetch('api/Edit/GetFiles', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: body
            }).then(response => {
                return response.json()
            })
                .then(json => {
                    if(json != null)
                        setFileList(json);
                });
        }
    }, [props.ContentInfo]);

    const [files, setFiles] = useState([]);
    const [fileItemList, setFileItemList] = useState([]);

    useEffect(() => {
        //console.log(files);
        props.setFiles(files);
    }, [files]);

    if (props.isViewMode)
        return null;
    else
        return (
            <div className="upload-area">
                <div className="info">
                    <p className="txt"> 페이지에 삽입할 이미지<br />또는 파일을 업로드하세요</p>
                    {/*<a className="btn-select-file" href={() => { }}>파일 선택</a>*/}
                </div>
                <FileArea path={props.ContentInfo.contentName} setDatas={setFiles} setList={setFileItemList} />
                <ul className="file-list">
                    {/*<li>*/}
                    {/*    <div className="icon-wrap"><i className="i-filetype-img"></i></div>*/}
                    {/*    <span className="filename" title="파일명">업로드이미지.jpg</span>*/}
                    {/*    <div className="btn-wrap">*/}
                    {/*        <button className="btn-copy-url" title="경로 복사하기"><i className="i-copy-url"></i></button>*/}
                    {/*        <button className="btn-delete-file" title="파일 삭제하기"><i className="i-delete-file"></i></button>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <div className="icon-wrap"><i className="i-filetype-doc"></i></div>*/}
                    {/*    <span className="filename" title="파일명">업로드파일.doc</span>*/}
                    {/*    <div className="btn-wrap">*/}
                    {/*        <button className="btn-copy-url" title="경로 복사하기"><i className="i-copy-url"></i></button>*/}
                    {/*        <button className="btn-delete-file" title="파일 삭제하기"><i className="i-delete-file"></i></button>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {fileItemList}
                </ul>
            </div>
        );
}



