import React from 'react'

const PopUp = (props) => {
    document.getElementsByTagName('body')[0].style.overflow = '';
    const PopUpOff = (e) => {
        e.preventDefault();
        if (e.target.classList.length > 0) {
            if (e.target.classList[0] == 'msg-popup-wrap' && Array.from(e.target.children[0].classList).indexOf('update-company') == -1
                || (e.target.classList[0] == 'btn-close-popup' || e.target.classList[0] == 'i-close-popup')) {
                props.setContent(null);
            }
        }
    }
    if (props.content != null) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        return (
            <div className="msg-popup-wrap dim" onClick={e => PopUpOff(e)}>
                {props.content}
            </div>
        );
    }
    else
        return null;
}
export default PopUp;