import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as contentActions from '../../store/modules/contentActions';
import { bindActionCreators } from 'redux';

const PDFLink = (props) => {
    const [loading, setLoading] = useState('');
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    const ContentStates = useSelector(state => state.contentActions);

    const clickDownloadPdf = () => {
        if (loading != '') {
            return;
        } else {
            setLoading('loading');
        }
        const callMethod = 'DownloadDocument';
        const body = JSON.stringify({
            ContentType: ContentStates.get('contentType'),
            ContentName: ContentStates.get('contentName'),
            FolderName: ContentStates.get('folderName')
        });
        fetch('api/Document/' + callMethod, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/octet-stream',
                'Cache-Control': 'no-cache'
            },
            body: body
        }).then(response => {

            return response.blob()
        })
            .then(blob => {
                const data = URL.createObjectURL(blob) + "#view=FltW";
                const link = document.createElement('a');
                link.href = data;
                setLoading('');
                link.download = ContentStates.get('contentName') + '.pdf';

                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    URL.revokeObjectURL(data);
                    link.remove();
                }, 1000);
            })
    }

    return (
        <button className={"btn-download " + loading} onClick={clickDownloadPdf} title="문서 다운로드">
            <div className="loading">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
            </div>
            <div className="btn-content-wrap">
                <i className="i-file-download"></i>
            </div>
        </button>
    );
}
export default PDFLink;