import React, { useEffect, useState, useRef } from 'react';
// import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavMenu.css';

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import * as loginActions from '../store/modules/loginActions';
import * as messageActions from '../store/modules/messageActions';

import storage from './libs/storage';

export const NavMenu = (props) => {
  
  //page
  const location = useLocation();
  const history = useNavigate();
  //redux
  const LoginStates = useSelector(state => state.loginActions);
    
  const dispatch = useDispatch();
  const LoginActions = bindActionCreators(loginActions, dispatch);
  const MessageActions = bindActionCreators(messageActions, dispatch);

  const [menuList, setMenuList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showMenuVisible, setShowMenuVisible] = useState(false);
  const [searchExpand, setSearchExpand] = useState("header-search");
  const [searchContent, setSearchContent] = useState("");
  const [menuMode, setMenuMode] = useState('');

  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && event.target.id != 'btnMyPage') {
        clickButtonSetting();
    }
}

  const searchIcon = () => {
    if (searchExpand == "header-search")
      setSearchExpand("header-search expand");
    else if (searchExpand == "header-search expand") {
        if (searchContent == "")
          setSearchExpand("header-search");
        else {
            showToast('success', 'searching...');
            history("/Search", {
                state: searchContent
            });
            setSearchContent('');
        }   
    }
  };

  useEffect(() => {
    if (props.menuData.length > 0) {
      setTopMenu(props.menuData);
    } 
  }, [props.menuData, LoginStates.get('sessionID')]);

  const checkAdmin = () => {
    const moveToAdmin = () => {
        showMenu();
        history("/Manager");
    }
    const adminList = LoginStates.get('admin').split(/,/g);      
      const gradeList = props.adminGrade;     
    const isAdmin = adminList.filter((item) => gradeList.includes(item));
    if (isAdmin.length > 0) {
        return (
            <button className="btn-admin" onClick={moveToAdmin}><i className="i-admin"></i></button>
        );
    }
  };

  const clickButtonSetting = () => {
    setShowMenuVisible(!showMenuVisible);
  };

  const setTopMenu = (menuData) => {
    let menuList = [], index = 0, companySeq = '0', companyType='0', userId = '', userAuth = '';
    if (LoginStates != null) {
        companySeq = LoginStates.get('companySeq');
        companyType = LoginStates.get('companyType');
        userId = LoginStates.get('userId');
        userAuth = LoginStates.get('admin');
    }
    if (companySeq != '128' && companySeq != '129' && companySeq != '130' && menuData != null && userId.indexOf('@ksystem.co.kr') == -1) {
        menuData = menuData.filter(x => x.path != 'DM');
    }
    if (menuData != null && menuData.length > 0) {            
      for (let i = 0; i < menuData.length; i++) {
          if (menuData[i].auth != undefined) {
              if (userAuth.split(',').filter(x => menuData[i].auth.indexOf(x) > -1).length > 0) {
                  menuList.push(
                      <li key={index++} id={menuData[i].path.toLowerCase()} style={{ display: menuData[i].visible ? '' : 'none' }}>
                          <Link onClick={showMenu} to={menuData[i].disable ? '#' : `/${menuData[i].path}`}>{menuData[i].menuName}</Link>
                      </li>
                  );
              }
              else if (companyType == '9') {
                if (menuData[i].auth.indexOf('Ylw') > -1) {
                    menuList.push(
                        <li key={index++} id={menuData[i].path.toLowerCase()} style={{ display: menuData[i].visible ? '' : 'none' }}>
                            <Link onClick={showMenu} to={menuData[i].disable ? '#' : `/${menuData[i].path}`}>{menuData[i].menuName}</Link>
                        </li>
                    );
                }
            }
          }
          else {
              menuList.push(
                  <li key={index++} id={menuData[i].path.toLowerCase()} style={{ display: menuData[i].visible ? '' : 'none' }}>
                      <Link onClick={showMenu} to={menuData[i].disable ? '#' : `/${menuData[i].path}`}>{menuData[i].menuName}</Link>
                  </li>
              );
          }
      }
    }
    setMenuList(menuList);
  };

  const handleChange = (e) => {
    setSearchContent(e.target.value);
  };

  const handleKeyDown = (e) => {
      if (e.key == 'Enter') {
          showToast('success', 'searching...');
          history("/Search", {
              state: e.target.value
          });
          setSearchContent('');
      }
  };
  const getLoginButton = () => {
    if (LoginStates.get('sessionID') == '') {
        return (
            <a className="logout" onClick={login} >로그인</a>
        );
    }
    else {
        return (
            <div className="btn-wrap">
                <Link to="/News" className="btn-news">
                    <i className="i-news"></i>
                    {/*<span className="i-new-dot"></span>*/}
                </Link>
                {checkAdmin()}
                <button className="btn-mypage" id="btnMyPage" onClick={clickButtonSetting}>{LoginStates.get('userId').substring(0, 1).toUpperCase()}{/*<span className="i-new-dot"></span>*/}</button>
                {
                    showMenuVisible
                        ? (
                            <div className="mypage-list" ref={wrapperRef}>
                                <ul>
                                    <li><Link onClick={clickButtonSetting} to={'/MyPage'}
                                        state={{
                                            userId: LoginStates.get('userId')
                                        }}
                                    >{LoginStates.get('userId')}</Link></li>
                                    {/*<li><Link to="/Applicant">기존가입신청관리<span className="count"></span></Link></li>*/}
                                    <li><a className="logout" onClick={logout}>로그아웃</a></li>
                                </ul>
                            </div>
                        )
                        : (
                            null
                        )
                }
            </div>
        );
      }
  }
  const showToast = (level, message, time) => {
      MessageActions.showToast({
          visible: true,
          level: level,
          message: message
      });
      setTimeout(() =>
          MessageActions.hideToast({ visible: false })
          , time == undefined ? 1500 : time);
  };
  const login = (e) => {
      e.preventDefault();
      props.goToLogin();
  }
  const logout = (e) => {
    e.preventDefault();
    const body = {
        userId: encodeURI(LoginStates.get('userId')),
        sessionID: encodeURI(LoginStates.get('sessionID'))
    }
    fetch('api/Login/DeleteUserSession', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(body)
    }).then((response) => response.json())
        .then((data) => {
            setShowMenuVisible(false);
            showToast('success', '로그아웃 되었습니다.', 1500);
            storage.remove('loginStates');
            LoginActions.init();
            //props.showMsgUserInfoValue(null);
            history("/");
        });
  }
  const showMenu = () => {
      if (menuMode == '' && window.innerWidth < 1100) {
          setMenuMode('menu-mode');
      } else {
        setMenuMode('');
      }
  };

  // return (
  //   <header>
  //     <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
  //       <NavbarBrand tag={Link} to="/">KDevelopers</NavbarBrand>
  //       <NavbarToggler onClick={toggleNavbar} className="mr-2" />
  //       <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
  //         <ul className="navbar-nav flex-grow">
  //           {menuData.map((menu, index) => {
  //             return (
  //               <NavItem key={index}>
  //                 <NavLink tag={Link} className="text-dark" to={menu.path}>{menu.menuName}</NavLink>
  //               </NavItem>
  //             )
  //           })}
  //         </ul>ƒƒ
  //       </Collapse>
  //     </Navbar>
  //   </header>
  // );
  return (
    <header className={menuMode}>
        <div className="gnb-wrap">
            <h1><Link to="/"><i className="logo-kd"/></Link></h1>
            <nav>
              <ul className="nav-list">
                {menuList}
              </ul>
            </nav>
            <div className="actions">
                <div className={searchExpand}>
                    <input
                        type="text"
                        name='searchContent'
                        value={searchContent}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        placeholder="검색어를 입력하세요"
                    />
                    <div className="btn-search">
                        <i className="i-search-s" onClick={searchIcon} />
                    </div>
                </div>
                {getLoginButton()}
                <button className="btn-list" onClick={showMenu}><i className="i-gnb-list"></i></button>
            </div>
        </div>
    </header>
  );
};

export default NavMenu;
