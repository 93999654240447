import React from "react";
import './htmlviewer.css';

const TopicViewer = ({
    data, className
}) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, 'text/html');
  
    const createElementFromNode = (node, key) => {
        if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent;
        }
        if (node.nodeType === Node.ELEMENT_NODE) {
            const tagName = node.tagName.toLowerCase();
            const props = {};
            Array.from(node.attributes).forEach(attr => {
                props[attr.name] = attr.value;
            });

            // props.indent = key;

            if (tagName === 'ul' && node.childNodes.length > 0 && node.childNodes[0].hasAttribute('data-checked')) {
                const isChecked = node.childNodes[0].getAttribute('data-checked');
                props["data-checked"] = isChecked === 'true';
                node.setAttribute('data-checked', isChecked);
            }

            // Handle data-checked attribute for <li> tags
            if (tagName === 'li' && node.hasAttribute('data-checked')) {
                const dataCheckedValue = node.getAttribute('data-checked');
                props.className = dataCheckedValue === 'true' ? 'checked' : 'unchecked';
            }
        
            // void elements는 children을 가질 수 없으므로 self-closing 처리 ex)<br>
            const voidElements = ['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr'];
            if (voidElements.includes(tagName)) {
                return React.createElement(tagName, { key, ...props });
            }
        
            const children = Array.from(node.childNodes).map((childNode, index) =>
                createElementFromNode(childNode, index)
            );
            return React.createElement(tagName, { key, ...props }, children);
        }
        return null;
    };
    
    return (
        <div className={`topic-viewer ${className ?? ''}`}>
            {Array.from(doc.body.childNodes).map((node, index) => createElementFromNode(node, index))}
        </div>
    );
};

export default TopicViewer;