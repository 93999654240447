import { useState, useEffect } from "react";

const buildFileSelector = () => {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('multiple', 'multiple');
  fileSelector.setAttribute('webkitdirectory', 'true');
  return fileSelector;
}

const FileUploader = ({
  tree, type, node, setIsOpen,
}) => {
  const [state, setState] = useState({           
    path: node.path,
    node: node.item.children,
    root: '',
    files: [],
    formdata: new FormData()
  });
  
  const ignoreList = [".DS_Store"];
  
  const [fileSelector, setFileSelector] = useState(buildFileSelector());

  useEffect(() => {
    if(fileSelector)
      fileSelector.addEventListener('change', addFileList);
  }, [fileSelector]);

  const _setIsOpen = () => {
    setIsOpen(false);
  };
  
  const addFileList = (e) => {
    let files = e.target.files;
    let list = state.files;
    var formdata = state.formdata;

    
    if(files.length == 0 || formdata == undefined) return;
    const root = files[0].webkitRelativePath.split("/")[0];

    const collator = new Intl.Collator("ko");
    // console.log(root, node.name);
    const isEqual = collator.compare(root, node.name);
    if(isEqual !== 0) {
      alert("경로가 일치하지 않습니다.");
      return;
    }

    let start = list.length.toString();
    for (let i=0; i<files.length; i++) {  
      if (ignoreList.includes(files[i].name)) continue;
      formdata.append('file_' + start++, files[i], files[i].webkitRelativePath.split(`${root}/`)[1]);
      list.push({ name: files[i].webkitRelativePath.split(`${root}/`)[1], size: files[i].size });
    }
    setState(prev => { 
      return {
        ...prev,
          root: root,
          files: list,
          formdata: formdata
      }
    });
  };

  const removeFile = (e) => {
    e.preventDefault();
    var index = e.target.id;
    let filelist = state.files;
    var formdata = state.formdata;
    filelist.splice(index, 1);

    formdata.delete('file_' + index);
    setState(prev => { return {
      ...prev,
        files: filelist
    }});
    setFileSelector(buildFileSelector());
  };

  const uploadFile = (e) => {       
    e.preventDefault();        
    fileSelector.click();       
  };

  const fileList = () => {
    let list = [];
    let filesize = 0;
    
    for (let i = 0; i < state.files?.length; i++) {
        if (!isNaN(state.files[i].size)) {
            if (state.files[i].size < 1024) {
                filesize = state.files[i].size.toFixed(1) + "byte";
            }
            else if (state.files[i].size < Math.pow(1024, 2)) {
                filesize = (state.files[i].size / 1024).toFixed(1) + "kb";
            }
            else if (state.files[i].size < Math.pow(1024, 3)) {
                filesize = (state.files[i].size / Math.pow(1024, 2)).toFixed(1) + "mb";
            }
            else {
                filesize = (state.files[i].size / Math.pow(1024, 3)).toFixed(1) + "gb";
            }
            state.files[i].size = filesize;
        }
        list.push(
          <li style={{ width: 'calc(100% - 10px)' }}>
              <p className="filename" id="fileName" style={{ width: '70%' }}>
                {truncate(state.files[i].name)}
              </p>
              <p className="filesize" id="fileSize" style={{ width: '20%' }}>{state.files[i].size}</p>
              <button className="btn-remove-file" id={i} onClick={e => removeFile(e)} style={{ width: '10%' }}><i id={i} className="i-remove"></i></button>
          </li>
        );
    }
    return list;
  };

  // const getConfigJson = () => {
  //   state.files.forEach((file) => {
  //     state.node.push({
  //       module: file.name,
  //       type: 'pdf'
  //     })
  //   });
  //   console.log("tree: ", tree);
  //   return true;
    // };

  const handleSubmit = () => {
    // checkJson();
    if (state.files.length === 0) {
      alert('첨부 파일을 하나 이상 선택해주세요.');
      return;
    }
    let path = type;
    // state.path;
    const sub = state.path.split('/');
    for(let i=1; i < sub.length; i++) {
      path += `/${sub[i]}`;
    }
    // console.log(path);
    const collator = new Intl.Collator("ko");
    const isEqual = collator.compare(state.root, node.name);
    if(isEqual !== 0) {
      alert("경로가 일치하지 않습니다.");
    } else {
      state.formdata.append("path", path);
      fetch('api/Edit/UploadContents', {
        method: 'POST',
        body: state.formdata
      }).then((response) => {
          if (response.status === 200) {
              alert("업로드가 완료되었습니다.");
              _setIsOpen();
              // console.log(response.status);
              //   // getConfigJson();
              //   console.log("success");
          } else {
              alert("업로드가 실패했습니다.");
          }
      });
    }
  };

  const truncate = (str, limit = 30) => {
    // return str.length > limit ? str.substring(0, limit - 3) + "..." : str;
    return str.length > limit ? str.substring(0, limit / 2) + "..." + str.slice(-(limit / 2 - 3)): str;

  };

  return (
    <div className="msg-popup-wrap dim">
        <div className="msg-popup apply-auth">
            <div className="box">
                <div className="title-wrap">
                    <p className="title">{"매뉴얼 업로드"}</p>
                    <button className="btn-close-popup" onClick={() => {
                        _setIsOpen();
                    }}><i className="i-close-popup"></i></button>
                </div>
                <form className="form-wrap">
                    <div className="apply-list">
                        <dl className="search target" >
                          <dt>경로 확인
                          </dt>
                          <dd>
                            {node.path}
                          </dd>
                        </dl>
                        <dl className="" >
                            <dt>{"파일 목록"}
                            </dt>
                            <dd>
                              <div className="title">첨부파일
                                <button className="btn-add-file" onClick={uploadFile}>
                                  <i className="i-add"></i>
                                </button>
                              </div>
                              {/* <li className="post-files"> */}
                                <ul className="attached-files" style={{ maxHeight: '30vh', overflowX: 'hidden', overflowY: 'auto' }}>
                                  {fileList()}
                                </ul>
                              {/* </li> */}
                            </dd>
                        </dl>

                    </div>
                    <div className="btn-wrap">
                        <button type="button" className="btn-secondary" onClick={() => _setIsOpen()}>취소</button>
                        <button type="button" className={"btn-primary"} onClick={() => handleSubmit()}>
                          확인
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
  )
};

const FileArea = ({
    path, setDatas, setList
}) => {
    const [state, setState] = useState({
        root: '',
        files: [],
        formdata: new FormData()
    });

    const ignoreList = [".DS_Store"];

    useEffect(() => {
        setDatas(state);
    }, [state]);

    useEffect(() => {
        if (state.files.length > 0) {
            addList(state.files);
        }
    }, [state.files.length]);

    const addList = (files) => {
        const checkImg = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];
        let list = [];
        for (let i = 0; i < files.length; i++) {
            const slice = files[i].name.split(".");
            const ext = slice.pop().toLowerCase();

            if (checkImg.includes(ext)) {
                list.push(<li>
                    <div className="icon-wrap"><i className="i-filetype-img"></i></div>
                    <span className="filename" title="파일명">{files[i].name}</span>
                    <div className="btn-wrap">
                        {/*<button className="btn-copy-url" title="경로 복사하기"><i className="i-copy-url"></i></button>*/}
                        <button className="btn-delete-file" title="파일 삭제하기" id={i} onClick={e => removeFile(e)}><i className="i-delete-file"></i></button>
                    </div>
                </li>);
            } else {
                list.push(<li>
                    <div className="icon-wrap"><i className="i-filetype-doc"></i></div>
                    <span className="filename" title="파일명">{files[i].name}</span>
                    <div className="btn-wrap">
                        {/*<button className="btn-copy-url" title="경로 복사하기"><i className="i-copy-url"></i></button>*/}
                        <button className="btn-delete-file" title="파일 삭제하기" id={i} onClick={e => removeFile(e)}><i className="i-delete-file"></i></button>
                    </div>
                </li>)
            }
        }
        setList(list);
    };

    const addFileList = (e, type) => {
        let files = e.target.files;
        let list = state.files;
        var formdata = state.formdata;
        let root = path;

        if (files.length == 0 || formdata == undefined) return;

        if(type === "folder") {
          root = files[0].webkitRelativePath.split("/")[0];

          const collator = new Intl.Collator("ko");
          const isEqual = collator.compare(path, root);
          if (isEqual !== 0) {
              alert("경로가 일치하지 않습니다.");
          } else {
              let start = list.length.toString();
              for (let i = 0; i < files.length; i++) {
                  if (ignoreList.includes(files[i].name)) continue;
                  const split = files[i].name.split(".");
                  const type = split.pop().toLowerCase();
                  let moduleName = '';
                  for (let i = 0; i < split.length; i++) {
                      moduleName += split[i];
                      if (i < split.length - 1) {
                          moduleName += ".";
                      }
                  }
                  formdata.append('file_' + start++, files[i], files[i].webkitRelativePath.split(`${root}/`)[1]);
                  list.push({ name: files[i].webkitRelativePath.split(`${root}/`)[1], size: files[i].size, module: moduleName, type: type });
              }
          }
        } else {
          let start = list.length.toString();
          for (let i = 0; i < files.length; i++) {
              if (ignoreList.includes(files[i].name)) continue;
              const split = files[i].name.split(".");
              const type = split.pop().toLowerCase();
              let moduleName = '';
              for (let i = 0; i < split.length; i++) {
                  moduleName += split[i];
                  if (i < split.length - 1) {
                      moduleName += ".";
                  }
              }
              formdata.append('file_' + start++, files[i], files[i].name);
              list.push({ name: files[i].name, size: files[i].size, module: moduleName, type: type });
          }
        }
        setState({
            root: path,
            files: list,
            formdata: formdata
        });
        e.target.value = "";
    };

    const removeFile = (e) => {
        e.preventDefault();
        var index = e.target.id;
        let filelist = state.files;
        var formdata = state.formdata;
        filelist.splice(index, 1);

        formdata.delete('file_' + index);
        setState(prev => {
            return {
                ...prev,
                files: filelist,
                formdata: formdata
            }
        });
    };

    // const truncate = (str, limit = 30) => {
    //     // return str.length > limit ? str.substring(0, limit - 3) + "..." : str;
    //     return str.length > limit ? str.substring(0, limit / 2) + "..." + str.slice(-(limit / 2 - 3)) : str;

    // };

    return (
        <div style={{ display: "flex" }}>
            <div className="info">
                <input id="upload-files" type="file" multiple onChange={(e) => addFileList(e, "file")} style={{ display: 'none' }} />
                <label className="btn-select-file" htmlFor="upload-files">파일 선택</label>
            </div>
            {/* <div className="info">
                <input id="upload-folder" type="file" webkitdirectory="" onChange={(e) => addFileList(e, "folder")} style={{ display: 'none' }} />
                <label className="btn-select-file" htmlFor="upload-folder">폴더 선택</label>
            </div> */}
        </div>
    )
};

const handleUpload = (state, name, path, jsonName, datas) => {
    // if (state.files.length === 0) {
    //     //alert('첨부 파일을 하나 이상 선택해주세요.');
    //     return;
    // }

    const jsonData = JSON.stringify(datas[0]);
    //console.log(jsonData);

    //console.log(path);
    const collator = new Intl.Collator("ko");
    const isEqual = collator.compare(state.root, name);
    //console.log(state.root + ", " + name);
    if (state.files.length !== 0 && isEqual !== 0) {
        alert("경로가 일치하지 않습니다.");
    } else {
        state.formdata.append("path", path);
        state.formdata.append("name", name);
        state.formdata.append("JsonName", jsonName);
        state.formdata.append("JsonData", jsonData);
        // UploadContents
        fetch('api/Edit/UpdateContent', {
            method: 'POST',
            body: state.formdata
        }).then((response) => {
            if (response.status === 200) {
                alert("OK");
                // console.log(response.status);
                return true;
            } else {
                alert("Fail");
                return false;
            }
        });
        //alert("OK");
        return false;
    }
};

export { FileUploader, FileArea, handleUpload };