import { Map} from 'immutable';
const storage = {
    set: (key, object) => {
        if (!localStorage) return;
        localStorage[key] = (typeof object) == 'string' ? object : JSON.stringify(object);
    },
    get: (key) => {
        if (!localStorage) return null;

        if (!localStorage[key]) return null;

        try {
            const parsed = JSON.parse(localStorage[key]);
            const Mapped = Map(parsed);
            return Mapped;
        } catch (e) {
            return localStorage[key];
        }
    },
    remove: (key) => {
        if (!localStorage) return null;

        if (!localStorage[key]) {
            localStorage.removeItem(key);
        }
        else {
            localStorage.removeItem(key);
        }
    }
};

export default storage;