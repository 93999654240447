import React, { Fragment, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import hljs from 'highlight.js';
import storage from '../libs/storage';

import PDFDocViewer from './PDFViewer';
import Player from './Player';

import { useDispatch } from 'react-redux';
import * as contentActions from '../../store/modules/contentActions';
import { bindActionCreators } from 'redux';

import jQuery from "jquery";
import Editor from './Editor';
import './Editor.css';

window.$ = window.jQuery = jQuery;

const ManualViewer = (props) => {
    const loginStates = storage.get('loginStates');
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    
    const [contentInfo, setContentInfo] = useState(props.ContentInfo);
    const [contentHtml, setContentHtml] = useState("");
    const [viewer, setViewer] = useState();

    useEffect(() => {
        loadClickNodeContents();
        
        return () => {
            ContentActions.setContentInfo({
                contentType: '',
                contentName: '',
                folderName: '',
                type: '',
                tabName: '',
                url: '',
                video: ''
            });
        }
    }, []);

    useEffect(() => {
        updateContentInfo(props.ContentInfo)
    }, [props, props.ContentInfo, contentHtml]);

    useEffect(() => {
        props.setEditorData(contentHtml);
        const content = renderingDoc(contentHtml);
        setViewer(content);
    }, [contentHtml]);
    
    const updateContentInfo = (info) =>
    {
        window.$(window).scroll(function () {
            if (window.$(window).scrollTop() >= 60) {
                window.$('.contents-wrap').addClass('fixed'); 
            }
            else {
                window.$('.contents-wrap').removeClass('fixed');
            }
        });                
        document.querySelectorAll("pre code").forEach(block => {
            hljs.highlightElement(block);
        });        
        if (document.getElementsByClassName('btn-copy')) {
            var userSelection = document.getElementsByClassName('btn-copy');

            for (let i = 0; i < userSelection.length; i++) {
                userSelection[i].addEventListener('click', copyFunction);
            }
        } 
        if (JSON.stringify(info) != JSON.stringify(contentInfo)){
            loadClickNodeContents();
        }
        if (info.video != undefined) {
            var videos = info.video;
            if (videos.length != undefined) {
                for (var i = 0; i < videos.length; i++) {
                    var video = document.getElementById(videos[i]);
                    if (video != null) {
                        const src = video.children[0].getAttribute("src");
                        if (src != null) 
                            createRoot(document.getElementById(videos[i])).render(<Player src={src} id={videos[i]} />);
                    }
                }
            }
        }
        setContentInfo(info);
    };

    const loadClickNodeContents = () => {        
        let callMethod = 'GetManual';
        var body = JSON.stringify({
            FolderName: props.ContentInfo.folderName !== "" ? props.ContentInfo.folderName : props.ContentInfo.contentType,
            DocId: props.ContentInfo.docId,
            DevUserId: loginStates != null ? loginStates.get('userId') : 'anonymous'
        });
        if (document.querySelector('.tree-title') != null) {
            if (props.NodePath == document.querySelector('.tree-title').innerText && document.querySelector('.on') != null) {
                document.querySelector('.on').classList.remove('on');
            }
        }     
        if (props.ContentInfo.type != 'pdf' && props.ContentInfo.type != 'notion') {
            fetch('api/Edit/' + callMethod, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: body
            }).then(response => response.json())
                .then(data => {
                    setContentHtml(data);
                    ContentActions.setContent(data);
                });
        } else {
            setContentHtml('');
        }
    };

    const copyFunction = (e) => {
        var textArea = document.createElement("textarea");
        textArea.value = e.target.parentNode.firstElementChild.value;
        document.body.appendChild(textArea);
        textArea.select();
        var result = document.execCommand("copy");
        textArea.style.display = "none";
        if (result)
            alert("복사되었습니다.");
    };

    const renderingDoc = (contentHtml) => {        
        let content = contentHtml;
        if (props.ContentInfo.type == 'notion') {            
            const url = props.ContentInfo.url;
            return (
                <div className="doc-content type-iframe">
                    <iframe title="doc-content-iframe" src={url} className="doc-content-iframe"></iframe>
                </div>
            );
        }
        if (content == 'No files..') {
            return (
                <div className="no-files" >
                    <i className="i-no-files" />
                    <p>컨텐츠 준비중입니다</p>
                </div>
            );
        }   
        else {
            if(content && content !== "") {
                const data = JSON.parse(content);
                if(data && data.data) {
                    return (
                        <div className='editor-section'>
                            <div className='title-section'><p>{data.title}</p></div>
                            {data.tag && 
                                <div className='tag-section'>
                                    {data.tag.map((tag, index) => (
                                        <div key={index} className='tag-item'>
                                            {tag}
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className="manual-content">
                                <Editor editorData={data} setEditor={props.setEditorEl}/>
                            </div>
                        </div>
                    );                
                }
            } 
            return(
                <div className="no-files" >
                    <i className="i-no-files" />
                    <p>컨텐츠 준비중입니다</p>
                </div>
            );
        }
    };

    return (
        <Fragment>               
            {viewer}
        </Fragment>
    );        
}

export default ManualViewer;