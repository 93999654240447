import React, { useState, useEffect, Fragment } from 'react'
import { useSelector} from 'react-redux';
import AuthRequest from './AuthAccountPanels/AuthRequest'
import { List, ListSection, ListTabSection } from '../Common/List';
import { transDate } from '../libs/Util';
import Table from '../Common/Table';

const UserInfoAcc = (props) => {
    const loginInfo = useSelector(state => state.loginActions);
    const userId = loginInfo.get('userId') ?? "";
    const [isShowRequestPopup, setIsShowRequestPopup] = useState(false);
    const [authKind, setAuthKind] = useState("");
    const [isMount, setIsMount] = useState(true);

    useEffect(() => {
        setIsMount(true);
        return () => {
            setIsMount(false);
        }
    }, []);

    useEffect(() => {
        setIsShowRequestPopup(authKind !== "");
    }, [authKind]);

    useEffect(() =>{
        if(!isShowRequestPopup) setAuthKind("");
    }, [isShowRequestPopup]);

    const sections = [
        {
          type: "dev",
          title: "개발 권한"
        },
        {
          type: "acc",
          title: "개발 서버 접속 권한"
        }
    ];
    
    const devAuthOptions = {
        "1": "패키지AS",
        "2": "Ever",
        "3": "사업부AS",
        "4": "협력사AS",
        "5": "고객사AS"
    };

    const [accAuth, setAccAuth] = useState([]);
    const [devAuth, setDevAuth] = useState([]);

    useEffect(() => {
        getMyAccInfo();        
    }, []);

    //내 권한 조회
    const getMyAccInfo = () => {
        let body = {
            userId: userId
        }
        fetch('api/User/GetMyAccInfo', {
            method: 'POST',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        }).then((response) => response.json())
        .then((data) => {
            setAccAuth(data.table);
            setDevAuth(data.table1);
        });
    };

    const viewAuthInfo = (info) => {
        // console.log(info);
    };

    const getAuthInfo = (datas, type) => {
        let result = []
        let isServer = type === "acc";

        if (datas.length > 0) 
            for (let i = 0; i < datas.length; i++) {
                const groupKind = isServer ? null : datas[i].addDevGroupSeq == null || datas[i].addDevGroupSeq == 0 || datas[i].basicDevGroupName == datas[i].addDevGroupName ? "basic" : "add";
                const devGroupName = isServer ? datas[i].devGroupName : groupKind == "basic" ? devAuthOptions[datas[i].basicDevGroupSeq] : devAuthOptions[datas[i].addDevGroupName];
                const diffDate = isServer ? datas[i].diffDate : groupKind == "basic" ? datas[i].basicDiffDate : datas[i].addDiffDate;
                const fromDate = isServer ? datas[i].fromDate : datas[i].reqDate;
                const toDate = isServer ? datas[i].toDate : groupKind == "basic" ? datas[i].basicDevExpDate : datas[i].addDevExpDate;

                result.push(
                    <li key={devGroupName} className={`type-${type}`} onClick={() => viewAuthInfo(datas[i])}>
                        <div className="auth-name"><i></i><p className="txt">{devGroupName}</p></div>
                        <div className="d-count"><div className={diffDate > 7 ? "tag-count" : "tag-count expire"}>D-{diffDate > 999 ? "999+" : diffDate}</div></div>
                        <div className="period">{fromDate} ~ {toDate}</div>
                    </li>
                )
            } 

        return result;
    };

    const buttons = [
        {
          type: "acc",
          title: "접속 권한",
          default: true,
          state: "G",
          column: [
            '그룹권한',
            "권한적용일",
            "권한만료일",
            "상태"
          ]
        },
        {
          type: "dev",
          title: "개발 권한",
          default: false,
          state: "U",
          column: [
            '개발권한',
            "권한적용일",
            "권한만료일",
            "상태"
          ]
        }
    ];

    const myinfooptions = [
        { value: "만료", class: "waiting color-t5" },
        { value: "적용중", class: "appr color-primary" },
        { value: "기본권한 적용중", class: "appr color-primary" }
    ];

    const companySeq = loginInfo.get('companySeq');

    const [columns, setColumns] = useState([]);
    const [btnState, setBtnState] = useState('G');
    const [myInfoData, setMyInfoData] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {
        buttons.forEach((item) => {
            if (item.state === btnState)
                setColumns(item.column);
        })
        getMyAccInfoHistory(btnState);
    }, [btnState]);

    useEffect(() => {
        setList(registerList(myInfoData));
    }, [myInfoData]);

    //내 권한 변경이력 조회
    const getMyAccInfoHistory = (btnState) => {
        let body = {
            state: btnState,
            userId: userId,
            companySeq: companySeq
        }
        fetch('api/User/GetMyAccInfoHistory', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        }).then((response) => response.json())
        .then((data) => {
            setMyInfoData(data.table);
        });
    };

    const registerList = (data) => {
        let list = [];
        if (data.length < 1) {
            return null;
        }
        for (let i = 0; i < data.length; i++) {
            if (data[i].addDevGroupName == '' && data[i].status == '만료') {
                data[i].addDevGroupName = data[i].basicDevGroupName;
                data[i].toDate = data[i].basictoDate;
            }

            const findIndex = myinfooptions.findIndex(element => element.value == data[i].status);
            if (findIndex != null && findIndex > 0) {
                let classtype = myinfooptions[findIndex].class ?? '';
                let innerstr = myinfooptions[findIndex].value;
                list.push(
                    <Fragment key={i}>
                        <tr>
                            <td>{(btnState == 'G' ? data[i].devGroupName :
                                (innerstr == '기본권한 적용중' ? data[i].basicDevGroupName : data[i].addDevGroupName))}
                            </td>
                            <td>{transDate(data[i].fromDate)}  
                            </td>
                            <td>
                                {(innerstr == '기본권한 적용중' ? transDate(data[i].basictoDate) : transDate(data[i].toDate))}
                            </td>
                            <Fragment>
                                <td className={classtype}>
                                    {innerstr}
                                </td>
                            </Fragment>
                        </tr>
                    </Fragment>
                );
            }
        }
        return list;
    };

    return (
        <div className="content">
            {isShowRequestPopup ? <AuthRequest setIsShowRequestPopup={setIsShowRequestPopup} authKind={authKind} /> : null}
            <div className="my-auth">
                <h2>내 권한</h2>
                {isMount &&
                        sections.map((item, index) => {
                            return (
                                <List 
                                    key={index}
                                    classNames={{
                                        section: "my-auth-list",
                                        div: `${item.type}-auth`,
                                        button: "btn-new-auth"
                                    }}
                                    title={item.title} 
                                    isVisible={props.isUseAuth} 
                                    handleClick={() => setAuthKind(`auth-${item.type}`)} 
                                    titleButton={"+ 권한 신청"} 
                                    list={getAuthInfo(item.type === "acc" ? accAuth : devAuth, item.type)} 
                                />
                            )
                        })
                }
                <section className="my-auth-history">
                    <h3>변경이력</h3>
                    {isMount &&
                        <Fragment>
                            <div className="list-tab">
                                {buttons.map((item, index) => {
                                    return (
                                        <div key={index} className="item">
                                            <input name={"history-tab"} type="radio" id={`auth-${item.type}`} defaultChecked={item.default} onChange={() => setBtnState(item.state)} />
                                            <label htmlFor={`auth-${item.type}`}>{item.title}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            <Table className={"auth-history"} headers={columns} list={list} />
                        </Fragment>
                    }
                </section>
            </div>
        </div>
    );
}
export default UserInfoAcc;