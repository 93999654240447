import React, { Fragment } from 'react'

const Table = (props) => {
    return (
        <Fragment>
            <section className="list-layout">
                <div className="list-wrap">
                    <table className={"list " + props.className}>
                        <thead>
                            <tr>
                                {
                                    props.headers.map((header, index) => {
                                        return (
                                            <th key={index}>
                                                {header}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {props.list}
                        </tbody>
                    </table>
                </div>
            </section>
        </Fragment>
    )
}
export default Table;