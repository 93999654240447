import React from 'react';

const ScrollIntoViewer = ({editorData}) => {
    
    const ClickIndex = (index) => {
        var offsetTop = 0;
        let topHeight = checkHeight();
        var contents = document.querySelector(`[data-id="${index}"]`);  // 각 블록에 data attribute를 추가하여 식별
        if (contents) {
            offsetTop = contents.offsetTop;
        }
        window.scrollTo({
            top: offsetTop + topHeight,
            behavior: 'smooth'
        });
    };

    const findSubJects = () => {
        if(!editorData || editorData === "" || editorData === "No files..") return;
        const data = JSON.parse(editorData);
        let returnArray = [];
        const blocks = data.data.blocks;

        blocks.forEach((block, index) => {
            const isHeader = (block.type === 'header' && (block.data.level === 1 || block.data.level === 2 || block.data.level === 3))
                || block.type === 'h1' || block.type === 'h2' || block.type === 'h3';
            if (isHeader) {
                let text = block.data.text.replaceAll("&nbsp", "").replaceAll(";", "");
                returnArray.push({ index, innerText: text, innerId: block.id, level: block.data.level });
            }
        });

        return returnArray;
    };

    const checkHeight = () => {
        let height = 0;
        const nav = document.querySelector(".doc-nav");
        const title = document.querySelector(".title-section");
        const tag = document.querySelector(".tag-section");
        if(nav) height = height + nav.clientHeight + 25;
        if(title) height = height + title.clientHeight + 50;
        if(tag) height = height + title.clientHeight + 20;
        return height;
    };

    var style = {
    };
    
    var listStyle = {
        "display": "list-item",
        "textAlign": "left"
    };
    let ankor = findSubJects();
    let index = 0;
    return (
        <div className="page-index">
            <nav style={style}>
                <h5>이 문서의 내용</h5>
                <ol>
                    {
                        ankor && ankor.map(
                            (subject) => (
                                <li 
                                    key={index++} 
                                    // 2 고정값으로 되어 있는 부분 -> data json의 h태그 중에서 가장 낮은 level 값으로 설정하도록 수정 필요
                                    style={subject.level > 2 ? {...listStyle, "paddingLeft":((subject.level -1)*10).toString()+ "px"}: listStyle} 
                                    onClick={() => ClickIndex(subject.innerId)}>
                                    {subject.innerText}
                                </li>
                            )
                        )
                    }
                </ol>
            </nav>
        </div>
    );
}

export default ScrollIntoViewer;
