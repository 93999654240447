import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as messageActions from '../../../store/modules/messageActions';
import { DateSelector } from '../../Common/DateSelector';
import { NameCard, SearchPerson } from '../../Common/NameCard';
import { includeByCho } from '../../libs/Util';
import { SelectRadio } from '../../Common/SelectRadio';

const AuthRequest = (props) => {
    //props
    //handleRequestPopup : 팝업 표시 유무 state set함수(필수) True, False
    //hadleCheckReq : 권한 요청 시 권한 요청 목록 새로 불러오는지 체크하는 props (optional) True, False
    const loginInfo = useSelector(state => state.loginActions);
    const userId = loginInfo.get('userId');
    const userCompanySeq = loginInfo.get('companySeq');
    const userCompanyName = loginInfo.get('companyName');

    let today = new Date();
    today = today.getFullYear() + "." + (today.getMonth() + 1) + "." + today.getDate();
    today = new Date(today);

    let popupAuthKind = props.authKind;
    const [searchDevUser, setSearchDevUser] = useState([]);
    const [searchGroup, setSearchGroup] = useState([]);
    const [selectGroup, setSelectGroup] = useState({});
    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedDevUser, setSelectedDevUser] = useState({});
    const [authUser, setAuthUser] = useState(0);
    const [existsGroup, setExistsGroup] = useState([]);
    const [loginUserData, setLoginUserData] = useState({});
    const [groupData, setGroupData] = useState([]);
    const [devUserData, setDevUserData] = useState([]);
    const [requestMessage, setRequestMessage] = useState('');
    const [requestDateFrom, setRequestDateFrom] = useState(today);
    const [requestDateTo, setRequestDateTo] = useState(today);
    const [requestDevAuth, setRequestDevAuth] = useState('1');
    const [inputText, setInputText] = useState();
    const [isLoadDevUserData, setIsLoadDevUserData] = useState(false);
    const [isLoadGroupData, setIsLoadGroupData] = useState(false);
    const [groupPopupContent, setGroupPopupContent] = useState(null);
    const [devUserPopupContent, setDevUserPopupContent] = useState(null);
    const [existsPopupContent, setExistsPopupContent] = useState(null);
    const [authData, setAuthData] = useState();
    const [userGroupAuth, setUserGroupAuth] = useState();
    const [expirationDate, setExpirationDate] = useState(1);
    const [applicableGroup, setApplicableGroup] = useState();
    let devAuthOptions = {
        "1": "패키지AS",
        "2": "Ever",
        "3": "사업부AS",
        "4": "협력사AS",
        "5": "고객사AS",
    };
    const period = 30;

    const dispatch = useDispatch();
    const MessageActions = bindActionCreators(messageActions, dispatch);

    useEffect(() => {
        getGroupList();
        getDevUserList();
        getExpiration();
        return () => { };
    }, []);

    useEffect(() => {
        searchCodeHelp();
    }, [inputText])

    useEffect(() => {
        if (existsGroup.length > 0) {
            setExistsPopupContent(setExistsPopup());
        }
    }, [existsGroup])

    useEffect(() => {
        if (devUserPopupContent != null) {
            // setDevUserPopupContent(searchDevUserPopup());
        }
    }, [searchDevUser])

    useEffect(() => {
        if (groupPopupContent != null) {
            setGroupPopupContent(searchGroupPopup());
        }
    }, [searchGroup, selectGroup])

    useEffect(() => {
        if (Object.keys(devUserData).length > 0) {
            setIsLoadDevUserData(true);
        }
    }, [devUserData])

    useEffect(() => {
        if (Object.keys(groupData).length > 0) {
            setIsLoadGroupData(true);
        }
    }, [groupData])

    useEffect(() => {
        setSelectedDevUser(authUser === 0 ? loginUserData : {});
    }, [authUser]);

    useEffect(() => {
        if (authData != undefined && selectedDevUser.devUserSeq != undefined) {
            setUserGroupAuth(authData.filter(x => x.devUserSeq == selectedDevUser.devUserSeq));
            setApplicableGroup(groupData.filter(x => x.svrGroupSeq != null && x.svrGroupSeq >= selectedDevUser.basicDevGroupSeq));
            // setApplicableGroup(groupData);
        }
        if (Object.keys(selectedDevUser).length == 0) {
            setSelectedGroup({});
        }
    }, [authData, selectedDevUser])

    useEffect(() => {
        if (userGroupAuth != undefined) {
            let userAuth = {};
            for (let i = 0; i < userGroupAuth.length; i++) {
                let group = applicableGroup.filter(x => x.devGroupSeq == userGroupAuth[i].devGroupSeq)[0];
                let remainDate = ((new Date(userGroupAuth[i].toDate.slice(0, 4) + "." + userGroupAuth[i].toDate.slice(4, 6) + "." + userGroupAuth[i].toDate.slice(6, 8))).getTime() - today.getTime()) / (1000 * 3600 * 24);
                group = { ...group, "fromDate": userGroupAuth[i].fromDate, "toDate": userGroupAuth[i].toDate, "remainDate": remainDate, "isExistAuth": "1" };
                if (group.devGroupSeq != undefined) {
                    userAuth = { ...userAuth, [group.devGroupSeq]: group };
                }
            }
            // setSelectedGroup(userAuth); 
            setSelectGroup(userAuth);
        }
    }, [userGroupAuth]);

    //toast 출력
    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    };

    //fetch 개발자 데이터
    const getDevUserList = () => {
        fetch('api/User/ReqDevUserList?UserId=' + userId, {
            method: 'GET',
        }).then((response) => response.json())
            .then((data) => {
                setAuthData(data.table2);
                setLoginUserData(data.table1[0]);
                setSelectedDevUser(data.table1[0]);
                setDevUserData(data.table);
            });
    };

    //fetch 그룹 데이터
    const getGroupList = () => {
        fetch('api/User/ReqGroupList?UserId=' + userId, {
            method: 'GET',
        }).then((response) => response.json())
            .then((data) => {
                setGroupData(data.table);
            });
    };

    //권한 임박 기간
    const getExpiration = () => {
        // fetch('./datas/expiration_date.json', {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Cache-Control': 'no-cache'
        //     }
        // }).then(response => response.json())
        //     .then(json => {
                setExpirationDate(period);
            // })
    };

    //개발권한 요청 API
    const userAuthReqSave = () => {
        let dateFrom = requestDateFrom.replace(/-/gi, '').replace(/(^0+)/, "");
        let dateTo = requestDateTo.replace(/-/gi, '').replace(/(^0+)/, "");
        let reqDevInfo = {
            companySeq: userCompanySeq,
            companyName: userCompanyName,
            reqDateFrom: dateFrom,
            reqDateTo: dateTo,
            reqUserSeq: selectedDevUser.devUserSeq,
            reqUserName: selectedDevUser.devUserName,
            reqDevAuth: requestDevAuth,
            reqDevAuthName: devAuthOptions[requestDevAuth],
            userId: userId,
            reqMessage: requestMessage,
            basicDevGroupSeq: selectedDevUser.basicDevGroupSeq,
            basicDevExpDate: selectedDevUser.basicDevExpDate,
        };

        if (Object.keys(selectedDevUser).length > 0 && requestDevAuth != selectedDevUser.basicDevGroupSeq && dateTo.length == 8 && requestMessage.length != 0) {
            fetch('api/User/UserAuthReqSave', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(reqDevInfo)
            }).then((response) => response.json())
                .then((message) => {
                    if (message == 'success') {
                        props.setIsShowRequestPopup(false);
                        showToast('success', "개발권한신청이 완료되었습니다.", 3000);

                    }
                    else if (message == "100" || message == "200") {
                        setExistsPopupContent(setExistsPopup());
                    }
                    else if (message == 'fail') {
                        alert("개발권한신청이 실패하였습니다.");
                    }
                });
            // alert(JSON.stringify(reqDevInfo));
        }
        else if (Object.keys(selectedDevUser).length == 0 && (requestDevAuth == selectedDevUser.basicDevGroupSeq)) {
            alert("권한 대상자가 없거나, 신청 권한이 잘못되었습니다.");
        }
        else if (Object.keys(selectedDevUser).length == 0) {
            alert("권한 대상자는 필수로 입력해야 합니다.");
        }
        else if (requestDevAuth == selectedDevUser.basicDevGroupSeq) {
            alert("이미 보유한 권한입니다.");
        }
        else if (dateTo.length != 8) {
            alert("권한 적용기간이 잘못되었습니다.");
        }
        else if (requestMessage.length == 0) {
            alert("신청 사유는 필수로 입력해야합니다.")
        }
    };

    //접속권한 요청 API
    const reqGroupMemberSave = () => {
        let dateFrom = requestDateFrom.replace(/-/gi, '');
        let dateTo = requestDateTo.replace(/-/gi, '');
        let keys = Object.keys(selectedGroup);
        let groupList = { ...selectedGroup };

        for (let i = 0; i < keys.length; i++) {
            let toDate = selectedGroup[keys[i]].toDate != undefined ? new Date(selectedGroup[keys[i]].toDate.slice(0, 4) + "."
                + selectedGroup[keys[i]].toDate.slice(4, 6) + "." + selectedGroup[keys[i]].toDate.slice(6, 8)) : "";
            let expiration = (toDate != "") ? ((toDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) : 0;

            if (isNaN(expiration) || expiration < expirationDate) {
                keys.splice(i, 1);
                i--;
            }
        }

        for (let i = 0; i < keys.length; i++) {
            delete groupList[keys[i]];
        }

        let reqGroupInfo = {
            companySeq: userCompanySeq,
            companyName: userCompanyName,
            reqDateFrom: dateFrom,
            reqDateTo: dateTo,
            reqUserSeq: selectedDevUser.devUserSeq,
            reqUserName: selectedDevUser.devUserName,
            reqGroup: groupList,
            userId: userId,
            reqMessage: requestMessage,
            expirationDate: expirationDate
        };

        if (Object.keys(selectedDevUser).length > 0 && Object.keys(selectedGroup).length > 0 && (dateTo > dateFrom) && dateTo.length == 8 && dateFrom.length == 8 && requestMessage.length != 0) {
            fetch('api/User/ReqGroupMemberSave', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(reqGroupInfo)
            }).then((response2) => response2.json())
                .then((message) => {
                    if (message == 'success') {
                        props.setIsShowRequestPopup(false);
                        showToast('success', "접속권한신청이 완료되었습니다.", 3000);
                    }
                    else if (message == "fail") {
                        alert("접속권한신청이 실패하였습니다.");
                    }
                    else {
                        setExistsGroup(message.split(',').slice(1,));
                    }
                });
            // alert(JSON.stringify(reqGroupInfo));
        }
        else if (Object.keys(selectedDevUser).length == 0 && Object.keys(selectedGroup).length == 0) {
            alert("권한 대상자, 신청 권한그룹은 필수로 입력해야합니다.");
        }
        else if (Object.keys(selectedDevUser).length == 0) {
            alert("권한 대상자는 필수로 입력해야합니다.");
        }
        else if (Object.keys(selectedGroup).length == 0) {
            alert("신청 권한그룹은 필수로 입력해야합니다.");
        }
        else if (dateFrom > dateTo || dateTo.length != 8 || dateFrom.length != 8) {
            alert("권한 적용기간이 잘못되었습니다.");
        }
        else if (requestMessage.length == 0) {
            alert("신청 사유는 필수로 입력해야합니다.")
        }
    };

    //검색 기능
    const searchCodeHelp = () => {
        // let re = new RegExp();
        if (groupPopupContent != null) {
            if (inputText != null && inputText.length > 0) {
                const result = applicableGroup.filter((item) => includeByCho(inputText.toUpperCase(), item.devGroupName.toUpperCase()));
                setSearchGroup(result);
            }
            else {
                setSearchGroup(applicableGroup);
            }
        }
    }

    //선택된 그룹 제거 하는 기능 (x 아이콘 클릭)
    const removeSelectedAcc = (e) => {
        let target = e.target.parentNode;

        if (target.getAttribute("class") == "btn-delete") {
            target = target.parentNode;
        }

        let index = target.getAttribute('index');
        let group = { ...selectedGroup };
        let keys = sortObject(selectedGroup);
        delete group[keys[index]];

        setSelectedGroup(group);
    }

    //선택된 그룹 제거 하는 기능 (그룹 검색 팝업)
    const removeSelectGroup = () => {
        let tmpSelectGroup = { ...selectGroup }
        for (let i = 0; i < existsGroup.length; i++) {
            delete tmpSelectGroup[existsGroup[i]];
        }

        setSelectGroup(tmpSelectGroup);
    }

    //미보유, 보유중인 권한 정렬
    const sortObject = (groupObject) => {
        let sortedSelectedGroup = [];
        for (let selectGroup in groupObject) {
            sortedSelectedGroup.push([selectGroup, groupObject[selectGroup]]);
        }
        sortedSelectedGroup.sort(function (a, b) {
            return a[1].isExistAuth - b[1].isExistAuth;
        });
        let keys = sortedSelectedGroup.map(x => x[0]);

        return keys;
    }

    //그룹팝업에서 선택된 그룹 표시
    const setSelectedAcc = () => {
        let result = [];
        let keys = sortObject(selectedGroup);

        for (let i = 0; i < keys.length; i++) {
            let toDate = selectedGroup[keys[i]].toDate != undefined ? new Date(selectedGroup[keys[i]].toDate.slice(0, 4) + "." + selectedGroup[keys[i]].toDate.slice(4, 6) + "." + selectedGroup[keys[i]].toDate.slice(6, 8)) : "";
            let expiration = (toDate != "") ? ((toDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) : "";

            result.push(
                <div className="selected-group-info" key={i}>
                    <div className="item" index={i}>
                        <i></i><span className="groupname">{selectedGroup[keys[i]].devGroupName}</span>
                        {
                            selectedGroup[keys[i]].isExistAuth == "1" ? <><span className="info color-primary">
                                &nbsp;({(expiration != "" && expiration < expirationDate) ? "D-" + expiration : toDate.getFullYear() + "." + (toDate.getMonth() + 1) + "." + toDate.getDate()})</span></>
                                : null
                        }
                        <button type="button" className="btn-delete" onClick={(e) => {
                            removeSelectedAcc(e);
                        }}><i></i></button>
                    </div>
                </div>)
        }
        result = (<div className="selected-group-info">  {result}  </div>);

        return result;
    }

    //그룹 검색 팝업의 그룹 표시
    const setGroupList = () => {
        let result = [];

        for (let i = 0; i < searchGroup.length; i++) {
            let target = userGroupAuth.filter(x => x.devGroupSeq == searchGroup[i].devGroupSeq).length > 0 ? userGroupAuth.filter(x => x.devGroupSeq == searchGroup[i].devGroupSeq)[0] : null;
            result.push(
                <a className="item" key={i} onClick={(e) => {
                    if (selectGroup[searchGroup[i].devGroupSeq] == null) {
                        if (target != null)
                            setSelectGroup({
                                ...selectGroup, [searchGroup[i].devGroupSeq]: {
                                    ...searchGroup[i], "fromDate": target.fromDate, "toDate": target.toDate, "isExistAuth": "1"
                                }
                            });
                        else
                            setSelectGroup({
                                ...selectGroup, [searchGroup[i].devGroupSeq]: { ...searchGroup[i], "fromDate": "", "toDate": "", "isExistAuth": "0" }
                            });
                    }
                    else {
                        let array = { ...selectGroup };

                        delete array[searchGroup[i].devGroupSeq];
                        setSelectGroup(array);
                    }

                }}>
                    <input type="checkbox" name="" checked={Object.values(selectGroup).filter(x => x.devGroupSeq == searchGroup[i].devGroupSeq).length > 0} readOnly />
                    <label >
                        <i></i>
                        <span className="groupname">{searchGroup[i].devGroupName} {userGroupAuth.filter(x => x.devGroupSeq == searchGroup[i].devGroupSeq).length > 0 ? <span className="info color-primary">보유 중</span> : null}</span>
                        <span className="company">{searchGroup[i].companyName}</span>
                    </label>
                </a>
            )
        }
        return result;
    }

    //개발 권한 표시
    const setDevAuth = () => {
        let devAuth = selectedDevUser.basicDevGroupSeq;
        let keys = Object.keys(devAuthOptions);
        let result = []

        if (devAuth != undefined) {
            for (let i = 0; i < keys.length; i++) {
                if (devAuth == keys[i]) {
                    if (devAuth == '1') {
                        return (<option key={i}>요청 가능한 권한이 없습니다.</option>);
                    }
                    continue;
                }
                result.push(<option value={keys[i]} key={i}>{devAuthOptions[keys[i]]}</option>)
            }
            return result;
        }
        else {
            for (let i = 0; i < keys.length; i++) {
                result.push(<option value={i} key={i} >{devAuthOptions[keys[i]]}</option>)
            }
        }
        return result;
    }

    //선택된 그룹 또는 선택 Input 표시
    const setInputGroup = () => {
        let groupCount = Object.keys(selectedGroup).length;
        let isSelectDevUser = (Object.keys(selectedDevUser).length > 0);
        return (<div className="search-input-pseudo"
            onClick={groupCount > 0 ? null : () => {
                if (isSelectDevUser) {
                    setInputText('');
                    setSelectGroup(selectedGroup);
                    setGroupPopupContent(searchGroupPopup());

                }
            }}>
            {groupCount > 0 ? <>{setSelectedAcc()}
                <button type="button" className="btn-search"
                    onClick={() => {
                        setInputText('');
                        setSelectGroup(selectedGroup);
                        setGroupPopupContent(searchGroupPopup());

                    }}><i></i></button></>
                :
                <>
                    <span className="plchd">{isSelectDevUser ? "권한 그룹을 선택하세요." : "권한 대상자를 먼저 선택해 주세요."}</span>
                    <button type="button" className="btn-search" disabled={!isSelectDevUser} ><i></i></button>
                </>
            }
        </div>)
    }

    //이미 요청중인 권한 또는 보유중인 권한 요청 시 보여주는 팝업
    const setExistsPopup = () => {
        let existsGroupList = [];

        if (popupAuthKind === "auth-acc") {
            for (let i = 0; i < existsGroup.length; i++) {
                let hasAuth = userGroupAuth.filter(x => x.devGroupSeq == existsGroup[i]).length;
                existsGroupList.push(<span className={hasAuth == 0 ? "info color-t3" : "info color-primary"} key={i}>{selectedGroup[existsGroup[i]]['devGroupName']}{hasAuth == 0 ? " (신청 중)" : " (보유 중)"} </span>);

                existsGroupList.push(<br></br>);
            }
        }
        return (
            <div className="msg-popup-wrap dim">
                <div className="msg-popup admin-approve-all">
                    <div className="box">
                        <p className="title color-t3">중복 요청</p>
                        <p className="">요청하려는{popupAuthKind === "auth-acc" ? " 그룹의 접속 권한이" : " 개발 권한이"} <span className="color-primary">보유 중</span> 이거나 <span className="color-t3">신청 중</span>입니다.</p>
                        {popupAuthKind === "auth-acc" ? <p className="">선택된 그룹을 <span className="color-t3">제외</span>하시겠습니까?</p> : null}<br />
                        {popupAuthKind === "auth-acc" ? existsGroupList : null}<br />
                        <div className="btn-wrap">
                            {popupAuthKind === "auth-acc" ? <><button className="btn-secondary-t5" onClick={() => {
                                setExistsGroup([]);
                                setExistsPopupContent(null);
                            }} >취소</button>
                                <button className="btn-primary-t3" onClick={() => {
                                    // removeSelectedGroup();
                                    setExistsGroup([]);
                                    setExistsPopupContent(null);
                                }}>제외</button></>
                                :
                                <button className="btn-secondary-t5" onClick={() => {
                                    setExistsPopupContent(null);
                                }} >취소</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //그룹 검색 팝업
    const searchGroupPopup = () => {
        return (
            <div className="msg-popup-wrap dim">
                <div className="msg-popup auth-slt-group">
                    <div className="box">
                        <div className="title-wrap">
                            <p className="title">권한그룹을 선택하세요.(복수선택가능)</p>
                            <button type="button" className="btn-close-popup"
                                onClick={() => {
                                    setGroupPopupContent(null);
                                    setInputText();
                                }} ><i className="i-close-popup"></i></button>
                        </div>

                        <div className="form-wrap">
                            <div className="search-input">
                                <input type="text" name="input-popup" onChange={(e) => {
                                    setInputText(e.target.value);
                                }} placeholder="권한그룹 검색" />
                            </div>
                            <div className="group-list">
                                {setGroupList()}
                            </div>


                            <div className="btn-wrap">
                                <button type="button" className="btn-secondary" onClick={() => {
                                    setSelectGroup({});
                                    setGroupPopupContent(null);
                                }} >취소</button>
                                <button type="button" className="btn-primary" onClick={() => {
                                    setSelectedGroup(selectGroup);
                                    setGroupPopupContent(null);
                                }} >선택 완료</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //권한 요청 팝업
    const authReqPopup = () => {
        return (
            <div className="msg-popup-wrap dim">
                {existsPopupContent}
                <div className="msg-popup apply-auth">
                    <div className="box">
                        <div className="title-wrap">
                            <p className="title">{popupAuthKind === "auth-acc" ? "개발 서버 접속 권한 신청" : "개발 권한 신청"}</p>
                            <button className="btn-close-popup" onClick={() => {
                                props.setIsShowRequestPopup(false);
                            }}><i className="i-close-popup"></i></button>
                        </div>
                        <form className="form-wrap">
                            <div className="apply-list">
                                {/*<dl className="" >*/}
                                {/*    <dt>권한 종류</dt>*/}
                                {/*    <dd>*/}
                                {/*        <div className="select-radio">*/}
                                {/*            <input name="auth-type" type="radio" value="0" checked={popupAuthKind === "auth-acc"} readOnly />*/}
                                {/*            <label onClick={() => { checkPopupAuthKind("auth-acc") }}>접속권한</label>*/}
                                {/*            <input name="auth-type" type="radio" value="1" checked={popupAuthKind === "auth-dev"} readOnly />*/}
                                {/*            <label onClick={() => { checkPopupAuthKind("auth-dev") }}>개발권한</label>*/}
                                {/*        </div>*/}
                                {/*    </dd>*/}
                                {/*</dl>*/}
                                <dl className="search target" >
                                    <dt>권한 대상자
                                        <SelectRadio 
                                            name={"select-radio target-type"}
                                            data={[
                                                {"name": "본인"},
                                                {"name": "대리"}
                                              ]}
                                            select={authUser} 
                                            handleClick={setAuthUser} 
                                        />
                                    </dt>
                                    <dd>
                                        {!authUser ? 
                                            <NameCard user={selectedDevUser} classWrapper={"search-input-pseudo"} classContent={"selected-user-info"} /> 
                                        : 
                                            <SearchPerson 
                                                userData={devUserData}
                                                classWrapper={"search-input-pseudo"}
                                                popupAuthKind={popupAuthKind}
                                                handleClick={setSelectedDevUser}
                                            />
                                        }
                                    </dd>
                                </dl>
                                {popupAuthKind === "auth-dev" ?
                                    <dl className="" >
                                        <dt>신청 권한</dt>
                                        <dd>
                                            <select disabled={Object.keys(selectedDevUser).length == 0} onChange={(e) => { setRequestDevAuth(e.target.value) }} >
                                                {setDevAuth()}
                                            </select>
                                        </dd>
                                    </dl>
                                    :
                                    <dl className="search auth-group">
                                        <dt>신청 권한그룹(복수선택가능)</dt>
                                        <dd>
                                            {setInputGroup()}
                                            {/*권한그룹검색팝업*/}
                                            {groupPopupContent}
                                        </dd>
                                    </dl>
                                }
                                <dl className="">
                                    <DateSelector
                                        title={popupAuthKind === "auth-acc" ? "권한적용기간" : "권한만료일"} 
                                        isFromTo={popupAuthKind === "auth-acc"} 
                                        handleChange={(from, to) => { setRequestDateFrom(from); setRequestDateTo(to); }} 
                                    />
                                </dl>
                                <dl className="" >
                                    <dt>신청사유</dt>
                                    <dd><input type="text" name="input-popup" placeholder="신청사유를 입력하세요." value={requestMessage} onChange={(e) => { setRequestMessage(e.target.value) }} /></dd>
                                </dl>

                            </div>
                            <div className="btn-wrap">
                                <button type="button" className="btn-secondary" onClick={() => {
                                    props.setIsShowRequestPopup(false);
                                }}>취소</button>
                                <button type="button" className={(Object.keys(selectedGroup).length < 1 && popupAuthKind === "auth-acc")
                                    || (selectedDevUser.basicDevGroupSeq == "1" && popupAuthKind === "auth-dev")
                                    || Object.keys(selectedDevUser).length == 0
                                    || requestMessage.length == 0 ? "btn-dis" : "btn-primary"}
                                    onClick={() => {
                                        popupAuthKind === "auth-dev" ? userAuthReqSave() : reqGroupMemberSave()
                                    }}>
                                    신청</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    if (isLoadDevUserData && isLoadGroupData) {
        return authReqPopup();
    }
    else
        return null;

}
export default AuthRequest;