import React, { Fragment, useRef, useState, useEffect } from 'react'
import PopUp from '../Common/PopUp';
import Filter from '../Common/Filter';
import Table from '../Common/Table';

export function usePrevState(state) {
    const ref = useRef(state);
    useEffect(() => {
        ref.current = state;
    }, [state]);
    return ref.current;
}

const UserAccountManagement = () => {
    const [totalList, setTotalList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [popupContent, setPopupContent] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [displayCompanyList, setDisplayCompanyList] = useState([]);
    const [selectedCompanyList, setSelectedCompanyList] = useState([]);
    const outsideRef = useRef(null);
    const prevTotalList = usePrevState(totalList);
    const prevDisplayCompanyList = usePrevState(displayCompanyList);
    const prevSelectedCompanyList = usePrevState(selectedCompanyList);
    const authGradeList = ["", "Staff", "Manager"];
    let selectedAuthGrade = '', selectedRecvMail = '0';

    useEffect(() => {
        if (totalList.length == 0 && userList.length == 0) {
            fetch('api/User/GetUserInfo', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => response.json())
                .then((data) => {
                    setTotalList(data.table);
                    setUserList(data.table);
                });
        }
    }, [totalList, userList, displayCompanyList, selectedCompanyList]);

    const handleChange = (e) => {
        if (e.target.id == 'authGrade') {
            selectedAuthGrade = (e.target.value == '없음' ? '' : e.target.value);
        } else if (e.target.id == 'recvMail') {
            selectedRecvMail = e.target.value;
        }
    }

    const getSendOtpName = (value) => {
        let Text = '사용안함';
        if (value == 1) {
            Text = '메일';
        }
        if (value == 2) {
            Text = '카카오톡';
        }
        return Text;
    }
    const btnOnPopup = (e, item, index) => {
        setPopupContent(item != null && item != undefined
            ? renderMoreMenu(item, index) : null);
    }

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseDown = (event) => {
        setPosition({ x: event.clientX, y: event.clientY });
    };

    const renderMoreMenu = (item, index) => {
        return (
            <div className="layer-list admin-more-menu" style={{ top: position.y + 'px', left: position.x + 'px' }}>
                <div className="box">
                    <ul>
                        <li><a onClick={e => renderSelectedUserInfo(item, index)}>사용자 정보</a></li>
                        <li><a onClick={e => setTempPassword(item)}>임시비밀번호 발급</a></li>
                        <li><a onClick={e => resetLoginFailCnt(item, index)}>로그인 실패횟수 초기화</a></li>
                    </ul>
                </div>
            </div>
        );
    }

    const renderSelectedUserInfo = (item, index) => {
        let pwdChgDate = '';

        if (item != null && item != undefined && item.pwdChgDate != null && item.pwdChgDate != undefined) {
            if (item.pwdChgDate.trim() != '') {
                pwdChgDate = item.pwdChgDate.substr(0, 4) + '.' + item.pwdChgDate.substr(4, 2) + '.' + item.pwdChgDate.substr(6, 2);
            }
        }
        setPopupContent(item != null && item != undefined
            ? <div className="msg-popup admin-user-info">
                <div className="box">
                    <div className="title-wrap">
                        <p className="title">사용자 정보</p>
                        <button className="btn-close-popup" onClick={e => btnOnPopup(e, null, null)}><i className="i-close-popup"></i></button>
                    </div>
                    <form className="form-wrap">
                        <div className="user-info-list">
                            <dl className="" id="kindText">
                                <dt>이름</dt>
                                <dd>{item.devUserName}</dd>
                            </dl>
                            <dl className="" id="">
                                <dt>이메일 아이디</dt>
                                <dd>{item.devUserID}</dd>
                            </dl>
                            <dl className="" id="">
                                <dt>소속</dt>
                                <dd>{item.getCompanyName}</dd>
                            </dl>
                            {item.deptName != null && item.deptName != '' ?
                                <dl className="">
                                    <dt>부서</dt>
                                    <dd>{item.deptName}</dd>
                                </dl> : null}
                            {item.getKindName != null && item.getKindName != '' ?
                                <dl className="">
                                    <dt>직무</dt>
                                    <dd>{item.getKindName}</dd>
                                </dl> : null}

                            <dl className="" id="">
                                <dt>2차인증</dt>
                                <dd>{getSendOtpName(item.sendOtpType)}</dd>
                            </dl>

                            <dl>
                                <dt>관리자 권한</dt>
                                <dd>
                                    <select id="authGrade" defaultValue={item.authGrade ?? ''} onChange={e => handleChange(e)}>
                                        {authGradeList.map((item, i) => (
                                            <option value={item} key={"authGrade" + i}>
                                                {item == '' ? '없음' : item}
                                            </option>
                                        ))}
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt>관리자 메일</dt>
                                <dd>
                                    <select id="recvMail" defaultValue={item.recvMail ?? '0'} onChange={e => handleChange(e)}>
                                        <option value="1" key="recvMail1">수신함</option>
                                        <option value="0" key="recvMail2">수신안함</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl className="" id="">
                                <dt>비밀번호 변경일</dt>
                                <dd>{pwdChgDate}</dd>
                            </dl>
                            <dl className="" id="">
                                <dt>로그인 실패횟수</dt>
                                <dd>{item.loginFailCnt != null ? item.loginFailCnt + "회" : "0회"}</dd>
                            </dl>
                            <dl className="" id="">
                                <dt>마지막 로그인</dt>
                                <dd>{item.lastLoginDateTime != null ? dateFormat(new Date(item.lastLoginDateTime)) : '미접속'}</dd>
                            </dl>

                        </div>
                    </form>
                    <div className="btn-wrap">
                        <button className="btn-primary" onClick={e => saveDevelopersCenterAdmin(item, index)}>업데이트</button>
                    </div>
                </div>
            </div> : null);
    }
    const setTempPassword = (item) => {
        if (window.confirm(item.devUserID + '에 대한 임시비밀번호 발급을 진행합니다. 계속하시겠습니까?')) {
            const userNameId = {
                userName: item.devUserName,
                userId: encodeURI(item.devUserID)
            };

            fetch('api/Login/FindPassword', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(userNameId)
            }).then(async (response) => response.json())
                .then((responseData) => {
                    if (responseData.status == "Success Finding") {
                        alert('임시 비밀번호 발급에 성공하였습니다.');
                        setPopupContent(null);
                    }
                    else if (responseData.status == "Fail Sending") {
                        alert(responseData.message);
                    }
                });
        }
    }
    const resetLoginFailCnt = (item, index) => {
        if (window.confirm(item.devUserID + '에 대한 로그인 실패횟수 초기화를 진행합니다. 계속하시겠습니까?')) {
            fetch('api/User/ResetLoginFailCnt?userId=' + item.devUserID, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then(async (response) => response.json())
                .then((responseData) => {
                    if (responseData.table[0].status == 'true') {
                        alert('초기화에 성공하였습니다.');
                        let newUserList = userList;
                        newUserList[index].loginFailCnt = 0;
                        setUserList(newUserList);
                        setPopupContent(null);
                    }
                    else {
                        alert(responseData.message);
                    }
                });
        }
    }
    const saveDevelopersCenterAdmin = (item, index) => {
        fetch('api/User/SaveDevelopersCenterAdmin?userId=' + item.devUserID + '&authGrade=' + selectedAuthGrade + '&recvMail=' + selectedRecvMail, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(async (response) => response.json())
            .then((responseData) => {
                if (responseData.table[0].status == 'true') {
                    alert('저장이 완료되었습니다.');
                    let newUserList = userList;
                    newUserList[index].authGrade = selectedAuthGrade;
                    newUserList[index].recvMail = selectedRecvMail;
                    setUserList(newUserList);
                    setPopupContent(null);
                }
                else {
                    alert(responseData.message);
                }
            });
    }
    const dateFormat = (date) => {
        let month = date.getMonth() + 1, day = date.getDate(), hour = date.getHours(), minute = date.getMinutes();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;

        return date.getFullYear() + '.' + month + '.' + day + ' ' + hour + ':' + minute;
    }
    const deleteCompany = (selectedCompanyList, companyName) => {
        let list = selectedCompanyList;
        setSelectedCompanyList(list.filter(x => x != companyName));
    }
    const renderUserList = () => {
        let list = [];
        for (let i = 0; i < userList.length; i++) {
            let pwdChgDate = '';
            if (userList[i].pwdChgDate != null) {
                if (userList[i].pwdChgDate.trim() != '') {
                    pwdChgDate = userList[i].pwdChgDate.substr(0, 4) + '.' + userList[i].pwdChgDate.substr(4, 2) + '.' + userList[i].pwdChgDate.substr(6, 2);
                }
            }
            list.push(
                <Fragment key={i}>
                    <tr>
                        <td>{userList[i].devUserName}</td>
                        <td>{userList[i].devUserID}</td>
                        <td>{userList[i].getCompanyName}</td>
                        <td>{getSendOtpName(userList[i].sendOtpType)}</td>
                        <td>{userList[i].lastLoginDateTime != null ? dateFormat(new Date(userList[i].lastLoginDateTime)) : '미접속'}</td>
                        <td>{pwdChgDate}</td>
                        <td>{userList[i].loginFailCnt ?? 0}회</td>
                        <td>{userList[i].authGrade ?? '없음'}</td>
                        <td><button className="btn-admin-more" onClick={e => btnOnPopup(e, userList[i], i)}><i className="i-btn-more"></i></button></td>
                    </tr>
                </Fragment>
            );
        }
        return list;
    }
    const list = renderUserList();

    const setHeaders = () => {
        return [
            "이름",
            "이메일 아이디",
            "소속",
            "2차인증",
            "최종로그인일시",
            "비밀번호변경일",
            "로그인실패",
            "관리자권한",
            ""
        ];
    };

    return (
        <div className="content" onMouseDown={handleMouseDown} >
            <h2>사용자관리</h2>
            <div className="list-tools">
                <Filter key={0}
                    listData={totalList}
                    setListView={setUserList}
                    multiKeywords={[{ name: "getCompanyName", title: "소속" }]}
                    searchKeyword={{ name: ["devUserID", "devUserName"], title: "이름 또는 아이디로 검색" }}
                />
            </div>
            <Table className={"userlist"} headers={setHeaders()} list={list} />
            <PopUp setContent={() => btnOnPopup()} content={popupContent} />
        </div>
    );
}
export default UserAccountManagement;