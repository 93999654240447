import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PopUp from '../Common/PopUp';
import UserInfo from '../AccountPanels/UserInfo';
import TopicList from './TopicPanels/TopicList';
import TopicView from './TopicPanels/TopicView';
import TopicWrite from './TopicPanels/TopicWrite';

const Topic = (props) => {
    const editorRef = React.createRef();
    const LoginStates = useSelector(state => state.loginActions);
    const [isRun, setIsRun] = useState(false);

    const [state, setState] = useState({
        catagoryList: [],
        current: props.current,
        currentPage: 1,
        content: '',
        docPath: props.nodePath != undefined ? props.nodePath : '',
        selectedCatagorySeq: 0,
        topicList: [],
        topicView: [],
        subTopic: [],
        topicSeq: 0,
        isReceiveMail: '0',
        workingTag: 'A',
        popupContent: null,
        pageCount: 10
    });

    const [prevCurrent, setPrevCurrent] = useState();
    
    useEffect(() => {
        getTopicList();
    }, []);

    useEffect(() => {
        checkCurrent();
    }, [props.current]);

    const checkCurrent = () => {
        if (props.current != prevCurrent) {
            setState(prev => {return {
                ...prev,
                current: props.current
            }});
            setPrevCurrent(props.current);
        }
    };

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseDown = (event) => {
        setPosition({ x: event.clientX, y: event.clientY });
    };

    const btnOnPopup = (item, e) => {
        let kindName = '';
        if (item != null && item.kind != null && props.isAdmin) {
            if (item.kind == 1) {
                kindName = '개발자';
            } else if (item.kind == 2) {
                kindName = '컨설턴트';
            } else if (item.kind == 3) {
                kindName = '영업';
            } else if (item.kind == 0) {
                kindName = item.kindText;
            }
        }
        setState(prev => {return {
            ...prev,
            popupContent: (item != null
                ? <div className="layer-list qna-user-info" style={{ top: e.clientY + 'px', left: e.clientX + 'px' }}>
                    <div className="box">
                        <ul>
                            <li className="name">{!props.isAdmin && item.subTopicSeq != undefined ? '관리자' : item.writer}{kindName != '' ? ' (' + kindName + ')' : null}</li>
                            <li>{!props.isAdmin && item.subTopicSeq != undefined ? 'platformnewtech@ksystem.co.kr' : item.writerUserId}</li>
                            <li>{item.companyName}</li>
                            {props.isAdmin && item.deptName != null ? <li>{item.deptName}</li> : null}
                        </ul>
                    </div>
                </div> : null)
        }});
    };

    const loadContent = () => {
        let current = state.current;
        if (state.catagoryList.length == 0) return;
        if ((current == 'topicList' || current == 'topicSearch')) {
            return <TopicList state={state}
                isAdmin={props.isAdmin}
                fnSearchTopic={searchTopic}
                fnOnChangeMode={onChangeMode}
                fnBtnOnPopup={btnOnPopup}
                btnTopicWriteName={'1:1 문의하기'}
                topicListTitle={props.isAdmin ? '1:1 문의관리' : '1:1 문의'}
                companyType={LoginStates.get('companyType')} />
        }
        else if (current == 'topicWrite') {
            let selectedCatagory = state.catagoryList.find(e => e.catagoryName == props.catagoryName);
            let selectedCatagorySeq = selectedCatagory != undefined ? selectedCatagory.catagorySeq : state.selectedCatagorySeq;
            let topicMessageInfo = [];
            topicMessageInfo.push(
                <div className="info-wrap">
                    <p className="info">K Developers에서 제공하는 문서 및 매뉴얼의 내용 및 사이트 이용에 관한 문의만 답변이 가능합니다.</p>
                    <p className="info">ACE 등 제품군 관련 문의는 본 사이트에서 직접 답변드리기 어렵습니다.</p>
                    <p className="info"><b>각 제품군 별 담당팀의 질의 답변 혹은 안내는 영림원 A/S 접수를 이용 부탁드립니다.</b>
                        <a href="https://erpnewas.ksystem.co.kr/" target="_blank" rel="noreferrer" className="btn-go-as">A/S 접수 바로가기</a>
                    </p>
                </div>
            );
           
           
            return <TopicWrite state={state}
                fnOnChangeMode={onChangeMode}
                fnTopicSave={topicSave}
                catagorySeq={selectedCatagorySeq}
                catagoryName={props.catagoryName}
                topicMessageInfo={topicMessageInfo}
                topicWriteTitle={'1:1 문의'} />
        }
        else if (current == 'topicView') {
            getTopicView();
            return <TopicView state={state}
                isAdmin={props.isAdmin}
                fnOnChangeMode={onChangeMode}
                fnBtnOnPopup={btnOnPopup}
                fnSubTopicSave={subTopicSave}
                fnSubTopicDelete={subTopicDelete}
                topicViewTitle={props.isAdmin ? '1:1 문의관리' : '1:1 문의'} />
        }
        else
            return (
                <UserInfo />
            );
    };

    const getTopicList = (currentPage) => {
        const userInfo = {
            keyword: '',
            userId: props.isAdmin ? '' : LoginStates?.get('userId'),
            pageCount: state.pageCount,
            currentPage: currentPage != undefined ? currentPage : state.currentPage
        };
        fetch('api/FeedBack/TopicListQuery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(userInfo)
        }).then((response) => response.json())
            .then((data) => {
                if (JSON.stringify(data.table) != JSON.stringify(state.topicList) || state.workingTag == 'U' || data.table.length == 0) {
                    setState(prev => {return {
                        ...prev,
                        current: (props.locationFrom != undefined ? props.current : 'topicList'),
                        topicList: data.table,
                        catagoryList: data.table1,
                        topicView: [],
                        subTopic: [],
                        selectedCatagorySeq: 0,
                        title: '',
                        content: '',
                        isReceiveMail: '0',
                        workingTag: 'A'
                    }});
                }
            });
    };

    const getTopicView = () => {
        const viewInfo = {
            topicSeq: state.topicSeq,
            userId: props.isAdmin ? '' : LoginStates.get('userId')
        };
        fetch('api/FeedBack/TopicQuery', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(viewInfo)
        }).then((response) => response.json())
            .then((data) => {
                if (JSON.stringify(data.table) != JSON.stringify(state.topicView) || JSON.stringify(data.table1) != JSON.stringify(state.subTopic)) {
                    setState(prev => {return {
                        ...prev,
                        current: 'topicView',
                        topicView: data.table,
                        subTopic: data.table1,
                        selectedCatagorySeq: 0,
                        title: '',
                        content: '',
                        isReceiveMail: '0',
                        workingTag: 'A'
                    }});
                }
            });
    };

    const onChangeMode = (type, currentPage, topicSeq, topicView) => {
        if (type == 'topicUpdate') {
            setState(prev => {return {
                ...prev,
                current: 'topicWrite',
                selectedCatagorySeq: topicView != undefined ? topicView[0].catagorySeq : 0,
                title: topicView != undefined ? topicView[0].title : '',
                content: topicView != undefined ? topicView[0].content : '',
                isReceiveMail: topicView != undefined ? topicView[0].isReceiveMail : '',
                workingTag: 'U'
            }});
        } else if (type == 'topicDelete') {
            if (window.confirm('삭제하시겠습니까?')) {
                topicDelete();
            }
        } else {
            setState(prev => {return {
                ...prev,
                current: type,
                currentPage: currentPage != undefined ? currentPage : state.currentPage,
                topicSeq: topicSeq != undefined ? topicSeq : 0,
                selectedCatagorySeq: 0,
                title: '',
                content: '',
                isReceiveMail: '0',
                workingTag: 'A'
            }});
            if (currentPage != undefined) {
                getTopicList(currentPage);
            }
        }
    };

    const searchTopic = (currentPage) => {
        let keyword = '', type = '1';
        keyword = document.getElementById('searchKeyword') != null ? document.getElementById('searchKeyword').value : '';
        let regExp = /([!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\“\’\,\.\/\`\?])/g;
        // 특수문자는 like 검색 시 대괄호([]) 씌워준다.
        keyword = keyword.replace(regExp, '[$1]');
        let sel = document.getElementById('ctgrSelect');
        type = sel != null ? sel.options[sel.selectedIndex].value : '1';
        const keywordInfo = {
            keyword: keyword,
            keywordType: type,
            userId: props.isAdmin ? '' : LoginStates.get('userId'),
            pageCount: state.pageCount,
            currentPage: currentPage
        };
        fetch('api/FeedBack/TopicSearch', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(keywordInfo)
        }).then((response) => response.json())
            .then((data) => {
                if (JSON.stringify(data.table) != JSON.stringify(state.topicList)) {
                    setState(prev => {return {
                        ...prev,
                        current: 'topicSearch',
                        topicList: data.table,
                        currentPage: currentPage
                    }});
                }
            });
    };
    
    const setHyperLinkRegExp = (text) => {
        // 하이퍼링크 새창으로 띄우기
        // const regex = /<a\s+[^>]*href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/gi;
        // return text.replace(regex, "<a target='_blank' rel='noreferrer' href=$1$2$1>$3</a>");
        return text;
    };

    const topicSave = (topicInfo) => {
        if (isRun) return;
        // isRun = true;
        setIsRun(true);
        if (topicInfo.title == '') {
            alert('제목을 입력하세요.');
            // isRun = false;
            setIsRun(false);
            return;
        }
        if (topicInfo.selectedCatagorySeq == 0) {
            alert('카테고리를 선택하세요.');
            // isRun = false;
            setIsRun(false);
            return;
        }
        if (window.confirm('등록하시겠습니까?')) {
            // 중복 처리 방지를 위해 disabled 처리
            document.getElementsByClassName('btn-primary')[0].disabled = true;
            const topicSaveInfo = {
                workingTag: state.workingTag,
                catagorySeq: topicInfo.selectedCatagorySeq,
                title: topicInfo.title,
                content: setHyperLinkRegExp(topicInfo.content),
                contentText: topicInfo.contentText,
                docPath: topicInfo.docPath,
                writer: LoginStates.get('userName'),
                writerUserId: LoginStates.get('userId'),
                isReceiveMail: topicInfo.isReceiveMail,
                topicSeq: topicInfo.topicSeq
            };
            fetch('api/FeedBack/TopicSave', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(topicSaveInfo)
            }).then((response) => response.json())
                .then((data) => {
                    // isRun = false;
                    setIsRun(false);
                    getTopicList();
                });
        } else {
            // isRun = false;
            setIsRun(false);
        }
    };

    const topicDelete = () => {
        const topicInfo = {
            topicSeq: state.topicSeq
        };
        fetch('api/FeedBack/TopicDelete', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(topicInfo)
        }).then((response) => response.json())
            .then((data) => {
                getTopicList();
            });
    };

    const subTopicSave = (topicInfo) => {
        var content = topicInfo != undefined ? topicInfo.content : editorRef.current.getInstance().getHTML();
        if (content == '') {
            alert('내용을 입력하세요.');
            return;
        }
        if (window.confirm('등록하시겠습니까?')) {
            content = setHyperLinkRegExp(content);
            var contentText = topicInfo != undefined ? topicInfo.contentText : editorRef.current.getInstance().getMarkdown();
            const subTopicInfo = {
                workingTag: topicInfo != undefined ? topicInfo.workingTag : state.workingTag,
                topicSeq: state.topicSeq,
                content: content,
                contentText: contentText,
                writer: LoginStates.get('userName'),
                writerUserId: LoginStates.get('userId')
            };
            fetch('api/FeedBack/SubTopicSave', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(subTopicInfo)
            }).then((response) => response.json())
                .then((data) => {
                    getTopicView();
                });
        }
    };

    const subTopicDelete = (subTopicSeq) => {
        const subTopicInfo = {
            topicSeq: state.topicSeq,
            subTopicSeq: subTopicSeq
        };
        fetch('api/FeedBack/SubTopicDelete', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(subTopicInfo)
        }).then((response) => response.json())
            .then((data) => {
                getTopicView();
            });
    };

    let content = loadContent();
    return (
        <div className="content">
            {content}
            <PopUp setContent={btnOnPopup} content={state.popupContent} />
        </div>
    );
}

export default Topic;