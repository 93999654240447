import { useEffect, useState } from "react";
import { includeByCho } from "../libs/Util";

export const NameCard = ({
  user, classWrapper, classContent
}) => {
  const devAuthOptions = {
    "1": "패키지AS",
    "2": "Ever",
    "3": "사업부AS",
    "4": "협력사AS",
    "5": "고객사AS",
  };
  return (
    <div className={classWrapper}>
      <div className={classContent}>
          <i></i>
          <div className="name-wrap">
              <span className="username">{user.devUserName}</span>
              <span className="current">{devAuthOptions[user.basicDevGroupSeq]}</span>
          </div>
          <span className="email">{user.devUserId}</span>
          <span className="company">{user.companyName}</span>
      </div>
    </div>
  )
};

export const SearchPerson = ({
  userData, classWrapper, popupAuthKind, handleClick
}) => {
  const [inputText, setInputText] = useState();
  const [devUserPopupContent, setDevUserPopupContent] = useState(null);
  const [searchDevUser, setSearchDevUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const devAuthOptions = {
    "1": "패키지AS",
    "2": "Ever",
    "3": "사업부AS",
    "4": "협력사AS",
    "5": "고객사AS",
  };

  useEffect(() => {
    searchCodeHelp();
  }, [inputText]);

  useEffect(() => {
      if (devUserPopupContent != null) {
          setDevUserPopupContent(searchDevUserPopup());
      }
  }, [searchDevUser]);

  useEffect(() => {
    handleClick(selectedUser);
  }, [selectedUser]);

  //검색 기능
  const searchCodeHelp = () => {
    let result = userData;

    if (devUserPopupContent != null) {
        if (inputText != null && inputText.length > 0) {
            result = result.filter((item) => includeByCho(inputText.toUpperCase(), item.devUserName.toUpperCase()));
        }

        if (popupAuthKind === "auth-dev") {
            result = result.filter((item) => item.basicDevGroupSeq !== 1);
        }
        setSearchDevUser(result);
    }
  };

  //대상자 검색 팝업의 개발자 표시
  const setDevUserList = () => {
    let result = [];
    let devUserList = {};

    if (popupAuthKind === "auth-dev") {
        devUserList = searchDevUser.filter((item) => item.basicDevGroupSeq !== 1);
    }
    else {
        devUserList = searchDevUser;
    }

    for (let i = 0; i < devUserList.length; i++) {
        result.push(
            <a className="item" key={i} onClick={(e) => {
                setSelectedUser(searchDevUser[i]);
                setDevUserPopupContent(null);
                setInputText();
            }}>
                <i></i>
                <div className="name-wrap">
                    <span className="username">{devUserList[i].devUserName}</span>
                    <span className="current">{devAuthOptions[devUserList[i].basicDevGroupSeq]}</span>
                </div>
                <span className="email">{devUserList[i].devUserId}</span>
                <span className="company">{devUserList[i].companyName}</span>

            </a>
        );
    }
    return result;
  };

  //권한 대상자 검색 팝업
  const searchDevUserPopup = () => {
    let devUserData = setDevUserList();

    return (
        <div className="msg-popup-wrap dim">
            <div className="msg-popup auth-slt-target">
                <div className="box">
                    <div className="title-wrap">
                        <p className="title">권한을 부여받을 대상자를 선택하세요.</p>
                        <button type="button" className="btn-close-popup"
                            onClick={() => {
                                setDevUserPopupContent(null);
                                setInputText();
                            }} ><i className="i-close-popup"></i></button>
                    </div>

                    <div className="form-wrap">
                        <div className="search-input" >
                            <input type="text" name="input-popup" onChange={(e) => {
                                setInputText(e.target.value);
                            }} placeholder="대상자 검색" />
                        </div>
                        <div className="target-list">
                            {devUserData}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  };

  //선택된 권한대상자 또는 검색 Input 표기
  return (
    <>
      <div className={classWrapper} onClick={() => {
          setInputText('');
          setDevUserPopupContent(searchDevUserPopup());
      }}>
          {Object.keys(selectedUser).length === 0 ?
            <span className="plchd" >권한을 부여받을 대상을 검색하세요.</span>
          :
            <NameCard user={selectedUser} classWrapper={classWrapper} classContent={"selected-user-info"} />
          }
          <button type="button" className="btn-search">
            <i></i>
          </button>
      </div>
      {devUserPopupContent}
    </>
  );
};