import React, { Component, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

const Messages = () => {
    const MessageStates = useSelector((state) => state.messageActions);
    const [visible, setVisible] = useState(false);

    const message = MessageStates.get('message');
    // let classes = 'toast ' + MessageStates.get('level') + ' ';
    const [classes, setClasses] = useState('toast ' + MessageStates.get('level') + ' ');

    useEffect(() => {
        const status = visible ? 'visible' : '';
        setClasses('toast ' + MessageStates.get('level') + ' ' + status);
    }, [visible]);

    useEffect(() => {
        setVisible(MessageStates.get('visible'));
    }, [MessageStates]);
        
    return (
        <div className={classes}>
            <figure>
                <img alt="" />
            </figure>
            <p>{message}</p>
        </div>
    );
};

export default Messages;