import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as contentActions from '../../store/modules/contentActions';
import * as messageActions from '../../store/modules/messageActions';
import { bindActionCreators } from 'redux';
import crypto from 'crypto-browserify';
import dotenv from 'dotenv';
dotenv.config();
const DOCUMENT_LINK_KEY = process.env.REACT_APP_DOCUMENT_LINK_KEY;

export const Encrypt = (text) => {    
    const cipher = crypto.createCipher('aes-128-cbc', DOCUMENT_LINK_KEY, '');
    let result = cipher.update(text, 'utf8', 'base64');
    result += cipher.final('base64');
    //암호화 시 url safe 처리
    return result.replace(/\//g, '_').replace(/\+/g, '-');
}
export const Decrypt = (text) => {
    const decipher = crypto.createDecipher('aes-128-cbc', DOCUMENT_LINK_KEY, '');
    //복호화 시 url safe 처리
    let result = decipher.update(text.replace(/\_/g, '/').replace(/\-/g, '+'), 'base64', 'utf8');
    result += decipher.final('utf8');

    return result;
}

export const GetLink = (data) => {
    const linkPath = data.contentType + '||' + data.contentName + '||' + data.folderName + '||' + data.type + '||' + data.path + '||' + data.url;
    return window.location.origin + '/?p=' + Encrypt(linkPath);
}

const DocumentLink = (props) => {
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    const ContentStates = useSelector(state => state.contentActions);
    const MessageActions = bindActionCreators(messageActions, dispatch);

    const clickCopyDoc = () => {
        let contentType, contentName, type, folderName, linkPath, url, video;
        var textArea = document.createElement("textarea");
        contentType = ContentStates.get('contentType');
        contentName = ContentStates.get('contentName');
        folderName = ContentStates.get('folderName') || '';
        type = ContentStates.get('docType') || '';
        url = ContentStates.get('url') || '';
        video = ContentStates.get('video') || '';
        linkPath = contentType + '||' + contentName + '||' + folderName + '||' + type + '||' + props.path.substring(props.path.indexOf('/') + 1) + '||' + url + '||' + video;
        textArea.value = window.location.origin + '/?p=' + Encrypt(linkPath);
        document.body.appendChild(textArea);
        textArea.select();
        var result = document.execCommand("copy");
        textArea.style.display = "none";
        if (result)
            showToast("success", "링크 주소가 복사되었습니다.");
    }

    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    }

    return (
        <button className="btn-copy-url-doc"
            title="링크 복사"
            onClick={clickCopyDoc}>
            <i className="i-copy-url-doc"></i>
        </button>
    );
}
export default DocumentLink;