import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const initialState = Map({
    latestViews: [],
    curCategory: '',
    curTitle: '',
    curPath:''
});

const LATESTVIEWINFO = 'simpleViewActions/LATESTVIEWINFO';
const CURRENTVIEWINFO = 'simpleViewActions/CURRENTVIEWINFO';
const SETSIMPLEVIEWINFO = 'simpleViewActions/SETSIMPLEVIEWINFO';

export const latestViewInfo = createAction(LATESTVIEWINFO);
export const currentViewInfo = createAction(CURRENTVIEWINFO);
export const setSimpleViewInfo = createAction(SETSIMPLEVIEWINFO);

export default handleActions({
    //최근 조회한 4항목 정보(로그인 했을 시)
    [LATESTVIEWINFO]: (state, action) => {
        return state.set('latestViews', action.payload.latestViews);
    },
    //현재 조회 중인 뷰 정보
    [CURRENTVIEWINFO]: (state, action) => {
        return state.set('curCategory', action.payload.curCategory)
            .set('curTitle', action.payload.curTitle)
            .set('curPath', action.payload.curPath);
    },
    //저장된 기록 받아오기
    [SETSIMPLEVIEWINFO]: (state, action) => {
        return state.merge(action.payload);
    }

}, initialState);
