import { useEffect, useState } from "react";

export const DateSelector = ({
  title, isFromTo, handleChange
}) => {
  const date = new Date();
    const dateInfo = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    };
    const formattedDate = (date, year = 0, month = 0, day = 0) => {
        return `${(date.year + year)}-${(date.month + month).toString().padStart(2, '0')}-${(date.day + day).toString().padStart(2, '0')}`;
    };
    const defaultDateFrom = formattedDate(dateInfo);
    const defaultDateTo = formattedDate(dateInfo, 1, 0, -1);
    const [dateFrom, setDateFrom] = useState(defaultDateFrom);
    const [dateTo, setDateTo] = useState(defaultDateTo);

    useEffect(() => {
      handleChange(dateFrom, dateTo);
    }, [dateFrom, dateTo]);

  return (
    <>
      <dt>{title}</dt>
      <dd>
          <div className="date-input">
              {isFromTo ? 
                <>
                  <input type="date" min={defaultDateFrom} value={dateFrom} max="9999-12-31" onChange={(e) => { setDateFrom(e.target.value); }} />
                  <span className="txt">~</span>
                </> 
                : null
              }
              <input type="date" min={defaultDateTo} value={dateTo} max="9999-12-31" onChange={(e) => { setDateTo(e.target.value); }} />
          </div>
      </dd>
    </>
  )
};