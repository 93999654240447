import { Fragment, useEffect, useState } from "react";

export const SelectRadio = ({
  name, filename, data, select, handleClick
}) => {
  const [list, setList] = useState([]);
  const getFile = () => {
    fetch(`./datas/${filename}.json`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
        }
    }).then(response => response.json())
        .then(json => {
          setList(json);
        })
  };

  useEffect(() => {
    if (filename)
      getFile();
    else 
      setList(data);
  }, []);
  
  return (
    <div className={name}>
        {list.map((item, index) => {
          return (
            <Fragment key={index}>
              <input name="" type="radio" value={index} checked={select === index} readOnly />
              <label onClick={() => handleClick(index)}>{item.name}</label>
            </Fragment>
          )
        })}
    </div>
  )
}