import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams,  } from 'react-router-dom';

import LoginPanel from './LoginPanels/LoginPanel';
import PwChangePanel from './LoginPanels/PwChangePanel';
import SignUpPanel from './LoginPanels/SignUpPanel'; 
import FindIdPasswordPanel from './LoginPanels/FindIdPasswordPanel';
import MessagePanel from './LoginPanels/MessagePanel';
import { NavMenu } from './NavMenu';

import { useDispatch, useSelector } from 'react-redux';
import * as loginActions from '../store/modules/loginActions';
import * as messageActions from '../store/modules/messageActions';
import { bindActionCreators } from 'redux';

import storage from './libs/storage';

const Login = () => {

    const LoginStates = useSelector((state) => state.loginActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    const MessageActions = bindActionCreators(messageActions, dispatch);
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const [companyList, setCompanyList] = useState('');
    const [deptList, setDeptList] = useState('');
    const [panelType, setPanelType] = useState('');    

    useEffect(() => {
        getCompanyAndDeptList();
        if (params.panel != '' && params.panel != undefined) {
            setPanelType(params.panel);
        }        
    }, []);

    useEffect(() => {
        if (location.state) {
            if (location.state.panelType)
                setPanelType(location.state.panelType);
        }

    }, [location.state]);
    // const goBackLogin = () => {
    //     LoginActions.panelLoad('loginAgain');
    //     LoginActions.init();
    // };

    const getCompanyAndDeptList = async () => {
        fetch('api/User/GetCompanyAndDeptListQuery?userId=-1', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then((response) => response.json())
            .then((data) => {
                if (Object.keys(data).length != 0) {
                    let companyList = data.table ?? '', deptList = data.table1 ?? '';
                    setCompanyList(companyList);
                    setDeptList(deptList);
                }
            });
    };
    
    //웹사이트 메인화면으로 경로 변경해주는 함수
    const routeChange = () => {
        let path = location.state?.path || '/';
        const loginStates = storage.get('loginStates');
        const from = location.state || ''
        // 로그인 성공 정보가 서버에 없으면 로컬에 복사하지 않는다.
        if (LoginStates.get('sessionID') != '') {
            storage.set('loginStates', LoginStates);
            if (from != '' && typeof from === 'string')
                window.location.href = path + "?p=" + from;
            else
                navigate(path);
        }
        // 로컬에 로그인 성공 정보가 없으면 메인으로 이동하지 않는다.
        if (LoginStates.get('sessionID') == '' && loginStates.get('sessionID') != '') {
            if (from != '' && typeof from === 'string')
                window.location.href = path + "?p=" + from;
            else
            navigate(path);
        }
            
    };

    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);

    };
    useEffect(() => {
        actionResult();
    },[LoginStates.get('result')]);

    //렌더링될 때마다 돌며 패널을 바꿔주는 함수
    const actionResult = () => {
        const result = LoginStates.get('result');
        
        if (result == '') {
            //result 값이 없는 경우는 화면 이벤트에 의한 Action
            setPanelType(panelType);
            //return panelType;
        }
        else {
            if (result == 'Success Login') {
                showToast('success', LoginStates.get('message'));
                setPanelType('doMain');
                //return 'doMain';                
            }
            else {
                //callBack에 의한 로그인 실패 로직.
                if (result == 'Fail Login') {
                    showToast('danger', LoginStates.get('message'), 3000);
                    setPanelType('loginAgain');
                    //return 'loginAgain';
                }
                else if (result == 'Change Password') {
                    showToast('warning', LoginStates.get('message'), 3000);
                    setPanelType('changePassword');
                    //return 'changePassword';
                }
                else if (result == 'Over Failed Count') {
                    setPanelType('showMessage');
                    //return 'showMessage';
                }
                else if (result == 'Success Finding') {
                    setPanelType('showMessage');
                    //return 'showMessage';
                }
                else if (result == 'Success Changing Password') {
                    showToast('success', LoginStates.get('message'));
                    setPanelType('loginAgain');
                    //return 'loginAgain';
                }
                else
                {   
                    setPanelType('showMessage');
                    //return 'showMessage';
                }
            }
        }
    };

    const panelChange = () => {

        if (panelType == '' | panelType.toLowerCase() == 'loginagain') {
            return (
                <LoginPanel panelType = { panelType } setPanelType = { setPanelType } />
            );
        }
        else if (panelType.toLowerCase() == 'changepassword') {
            return (
                <PwChangePanel panelType={panelType} setPanelType={setPanelType} />
            );
        }
        else if (panelType.toLowerCase() == 'signup') {
            return (
                <SignUpPanel panelType={panelType} setPanelType={setPanelType}  companyList={companyList} deptList={deptList}/>
            );
        }
        else if (panelType.toLowerCase() == 'showmessage') {
            return (
                <MessagePanel panelType={panelType} setPanelType={setPanelType}  />
            );
        }
        else if (panelType.toLowerCase() == 'findpassword') {
            return (
                <FindIdPasswordPanel panelType={panelType} setPanelType={setPanelType}  />
            );
        }
        else if (panelType.toLowerCase() == 'findinfo') {
            return (
                <FindIdPasswordPanel panelType={panelType} setPanelType={setPanelType} />
            );
        }
        else if (panelType.toLowerCase() == 'domain') {
            routeChange();
        }
        else {
            return (
                <div>No Panel</div>
            );
        }

    };
  
    const loginStates = storage.get('loginStates');
    if (loginStates) {
        const date = loginStates.get('logindate');
        if (date) {
            var difference = Math.floor(date.replace(/-/gi, "") - new Date().toISOString().slice(0, 10).replace(/-/gi, ""));
            if (difference < 3) {
                routeChange();
            }
            else
                storage.remove('loginStates');
        } else {
            storage.remove('loginStates');
        }
    }

    
    
    return (
        <Fragment>
            {panelChange()}
        </Fragment>
    );
};

export default Login;