import React, {useEffect, useState, Fragment} from 'react';
import { Route, Routes, useLocation, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import * as loginActions from '../store/modules/loginActions';
import * as tutorialActions from '../store/modules/tutorialActions';
import * as contentActions from '../store/modules/contentActions';
import * as simpleViewActions from '../store/modules/simpleViewActions';
import * as messageActions from '../store/modules/messageActions';

import AppRoutes from '../AppRoutes';
// import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import UpdateCompany from './AccountPanels/UpdateCompany';
import Messages from './Common/Messages';

import storage from './libs/storage';

import { Decrypt } from './Common/DocumentLink';
import Document from './Document';
import Tutorial from './Tutorial';
import News from './News';
import Manual from './Manual';
import PopUp from './Common/PopUp';

const Main = (props) => {
    let login = false;
    let current = false;
    let favorite = false;
    if (storage.get('loginStates'))
        login = true;
    if (login && storage.get('tutorialStates'))
        current = true;
    if (login && storage.get('simpleViewStates'))
        favorite = true;
    //page
    const location = useLocation();
    const history = useNavigate();
    //redux
    const LoginStates = useSelector(state => state.loginActions);
    const TutorialStates = useSelector(state => state.tutorialActions);
    const ContentStates = useSelector(state => state.contentActions);
    const SimpleViewStates = useSelector(state => state.simpleViewActions);
    
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    const TutorialActions = bindActionCreators(tutorialActions, dispatch);
    const ContentActions = bindActionCreators(contentActions, dispatch);
    const SimpleViewActions = bindActionCreators(simpleViewActions, dispatch);
    const MessageActions = bindActionCreators(messageActions, dispatch);
    //State
    const [menuData, setMenuData] = useState([]);
    const [isLogin, setIsLogin] = useState(login);
    const [isCurrent, setCurrent] = useState(current);
    const [isFavorite, setIsFavorite] = useState(favorite);
    const [msgContent, setMsgContent] = useState(null);
    const [devUserType, setDevUserType] = useState(0);
    const [msgUserInfoContent, setMsgUserInfoContent] = useState(null);
    const [prevIsLogin, setPrevIsLogin] = useState(login);
    const [prevLoginStates, setPrevLoginStates] = useState(LoginStates);
    const [addMenuData, setAddMenuData] = useState([]);
    const [adminGrade, setAdminGrade] = useState([]);
    
    if(storage.get('loginStates')){
       if(!isLogin)
        setIsLogin(true);
    }
    //     setIsLogin(true);
    useEffect(() => {
        if (isLogin) {
            fetchSessionCheck();//--> 개선 해야함. (로그인 사용자 정보 변조 방지 용도 필요)
        }
    })
    useEffect(()=>{
        initialize();
        getTopMenu();
        getAdminGrade();
        if(isLogin){
            const loginStates = storage.get('loginStates');
            if(loginStates){
                const date = loginStates.get('logindate');
                if (date) {
                    var difference = Math.floor(new Date().toISOString().slice(0, 10).replace(/-/gi, "") - date.replace(/-/gi, ""));
                    if (difference < 3) {
                        fetchSessionCheck();
                        if (getParameterByName('p') != '') {
                            let contentInfoArr = Decrypt(getParameterByName('p')).split('||');
                            if (contentInfoArr[0] == 'Tutorial') {
                                let path = contentInfoArr[4].split('|')[1];
                                if (path.split('/').length < 2)
                                    path = path.replace('/', '') + '/0';
                                TutorialActions.setPath(path);
                            }
                            else {
                                ContentActions.setContentInfo({
                                    contentType: contentInfoArr[0].substring(1), contentName: contentInfoArr[1], folderName: contentInfoArr[2], type: contentInfoArr[3], url: contentInfoArr[5], video: contentInfoArr[6].split(',')
                                });
                                ContentActions.setPath(contentInfoArr[4]);
                            }
                            
                            history(contentInfoArr[0], {
                                state: 'DocumentLink'
                            });
                        }
                    }
                    else {
                        storage.remove('loginStates');
                        setIsLogin(false);
                    }
                } else {
                    storage.remove('loginStates');
                    setIsLogin(false);
                }
            }
        } else {
            if (location.pathname.toLowerCase().indexOf('kdcopen') < 0 && (location.pathname != '/' || getParameterByName('p') != '')) {
                if (location.pathname.toLowerCase().indexOf('/login') == -1) {
                    showToast('warning', '로그인 후 이용해 주세요.');
                    if (getParameterByName('p') != '') {
                        history("/Login", {
                            state: getParameterByName('p'),
                            replace: props.replace
                        });
                    }
                    else {
                        history("/Login", {state: {path: location.pathname}, replace: props.replace});
                    }
                }
                return;
            }
        }
    },[])

    useEffect(()=>{
        if(isLogin)
            updateStore();
        else{
            if(storage.get('loginStates'))
                setIsLogin(true);
        }
        setPrevIsLogin(isLogin);
    },[isLogin])

    useEffect(() => {
        if (isLogin) {
            if (!prevIsLogin) {
                initialize();
            } else {
                if (prevLoginStates != LoginStates && storage.get('loginStates') != null) {
                    //storage.set('loginStates', LoginStates);
                    //setPrevLoginStates(LoginStates);
                }
            }
            updateStore();
        }
        else {
            if (prevIsLogin) {
                initialize();
            }
            if (storage.get("loginStates")) {
                setIsLogin(true);
            }
        }
    });

    const getTopMenu = () => {
        fetch('datas/menu.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Cache-Control': 'no-cache'
            }
        }).then((response) => response.json())
            .then(menuData => {
                setMenuData(menuData);
            });
    };

    const getAdminGrade = () => {
        fetch('datas/user_grade.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Cache-Control': 'no-cache'
            }
        }).then((response) => response.json())
            .then(grade => {
                setAdminGrade(grade);
            });
    };

    const initialize = () => {
        if (login) {
            LoginActions.setLoginInfo(storage.get('loginStates'));

            if (isCurrent) {
                TutorialActions.setTutorialInfo(storage.get('tutorialStates'));
            }
            if (isFavorite) {
                SimpleViewActions.setSimpleViewInfo(storage.get('simpleViewStates'));
            }
            getCompanyAndDeptList();
        } else {
            let latestViews = [];
            latestViews.push({ category: 'WebDev', categoryName: 'ACE', type: 'Recommend', title: '서비스 담당 부서 & 서비스 필수 입력항목', path: '' });
            latestViews.push({ category: 'Documents', categoryName: '제뉴인', type: 'Most view', title: 'Introduce', path: '' });
            latestViews.push({ category: 'Mobile', categoryName: '모바일', type: 'New', title: '모바일 ERP 개요', path: '' });
            latestViews.push({ category: 'KsystemAPI', categoryName: 'K-SystemAPI', type: 'Recommend', title: 'K-System API 개요', path: '' });
            SimpleViewActions.latestViewInfo({
                latestViews: latestViews
            });
        }
    };

    const getCompanyAndDeptList = () => {
        if (isLogin) {
            let LoginStates = storage.get('loginStates');
            fetch('api/User/GetCompanyAndDeptListQuery?userId=' + LoginStates.get('userId'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => response.json())
                .then((data) => {
                    if (Object.keys(data).length != 0) {
                        let companyList = data.table ?? '', deptList = data.table1 ?? '';
                        let companySeq = 0, companyName = '', companyType = 0, deptDisplay = 'none';
                        companySeq = data.table2[0].setCompanySeq ?? 0;
                        companyName = data.table2[0].setCompanyName;
                        companyType = data.table2[0].companyType;
                        if (data.table2[0].companySeq == 128 || data.table2[0].companySeq == 129 || data.table2[0].companySeq == 130) {
                            deptDisplay = 'block';
                        }
                        setMsgUserInfoContent(companyList != '' ? (<UpdateCompany companyList={companyList} companySeq={companySeq} companyName={companyName} companyType={companyType} deptList={deptList} deptDisplay={deptDisplay} closePopup={this.closePopup} />) : null);
                        
                    }
                });
        }
    };

    const getParameterByName = (name) => {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    const fetchSessionCheck = () => {
        
        if (!storage.get('loginStates')) return;

        const userId = storage.get('loginStates').get('userId');
        let sessionID = storage.get('loginStates').get('sessionID');

        if (sessionID == undefined) {
            const sessionId = storage.get('loginStates').get('sessionId');
            if(sessionId) {
                let cookies = Object.fromEntries(storage.get('loginStates').entries());
                cookies = Object.keys(cookies).reduce((acc, key) => {
                    if (key !== 'sessionId') {
                      acc[key] = cookies[key];
                    }
                    return acc;
                  }, {});
                cookies = {...cookies, sessionID: sessionId};
                LoginActions.setLoginInfo(cookies);
                storage.set('loginStates', cookies);
                sessionID = sessionId;
            }
        }
        const body = {
            userId: encodeURI(userId),
            sessionID: encodeURI(sessionID)
        }
        fetch('api/Login/CheckSession', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        }).then((response) => response.json())
            .then(resutData => {                
                if (resutData.status == 'OK') {
                    let cookies = Object.fromEntries(storage.get('loginStates').entries());
                    if (cookies.logindate != new Date().toISOString().slice(0, 10)) {
                        cookies.logindate = new Date().toISOString().slice(0, 10);                        
                    }
                    let userInfomation = Object.fromEntries(
                        Object.entries(resutData.userInformation).filter(([key, value]) => value !== null)
                    );
                    cookies = {
                        ...cookies, ...userInfomation
                    };
                    LoginActions.setLoginInfo(cookies);
                    storage.set('loginStates', cookies);
                } else {
                    storage.remove('loginStates');
                    LoginActions.init();
                    setIsLogin(false);
                    
                    if (resutData.status == 'SessionExpired') {
                        showToast('danger', '세션이 만료되었습니다. 다시 로그인해주세요.', 3000);

                    } else if (resutData.status == 'PasswordExpired') {
                        showToast('danger', '비밀번호 유효기간이 지났습니다. 비밀번호를 변경해주세요.', 3000);
                    } else {
                        this.showToast('danger', '로그인 계정이 유효하지 않습니다. 영림원에 문의해주세요.', 3000);
                    }
                    history("/Login");
                }

            });
    };

    const updateStore = () => {
        const pathname = location.pathname;
        let Type = '';
        if (pathname.indexOf('Lua') > -1)
            Type = 'Lua';
        if (pathname.indexOf('Documents') > -1)
            Type = 'Documents';
        if (pathname.indexOf('Tutorial') > -1)
            Type = 'Tutorial';
        if (pathname.indexOf('OpenApi') > -1)
            Type = 'OpenApi';
        if (Type == '') {
            Type = pathname.replace('/', '');
        }

        let category = '';
        let categoryName = '';
        if (menuData != null && Type != '') {
            if (menuData.find(n => n.path == pathname.replace('/', '')) != undefined)
                categoryName = menuData.find(n => n.path == pathname.replace('/', '')).menuName;
        }
        let title = '';
        let path = '';
        let type = '';
        let contentType = '';
        let folderName = '';
        let url = '';
        if (Type == 'Tutorial') {
            path = TutorialStates.get('path');
            const menuDatas = TutorialStates.get('menuDatas');
            if (path != '') {
                const menuIndex = path.split('/')[0];
                const stepIndex = path.split('/')[1];
                if (menuDatas != null) {
                    const menuName = TutorialStates.get('menuDatas').children[menuIndex].module;
                    const stepName = TutorialStates.get('menuDatas').children[menuIndex].children[stepIndex].module;
                    category = 'Tutorial';
                    title = menuName + ': ' + stepName;
                }
            }

        } else {
            contentType = ContentStates.get('contentType');
            if (contentType == Type) {
                category = pathname.replace('/', '');
                title = ContentStates.get('contentName');
                path = ContentStates.get('nodepath');
                type = ContentStates.get('docType');
                folderName = ContentStates.get('folderName') || '';
                url = ContentStates.get('url') || '';
            }
        }
        // if (Type == 'OpenApi') {
        //     const { openApiStates } = this.props;
        //     contentType = openApiStates.get('nodepath');
        //     if (contentType != '') {
        //         category = 'OpenApi';
        //         categoryName = Type;
        //         title = openApiStates.get('title');
        //         path = openApiStates.get('path');
        //     }
        // }
        const latestViews = SimpleViewStates.get('latestViews');
        let newList = [];
        if (category != '' && categoryName != '') {
            newList.push({
                categoryName: categoryName,
                category: category,
                title: title,
                path: path,
                type: type,
                folderName: folderName,
                url: url
            });
        }
        if (latestViews.length > 0) {
            for (var z = 0; z < latestViews.length; z++) {
                if (latestViews[z].title == title && latestViews[z].category == category) {
                    continue;
                }
                else {
                    if (newList.length < 4) {
                        newList.push({
                            categoryName: latestViews[z].categoryName,
                            category: latestViews[z].category,
                            title: latestViews[z].title,
                            path: latestViews[z].path,
                            type: latestViews[z].type ?? '',
                            folderName: latestViews[z].folderName ?? '',
                            url: url
                        });
                    }
                }
            }
            if (title != '' && latestViews[0].title != title) {
                SimpleViewActions.latestViewInfo({
                    latestViews: newList
                });
                storage.set('simpleViewStates', SimpleViewStates);
            }
        }

    };

    const setRoutePath = (length) => {
        let routeList = [], index = length;
        if (menuData == null)
            return null;
        for (let i = 0; i < menuData.length; i++) {
            let element = null;

            if (menuData[i].type == 'Document')
                element = <Document />;
            else if (menuData[i].type == 'Manual')
                element = <Manual />;
            else if (menuData[i].type == 'Tutorial')
                element = <Tutorial />;
            else if (menuData[i].type == 'News')
                element = <News />;
            else
                element = <Document />;

            routeList.push(
                <Route key={index++} path={`/${menuData[i].path}`} element={element} />
            );
        }
        return routeList;
    };

    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    };

    useEffect(() => {
        setAddMenuData(setRoutePath(AppRoutes.length));
    }, [menuData]);

    const showMsgUserInfoValue = (value) => {
        setMsgUserInfoContent(value);
    };

    const clickButtonSetting = (link) => {
        let LoginStates = storage.get('loginStates');
        if (LoginStates === null) return;
        if (msgContent !== null) {
            if (link != null) window.open(link);
            document.getElementsByTagName('body')[0].style = '';
            setMsgContent(null);
        }
        else {
            // 1:1문의 관련페이지 경로 설정
            let node = '', nodepath = '', catagoryName = '';
            let userId = LoginStates.get('userId');
            let companyType = LoginStates.get('companyType');
            if (window.location.href.indexOf('/Tutorial') !== -1) {
                let tutorialTitle = document.querySelector('.tutorial > li[class="current"] > a').textContent;
                let status = document.querySelector('.status > li[class="current"] > p').textContent;
                nodepath = 'Tutorial / ' + tutorialTitle + ' / ' + status;
            } else if (window.location.href.indexOf('/News') !== -1) {
                node = document.querySelectorAll('.path-nav > li');
            } else {
                node = window.document.querySelectorAll('.doc-nav > li');
            }
            // 카테고리명 넘겨주기
            if (window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === '') {
                catagoryName = '';
            } else if (window.location.href.indexOf('/OpenApi') !== -1) {
                catagoryName = 'OpenAPI';
            } else if (window.location.href.indexOf('/Search') !== -1) {
                catagoryName = 'Search';
            } else {
                catagoryName = menuData.find(n => n.path == window.location.href.substring(window.location.href.lastIndexOf('/') + 1)).menuName;
            }
            for (var i = 0; i < node.length; i++) {
                if (i === 0) nodepath = node[i].textContent;
                else nodepath += ' / ' + node[i].textContent;
            }
            if (userId) {
                setMsgContent(
                        companyType != '0' ?
                        <div className="msg-popup quick-qna">
                            <div className="box">
                                <ul>
                                        <li><Link onClick={() => clickButtonSetting()} to={{
                                        pathname: `/MyPage`
                                    }} state={{
                                        current: 'topicWrite',
                                        userId: userId,
                                        nodePath: nodepath,
                                        catagoryName: catagoryName,
                                        locationFrom: 'qna'
                                    }}>이 페이지에 대해 의견이 있으신가요?</Link></li>
                                        <li><Link onClick={() => clickButtonSetting()} to={{
                                        pathname: `/MyPage`,
                                    }} state={{
                                        current: 'topicWrite',
                                        userId: userId,
                                        locationFrom: 'qna'
                                    }} >K-Developers에 궁금한 점이 있으신가요?</Link></li>
                                </ul>
                            </div>
                        </div> :
                            <div className="msg-popup quick-qna">
                                <div className="box">
                                    <ul>
                                        <li><Link onClick={() => clickButtonSetting()} to={{
                                            pathname: `/MyPage`,
                                        }} state={{
                                            current: 'topicWrite',
                                            userId: userId,
                                            nodePath: nodepath,
                                            catagoryName: catagoryName,
                                            locationFrom: 'qna'
                                        }}>이 페이지에 대해 의견이 있으신가요?</Link></li>
                                        <li><Link onClick={() => clickButtonSetting('https://erpnewas.ksystem.co.kr/')} to={{}} >개발 관련 문의 A/S 접수 바로가기</Link></li>
                                    </ul>
                                </div>
                            </div>
                    );
            } else {
                LoginStates.remove('loginStates');
                setIsLogin(false);
            }
        }
    };

    const showMsgValue = (value) => {
        setMsgContent(value);
    };
    const goToLogin = () => {       
        history("/Login", { state: {panelType: ''}});       
    }
    return(
        <div className='wrapper'>
            <Messages />
            <NavMenu menuData={menuData} setMenuData={setMenuData} adminGrade={adminGrade} goToLogin={goToLogin} />            
            <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={element} />;
                })}
                {addMenuData}
            </Routes>
            {isLogin ? <PopUp content={msgUserInfoContent} setContent={showMsgUserInfoValue} /> : null}
                {isLogin && window.location.href.indexOf('/Manager') === -1 && window.location.href.indexOf('/MyPage') === -1
                    ? <Fragment>
                        <button className="btn-quick-qna" onClick={clickButtonSetting}>
                            <i className="i-quick-qna"></i>
                        </button>
                        <PopUp content={msgContent} setContent={showMsgValue} />
                    </Fragment> : null}
        </div>
    );
}
export default Main;