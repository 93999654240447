import React, { useEffect, useState } from 'react';

const Player = (props) => {
    const [videoInfo, setVideoInfo] = useState({
        playOrPause: 'play',
        onAndOffVol: 'volume',
        screenMode: 'fullscreen',
        currentTime: '0:00',
        totalTime: '0:00',
        currentRate: 0,
        totalRate: 0,
        conrolboxHidden: 'none',
        currentVolume: 0.5,
        elementID: 'Video_' + props.id           
    });

    const LoadedData = () => {
        const player = document.getElementById(videoInfo.elementID);
        if (player) {
            player.volume = videoInfo.currentVolume;
            setVideoInfo(prev => {
                return {
                    ...prev,
                    totalTime: secondsToTime(player.duration),
                    totalRate: player.duration
                }
            });
        }

    };

    const Play = (e) => {
        console.log("Play Start");
    };

    const RateChange = (e) => {        
    };

    const VolumeChange = (e) => {
        const player = document.getElementById(videoInfo.elementID);
    };

    const TimeUpdate = () => {
        const player = document.getElementById(videoInfo.elementID);
        if (player)
            setVideoInfo(prev => {
                return {
                    ...prev,
                    currentTime: secondsToTime(player.currentTime),
                    currentRate: player.currentTime
                }
            });
    };

    const handleMouseOver = (e) => {
        const player = document.getElementById(videoInfo.elementID);        
        if (!player.paused) {
            setVideoInfo(prev => {
                return {
                    ...prev,
                    conrolboxHidden: 'inline-block'
                }
            });
        }
        //document.body.style.overflow = 'hidden';
    };

    const handleMouseLeave = () => {
        setVideoInfo(prev => {
            return {
                ...prev,
                conrolboxHidden: 'none'
            }
        });
        //document.body.style.overflow = 'auto';
    };

    const handleRangeChange = (e) => {
        const player = document.getElementById(videoInfo.elementID);
        player.currentTime = e.target.value;
        setVideoInfo(prev => {
            return {
                ...prev,
                currentTime: secondsToTime(player.currentTime),
                currentRate: player.currentTime
            }
        });
    };

    const handleVolumeChange = (e) => {
        const player = document.getElementById(videoInfo.elementID); 
        player.volume = e.target.value;
        setVideoInfo(prev => {
            return {
                ...prev,
                currentVolume: player.volume
            }
        });
    };

    const handleVolumeChangeScroll = (e) => {       
        const player = document.getElementById(videoInfo.elementID); 
                
        if (e.deltaY < 0 ) {
            if (player.volume + 0.1 > 1)
                player.volume = 1;
            else
                player.volume += 0.1

            setVideoInfo(prev => {
                return {
                    ...prev,
                    currentVolume: player.volume
                }
            });
        } else {
            if (player.volume - 0.1 < 0)
                player.volume = 0;
            else
                player.volume -= 0.1

            setVideoInfo(prev => {
                return {
                    ...prev,
                    currentVolume: player.volume
                }
            });
        }
    };

    const handleKeyDown = (e) => { 
        e.preventDefault();
        e.stopPropagation();
      
        const player = document.getElementById(videoInfo.elementID); 
        if (e.keyCode == 32) {
            clickPlayAndPause();
        }
        if (e.keyCode == 37 || e.keyCode == 39 ) {
            if (e.keyCode < 38) {
                if (player.currentTime < 5)
                    player.currentTime = 0;
                else
                    player.currentTime -= 5
                setVideoInfo(prev => {
                    return {
                        ...prev,
                        currentTime: secondsToTime(player.currentTime),
                        currentRate: player.currentTime
                    }
                });
            } else {
                if (player.duration - player.currentTime < 5)
                    player.currentTime = player.duration;
                else
                    player.currentTime += 5
                setVideoInfo(prev => {
                    return {
                        ...prev,
                        currentTime: secondsToTime(player.currentTime),
                        currentRate: player.currentTime
                    }
                });
            }
        }
        if (e.keyCode == 38 || e.keyCode == 40) {
            if (e.keyCode < 39) {
                if (player.volume + 0.1 > 1)
                    player.volume = 1;
                else
                    player.volume += 0.1

                setVideoInfo(prev => {
                    return {
                        ...prev,
                        currentVolume: player.volume
                    }
                });
            } else {
                if (player.volume - 0.1 < 0)
                    player.volume = 0;
                else
                    player.volume -= 0.1

                setVideoInfo(prev => {
                    return {
                        ...prev,
                        currentVolume: player.volume
                    }
                });
            }
        }
        if (e.keyCode == 32) {
            clickPlayAndPause();
        }
    };

    const clickScreenMode = () => {
        const player = document.getElementById(videoInfo.elementID);
        
        if (player.requestFullscreen) {
            player.requestFullscreen();
        } else if (player.mozRequestFullScreen) {
            player.mozRequestFullScreen();
        } else if (player.webkitRequestFullscreen) {
            player.webkitRequestFullscreen();
        }
    };

    const clickPlayAndPause = () => {
        const player = document.getElementById(videoInfo.elementID);

        if (player.paused || player.ended) {
            setVideoInfo(prev => {return { ...prev, playOrPause: 'pause' }});
            player.play();
        } else {
            setVideoInfo(prev => {return { ...prev, playOrPause: 'play' }});
            player.pause();
        }
    };

    const clickOnAndOffVolume = () => {
        const player = document.getElementById(videoInfo.elementID);

        if (player.muted) {
            setVideoInfo(prev => {
                return {
                    ...prev,
                    onAndOffVol: 'volume',
                    currentVolume: player.volume
                }
            });
            player.muted = false;
        } else {
            setVideoInfo(prev => {
                return {
                    ...prev,
                    onAndOffVol: 'mute',
                    currentVolume: 0
                }
            });
            player.muted = true;
        }
    };

    const clickPIPMode = () => {
        const player = document.getElementById(videoInfo.elementID);
        if (!document.pictureInPictureElement) {            
            document.getElementById('video-box' + videoInfo.elementID).style.display = 'none';
            player.requestPictureInPicture()
                .catch(error => {
                    // Video failed to enter Picture-in-Picture mode.
                });
            player.addEventListener('leavepictureinpicture', () => {
                if (document.getElementById('video-box' + videoInfo.elementID) != null)
                    document.getElementById('video-box' + videoInfo.elementID).style.display = '';
                if (player.paused || player.ended) {                   
                    setVideoInfo(prev => {return { ...prev, playOrPause: 'play' }});
                }
                else {
                    setVideoInfo(prev => {return { ...prev, playOrPause: 'pause' }});
                    player.play();
                }
            });
        } else {
            document.exitPictureInPicture()
                .catch(error => {
                    // Video failed to leave Picture-in-Picture mode.
                });
        }
    };

    const secondsToTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.round(seconds % 60);
        return [
            h,
            m > 9 ? m : (h ? '0' + m : m || '0'),
            s > 9 ? s : '0' + s
        ].filter(Boolean).join(':');
    };

    return (
        <div id={"video-box" + videoInfo.elementID}
            style={{
                position: "relative",
                marginBottom: "20px"
            }}
        >
            <video id={videoInfo.elementID}
                style={{
                    position: "relative",
                    zIndex: "9000",
                    display: "inline-block",
                    width: "100%"
                }}
                preload="metadata"
                controlsList="nodownload"
                onLoadedData={LoadedData}
                onPlay={(e) => Play(e)}
                onTimeUpdate={TimeUpdate}
                onRateChange={(e) => RateChange(e)}
                onProgress={(e) => RateChange(e)}
                onVolumeChange={(e) => VolumeChange(e)}                    
            >
                <source src={props.src} type="video/mp4" />
            </video>
            <div id="video-controls"
                tabIndex="0"
                style={{
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    zIndex: "9001",
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    background: "black",
                    opacity: videoInfo.playOrPause == 'play' ? "0.4" : "0",
                    backgroundImage: 'url("/content/images/video-control/play-128.png")',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat'
                }}
                onClick={clickPlayAndPause}
                onMouseOver={e => handleMouseOver(e)}
                onMouseLeave={handleMouseLeave}
                onKeyDown={ e => handleKeyDown(e)}
            >
            </div>
            <div id="video-controlbox"
                style={{
                    position: "absolute",
                    left: "0px",
                    top: "calc(100% - 100px)",
                    zIndex: "9002",
                    display: "inline-block",
                    width: "100%",
                    height: "100px",
                    background: "linear-gradient(rgba(20, 20, 20, 0), rgba(20, 20, 20, 1))",
                    display: videoInfo.conrolboxHidden
                }}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}                   
            >
                <div id="control-button"
                    style={{
                        width: "96%",
                        marginLeft: "20px",
                        marginTop: "41px",
                        marginBotton: "20px"
                    }}
                >
                    <div
                        style={{ float: "left" }}
                    >
                        <input type="image"
                            style={{
                                width: "14px",
                                marginRight: "15px",
                                verticalAlign: "bottom"
                            }}
                            src={"/content/images/video-control/" + videoInfo.playOrPause + ".png"}
                            onClick={clickPlayAndPause}
                            alt=""
                        ></input>
                        <span
                            style={{
                                color: "white",
                                fontSize: "15px",
                                position: "absolute"
                            }}
                        >{videoInfo.currentTime + " / " + videoInfo.totalTime}</span>
                    </div>
                    <div
                        style={{ float: "right" }}
                    >
                        <input type="image"
                            style={{
                                marginRight: "15px"
                            }}
                            src={"/content/images/video-control/" + videoInfo.onAndOffVol + ".png"}
                            onClick={clickOnAndOffVolume}
                            alt=""
                        ></input>
                        <input type="range" value={videoInfo.currentVolume} min="0" max="1" step="0.1"
                            style={{
                                width: "80px",
                                marginRight: "15px"
                            }}
                            onChange={e => handleVolumeChange(e)}
                            onWheel={e => handleVolumeChangeScroll(e)}
                        >
                        </input>
                        <input type="image"
                            style={{
                                marginRight: "15px"
                            }}
                            src={"/content/images/video-control/" + videoInfo.screenMode + ".png"}
                            onClick={clickScreenMode}
                            alt=""
                        ></input>
                        <input type="image"
                            style={{
                                width: "22px",
                                marginRight: "15px",
                                verticalAlign: "bottom"
                            }}
                            src={"/content/images/video-control/pip.png"}
                            onClick={clickPIPMode}
                            alt=""
                        ></input>
                    </div>
                </div>
                <input type="range" onChange={e => handleRangeChange(e)} value={videoInfo.currentRate} min="0" max={videoInfo.totalRate}
                    style={{
                        width: "96%",
                        marginLeft: "2%",
                        marginTop: "10px"
                    }}
                />
            </div>
        </div>
    );
};

export default Player;