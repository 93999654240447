//초성 검색
const makeRegexByCho = (search = "") => {
  const Choseong = [
      'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ',
      'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ',
      'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ',
      'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ',
  ];

  const StartChar = "가".charCodeAt();

  const ChoseongPeriod = Math.floor("까".charCodeAt() - "가".charCodeAt());
  const JungseongPeriod = Math.floor("개".charCodeAt() - "가".charCodeAt());

  const combine = (cho, jung, jong) => {
      return String.fromCharCode(
          StartChar + cho * ChoseongPeriod + jung * JungseongPeriod + jong
      );
  }

  const regex = Choseong.reduce(
      (acc, cho, index) =>
          acc.replace(
              new RegExp(cho, "g"),
              `[${combine(index, 0, 0)}-${combine(index + 1, 0, -1)}]`
          ),
      search
  );

  return new RegExp(`(${regex})`, "g");
};

export const includeByCho = (search, targetWord) => {
  return makeRegexByCho(search).test(targetWord);
};

export const transDate = (date) => {
    const regDate = /^\d{4}\.(0[1-9]|1[0-2])\.(0[1-9]|[12][0-9]|3[01])$/;
    const regRowDate = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
    if (date.length == 10 && regDate.test(date)) {
        return date;
    }
    else if(regRowDate.test(date)) {
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8);

        return year + "." + month + "." + day;
    }
    else {
        return "1900.01.01";
    }
};

export const checkIsNull = (data) => {    
    return data === null || data === undefined;
};