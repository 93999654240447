import React, { Component, useEffect, useRef, useState } from 'react';
// import Editor from '../libs/editor';
import Editor from '../libs/QuillEditor';

import { useSelector, useDispatch } from 'react-redux';
import * as loginActions from '../../store/modules/loginActions';
import * as newsActions from '../../store/modules/newsActions';
import { bindActionCreators } from 'redux';
import { deltaToHtml } from '../libs/DeltaConverter';

function buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    return fileSelector;
}

const PostEditor = (props) => {
    const LoginStates = useSelector(state => state.loginActions);
    const NewsStates = useSelector(state => state.newsActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    const NewsActions = bindActionCreators(newsActions, dispatch);
    const child = useRef();
    const editorRef = useRef(null);
    
    const [state, setState] = useState({            
        writertitle: '',
        writercontent: '',
        files: [],
        formdata: new FormData()
    });
    const [fileSelector, setFileSelector] = useState(buildFileSelector());
    
    useEffect(() => {
        if(fileSelector)
            fileSelector.addEventListener('change', addFileList);
    }, [fileSelector]);
    
    useEffect(() => {
        if (NewsStates.get('display') == 'none') {
            if (state.writertitle != '' 
                // || state.writercontent.trim() != '') {
                || editorRef.current?.getLength() > 1) {
                var r = window.confirm("작성중인 내용을 취소하겠습니까?");
                if (r) {
                    setState(prev => { return {
                        ...prev,
                        writertitle: '',
                        writercontent: '',
                        files: []
                    }});
                    editorRef.current?.setContents([]);
                    // editorRef.current?.setContents([{ insert: '\n' }]);
                    NewsActions.setDisplay({
                        display: 'none'
                    });
                } else {
                    NewsActions.setDisplay({
                        display: 'block'
                    });
                }
            }
            else {
                NewsActions.setDisplay({
                    display: 'none'
                });
            }
        }
    }, [NewsStates.get('display')]);

    const handleChange = (e) => {
        if (e.hasOwnProperty('defaultPrevented'))
            e.preventDefault();
        const value = e.target.value == undefined ? e.target.innerHTML : e.target.value;
        setState(prev => { return { 
                ...prev, [e.target.id]: value 
            }}
        );

    };
    const addFileList = (e) => {
        let files = e.target.files;
        let list = state.files;
        var formdata = state.formdata;

        if(files.length == 0 || formdata == undefined) return;
        let start = list.length.toString();
        for (let i=0; i<files.length; i++) {
            formdata.append('file_' + start++, files[i], files[i].name);
            
            list.push({ name: files[i].name, size: files[i].size });
        }
        setState(prev => { return {
            ...prev,
            files: list,
            formdata: formdata
        }});
    };

    const uploadFile = (e) => {       
        e.preventDefault();        
        fileSelector.click();       
    };

    const removeFile = (e) => {
        e.preventDefault();
        var index = e.target.id;
        let filelist = state.files;
        var formdata = state.formdata;
        filelist.splice(index, 1);

        formdata.delete('file_' + index);
        setState(prev => { return {
            ...prev,
            files: filelist
        }});
        setFileSelector(buildFileSelector());
        // fileSelector.addEventListener('change', addFileList);
    };

    const fileList = () => {
        let list = [];
        let filesize = 0;
        
        for (let i = 0; i < state.files?.length; i++) {
            if (!isNaN(state.files[i].size)) {
                if (state.files[i].size < 1024) {
                    filesize = state.files[i].size.toFixed(1) + "byte";
                }
                else if (state.files[i].size < Math.pow(1024, 2)) {
                    filesize = (state.files[i].size / 1024).toFixed(1) + "kb";
                }
                else if (state.files[i].size < Math.pow(1024, 3)) {
                    filesize = (state.files[i].size / Math.pow(1024, 2)).toFixed(1) + "mb";
                }
                else {
                    filesize = (state.files[i].size / Math.pow(1024, 3)).toFixed(1) + "gb";
                }
                state.files[i].size = filesize;
            }
            list.push(
                <li>
                    <p className="filename" id="fileName">{state.files[i].name}</p>
                    <p className="filesize" id="fileSize">{state.files[i].size}</p>
                    <button className="btn-remove-file" id={i} onClick={e => removeFile(e)}><i id={i} className="i-remove"></i></button>
                </li>
            );
        }
        return list;
    };

    const clickPost = (e) => {
        e.preventDefault();
        fetch('api/File/UploadFiles', {
            method: 'POST',
            body: state.formdata
        });

        if (NewsStates.get('display') == 'block') {
            const content = deltaToHtml(editorRef.current.getContents());
            if (state.writertitle.trim() == ''
                // || state.writercontent.trim() == '') {
                    || content.trim() === '') {
                alert(state.writercontent);
            }
            else {
                const input = {
                    userId: LoginStates.get('userId'),
                    userName: LoginStates.get('userName'),
                    workingTag: 'postSave',
                    pageNo: 1,
                    groupNo: NewsStates.get('groupno'),
                    postInfo: {
                        contentType: 'Post',
                        postSeq: 0,
                        attachSeq: 0,
                        commentSeq: 0,
                        postData: {
                            title: state.writertitle,
                            // content: state.writercontent,
                            content: content,
                            attachment: state.files,
                            comment: ''
                        }
                    }
                };
                props.executeFetch(input);
                setState(prev => { return {
                    ...prev,
                    writertitle: '',
                    writercontent: '',
                    files: []
                }});
                editorRef.current?.setContents([]);
                // child.current?.resetContent();
            }
        }
    };

    const clickWrite = (e) => {
        e.preventDefault();

        if (NewsStates.get('display') == 'block') {
            if (state.writertitle != ''
                // || state.writercontent.trim() != ''
                || editorRef.current?.getLength() > 1
                || state.files.length > 0) {
                var r = window.confirm("작성중인 내용을 취소하겠습니까?");
                if (r) {
                    NewsActions.setDisplay({
                        display: 'none'
                    });
                    setState(prev => { return {
                        ...prev,
                        writertitle: '',
                        writercontent: '',
                        files: []
                    }});
                    console.log(editorRef.current);
                    editorRef.current?.deleteText(0, editorRef.current?.getLength());
                    // editorRef.current?.setContents(new Delta().insert('\n'))
                    // child.current?.resetContent();
                } else {
                    return;
                }
            }
            else {
                NewsActions.setDisplay({
                    display: 'none'
                });
            }
        }
    };
        
    return (
        <section className="post-write" style={{ display: NewsStates.get('display') }} >
            <ul className="write-box">
                <li className="post-title">
                    <input
                        type="text"
                        placeholder="제목을 입력하세요"
                        id="writertitle"
                        value={state.writertitle}
                        onChange={(e) => handleChange(e)} />
                </li>
                <li className="post-content">
                    {/* <Editor
                        id="writercontent"
                        text={state.writercontent}
                        onChange={(e) => handleChange(e)}
                        options={{
                            toolbar: {
                                allowMultiParagraphSelection: false,
                                buttons: ['bold', 'underline', 'anchor', 'h2', 'h3', 'quote'],
                                firstButtonClass: 'medium-editor-button-first'
                            },
                            placeholder: { text: '내용을 입력하세요' }
                        }}
                        // onRef={child}
                        ref={child}
                    /> */}
                    <Editor
                        ref={editorRef}
                        readOnly={false}
                        defaultValue={null}
                    />

                </li>
                <li className="post-files">
                    <div className="title">첨부파일<button className="btn-add-file" onClick={uploadFile}><i className="i-add"></i></button></div>
                    <ul className="attached-files">
                        {fileList()}
                    </ul>
                </li>
            </ul>
            <div className="btn-wrap">
                <button className="" onClick={clickWrite}>취소</button>
                <button className="btn-primary" onClick={clickPost}>등록</button>
            </div>
        </section>
    );
};
export default PostEditor;