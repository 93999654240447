import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';


const initialState = Map({
    productType: '',
    serviceID: '',
    methodID: '',
    title:'',
    serviceListData: [],
    serviceListView: [],
    nodepath: '',
    path:''
});


const SETPRODUCTTYPE = 'openApiActions/SETPRODUCTTYPE';
const INITSTATES = 'openApiActions/INITSTATES';
const MENUSET = 'openApiActions/MENUSET';
const PATHSET = 'openApiActions/PATHSET';
const INFOSET = 'openApiActions/INFOSET';

export const setProductType = createAction(SETPRODUCTTYPE);
export const initStates = createAction(INITSTATES);
export const setList = createAction(MENUSET);
export const setPath = createAction(PATHSET);
export const setInfo = createAction(INFOSET);

export default handleActions({
    // 메뉴 클릭시 데이터 전달
    [SETPRODUCTTYPE]: (state, action) => {
        return state.set('productType', action.payload);
    },
    [INITSTATES]: (state, action) => {
        return state.set('productType', action.payload)
            .set('serviceListData', [])
            .set('serviceListView', []);
    },
    [MENUSET]: (state, action) => {
        const strJson = JSON.stringify(action.payload.treeData);
        return state.set('serviceListData', JSON.parse(strJson))
            .set('serviceListView', action.payload.treeView);
    },
    [PATHSET]: (state, action) => {
        return state.set('nodepath', action.payload);
    },
    [INFOSET]: (state, action) => {
        return state.set('serviceID', action.payload.serviceID)
            .set('methodID', action.payload.methodID)
            .set('title', action.payload.title)
            .set('path', action.payload.path);
    },
}, initialState);