import React from 'react';

const TabMenu = (props) => {

    const onClickMenu = (e,id) => {
        e.preventDefault();
        props.ClickMenu(id);
    };

    const menuList = () => {
        let list = [];
        const menu = props.menuDatas;
        let current = props.current;
        if (current == '')
            current = menu[0]?.id;
        for (let i = 0; i < menu.length; i++) {
            const item = menu[i];
            if (item.type == "tab")
                list.push(
                    <li key={item.id} className={current == item.id ? 'selected' : ''} onClick={(e) => onClickMenu(e, item.id)}>
                        <a>{item.title}</a>
                    </li>
                );
        }
        return list;
    };

    const title = props.title;
    const tabMenu = menuList();
    return (
        <header>
            <p className="title">{title}</p>
            <ul className="tab-menu">
                {tabMenu}
            </ul>
            {props.buttons}
        </header>
    );
}

export default TabMenu;