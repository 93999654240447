import React, { useState } from 'react';

const StepList = (props) => {

    const [stepList, setStepList] = useState(props.data);

    const showSteps = () => { 
        const stepIndex = props.stepIndex;  
        let stepList = [], returnList = [], index = 0;

        stepList = props.data.children;              
        for (let i = 0; i < stepList.length; i++) {
            let className = '';
            if (i == parseInt(stepIndex))
                className = 'current';
            returnList.push(
                <li 
                    key={index++}
                    className={className}
                    style={{ width: 100 / stepList.length + "%" }}
                >
                    <div className="process-ball" />
                    <p>{stepList[i].module}</p>
                </li>
            );
        }
        return returnList; 
    };

    const changeStep = (e) => {
        const currentStepIndex = props.stepIndex; 
        const buttonID = e.currentTarget.id;

        if (buttonID == 'prev' && currentStepIndex == 0) {
            window.confirm('이전 강의로 이동하시겠습니까?') && changeMenu(buttonID);
        } else if (buttonID == 'next' && currentStepIndex == props.data.children.length - 1) {
            window.confirm('다음 강의로 이동하시겠습니까?') && changeMenu(buttonID, "yes");
        } else {            
            let step = 0;
            if (buttonID == 'prev') {
                step = parseInt(currentStepIndex) - 1;
            }
            else if (buttonID == 'next') {
                step = parseInt(currentStepIndex) + 1;
            }            
            props.onChangeStep(step);
        }
    };

    const changeMenu = (buttonID, isComplete = "no") => {
        props.onChangeSubject(buttonID, isComplete);
    };

    const buttonClassName = () => {
        const menuIndex = props.menuIndex;
        const stepIndex = props.stepIndex;
       
        var btnClass = {
            preButton: "prev",
            nextButton: "next"
        };        
        if (menuIndex == 0 && stepIndex == 0) {
            btnClass.preButton = "prev btn-dis";
            btnClass.nextButton = "next";

        } else if (props.isLast) {
            if (stepIndex == props.data.children.length - 1) {
                btnClass.preButton = "prev";
                btnClass.nextButton = "next btn-dis";
            }
        }        
        return btnClass;
    };

    const renderContent = () => {
        const btnClass = buttonClassName();
        return (
            <div className="steps">
                <button
                    className={btnClass.preButton}
                    id="prev"
                    onClick={(e) => changeStep(e)}>
                    <i className="i-arrow" />
                    <span className="txt">이전</span>
                    </button>
                <div className="process-wrap">
                    <ul className="status">
                        {showSteps()}
                    </ul>
                </div>
                <button
                    className={btnClass.nextButton}
                    id="next"
                    onClick={(e) => changeStep(e)}>
                    <span className="txt">다음</span>
                    <i className="i-arrow" />
                </button>
            </div>
        );
    };

    const rendering = (stepList.children.length > 0) ? renderContent() : "";

    return (
        <div className="tutorial-steps">
            {rendering}
        </div>
    );
}
export default StepList;