import React, { useEffect, useState } from 'react';
import PostEditor from './NewsPanels/PostEditor';
import NewsItem from './NewsPanels/NewsItem';
import { useSelector, useDispatch } from 'react-redux';
import * as newsActions from '../store/modules/newsActions';
import { bindActionCreators } from 'redux';
import TabMenu from './Common/TabMenu';
import { useLocation } from 'react-router-dom';
import { checkIsNull } from './libs/Util';

const News = (props) => {
    const location = useLocation();
    const LoginStates = useSelector(state => state.loginActions);
    const NewsStates = useSelector(state => state.newsActions);
    const dispatch = useDispatch();
    const NewsActions = bindActionCreators(newsActions, dispatch);

    const [state, setState] = useState({
        current: '',
        value: '',
        writertitle: '',
        writercontent: '',
        postDatas: '',
        attachments: [],
        file: null,
        dataFromEditor: [],
        list: null,
        groupname: "Notice",
        groupno: 1,
        menu: [
            { id: "Notice", title: "Notice", groupno: 1, type: 'tab' }
            //,{ module: "FAQ", groupno: 2, type: 'tab' }
        ],
    });
    const [prevGroupno, setPrevGroupno] = useState(1);

    const getNews = () => {
        fetch('datas/news.json').then((resp) => resp.json()).then(data => {
            let groupNo = location.state ? location.state.groupNo : 1;
            setState(prev => { return {
                ...prev,
                groupname: data.children.find(a => a.groupno === parseInt(groupNo))?.title,
                groupno: groupNo,
                list: data,
                menu: data.children
            }});
        });
    };
    
    useEffect(() => {
        getNews();
        showPostList();
    }, []);

    // ! 체크 필요
    useEffect(() => {
        if(!checkIsNull(state.groupno) && state.groupno !== prevGroupno) {
            showPostList();
            setPrevGroupno(state.groupno);
        }
    }, [state.groupno]);
    
    const clickWrite = (e) => {
        e.preventDefault();

        if (NewsStates.display === 'none') {
            NewsActions.setDisplay({
                display: 'block'
            });
        }
        else if (NewsStates.display === 'block') {
            NewsActions.setDisplay({
                display: 'none'
            });
        }
    };

    const checkAdmin = () => {
        if (LoginStates.get('admin') !== '') {
            return true;
        } else {
            return false;
        }
    };

    const handlePanels = (mode) => {
        let groupno = state.menu.filter((obj) => { return obj.title === mode })[0].groupno;
        setState(prev => { return {
            ...prev,
            groupname: mode,
            groupno: groupno,
            current: mode
        }});
        // setPrevGroupno(groupno);

        NewsActions.setGroupNo({
            groupno: groupno
        });
    };

    const showPostList = () => {
        const input = {
            userId: '',
            userName: '',
            workingTag: 'postList',
            pageNo: 1,
            groupNo: state.groupno,
            postInfo: {
                contentType: '',
                postSeq: 0,
                attachSeq: 0,
                commentSeq: 0,
                postData: {
                    title: '',
                    content: '',
                    attachment: [],
                    comment: ''
                }
            }
        };
        executeFetch(input);
    };

    const executeFetch = (fetchData) => {
        fetch('api/Document/NewsManage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(fetchData)
        }).then((response) => response.json())
            .then((data) => {
                if (fetchData.workingTag === 'postSave'
                    || fetchData.workingTag === 'commentSave'
                    || fetchData.workingTag === 'commentDelete') {
                    showPostList();
                }
                else {                    
                    setState(prev => { return {
                        ...prev,
                        postDatas: JSON.parse(data.postData),
                        writertitle: '',
                        writercontent: '',
                        attachments: []
                    }});
                    NewsActions.setDisplay({
                        display: 'none'
                    });
                }
            });
    };

    // const onClickNode = (node) => {
    //     setState(prev => { return {
    //         ...prev,
    //         groupname: node.name,
    //         groupno: node.item.groupno
    //     }});

    //     NewsActions.setGroupNo({
    //         groupno: node.item.groupno
    //     });
    // };

    let postNodes = [], index = 0;
    if (!checkIsNull(state.postDatas)) {
        const postList = state.postDatas.postDatas;

        if (!checkIsNull(postList)) {
            for (var i = 0; i < postList.length; i++) {
                postNodes.push(
                    <NewsItem key={index++} postSeq={location.state ? location.state.postSeq : 0} data={postList[i]} executeFetch={executeFetch} />
                );
            }
        }
    }

    return (
        <div className="container doc-type">
            <div className="setting-wrap">
                <TabMenu ClickMenu={handlePanels} menuDatas={state.menu} current={state.current} title={'새소식'} />
                <div className="content">
                    <div className="doc-wrap">
                        <div className="news">
                            <div className="news-wrap" style={{ width: "100%" }}>
                                <h2>{state.groupname}</h2>
                                <section className="top">
                                    <div className="post-search">
                                        <input type="text" placeholder={state.groupname + "에서 검색"} />
                                        <div className="btn-search"><i className="i-search-s"></i></div>
                                    </div>
                                    {checkAdmin() &&
                                        <button className="btn-secondary btn-write" onClick={clickWrite}>
                                            글쓰기
                                        </button>
                                    }
                                </section>
                                {checkAdmin() &&
                                   <PostEditor executeFetch={executeFetch} />
                                }
                                <section className="post-list">
                                    {postNodes}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;