import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as messageActions from '../../store/modules/messageActions';
import { bindActionCreators } from 'redux';

const EmailVerificationPanel = (props) => {
    const dispatch = useDispatch();
    const MessageActions = bindActionCreators(messageActions, dispatch);

    const validMinute = 30;
    const [buttonTitle, setButtonTitle] = useState(props.buttonTitle);
    const [userId, setUserId] = useState(props.state.userId);
    const [userName, setUserName] = useState(props.state.userName);
    const [isRun, setIsRun] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [showAuth, setShowAuth] = useState(false);
    const [emailVerificationSend, setEmailVerificationSend] = useState(false);
    const [authCode, setAuthCode] = useState("");
    const [validTime, setValidTime] = useState(validMinute * 60); // 3 minutes in seconds

    useEffect(() => {
        setUserId(props.state.userEmail != undefined && props.state.userEmail != "" ? props.state.userEmail : props.state.userId);
        setUserName(props.state.userName);
        let interval;
        if (validTime > 0 && showAuth) {
            interval = setInterval(() => {
                setValidTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [validTime, showAuth, props.state.userId, props.state.userName, props.state.userEmail]);

    useEffect(() => {
        // console.log("userId: ", userId);
    },[userId]);

    useEffect(() => {
        // console.log("userName: ", userName);
    },[userName]);

    const handleRequestAuth = (event) => {
        event.preventDefault();
        if (!isRun) {
            if (userId == '' || userName == '') {
                showToast('warning', '입력하지 않은 정보가 있습니다.');
                return;
            }
            let emailExpression = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            if (!emailExpression.test(userId)) {
                showToast('warning', '이메일 주소가 올바르지 않습니다.');
                return;
            }
            setIsRun(true);
            setShowAuth(true);
            setEmailVerificationSend(true);
            if (props.fnAfterRequestAuth != undefined) {
                props.fnAfterRequestAuth();
            }
            setValidTime(validMinute * 60);
            const userInfo = {
                userId: encodeURI(userId),
                userName: encodeURI(userName)
            };
            fetch('api/Login/EmailVerificationSend', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(userInfo)
            }).then((response) => response.json())
                .then((data) => {
                    setIsRun(false);
                    if (data.status == 'OK') {
                        showToast('success', data.message);
                    } else {
                        showToast('warning', data.message);
                    }
                });
        }
    };

    const handleAuthCodeChange = (event) => {
        let number = /^[0-9]+$/;
        let value = event.target.value;
        if (number.test(value) == true || value == '') {
            setAuthCode(value);
        }
    };

    const handleCheckAuth = (event) => {
        event.preventDefault();
        if (!isRun) {
            setIsRun(true);
            if (validTime == 0) {
                showToast('warning', '유효시간이 만료되었습니다.');
                setIsRun(false);
                return;
            }
            const userInfo = {
                userEmail: encodeURI(userId),
                authCode: (authCode == "" ? "0" : authCode),
                validTime: validMinute
            };
            fetch('api/Login/EmailVerificationCheck', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(userInfo)
            }).then((response) => response.json())
                .then((data) => {
                    setIsRun(false);
                    if (data.status == 'OK') {
                        showToast('success', data.message);
                        if (props.fnAfterCheckAuth != undefined) {
                            props.fnAfterCheckAuth();
                        }
                        setShowAuth(false);
                        setIsVerification(true);
                    } else {
                        showToast('warning', data.message);
                    }
                });
        }
    };
    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    }
    const authButtonClass = () => {
        if (isVerification) {
            return (
                <button className='btn-req-auth btn-dis'> 인증 완료</button>
            );
        } else if (!emailVerificationSend) {
            return (
                <button onClick={handleRequestAuth} className='btn-req-auth'>{buttonTitle}</button>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="email-auth-wrap">
            {authButtonClass()}
            {showAuth && (
                <div className="email-auth-box">
                    <p className="txt-title">이메일로 전송된 인증번호를 입력해 주세요.</p>
                    <div className="auth-input">
                        <input
                            maxLength="6"
                            value={authCode}
                            onChange={handleAuthCodeChange}
                            placeholder='인증번호를 입력하세요.'
                        />
                        <div className="timer">
                            {Math.floor(validTime / 60)}:
                            {validTime % 60 < 10 ? `0${validTime % 60}` : validTime % 60}
                        </div>
                        <button onClick={handleCheckAuth} className='btn-primary'>확인</button>
                    </div>
                    <div className="resend">
                        <span className="txt">이메일을 받지 못하셨나요? </span>
                        <a onClick={handleRequestAuth} className="btn-resend">이메일 재전송</a>
                    </div>
                    <label>

                    </label>
                </div>
            )}
        </div>
    );
}

export default EmailVerificationPanel;