import React, { useState, useEffect } from 'react';
import ApplicantList from './ManagerPanels/Applicant';
import Topic from './ManagerPanels/Topic';
import TabMenu from './Common/TabMenu';
import UserAccountManagement from './ManagerPanels/UserAccountManagement';
import storage from './libs/storage';
import { useSelector } from 'react-redux';
// import AuthConfirm from './ManagerPanels/AuthConfirm';
import { useLocation, useNavigate } from 'react-router-dom';

const Admin = () => {
    const history = useNavigate();
    const location = useLocation();
    const loginStates = storage.get('loginStates');
    
    const LoginStates = useSelector(state => state.loginActions);
    
    const [state, setState] = useState({
        current: '',
        menu: []
    });

    const [buttons, setButtons] = useState();
    
    useEffect(() => {
        getAdminList();
    }, []);

    useEffect(() => {
        setButtons(getAdminButtons());
    }, [state.menu]);

    const moveToContentEditor = (e) => {
        const id = e.target.id;
        if (id == 'ContentEdit') {
            history("/ContentEdit");
        }
        else if (id == 'UpdateIndex') {
            updateManual();
        }
    };
    
    const handlePanels = (mode) => {
        setState({
            ...state,
            current: mode
        })
    };

    const updateManual = () => {
        fetch(`api/Search/Index`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(response => response.json())
            .then((data) => {
                if (data == 'ok') {
                    alert("최신화 성공")
                }
            })
    };

    const getAdminList = () => {
        fetch('./datas/admin_menu.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache'
            }
        }).then(response => response.json())
            .then(json => {
                let auth = LoginStates?.get('admin');
                if (auth == '') {
                    auth = loginStates?.get('admin');
                }
                if(!auth || auth === '')
                    return;
                let data = [];
                let splauth = auth.split(",");
                for (let i = 0; i < splauth.length; i++) {
                    let tmpdata = [];
                    tmpdata = json.filter(x => x.auth.indexOf(splauth[i]) > -1);
                    if (tmpdata.length > 0) {
                        for (let j = 0; j < tmpdata.length; j++) {
                            if (!(data.indexOf(tmpdata[j]) > -1)) data.push(tmpdata[j]);
                        }
                    }
                }
                setState({
                    menu: data,
                    current: data[0].id
                });
            })
    };

    const loadTabContent = () => {
        const current = state.current;
        if (current == 'Authorization')
            return (
                <div className="content">
                    <h2>권한신청관리</h2>
                    <section className="">

                    </section>
                </div>
            );
        else if (current == 'TopicList')
            return (
                <Topic ClickMenu={handlePanels}
                    current={'topicList'}
                    isAdmin={true} />
            );
        else if (current == 'UserAccountList')
            return (
                <UserAccountManagement />
            );
        else if (current == 'Applicant')
            return (
                <ApplicantList />
            );
        else
            return null;
    };

    const getAdminButtons = () => {
        let buttons = []
        let auth = LoginStates?.get('admin');
        if (auth == '') {
            auth = loginStates?.get('admin');
        }
        if (auth === null || auth === undefined)
            return null;
        const menu = state.menu;
        if (menu.length > 0) {
            let auth_buttons = [];
            let splauth = auth.split(",");
            const m_buttons = menu.filter(x => x.type == "button")
            for (let i = 0; i < splauth.length; i++) {
                auth_buttons = m_buttons.filter(n => n.auth.indexOf(splauth[i]) > -1)
                buttons.push(
                    auth_buttons.map((x, i) => (
                        <button className={x.style} id={x.id} key={x.id}
                            onClick={(e) => moveToContentEditor(e)}>
                            <i className={x.icon}></i>{x.title}
                        </button>
                    ))
                );
            }
        }
        return buttons;
    };

    const tabContent = loadTabContent();
    // let buttons = getAdminButtons();

    const checkAdmin = () => {
        if (LoginStates.get("admin").replace(/,/g, "") !== '') {
            return true;
        } else {
            return false;
        }
    };
    
    if (checkAdmin())
        return (
            <>
                <div className="container">
                    <div className="setting-wrap">
                        <TabMenu ClickMenu={handlePanels} menuDatas={state.menu} current={state.current} title={"관리자 센터"} buttons={buttons} />
                        {tabContent}
                    </div>
                </div>
            </>
        );
    else   
        history("/")
}
export default Admin;