import React, { useEffect, useState } from 'react';
import Comments from './Comments';
import Post from './Post';

const NewsItem = (props) => {
    
    const [state, setState] = useState({
        activate: props.postSeq == props.data.postdata.postSeq ? true : false,
        style: props.postSeq == props.data.postdata.postSeq ? 'expand' : '',
        commentCount: ''
    });
    
    useEffect(() => {
        if (state.style == 'expand') {
            document.getElementById(props.data.postdata.postSeq).scrollIntoView();
        }
    }, []);
    
    const clickPostNode = (e, activate) => {
        if (state.activate)
            setState(prev => { return {
                ...prev,
                activate: false,
                style: ''
            }});
        else
            setState(prev => { return {
                ...prev,
                activate: true,
                style: 'expand'
            }});
    };

    const clickConfirm = (input) => {        
        props.executeFetch(input);
    };


    return (
        <div id={props.data.postdata.postSeq} className={"item " + state.style}>
            <Post activate={state.activate} postdata={props.data.postdata} clickPostNode={clickPostNode} commentCount={state.commentCount} isAccessFromHome={props.postSeq == props.data.postdata.postSeq ? true : false} />
            <Comments comments={props.data.commentdata} postSeq={props.data.postdata.postSeq} clickConfirm={clickConfirm} executeFetch={props.executeFetch} />
        </div>
    );
};

export default NewsItem;