import React, { Fragment, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import hljs from 'highlight.js';
import storage from '../libs/storage';

import PDFDocViewer from './PDFViewer';
import Player from './Player';

import { useDispatch } from 'react-redux';
import * as contentActions from '../../store/modules/contentActions';
import { bindActionCreators } from 'redux';

import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

const ContentViewer = (props) => {
    const loginStates = storage.get('loginStates');
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    
    const [contentInfo, setContentInfo] = useState(props.ContentInfo);
    const [contentHtml, setContentHtml] = useState("");
    const [menuData, setMenuData] = useState(null);

    useEffect(() => {
        getTopMenu();
        
        return () => {
            ContentActions.setContentInfo({
                contentType: '',
                contentName: '',
                folderName: '',
                type: '',
                tabName: '',
                url: '',
                video: ''
            });
        }
    }, []);

    useEffect(() => {
        // console.log("content update");
    });

    useEffect(() => {
        // console.log("update info: ", props);
        updateContentInfo(props.ContentInfo)
    }, [props, props.ContentInfo, contentHtml]);
    
    const updateContentInfo = (info) =>
    {
        window.$(window).scroll(function () {
            if (window.$(window).scrollTop() >= 60) {
                window.$('.contents-wrap').addClass('fixed'); 
            }
            else {
                window.$('.contents-wrap').removeClass('fixed');
            }
        });                
        document.querySelectorAll("pre code").forEach(block => {
            // hljs.highlightBlock(block);
            hljs.highlightElement(block);
        });        
        if (document.getElementsByClassName('btn-copy')) {
            var userSelection = document.getElementsByClassName('btn-copy');

            for (let i = 0; i < userSelection.length; i++) {
                userSelection[i].addEventListener('click', copyFunction);
            }
        } 
        if (JSON.stringify(info) != JSON.stringify(contentInfo)){
            loadClickNodeContents();
        }
        // console.log(info.video);
        if (info.video != undefined) {
            var videos = info.video;
            if (videos.length != undefined) {
                for (var i = 0; i < videos.length; i++) {
                    var video = document.getElementById(videos[i]);
                    // console.log(video);
                    if (video != null) {
                        const src = video.children[0].getAttribute("src");
                        if (src != null) 
                            createRoot(document.getElementById(videos[i])).render(<Player src={src} id={videos[i]} />);
                    }
                }
            }
        }
        setContentInfo(info);
    };

    const getTopMenu = () => {
        fetch('datas/menu.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Cache-Control': 'no-cache'
            }
        }).then((response) => response.json())
            .then(menuData => {
                setMenuData(menuData);
                loadClickNodeContents();
            });
    };

    const loadClickNodeContents = () => {        
        let callMethod = 'MdManual';
        let contentType = typeof (props.ContentInfo.contentType) == 'object' ? 'Tutorial' : props.ContentInfo.contentType;
        let ContentMenuName = menuData != null ? menuData.find(n => n.path == contentType).menuName : '';
        var body = JSON.stringify({
            ContentType: props.ContentInfo.contentType,
            ContentName: props.ContentInfo.contentName,
            FolderName: props.ContentInfo.type == '' ? '' : props.ContentInfo.folderName,
            DocumentNo: props.ContentInfo.docNo == undefined ? props.ContentInfo.contentType : props.ContentInfo.contentType + props.ContentInfo.docNo,
            ContentMenuName: ContentMenuName,
            ContentMenuPath: props.NodePath,
            DevUserId: loginStates != null ? loginStates.get('userId') : 'anonymous'
        });
        if (document.querySelector('.tree-title') != null) {
            if (props.NodePath == document.querySelector('.tree-title').innerText && document.querySelector('.on') != null) {
                document.querySelector('.on').classList.remove('on');
            }
        }
        if (typeof (props.ContentInfo.contentName) == 'object') {
            callMethod = 'MdCombine';          
            let contentNames = props.ContentInfo.contentName.markdown.slice();
            contentNames.push({ "DevUserId": loginStates != null ? loginStates.get('userId') : 'anonymous', "ContentMenuName": ContentMenuName });
            body = JSON.stringify(contentNames);
        }       
        if (props.ContentInfo.type != 'pdf' && props.ContentInfo.type != 'notion') {
            fetch('api/Document/' + callMethod, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: body
            }).then(response => response.json())
                .then(data => {
                    setContentHtml(data);
                    ContentActions.setContent(data);
                });
        } else {
            setContentHtml('');
        }
    };

    const copyFunction = (e) => {
        var textArea = document.createElement("textarea");
        textArea.value = e.target.parentNode.firstElementChild.value;
        document.body.appendChild(textArea);
        textArea.select();
        var result = document.execCommand("copy");
        textArea.style.display = "none";
        if (result)
            alert("복사되었습니다.");
    };

    const renderingDoc = () => {        
        let html = contentHtml;
        // console.log("html: ", html);
        // console.log(props.ContentInfo);
        if (props.ContentInfo.type == 'notion') {            
            const url = props.ContentInfo.url;
            return (
                <div className="doc-content type-iframe">
                    <iframe title="doc-content-iframe" src={url} className="doc-content-iframe"></iframe>
                </div>
            );
        }
        if (html == 'No files..') {
            return (
                <div className="no-files" >
                    <i className="i-no-files" />
                    <p>컨텐츠 준비중입니다</p>
                </div>
            );
        }   
        else if (props.ContentInfo.type == 'pdf') {            
            return (
                <PDFDocViewer ContentInfo={props.ContentInfo} />
            );
        } 
        else {
            return (
                <div className="doc-content" dangerouslySetInnerHTML={{ __html: html }}>
                </div>
            );
        }
    };

    const viewer = renderingDoc();
    return (
        <Fragment>               
            {viewer}
        </Fragment>
    );        
}

export default ContentViewer;