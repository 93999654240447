import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import LeftContent from './TutorialPanels/LeftContent';
import StepList from './TutorialPanels/StepList';
import ContentViewer from './Common/ContentViewer';

import './styles/themes/vs.css';

import { useSelector, useDispatch } from 'react-redux';
import * as tutorialActions from '../store/modules/tutorialActions';
import { bindActionCreators } from 'redux';

const Tutorial = (props) => {
    const location = useLocation();
    const listName = location.pathname.replace('/', '');

    const TutorialStates = useSelector((state) => state.tutorialActions);
    const dispatch = useDispatch();

    const TutorialActions = bindActionCreators(tutorialActions, dispatch);

    const [menuView, setMenuView] = useState();
    const [menuIndex, setMenuIndex] = useState(0);
    const [stepIndex, setStepIndex] = useState(0);

    useEffect(() => {
        updateMenu(listName);
        if (location.state?.index != undefined) {
            setMenuIndex(location.state.index);
            setStepIndex(0);
        } else {
            const path = TutorialStates.get('path')
            if (path != '') {
                setMenuIndex(path.split('/')[0]);
                setStepIndex(path.split('/')[1]);
            }
        }
    }, []);

    const updateMenu = (listName) => {
        fetch('datas/' + listName + '.json',
            {
                headers: {
                    'Content-Type': 'application.json',
                    'Accept': 'application/json',
                    'Cache-Control': 'no-cache'
                }
            }).then((resp) => resp.json()).then(data => {
                setMenuView(data);
                TutorialActions.setMenu({"menuDatas": data});
            });
    };

    const changeStep = (step) => {        
        setStepIndex(step);
        TutorialActions.setPath(`${menuIndex}/${step}`);
    };
    
    const clickSubject = (index) => {
        setMenuIndex(index);
        setStepIndex(0);
        TutorialActions.setPath(`${index}/${0}`);
    };

    const changeSubject = (state, isComplete = "no") => {
        if (state == 'prev') {
            setMenuIndex(menuIndex - 1);
            setStepIndex(0);
            TutorialActions.setComplete({"menuIndex": menuIndex, "complete": isComplete});
            TutorialActions.setPath(`${menuIndex - 1}/${0}`);
        } else {
            setMenuIndex(menuIndex + 1);
            setStepIndex(0);
            TutorialActions.setComplete({"menuIndex": menuIndex, "complete": isComplete});
            TutorialActions.setPath(`${menuIndex + 1}/${0}`);
        }
        
    };

    const renderContent = () => {
        if (menuView?.hasOwnProperty('children')) {
            const index = menuIndex;
            const step = stepIndex;
            const isLast = index == menuView.children.length - 1 ? true : false
           
            const contentInfo = {
                contentType: menuView.children[index].children[step],
                contentName: menuView.children[index].children[step],
                folderName: '',
                type: '',
                video: menuView.children[index].children[step].video != undefined ? menuView.children[index].children[step].video : ''
            };
            return (
                <div className="contents-wrap">
                    <div className="left">
                        <h3 className="narrow-title"><a>K-STUDIO<i className="i-menu-dropdown"></i></a></h3>
                        <LeftContent menuIndex={index} data={menuView}
                            onClickSubject={clickSubject} />
                    </div>
                    <div className="content">
                        <div className="doc-wrap">
                            <StepList stepIndex={step} isLast={isLast} menuIndex={index} data={menuView.children[index]}
                                onChangeStep={changeStep}
                                onChangeSubject={changeSubject} />
                            <ContentViewer ContentInfo={contentInfo} />
                        </div>

                    </div>
                </div>
            );
        }
        else
            return '';
    };

    const render = renderContent();       

    return (
        <div className="container doc-type">
            {render}
        </div>
    );
};

export default Tutorial;