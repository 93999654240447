import React, { useState, useEffect } from 'react'
import storage from '../libs/storage';

const UpdateCompany = (props) => {
    const [companyList, setCompanyList] = useState(props.companyList);
    const [deptList, setDeptList] = useState(props.deptList);
    const [companyName, setCompanyName] = useState(props.companyName);
    const [companySeq, setCompanySeq] = useState(props.companySeq);
    const [companyType, setCompanyType] = useState(props.companyType);
    const [dept, setDept] = useState('0');
    const [kind, setKind] = useState(props.companyType == 7 || props.companyType == 9 ? '1' : '4');
    const [originKindList, setOriginKindList] = useState([]);
    const [kindList, setKindList] = useState([]);
    const [kindText, setKindText] = useState('');
    const [results, setResults] = useState([]);
    const [kindTextDisplay, setKindTextDisplay] = useState('none');
    const [deptDisplay, setDeptDisplay] = useState(props.deptDisplay);

    useEffect(() => {
        getKindList();
    }, []);

    const renderUserInfoSave = () => {
        if ((companyType == 9 && dept == '0')
            || ((companyType == 7 || companyType == 9) && kind == '0' && kindText == '')
            || companyName == '') {
            return (<button className='btn-primary btn-dis'>저장</button>);
        } else {
            return (<button className='btn-primary' onClick={companySave.bind(this)}>저장</button>);
        }
    }
    const companySave = () => {
        if ((companySeq == 128 || companySeq == 128 || companySeq == 128) && dept == '0') {
            alert('부서를 선택해주세요.');
            return;
        }
        let LoginCookies = storage.get('loginStates');
        const userCompanyInfo = {
            companySeq: companySeq,
            deptSeq: dept,
            kind: kind,
            kindText: kindText,
            userId: LoginCookies.get('userId'),
            companyType: companyType
        };
        fetch('api/User/CompanyInfoSave', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(userCompanyInfo)
        }).then((response) => response.json())
            .then((data) => {
                props.closePopup(data);
            });
    }
    const updateText = (text, seq) => {
        updateField("keyword", text, false);
        updateField("companySeq", seq, false);
        updateField("results", []);
        setKindTextDisplay('none');
        setKindText('');
    };
    const updateField = (field, value, update = true) => {
        if (update) onSearch(value);
        if (field == 'keyword') {
            setCompanySeq(0);
            setDeptDisplay('none');
            setCompanyName(value);
        }
        if (field == 'companySeq') {
            setCompanySeq(value);
            let searchCompanyType = companyList.filter(x => x.companySeq == value)[0].companyType;
            setCompanyType(searchCompanyType);
            changeKindList(originKindList, searchCompanyType);
            if (searchCompanyType == 7 || searchCompanyType == 9) {
                setKind('1');
            } else {
                setKind('4');
            }
            if (value == 128 || value == 129 || value == 130) {
                setDeptDisplay('block');
            } else {
                setDeptDisplay('none');
                setDept('0');
            }
        }
        if (field == 'results') {
            setResults(value);
        }
    }
    const onSearch = text => {
        var results = companyList.filter(item => true == matchName(item.companyName, text));
        setResults(results);
    };
    const matchName = (name, keyword) => {
        var keyLen = keyword.length;
        name = name.toLowerCase().substring(0, keyLen);
        if (keyword == "") return false;
        return name == keyword.toString().toLowerCase();
    };
    const handleChange = (e) => {
        if (e.target.tagName == 'LABEL') {
            setKind(e.target.previousSibling.value);
            if (e.target.previousSibling.value == '0') {
                setKindTextDisplay('block');
            } else {
                setKindTextDisplay('none');
                setKindText('');
            }
        }
        if (e.target.className == 'search-bar') {
            setCompanyName(e.target.value);
            updateField("keyword", e.target.value);
        }
        if (e.target.name == 'dept') {
            setDept(e.target.value);
        }
        if (e.target.name == 'kind') {
            setKind(e.target.value);
        }
        if (e.target.name == 'kindText') {
            setKindText(e.target.value);
        }
    }
    const searchBar = () => {
        var renderResults;
        if (results) {
            renderResults = results.map((item => {
                return (
                    <SearchView
                        updateText={updateText}
                        companyName={item.companyName}
                        companySeq={item.companySeq}
                    />
                );
            }));
        }
        return (
            <>
                <div className="search-input">
                    <i className="i-company"></i>
                    {companySeq == 0 ?
                        <input
                            className="search-bar"
                            placeholder="소속회사를 검색하세요"
                            value={companyName}
                            onChange={handleChange}
                        />
                        :
                        <input
                            className="search-bar"
                            placeholder="소속회사를 검색하세요"
                            value={companyName}
                            readOnly
                        />
                    }
                    <i className="i-search-s"></i>
                </div>
                {renderResults.length != 0 ? <div className="com-list">{renderResults}</div> : null}
            </>
        );
    };
    const renderDeptList = () => {
        let deptArr = [];
        deptArr.push(
            <option key={0} value={0}>부서를 선택하세요.</option>
        );
        for (let i = 0; i < deptList.length; i++) {
            deptArr.push(
                <option key={i + 1} value={deptList[i].deptSeq}>{deptList[i].deptName}</option>
            );
        }
        return deptArr;
    }
    const SearchView = ({ companyName, companySeq, index, updateText }) => {
        return (
            <div
                onClick={() => updateText(companyName, companySeq)}
                className={`com-item ${index == 0 ? "start" : ""}`}
            >
                    <i className="i-company"></i>
                    <p className="CompanyName" companySeq={companySeq}>{companyName}</p>
            </div>
        );
    };
    const getKindList = () => {
        fetch('./datas/kindtype.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache'
            }
        }).then(response => response.json())
            .then(json => {
                setOriginKindList(json);
                changeKindList(json, companyType);
            });
    }
    const changeKindList = (list, companyType) => {
        if (companyType == 7 || companyType == 9) {
            setKindList(list.filter(x => parseInt(x.kindValue) >= 0 && parseInt(x.kindValue) <= 3));
        } else {
            setKindList(list.filter(x => parseInt(x.kindValue) >= 4 && parseInt(x.kindValue) <= 5));
        }
    }
    const KindClass = () => {
        let kindArr = [];
        for (let i = 0; i < kindList.length; i++) {
            kindArr.push(
                <input key={'kind' + i} name='kind' type='radio' id={kindList[i].kindType} value={kindList[i].kindValue} checked={kind == kindList[i].kindValue} onChange={handleChange} />
            );
            kindArr.push(
                <label key={'kindLabel' + i} onClick={handleChange} htmlFor={kindList[i].kindType}>{kindList[i].kindName}</label>
            );
        }
        return kindArr;
    }
    const KindNameClass = () => {
        if (kindTextDisplay != 'none') {
            return (
                <div className="job-input">
                    <input name='kindText' value={kindText} onChange={handleChange} placeholder='기타 직무 명을 입력해 주세요.' />
                </div>
            );
        }
    }

    return (
            <div className="msg-popup update-company">
                <div className="box">
                    <div className="title-wrap">
                        <i className="i-alert-tri"></i>
                        <p className="title">회사 정보를 업데이트 해 주세요.</p>
                        <p className="sub-title">더 나은 서비스 제공을 위해 정확한 정보로 수정 해 주세요.</p>
                    </div>
                    <form className="form-wrap">
                        {searchBar()}
                        <div className="select-company" style={{ display: deptDisplay }}>
                            <select name="dept" onChange={handleChange}>
                                {renderDeptList()}
                            </select>
                        </div>
                    <div className="job-type">
                            <dl>
                                <dt>직무</dt>
                            <dd>
                                <div className="select-radio">
                                    {KindClass()}
                                </div>
                                {KindNameClass()}
                            </dd>

                            </dl>
                        </div>
                    </form>

                        <div className="btn-wrap">
                            {renderUserInfoSave()}
                        </div>
                </div>
            </div>
    );
}
export default UpdateCompany;