import React from 'react';

const Location = (props) => {
    const makePathList = (array) => {
        var newArray = [], index = 0;
        for (var i = 0; i < array.length; i++) {
            newArray.push(
                <li key={index++} className="path">{array[i]}</li>
            );
        }
        return newArray;
    };

    var arrPath = props.path.split('/');
    const pathList = makePathList(arrPath);
    return (
        <ol className="doc-nav">
            {pathList}
        </ol>
    );
}
export default Location;