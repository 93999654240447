import React, { Fragment, useRef, useState, useEffect } from 'react'
import PopUp from '../Common/PopUp';
import Filter from '../Common/Filter';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as messageActions from '../../store/modules/messageActions';
import storage from '../libs/storage';
import { useNavigate } from 'react-router-dom';
import Table from '../Common/Table';

export function usePrevState(state) {
    const ref = useRef(state);
    useEffect(() => {
        ref.current = state;
    }, [state]);
    return ref.current;
}

const AuthConfirm = () => {
    let selectedAppType = '';
    let selectedApproverToDate = '';
    let selectedApproverFromDate = '';
    let selectedAppMsg = '';
    let inputmsg = '';

    const history = useNavigate();

    const loginInfo = useSelector(state => state.loginActions);
    let loginuserid = loginInfo.get('userId') ?? "";
    // let checkingAdmin = (loginInfo.get('admin').indexOf('GroupAdmin') > -1  || loginInfo.get('admin').indexOf('DevUserAdmin') > -1) ? true : false ;
    let checkingAdmin = true;
    //let authType = loginInfo.get('admin').indexOf('GroupAdmin') > -1 ? "GroupMember" : "DevUser" ;

    let options = [
        { key: 145001, value: "요청중", class: "waiting color-t5", name: "요청중" },
        { key: 145002, value: "승인", class: "appr color-primary", name: "승인" },
        { key: 145003, value: "반려", class: "unappr color-t3", name: "반려" },
        { key: 0, value: "전체", class: "", name: "전체" }
    ];
    //Message Action
    const dispatch = useDispatch();
    const MessageActions = bindActionCreators(messageActions, dispatch);

    //State 
    const [data, setdata] = useState([]);
    const [totaldata, settotaldata] = useState([]);
    const [authType, setAuthType] = useState(loginInfo.get('admin').indexOf('GroupAdmin') > -1 ? "GroupMember" : "DevUser");
    const [popupContent, setpopupContent] = useState(null);
    const [isCheckedAll, setisCheckedAll] = useState(false);
    const [checkedItems, setcheckedItems] = useState([]);
    const [IsLoading, setIsLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState({ "from": "", "to": "" });
    const [popMsg, setPopMsg] = useState("반려 사유는 필수로 입력하세요.");

    let refpopupmsg = useRef();
    let refshowdate = useRef();

    useEffect(() => {
        setIsLoading(true);
        getList();

    }, [authType]);

    useEffect(() => {
        setisCheckedAll(false);
        setcheckedItems([]);
    }, [data]);

    // 리스트 데이터 조회
    const getList = () => {
        //인풋으로 담을 데이터
        let body = {
            'LoginUserid': loginuserid
        }
        //U: 개발권한
        let apiurl = 'api/Manager/AuthReq' + authType + 'TypeList';
        fetch(apiurl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        }).then((response) => response.json())
            .then((data) => {
                setdata(data?.table);
                settotaldata(data?.table);
                setIsLoading(false);
            }
            );
    }

    //개발권한 , 서버그룹접속권한 버튼 클릭
    const handleClick = (e) => {
        e.stopPropagation();
        if (authType == 'GroupMember') {
            setAuthType('DevUser');
        } else {
            setAuthType('GroupMember');
        }
        setcheckedItems([]);
        setisCheckedAll(false);
        
    }

    //체크박스 데이터 처리 
    const popupApplyList = (btnapproverType) => {
        if (checkedItems.length <= 0) {
            showToast('danger', '선택된 데이터가 없습니다.');
            return;
        }
        if (btnapproverType == null) {
            setpopupContent(null);
        }
        else if (btnapproverType == 145002) {
            checkApproverDataList();
        }
        else if (btnapproverType == 145003) {
            //승인거부시 사유작성
            setpopupContent(
                <div className="msg-popup-wrap dim">
                    <div className="msg-popup admin-approve-all">
                        <div className="box">
                            <p className="title color-t3">반려 처리</p>
                            <p className="">선택한 신청을 모두 반려 처리하시겠습니까?</p>
                            <dl id="" className="reason">
                                <dt>반려사유 <span className="required">* 필수</span><span className="info">모든 신청에 적용됩니다.</span></dt>
                                <dd>
                                    <textarea id="inputapprovermsg" name="remark" placeholder="반려 사유를 입력하세요." onChange={(e) => selectvalue(e)} />
                                </dd>
                            </dl>
                            <div className="btn-wrap">
                                <button className="btn-secondary-t5" onClick={() => popupApplyInfo(null)}>취소</button>
                                <button className="btn-primary-t3" onClick={() => updateStatusItemList(145003)}>반려</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    // 중복권한 메세지 처리 (체크박스 승인시)
    const CheckAccMessageShow = (msginfo) => {
        if (msginfo.length == 0) {
            return (
                <div className="msg-popup-wrap dim">
                    <div className="msg-popup admin-approve-all">
                        <div className="box">
                            <p className="title">승인 처리</p>
                            <p className="">선택한 신청을 모두 승인 하시겠습니까?</p>
                            <div className="btn-wrap">
                                <button className="btn-secondary-t5" onClick={() => popupApplyInfo(null)}>취소</button>
                                <button className="btn-primary" onClick={() => updateStatusItemList(145002)}>승인</button>
                            </div>
                        </div>
                    </div>
                </div>);
        } else {
            let list = [];
            for (let i = 0; i < msginfo.length; i++) {
                list.push(
                    <p key={i} className="">{msginfo[i]}</p>
                )
            }
            return (
                <div className="msg-popup-wrap dim">
                    <div className="msg-popup admin-approve-all">
                        <div className="box">
                            <p className="title">가입 승인</p>
                            <p className="">중복된 권한 목록이 있습니다. 승인시 기존 적용된 권한은 삭제 됩니다.</p>
                            <>
                                {list}
                            </>
                            <div className="btn-wrap">
                                <button className="btn-secondary-t5" onClick={() => popupApplyInfo(null)}>취소</button>
                                <button className="btn-primary" onClick={() => updateStatusItemList(145002)}>승인</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    // 체크박스 선택 후 팝업 
    const updateStatusItemList = (approverType) => {
        const body = [];
        if (approverType == 145003 && String(inputmsg).replace(' ', '').length < 1) {
            alert('(필수사유)반려 사유를 입력해주세요.');
            return;
        }

        for (let i = 0; i < data.length; i++) {
            //체크한 항목과 요청중인 항목만 처리되도록 수정 
            if ((checkedItems.indexOf(i) != -1) && (data[i].approverType == 145001)) {
                body.push({
                    ApproverMessage: (approverType == 145003 ? inputmsg : ''),
                    ReqSeq: data[i].reqSeq,
                    ApproverToDate: data[i].toDate,
                    ApproverFromDate: data[i].fromDate,
                    ApproverType: approverType,
                    WorkingTag: 'U',
                    LoginUserid: loginuserid
                })
            }
        }
        let apiaddr = 'api/Manager/Auth' + authType + 'ReqSave';
        fetch(apiaddr, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        }).then(async (response) => response.json())
            .then(async (data) => {
                setpopupContent(null);
                selectedAppType = (null);
                getList();
                showToast('success', '처리가 완료되었습니다.', 3000);
            })
            .catch(error => {
                console.log(error);
            })
    }

    //Date 양식 확인
    const convertdatedash = (date) => {
        if (date.length == 8) {
            return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');;
        }
        else {
            return date;
        }
    }

    // 리스트 클릭시 팝업
    const popupApplyInfo = (item) => {
        let datefrom = '';
        let dateto = '';
        if (item != null) {
            const fromDate = item.fromDate.split('.').join('');
            const toDate = item.toDate.split('.').join('');
            datefrom = convertdatedash(fromDate);
            dateto = convertdatedash(toDate);
        }
        else {
            selectedAppType = (null);
            setpopupContent(null);
            return;
        }
        selectedAppType = (item != null ? item.approverType : null);
        setpopupContent(popupDetailApplyInfo(item, datefrom, dateto, selectedAppType));
    }
    const popupClose = (content) => {
        setpopupContent(content);
    }
    const selectvalue = (e) => {
        if (e.target.id == 'detailselected') { //select
            selectedAppType = (e.target.value);
        } else if (e.target.id == 'approverMsg') {  //input text
            selectedAppMsg = e.target.value;
        } else if (e.target.id == 'inputapprovermsg') {//input text
            inputmsg = e.target.value;
        } 
    }
    const selectDate = (e, flag) => {
        let dates = selectedDate;
        dates[flag] = e.target.value;
        setSelectedDate(dates);
    }
    //리스트 클릭 처리시 체크  
    const updateStatusChecking = (item) => {
        let checkdatamsg = '';
        let appmsg = selectedAppMsg;

        
        let fromdate = selectedDate.from != "" ? selectedDate.from: item.fromDate.replace('.', '').replace('.', '');
        let todate = selectedDate.to != "" ? selectedDate.to : item.toDate.replace('.', '').replace('.', '');

        if (todate == '' || todate == 0 || todate == -1) {
            checkdatamsg = '만료일을 입력해 주세요';
        } else if (fromdate == '' || fromdate == 0 || fromdate == -1) {
            checkdatamsg = '적용일을 입력해 주세요';
        } else if (fromdate > todate) {
            checkdatamsg = '권한적용일이 만료일보다 클 수 없습니다.';
        }

        refpopupmsg.current.style.display = 'none';

        if (selectedAppType == '145003' && appmsg == '') {
            checkdatamsg = '반려시 사유는 필수 입력사항 입니다.';
        }
        if (selectedAppType == null || item.approverType == selectedAppType || selectedAppType == '145001') {
            checkdatamsg = '상태란의 승인 또는 반려를 선택해주세요.';
        }
        if (checkdatamsg != '') {
            setPopMsg(checkdatamsg);
            alert(checkdatamsg);
            return;
        }

        if (authType == 'GroupMember' && selectedAppType == '145002') {
            let Checkdata = true; //Check로 걸리는게 있으면 false
            const body = [];
            body.push({
                DevUserSeq: item.devUserSeq,
                DevGroupSeq: item.devGroupSeq,
                ApproverToDate: todate,
                ApproverFromDate: fromdate
            })
            fetch('api/Manager/AuthGroupMemberReqCheck', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(body)
            }).then(async (response) => response.json())
                .then(async (data) => {
                    let checkdata = data.table;
                    if (checkdata.length > 0) {
                        for (let i = 0; i < checkdata.length; i++) {
                            if (checkdata[i].status > 0) {
                                let fromdate = checkdata[i].fromDate;
                                let todate = checkdata[i].toDate;
                                refshowdate.current.innerText = (fromdate + ' ~ ' + todate);
                                refpopupmsg.current.style.display = '';
                                Checkdata = false;
                            }
                        }
                    }
                    if (Checkdata) {
                        updateStatusItem(item);
                    }
                })
        } else {
            updateStatusItem(item);
        }     
    } 
    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    }
    
    //승인 및 반려 처리 로직
    const updateStatusItem = (item) =>{      
        
        let fromdate = selectedDate.from.length > 0 ? selectedDate.from : item.fromDate.replace('.', '').replace('.', '');
        let todate = selectedDate.to.length > 0 ? selectedDate.to : item.toDate.replace('.', '').replace('.', '');
        let appmsg = selectedAppMsg;

        const body = [];
        body.push({
            WorkingTag: 'U',
            ApproverType: selectedAppType,
            ApproverToDate : todate,
            ApproverFromDate : fromdate,
            ApproverMessage :appmsg,
            ReqSeq : item.reqSeq,
            DevUserSeq : item.devUserSeq,
            DevGroupSeq : item.devGroupSeq,
            LoginUserid: loginuserid
        })

        let apiaddr =  'api/Manager/Auth' + authType + 'ReqSave';
        fetch(apiaddr, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(body)
            }).then(async(response) => response.json())
                .then(async(data) => {
                    setpopupContent(null);
                    selectedAppType = (null);
                    getList();
                    showToast('success', '처리가 완료되었습니다.', 3000);
                })
            .catch(error => {
                console.log(error);
            })

}

    const popupcon = (e) => {
        e.stopPropagation();
        if(refpopupmsg.current.style.display == ''){
            refpopupmsg.current.style.display = 'none';
        }
    }

    // 리스트 클릭시 팝업 
    const popupDetailApplyInfo = (applyInfo, datefrom, dateto, approverType) => {

        //개발권한의 경우 권한만료만 표기 
        const InputMod = [];
        InputMod.push(<input type="date" id="from" max="9999-12-31" defaultValue={datefrom} onChange={(e) => selectDate(e, "from")} onClick={(e) => { e.stopPropagation(); } } />);
        if (authType == 'GroupMember') {
            InputMod.push(<span className="txt">~</span>);
            InputMod.push(<input type="date" id="to" max="9999-12-31" defaultValue={dateto} onChange={(e) => selectDate(e, "to")} onClick={(e) => { e.stopPropagation(); } } />);
        } 

        return (applyInfo != null
            ?
            <div className="msg-popup-wrap dim">
                <div className="msg-popup-wrap dim" onClick={(e) => popupcon(e)} ref={refpopupmsg} style={{ display: 'none' }}>
                    <div className="msg-popup admin-approve-all">
                        <div className="box">
                            <p className="title">가입 승인</p>
                            <span className="color-t3" ref={refshowdate} ></span> <span> 기간까지 이미 권한이 등록된 사용자 입니다.</span>
                            <br></br>
                            <span>승인시 기존 권한은 삭제 됩니다.</span>
                            <div className="btn-wrap">
                                <button className="btn-secondary-t5" onClick={() => refpopupmsg.current.style.display = 'none'}>취소</button>
                                <button className="btn-primary" onClick={() => updateStatusItem(applyInfo)}>승인</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="msg-popup admin-user-info">
                    <div className="box">
                        <div className="title-wrap">
                            <p className="title">{authType == 'GroupMember' ? '서버접속권한 정보' : '개발권한 정보'}</p>
                            <button className="btn-close-popup" onClick={e => popupClose(null)}><i className="i-close-popup"></i></button>
                        </div>
                        <form className="form-wrap">
                            <div className="user-info-list">
                                <dl>
                                    <dt>신청자</dt>
                                    <dd>{applyInfo.repUserName}</dd>
                                </dl>
                                <dl>
                                    <dt>대상자</dt>
                                    <dd>{applyInfo.devUserName}</dd>
                                </dl>
                                <dl>
                                    <dt>{authType == 'GroupMember' ? '신청 그룹' : '신청 권한'}</dt>
                                    <dd>{applyInfo.devGroupName}</dd>
                                </dl>
                                <dl>
                                    <dt>소속</dt>
                                    <dd>{applyInfo.companyName}</dd>
                                </dl>
                                <dl>
                                    <dt>신청 사유</dt>
                                    <dd>{applyInfo.reqMessage}</dd>
                                </dl>
                                <dl className="">
                                    <dt>{authType == 'GroupMember' ? '권한적용일' : '권한만료일'}</dt>
                                    <dd>
                                        <div className="date-input" >
                                            {InputMod}
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>접수일</dt>
                                    <dd>{applyInfo.reqDateTime}</dd>
                                </dl>
                                <dl>
                                    <dt>상태</dt>
                                    <dd>
                                        <select id="detailselected" defaultValue={approverType} onChange={(e) => selectvalue(e)} >
                                            {options.map(x => {
                                                if (x.key > 0) {
                                                    return (<option value={x.key} key={x.value}>{x.name}</option>);
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </select>
                                    </dd>
                                </dl>
                                <dl className="reason">
                                    <dt>사유</dt>
                                    <dd>
                                        <input id="approverMsg" placeholder="승인 및 반려 사유를 입력하세요." onChange={(e) => selectvalue(e)} defaultValue={applyInfo.approverMessage} />
                                        <p className="required">{popMsg}</p>
                                    </dd>
                                </dl>
                            </div>
                            <div className="btn-wrap">
                                {(String(applyInfo.approverType) == '145001') ? <button className="btn-primary" onClick={() => updateStatusChecking(applyInfo)}>처리 완료</button> : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            : null
        );
    }

    //체크박스 승인시 중복검사 체크 로직
    const checkApproverDataList = () => {
        if (authType == 'GroupMember') {
            const body = [];
            for (let i = 0; i < data.length; i++) {
                if (checkedItems.indexOf(i) != -1) {
                    body.push({
                        DevUserSeq: data[i].devUserSeq,
                        DevGroupSeq: data[i].devGroupSeq,
                        ApproverToDate: data[i].toDate,
                        ApproverFromDate: data[i].fromDate,
                        WorkingTag: 'U'
                    })
                }
            }
            fetch('api/Manager/AuthGroupMemberReqCheck', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(body)
            }).then(async (response) => response.json())
                .then(async (data) => {
                    let ds = data.table;
                    let message = [];
                    for (let i = 0; i < ds.length; i++) {
                        if (ds[i].status > 0) {
                            message.push('대상자  :  ' + ds[i].devUserName + '[' + ds[i].devGroupName + ']');
                        }
                    }
                    setpopupContent(CheckAccMessageShow(message));
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            setpopupContent(CheckAccMessageShow(''));
        }
    }    
    const checkHandler = (e) => {
        let value = parseInt(e.target.value) ; 
        let isChecked = e.target.checked;
        if (isChecked) {
            if (checkedItems.length == data.length - 1) {
                setcheckedItems(prev => {return [...prev, value]});
                setisCheckedAll(true);
            } else {
                setcheckedItems(prev => {return [...prev, value]});
            }
        } else if (!isChecked && checkedItems.indexOf(value) != -1) {
                setcheckedItems(prev => prev.filter(item => item != value));
                setisCheckedAll(false);
        }
    }
    const allCheckedHandler = (e) => {
        if (e.target.checked) {
            var arr = [];
            for (let i = 0; i < data.length; i++) {
                arr.push(i);
            }
            setcheckedItems(arr) ;
            setisCheckedAll(true);
        } else {
            setcheckedItems([]);
            setisCheckedAll(false);
        }
    };

    //getList() 함수 데이터 출력  
    const registerList = () => {
        let list = [];
        if (!data || data?.length < 1)
        {
            return null;
        }
         for (let i = 0; i < data.length; i++) {
             const findIndex = options.findIndex(element => element.key == data[i].approverType);
             if (findIndex != null)
             {
                let classtype = (options[findIndex]?.class == undefined ? '0' : options[findIndex]?.class );
                let innerstr = options[findIndex]?.value;
                list.push(
                    <Fragment>   
                        <tr key={i} onClick={() => popupApplyInfo(data[i])}>
                            <td onClick={e => e.stopPropagation()}>
                                <div className="list-checkbox">
                                    <label htmlFor={"chk" + i}>
                                        <input type="checkbox" id={"chk" + i} value={i} onChange={(e) => checkHandler(e)} checked={checkedItems.includes(i) ? true : false} />
                                    </label>
                                </div>
                            </td>
                            <td>{data[i].reqDateTime}</td>
                            <td>{data[i].repUserName }</td>
                            <td>{data[i].devUserName + '(' + data[i].companyName + ')'}</td>
                            <td>{data[i].devGroupName}</td> 
                            <td>
                               {data[i].fromDate} 
                           </td>
                           <td>  {data[i].toDate} </td>
                            {                           
                             <>
                               <td className = {classtype}>
                                 {innerstr}
                               </td>
                             </>
                            }
                        </tr>
                    </Fragment>
                );
             }
         }
         return list;
    }

    //관리자권한 유무 확인
    const logsearchbtn = () => {
        let btnSearch = [];
        const checked = authType == 'GroupMember' ? true : false;
        if (loginInfo.get('admin').indexOf('GroupAdmin') > -1) {
            btnSearch.push(
                <div className="item" style={{ display: loginInfo.get('admin').indexOf('GroupAdmin') > -1 ? '' : 'none' }} >
                    <input id="done" name="register-tab" type="radio" checked={checked} onChange={(e) => handleClick(e)} />
                    <label htmlFor="done">서버 접속 권한</label>
                </div>
            );
        }
        if (loginInfo.get('admin').indexOf('DevUserAdmin') > -1) {
            btnSearch.push(
                <div className="item" style={{ display: loginInfo.get('admin').indexOf('DevUserAdmin') > -1 ? '' : 'none' }} >
                    <input id="progress" name="register-tab" type="radio" checked={!checked} onChange={(e) => handleClick(e)} />
                    <label htmlFor="progress">개발 권한</label>
                </div>
            );
        }
        return btnSearch;
    }

    //필터셋팅(서버그룹권한)
    const setFilter = () => {
        const seachKeywordTitle = authType == 'GroupMember'? '그룹' : '권한';
        return (
            <Filter key={0}
                listData={totaldata}
                setListView={setdata}
                comboBox={[{ name: "approverType", current: "145001", title: "상태", options: options }]}
                multiKeywords={[{ name: "companyName", title: "소속" }]}
                searchKeyword={{ name: ["repUserName", "devUserName", "devGroupName"], title: "이름," + seachKeywordTitle + "으로 검색" }}
                dateFromTo={[{ name: "fromDate", title: "기간", options: [{ key: "", name: "기간 전체" }, { key: "90", name: "최근 3개월" }, { key: "180", name: "최근 6개월" }, { key: "365", name: "최근 1년" }] }]}
            />);
    }
    const list = registerList();

    const setHeaders = () => {
        return [
            <div className="list-checkbox">
                <input type="checkbox" id="chkAll" onChange={(e) => allCheckedHandler(e)} checked={isCheckedAll} style={{ display: 'none' }} />
                <label htmlFor="chkAll"></label>
            </div>
            , 
            "신청일",
            "신청자",
            "대상자(소속)",
            authType == 'GroupMember' ? "신청 그룹" : "신청 권한",
            "권한 적용일",
            "권한 만료일",
            "상태"
        ];
    };

    if (checkingAdmin){
        return (
            <div className="content">
                <h2>권한신청관리</h2>
                <div className="list-tab">
                    {logsearchbtn()}
                    
                </div>
                {!IsLoading && (
                    <>
                        <div className="list-tools" key={0}>
                            <div className="batch-wrap">
                                <p className="count"><span className="selected">{checkedItems.length}</span>/{data?.length ?? 0} 선택됨</p>
                                <div className="btn-wrap">
                                    <button className="btn-primary" onClick={() => popupApplyList(145002)}>승인</button>
                                    <button className="btn-primary-t3" onClick={() => popupApplyList(145003)}>반려</button>
                                </div>
                            </div>
                            {setFilter()}
                        </div>
                        <Table className={"progress"} headers={setHeaders()} list={list} />
                        <PopUp content={popupContent} setContent={setpopupContent} />
                    </>
                )}
            </div>
    
        );
    }else{
        return history(-1);
    }
    
}
export default AuthConfirm;