import React, { Component, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import * as loginActions from '../../store/modules/loginActions';
import { bindActionCreators } from 'redux';

const Comments = (props) => {
    const LoginStates = useSelector(state => state.loginActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    
    const [state, setState] = useState({
        value: '',
        writercomment: '',
        postSeq: props.postSeq
    });

    const handleChange = (e, text) => {
        e.preventDefault();
        const value = e.target.value == undefined ? e.target.innerHTML : e.target.value;
        setState(
            { ...state, [e.target.id]: value }
        );
    };

    const clickConfirm = () => {
        if (state.writercomment.trim() == '') {
            alert("댓글을 입력해주세요.");
        }
        else {
            const input = {
                userId: LoginStates.get('userId'),
                userName: LoginStates.get('userName'),
                workingTag: 'commentSave',
                pageNo: 1,
                postInfo: {
                    contentType: '',
                    postSeq: props.postSeq,
                    attachSeq: 0,
                    commentSeq: 0,
                    postData: {
                        title: '',
                        content: '',
                        attachment: [],
                        comment: state.writercomment
                    }
                }
            };
            props.clickConfirm(input);
            setState({ ...state, writercomment: '' });
        }
    };

    const deleteComment = (num) => {
        const input = {
            userId: LoginStates.get('userId'),
            userName: LoginStates.get('userName'),
            workingTag: 'commentDelete',
            pageNo: 1,
            postInfo: {
                contentType: '',
                postSeq: props.postSeq,
                attachSeq: 0,
                commentSeq: props.comments[num].commentSeq,
                postData: {
                    title: '',
                    content: '',
                    attachment: [],
                    comment: ''
                }
            }
        };
        props.clickConfirm(input);       
    };

    let commentnode = [], index = 0;
    const commentlist = props.comments;

    for (var i = 0; i < commentlist.length; i++) {
        commentnode.push(
            <li key={index++}>
                <div className="info">
                    <span className="writer">{commentlist[i].commentwriter}&nbsp;&nbsp;&nbsp;</span>
                    <span className="timestamp">{commentlist[i].timestamp}</span>
                </div>
                <p className="comment">{commentlist[i].comment}</p>
                {LoginStates.get('userName') == commentlist[i].commentwriter ? <button onClick={deleteComment}>삭제</button> : null}
            </li>
        );
    }
    return (
        <div className="comment-wrap">
            <ul className="comment-view">
                {commentnode}
            </ul>
            <div className="comment-write">
                <textarea
                    type="text"
                    placeholder="댓글 입력"
                    id="writercomment"
                    value={state.writercomment}
                    onChange={(e) => handleChange(e, e.target.value)}></textarea>
                <button className="btn-submit btn-primary" onClick={clickConfirm}>확인</button>
            </div>
        </div>
    );
};
export default Comments;