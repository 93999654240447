import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const initialState = Map({
    visible: false,
    level: 'success',
    message: null
});

const SHOWMESSAGE = 'messageActions/SHOWMESSAGE';
const HIDEMESSAGE = 'messageActions/HIDEMESSAGE';

export const showToast = createAction(SHOWMESSAGE);
export const hideToast = createAction(HIDEMESSAGE);

export default handleActions({    
    [SHOWMESSAGE]: (state, action) => {
        return state.set('visible', action.payload.visible)
            .set('level', action.payload.level)
            .set('message', action.payload.message);
    },
    [HIDEMESSAGE]: (state, action) => {
        return state.set('visible', action.payload.visible);
    }
}, initialState);