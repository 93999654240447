import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as loginActions from '../../store/modules/loginActions';
// import * as messageActions from '../../store/modules/messageActions';
import { bindActionCreators } from 'redux';
import EmailVerificationPanel from './EmailVerificationPanel';

const SignUpPanel = (props) => {
    // const LoginStates = useSelector((state) => state.loginActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    // const MessageActions = bindActionCreators(messageActions, dispatch);

    const [isRun, setIsRun] = useState(false);
    const [signupInfo, setSignupInfo] = useState({
        userId: '',
        userEmail: '',
        userPw: '',
        userRePw: '',
        userName: '',
        userCompany: '',
        userPhoneNum: '',
        userSendOtpType: '2',
        userNameCheck: 'default',
        idCheck: 'default',
        emailCheck: 'default',
        pwCheck: 'default',
        rePwCheck: 'default',
        phoneCheck: 'default',
        otpTypePhoneNbDisplay: '',
        companyType: '',
        devType: '',
        kind: '1',
        kindNameDisplay: 'none',
        kindEtcName: '',
        deptSeq: 0,
        deptName: '',
        deptNameDisplay: 'block',
        signupInputMessage: '부서와 직무를 선택해 주세요.',
        searchDeptResult: [],
        searchCompanyResult: [],
        companySeq: 0,
        companyName: '',
        signupInputDisplay: 'none',
        signupQlfDisplay: 'none',
        kindList: [],
        originKindList: [],
        signupCpnClass: '',
        step02Display: 'none',
        companyNameDisplay: '',
        userEmailDisplay: 'none',
        isUseEmail: false,
        isNextButtonClicked: false,
        isDisabled: '',
        showEmailAdd: true,
        isVerify: false,
        companyDisabled: ''
    });

    useEffect(() => {
      getKindList();
    }, []);

    useEffect(() => {
        // console.log(signupInfo);
    }, [signupInfo]);

    const searchView = (type, item, updateText, index) => {
        var name, seq;
        if (type == 'dept') {
            name = item.deptName;
            seq = item.deptSeq;
        } else if (type == 'company') {
            name = item.companyName;
            seq = item.companySeq;
        }
        return (
            <div
                key={index}
                onMouseDown={() => updateText(type, name, seq)}
                className={`com-item ${index == 0 ? "start" : ""}`}
            >
                <i className="i-company"></i>
                <p className="CompanyName" seq={seq}>{name}</p>
            </div>
        );
    };

    const searchBar = (type) => {
        var renderResults;
        let results = [];
        if (type == 'dept') {
            results = signupInfo.searchDeptResult;
        } else if (type == 'company') {
            results = signupInfo.searchCompanyResult;
        }
        if (results) {
            renderResults = results.map(((item, index) => {
                return (
                    searchView(type, item, updateText, index)
                );
            }));
        }
        if (type == 'dept') {
            return (
                <>
                    <input name='deptName' value={signupInfo.deptName} onBlur={() => setSignupInfo({ ...signupInfo, searchDeptResult: [] })} onChange={e => handleChange(e)} placeholder='부서를 검색하세요.' />
                    {renderResults.length != 0 ? <div className="com-list">{renderResults}</div> : null}
                </>
            );
        } else if (type == 'company') {
            return (
                <>
                    <input name='companyName' value={signupInfo.companyName} onBlur={() => setSignupInfo({ ...signupInfo, searchCompanyResult: [] })} onChange={e => handleChange(e)} disabled={signupInfo.companyDisabled} placeholder='소속회사를 검색하세요.' />
                    {renderResults.length != 0 ? <div className="com-list">{renderResults}</div> : null}
                </>
            );
        }
    };
    
    const updateText = (type, text, seq) => {
        if (type == 'dept') {
            updateField(type, "deptName", text, false);
            updateField(type, "deptSeq", seq, false);
            updateField(type, "searchDeptResult", []);
        } else if (type == 'company') {
            updateField(type, "companyName", text, false);
            updateField(type, "companySeq", seq, false);
            updateField(type, "searchCompanyResult", []);
        }
    };

    const updateField = (type, field, value, update = true) => {
        if (update) onSearch(type, value);
        setSignupInfo(prev => {return { ...prev, [field]: value }});
    };

    const onSearch = (type, text) => {
        let results = [];
        if (type == 'dept') {
            let deptList = props.deptList;
            results = deptList.filter(item => true == matchName(item.deptName, text));
            setSignupInfo(prev => {return { ...prev, searchDeptResult: results }});
        } else if (type == 'company') {
            let companyList = props.companyList;
            results = companyList.filter(item => true == matchName(item.companyName, text));
            setSignupInfo(prev => {return { ...prev, searchCompanyResult: results }});
        }
    };
    
    const matchName = (name, keyword) => {
        if (keyword == "") return false;
        return name.toLowerCase().includes(keyword.toString().toLowerCase());
    };

    const nextStep = () => {
        setSignupInfo({ ...signupInfo, isNextButtonClicked: true, step02Display: '', otpTypePhoneNbDisplay: signupInfo.userSendOtpType == '2' ? '' : 'none', companyDisabled: 'disable' })
        scrollTo(document.documentElement, document.getElementById('step01').scrollHeight + 60);
    };

    const getKindList = () => {
        fetch('./datas/kindtype.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cache-Control': 'no-cache'
            }
        }).then(response => response.json())
            .then(json => {
                setSignupInfo({
                    ...signupInfo,
                    originKindList: json
                });
            })
    };

    const scrollTo = (element, to) => {
        var scrollTop = element.scrollTop;
        var interval = "";
        interval = setInterval(function () {
            element.scrollTop = scrollTop;
            scrollTop += 10;
            if (scrollTop >= to) {
                clearInterval(interval);
            }
        }, 1);
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = (name == 'userId' || name == 'userEmail' ? e.target.value.toLowerCase() : e.target.value);
        const id = e.target.id;
        let userName = signupInfo.userName;
        let userId = signupInfo.userId;
        let userEmail = signupInfo.userEmail;
        let password = signupInfo.userPw;
        let rePassword = signupInfo.userRePw;
        let phoneNumber = signupInfo.userPhoneNum;

        let upperLetters = /[A-Z]/;
        let lowerLetters = /[a-z]/;
        let number = /[0-9]/;
        let special = /[~!@#$%^&*()_+|<>?:{}]/;
        let emailExpression = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        let phoneNumberExpression = /(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
        let userNameExpression = /^[a-zA-Zㄱ-힣0-9]*$/;

        if (value.indexOf(' ') != -1) {
            return;
        }

        setSignupInfo(prev => {return { ...prev, [name]: value }});

        if (name == 'userPhoneNum') {
            let newValue = value.replace(/[^0-9]/g, '');
            phoneNumber = newValue;
            setSignupInfo(prev => {return { ...prev, userPhoneNum: newValue }});
        }
        if (id == 'email') {
            if (e.target.checked) {
                setSignupInfo(prev => {return { ...prev, isUseEmail: true, userEmailDisplay: '' }});
            } else {
                setSignupInfo(prev => {return { ...prev, isUseEmail: false, userEmailDisplay: 'none', userEmail: '' }});
            }
        }

        if (name == 'deptName') {
            updateField("dept", "deptName", value);
            setSignupInfo(prev => {return { ...prev, "deptSeq": 0 }});
        }
        if (name == 'companyName') {
            updateField("company", "companyName", value);
            setSignupInfo(prev => {return { ...prev, "companySeq": 0 }});
        }
        
        if (name == 'userId')
            userId = value;
        else if (name == 'userEmail')
            userEmail = value;
        else if (name == 'userPw')
            password = value;
        else if (name == 'userRePw')
            rePassword = value;
        else if (name == 'userName')
            userName = value;

        //이름 체크
        if (userName.length == 0)
            setSignupInfo(prev => {return { ...prev, userNameCheck: 'default' }});
        else if (userNameExpression.test(userName) == true)
            setSignupInfo(prev => {return { ...prev, userNameCheck: 'right' }});
        else if (userNameExpression.test(userName) == false)
            setSignupInfo(prev => {return { ...prev, userNameCheck: 'wrong' }});

        //Id 체크
        if (userId.length == 0) {
            setSignupInfo(prev => {return { ...prev, idCheck: 'default' }});
        }
        else if (emailExpression.test(userId) == true) {
            let expression = /^sc-([\w-]+(?:\.[\w-]+)*)@ksystem\.co\.kr$/;
            // 영림원 외주/계약인 경우
            if (signupInfo.devType == '2' && !expression.test(userId)) {
                setSignupInfo(prev => {return { ...prev, idCheck: 'wrong' }});
            }
            // 외주/계약 선택하지 않았는데 외주 계정으로 가입 시도 시
            else if (signupInfo.devType != '2' && expression.test(userId)) {
                setSignupInfo(prev => {return { ...prev, idCheck: 'wrong' }});
            }
            // 협력사나 고객사인 경우
            else if (signupInfo.devType != '2' && signupInfo.companySeq != 128 && signupInfo.companySeq != 129 && signupInfo.companySeq != 130 && userId.indexOf('@ksystem.co.kr') != -1) {
                setSignupInfo(prev => {return { ...prev, idCheck: 'wrong' }});
            }
            // 영림원 내부인 경우
            else if (signupInfo.devType != '2' && signupInfo.companySeq == 128 && userId.indexOf('@ksystem.co.kr') == -1 && userId.indexOf('@systemever.com') == -1) {
                setSignupInfo(prev => {return { ...prev, idCheck: 'wrong' }});
            }
            else {
                setSignupInfo(prev => {return { ...prev, idCheck: 'right' }});
            }
        }
        else if (emailExpression.test(userId) == false) {
            setSignupInfo(prev => {return { ...prev, idCheck: 'wrong' }});
        }

        //email 체크
        if (userEmail.length == 0)
            setSignupInfo(prev => {return { ...prev, emailCheck: 'default' }});
        else if (emailExpression.test(userEmail) == true)
            setSignupInfo(prev => {return { ...prev, emailCheck: 'right' }});
        else if (emailExpression.test(userEmail) == false)
            setSignupInfo(prev => {return { ...prev, emailCheck: 'wrong' }});

        //Pw 체크
        if (password.length == 0) {
            // userRePw = '';
            rePassword = '';
            setSignupInfo(prev => {return { ...prev, pwCheck: 'default' }});
        }
        else if (password.length >= 7 && upperLetters.test(password) && lowerLetters.test(password) &&
            special.test(password) && number.test(password))
            setSignupInfo(prev => {return { ...prev, pwCheck: 'right' }});
        else
            setSignupInfo(prev => {return { ...prev, pwCheck: 'wrong' }});

        //Pw 다시 체크
        if (rePassword.length == 0)
            setSignupInfo(prev => {return { ...prev, rePwCheck: 'default' }});
        else if (rePassword == password)
            setSignupInfo(prev => {return { ...prev, rePwCheck: 'right' }});
        else if (rePassword != password)
            setSignupInfo(prev => {return { ...prev, rePwCheck: 'wrong' }});

        //핸드폰 체크
        if (phoneNumber.length == 0) {
            setSignupInfo(prev => {return { ...prev, phoneCheck: 'default' }});
        } else if (phoneNumberExpression.test(phoneNumber)) {    
            setSignupInfo(prev => {return { ...prev, phoneCheck: 'right' }});
        } else {
            setSignupInfo(prev => {return { ...prev, phoneCheck: 'wrong' }});
        }
        
        setDisplay(name, id, value);
    };

    const setDisplay = (name, id, value) => {
        if (name == 'kind') {
            if (value == '0') {
                setSignupInfo(prev => {return { ...prev, isNextButtonClicked: false, kindNameDisplay: 'block', step02Display: 'none' }});
            } else {
                setSignupInfo(prev => {return { ...prev, isNextButtonClicked: false, kindNameDisplay: 'none', kindEtcName: '' }});
            }
        }
        if (document.querySelector('input[type="radio"][name="userSendOtpType"]:checked') != null) {
            if (document.querySelector('input[type="radio"][name="userSendOtpType"]:checked').value == '2') {
                setSignupInfo(prev => {return { ...prev, otpTypePhoneNbDisplay: '' }});
            } else {
                setSignupInfo(prev => {return { ...prev, otpTypePhoneNbDisplay: 'none', userPhoneNum: '', phoneCheck: 'default' }});
            }
        }
        if (name == 'companyType' || name == 'devType') {
            let kindArr = signupInfo.originKindList.filter(x => parseInt(x.kindValue) >= 0 && parseInt(x.kindValue) <= 3);
            if (name == 'companyType') {
                if (id == 'selCpnYlw') {
                    setSignupInfo(prev => {return { ...prev, companyDisabled: '', isDisabled: '', isVerify: false, isNextButtonClicked: false, companyNameDisplay: 'none', step02Display: 'none', signupCpnClass: 'clps', signupQlfClass: '', kind: '1', kindNameDisplay: 'none', deptNameDisplay: 'block', signupInputDisplay: 'none', signupQlfDisplay: 'block', deptName: '', devType: '', signupInputMessage: '부서와 직무를 선택해 주세요.', kindList: kindArr }});
                } else if (id == 'selCpnPtn') {
                    setSignupInfo(prev => {return { ...prev, companyDisabled: '', isDisabled: '', isVerify: false, isNextButtonClicked: false, companyNameDisplay: '', companyName: '', companySeq: 0,step02Display: 'none', signupCpnClass: 'clps', signupQlfClass: '', kind: '1', kindNameDisplay: 'none', deptNameDisplay: 'none', signupInputDisplay: 'block', signupQlfDisplay: 'none', deptName: '', signupInputMessage: '회사와 직무를 선택해 주세요.', kindList: kindArr }});
                } else if (id == 'selCpnCs') {
                    kindArr = signupInfo.originKindList.filter(x => parseInt(x.kindValue) >= 4 && parseInt(x.kindValue) <= 5);
                    setSignupInfo(prev => {return { ...prev, companyDisabled: '', isDisabled: '', isVerify: false, isNextButtonClicked: false, companyNameDisplay: '', companyName: '', companySeq: 0,step02Display: 'none', signupCpnClass: 'clps', signupQlfClass: '', kind: '4', kindNameDisplay: 'none', deptNameDisplay: 'none', signupInputDisplay: 'block', signupQlfDisplay: 'none', deptName: '', signupInputMessage: '회사와 직무를 선택해 주세요.', kindList: kindArr }});
                }
            }
            if (name == 'devType') {
                if (id == 'selQlfIn') {
                    setSignupInfo(prev => {return { ...prev, companyDisabled: '', isDisabled: '', isVerify: false, isNextButtonClicked: false, companyNameDisplay: 'none', companyName: '영림원소프트랩', companySeq: 128, step02Display: 'none', signupQlfClass: 'clps', kind: '1', kindNameDisplay: 'none', deptNameDisplay: 'block', signupInputDisplay: 'block', signupQlfDisplay: 'block', deptName: '', signupInputMessage: '부서와 직무를 선택해 주세요.', kindList: kindArr }});
                } else if (id == 'edu') {
                    kindArr = signupInfo.originKindList.filter(x => parseInt(x.kindValue) >= 6 && parseInt(x.kindValue) <= 7);
                    setSignupInfo(prev => {return { ...prev, companyDisabled: '', isDisabled: '', isVerify: false, isNextButtonClicked: false, companyNameDisplay: 'none', companyName: '영림원소프트랩(교육생)', companySeq: 129, step02Display: 'none', signupQlfClass: 'clps', kind: '6', kindNameDisplay: 'none', deptNameDisplay: 'none', signupInputDisplay: 'block', signupQlfDisplay: 'block', deptName: '', signupInputMessage: '교육 과정을 선택해 주세요.', kindList: kindArr }});
                } else {
                    setSignupInfo(prev => {return { ...prev, companyDisabled: '', isDisabled: '', isVerify: false, isNextButtonClicked: false, companyNameDisplay: '', companyName: '', companySeq: 130, step02Display: 'none', signupQlfClass: 'clps', kind: '1', kindNameDisplay: 'none', deptNameDisplay: 'none', signupInputDisplay: 'block', signupQlfDisplay: 'block', deptName: '', signupInputMessage: '회사와 직무를 선택해 주세요.', kindList: kindArr }});
                }
            }
        }
        // setSignupInfo(prev => {return {...prev, ...content}});
    };
    const CheckClass = (num) => {
        if (num == 'default')
            return (null);
        else if (num == 'wrong')
            return (<div className='ico-check wrong'><i className='i-input-wrong'></i></div>);
        else if (num == 'right')
            return (<div className='ico-check'><i className='i-input-ok'></i></div>);
    };
    
    const AfterCheckAuth = () => {
        setSignupInfo({
            ...signupInfo,
            isVerify: true
        });
    };

    const AfterRequestAuth = () => {
        if (signupInfo.isUseEmail) {
            setSignupInfo({
                ...signupInfo,
                isDisabled: 'disable'
            });
        } else {
            setSignupInfo({
                ...signupInfo,
                showEmailAdd: false
            });
        }
    };

    const ConditionPw = (num) => {
        if (num == 'default' || num == 'right')
            return (<p></p>);
        else
            return (<div className="pw-msg">비밀번호는 대문자, 소문자, 숫자, 특수문자 조합으로 7자 이상이어야 합니다.</div>);
    };

    const ConditionId = (num) => {
        if (num == 'default' || num == 'right')
            return (<p></p>);
        else {
            // 영림원 외주/계약인 경우
            let expression = /^sc-([\w-]+(?:\.[\w-]+)*)@ksystem\.co\.kr$/;
            if (signupInfo.devType == '2' && !expression.test(signupInfo.userId)) {
                return (<div className="pw-msg">아이디는 sc-(문자)@ksystem.co.kr 주소로 가입이 가능합니다.</div>);
            }
            // 외주/계약 선택하지 않았는데 외주 계정으로 가입 시도 시
            else if (signupInfo.devType != '2' && expression.test(signupInfo.userId)) {
                return (<div className="pw-msg">입력하신 이메일 주소는 외주/계약 선택 후 가입 가능합니다.</div>);
            }
            // 협력사나 고객사인 경우
            else if (signupInfo.devType != '2' && signupInfo.companySeq != 128 && signupInfo.companySeq != 129 && signupInfo.companySeq != 130 && signupInfo.userId.indexOf('@ksystem.co.kr') != -1) {
                return (<div className="pw-msg">ksystem.co.kr 주소는 영림원 소속인 경우 사용할 수 있습니다.</div>);
            }
            // 영림원 내부인 경우
            else if (signupInfo.devType != '2' && signupInfo.companySeq == 128 && signupInfo.userId.indexOf('@ksystem.co.kr') == -1 && signupInfo.userId.indexOf('@systemever.com') == -1) {
                return (<div className="pw-msg">사내 이메일 주소로 가입 신청해주세요.</div>);
            } else {
                return (<p></p>);
            }
        }
    };

    const CompanyNameClass = () => {
        if (signupInfo.companyNameDisplay != 'none') {
            return (
                <dl className="input-ui">
                    <dt>회사</dt>
                    <dd>
                        {searchBar("company")}
                    </dd>
                </dl>
                );
        }
    };
    const DeptNameClass = () => {
        if (signupInfo.deptNameDisplay != 'none') {
            return (
                <dl className="input-ui" id="inputDeptName">
                    <dt>부서</dt>
                    <dd>
                        {searchBar("dept")}
                    </dd>
                </dl>
            );
        }
    };

    const SignUpClass = () => {
        if (signupInfo.idCheck == 'right' && signupInfo.emailCheck != 'wrong' && signupInfo.pwCheck == 'right' &&
            signupInfo.rePwCheck == 'right' && signupInfo.userName != '' && signupInfo.phoneCheck != 'wrong' && signupInfo.userNameCheck != 'wrong' && signupInfo.isVerify == true) {
            if ((signupInfo.userSendOtpType == '2' && signupInfo.phoneCheck == 'right') || signupInfo.userSendOtpType != '2') {
                return (<button onClick={ShowMessage} className='btn-primary'>가입 신청</button>);
            } else {
                return (<button className='btn-primary btn-dis'>가입 신청</button>);
            }
        } else {
            return (<button className='btn-primary btn-dis'>가입 신청</button>);
        } 
    };

    const StepClass = () => {
        if ((signupInfo.deptName != '' || signupInfo.devType != '1' || signupInfo.companyType != '1') && !signupInfo.isNextButtonClicked) {
            if (signupInfo.kind == '0' && signupInfo.kindEtcName == '' || signupInfo.companySeq == 0 || signupInfo.companySeq == 130 || (signupInfo.companySeq == 128 && signupInfo.deptSeq == 0)) {
                return (<div className="btn-next-wrap btn-dis">
                    <div className="next-txt">이제 마지막 단계예요.</div>
                    <button className="btn-next-step btn-primary">
                        <div className="ico-wrap"><i className="i-arrow-w-b"></i></div>
                    </button>
                </div>);
            } else {
                return (
                    <div className="btn-next-wrap">
                        <div className="next-txt">이제 마지막 단계예요.</div>
                        <button onClick={e => nextStep(e)} className="btn-next-step btn-primary">
                            <div className="ico-wrap"><i className="i-arrow-w-b">
                            </i></div>
                        </button>
                    </div>);
            }
        } else {
            return (<div className="btn-next-wrap btn-dis">
                <div className="next-txt">이제 마지막 단계예요.</div>
                <button className="btn-next-step btn-primary">
                    <div className="ico-wrap"><i className="i-arrow-w-b"></i></div>
                </button>
            </div>);
        }
    };

    const KindClass = () => {
        let kindArr = [];
        for (let i = 0; i < signupInfo.kindList.length; i++) {
            kindArr.push(
                <input key={'kind' + i} name='kind' type='radio' id={signupInfo.kindList[i].kindType} value={signupInfo.kindList[i].kindValue} checked={signupInfo.kind == signupInfo.kindList[i].kindValue} onChange={e => handleChange(e)} />
            );
            kindArr.push(
                <label key={'kindLabel' + i} htmlFor={signupInfo.kindList[i].kindType}>{signupInfo.kindList[i].kindName}</label>
            );
        }
        return kindArr;
    };

    const KindNameClass = () => {
        if (signupInfo.kindNameDisplay != 'none') {
            return (
                <div className="input-ui">
                    <input name='kindEtcName' value={signupInfo.kindEtcName} onChange={e => handleChange(e)} placeholder='기타 직무 명을 입력해 주세요.' />
                </div>
            );
        }
    };

    const OtpTypePhoneNbClass = () => {
        if (signupInfo.otpTypePhoneNbDisplay != 'none') {
            return (
                <dl className="input-ui otpTypePhoneNb" id="otpTypePhoneNb">
                    <dt>휴대폰 번호<span className="tiny">2차인증 카카오톡 선택 시 필수</span></dt>
                    <dd>
                        <div className="input-ui">
                            <input
                                name='userPhoneNum'
                                value={signupInfo.userPhoneNum}
                                onChange={e => handleChange(e)}
                                maxLength='11'
                                placeholder='휴대폰 번호(숫자만 입력하세요)' />
                            {CheckClass(signupInfo.phoneCheck)}
                        </div>
                    </dd>
                </dl>
            );
        }
    };

    const SignupInputClass = () => {
        if (signupInfo.signupInputDisplay != 'none') {
            return (
                <div className="signup-input">
                    <h2>{signupInfo.signupInputMessage}</h2>
                    {DeptNameClass()}
                    {CompanyNameClass()}
                    <dl className="input-ui">
                        <dt>직무</dt>
                        <dd>
                            <div className="select-radio">
                                {KindClass()}
                            </div>
                            {KindNameClass()}
                        </dd>
                    </dl>
                    {StepClass()}
                </div>
            );
        }
    };

    const SignupQlfClass = () => {
        if (signupInfo.signupQlfDisplay != 'none') {
            return (
                <div className={`signup-qlf ${signupInfo.signupQlfClass ?? ''}`}>
                    <h2>자격을 선택해 주세요.</h2>
                    <div className="signup-select-ui shorten">
                        <ul>
                            <li className="sel-qlf-in">
                                <input type="radio" id="selQlfIn" name="devType" value="1" checked={signupInfo.devType == '1'} onChange={e => handleChange(e)} />
                                <label htmlFor="selQlfIn">
                                    <div className="checkbox"><i className="i-check-white"></i></div>
                                    <h3>영림인</h3>
                                    <p className="txt"></p>
                                    <i></i>
                                </label>
                            </li>
                            <li className="sel-qlf-out">
                                <input type="radio" id="selQlfOut" name="devType" value="2" checked={signupInfo.devType == '2'} onChange={e => handleChange(e)} />
                                <label htmlFor="selQlfOut">
                                    <div className="checkbox"><i className="i-check-white"></i></div>
                                    <h3>외주/계약</h3>
                                    <p className="txt"></p>
                                    <i></i>
                                </label>
                            </li>
                            <li className="sel-qlf-trn">
                                <input type="radio" id="edu" name="devType" value="3" checked={signupInfo.devType == '3'} onChange={e => handleChange(e)} />
                                <label htmlFor="edu">
                                    <div className="checkbox"><i className="i-check-white"></i></div>
                                    <h3>교육생</h3>
                                    <p className="txt"></p>
                                    <i></i>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
    };

    const ShowMessage = (e) => {
        e.preventDefault();
        if (!isRun) {
            setIsRun(true);
            const signupUserInfo = {
                userName: signupInfo.userName,
                userCompany: signupInfo.companyName.trim(),
                userId: encodeURI(signupInfo.userId),
                userEmail: encodeURI(signupInfo.userEmail == '' ? signupInfo.userId : signupInfo.userEmail),
                userPw: encodeURI(signupInfo.userPw),
                userPhoneNum: signupInfo.userPhoneNum,
                userSendOtpType: signupInfo.userSendOtpType,
                companyType: signupInfo.companyType,
                companySeq: (signupInfo.devType == '2' ? 130 : signupInfo.companySeq),
                devType: signupInfo.devType,
                kind: signupInfo.kind,
                kindEtcName: signupInfo.kindEtcName,
                deptSeq: signupInfo.deptSeq,
                deptName: signupInfo.deptName
            };

            fetch('api/Login/Signup', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(signupUserInfo)
            }).then((response) => response.json())
                .then((data) => {
                    setIsRun(false);
                    if (data.status == 'OK') {
                        props.setPanelType('showMessage');
                    } else {
                        const { MessageActions } = props;
                        MessageActions.showToast({
                            visible: true,
                            level: 'warning',
                            message: data.message
                        });
                        setTimeout(() =>
                            MessageActions.hideToast({ visible: false })
                            , 1500);
                    }
                });
        }
    };

    const GoBackLogin = () => {
        props.setPanelType('loginAgain');
    };

    const UserEmailClass = () => {
        if (signupInfo.userEmailDisplay != 'none') {
            return (
                <dl className="input-ui">
                    <dt>임시아이디 등 특수한 경우에만 사용하세요.</dt>
                    <dd>
                        <input
                            name='userEmail'
                            value={signupInfo.userEmail}
                            onChange={e => handleChange(e)}
                            placeholder='알림을 수신할 이메일을 입력하세요.'
                            disabled={signupInfo.isDisabled}
                        />
                        {CheckClass(signupInfo.emailCheck)}
                    </dd>
                </dl>
                );
        }
    };

    const Step02Class = () => {
        if (signupInfo.step02Display != 'none') {
            return (
                <div className="signup-step step02">
                    <div className="signup-input">
                        <h2>가입 신청을 완료해 주세요.</h2>

                        <dl className="input-ui">
                            <dt>이름</dt>
                            <dd>
                                <input
                                    name='userName'
                                    value={signupInfo.userName}
                                    onChange={e => handleChange(e)}
                                    placeholder='이름'
                                    disabled={signupInfo.isDisabled} />
                                {CheckClass(signupInfo.userNameCheck)}
                            </dd>
                        </dl>
                        <dl className="input-ui id-email">
                            <dt>이메일(아이디)</dt>
                            <dd>
                                <input
                                    name='userId'
                                    value={signupInfo.userId}
                                    onChange={e => handleChange(e)}
                                    placeholder='이메일(아이디로 사용됩니다)'
                                    disabled={signupInfo.isDisabled} />
                                {CheckClass(signupInfo.idCheck)}
                                {ConditionId(signupInfo.idCheck)}
                            </dd>
                            {signupInfo.showEmailAdd ? <div className="email-add">
                                <div className="check-wrap"><input type="checkbox" id="email" checked={signupInfo.isUseEmail} disabled={signupInfo.isDisabled} onChange={e => handleChange(e)} /><label htmlFor="email" className="toggle"></label><label htmlFor="email" className="txt">다른 메일로 인증 및 알림 메일을 받을래요.</label></div>
                                {UserEmailClass()}
                            </div>
                            : null}
                            {/*id로만 체크할 경우 id 유효성 체크하고 다른 메일 인증 시 다른 메일 유효성 체크*/}
                            {((signupInfo.idCheck == 'right' && signupInfo.userEmailDisplay == 'none')
                                || (signupInfo.idCheck == 'right' && signupInfo.emailCheck == 'right' && signupInfo.userEmailDisplay != 'none'))
                                && signupInfo.userName != '' ? <EmailVerificationPanel buttonTitle={'인증번호 요청'} state={signupInfo} fnAfterCheckAuth={AfterCheckAuth} fnAfterRequestAuth={AfterRequestAuth} /> : null}
                        </dl>
                        <dl className="input-ui"><dt>비밀번호</dt>
                            <dd>
                                <input
                                    name='userPw'
                                    value={signupInfo.userPw}
                                    type='password'
                                    onChange={e => handleChange(e)}
                                    placeholder='비밀번호(대문자, 소문자, 숫자, 특수문자 조합으로 7자 이상)' />
                                {CheckClass(signupInfo.pwCheck)}
                                {ConditionPw(signupInfo.pwCheck)}
                            </dd>
                        </dl>
                        <dl className="input-ui">
                            <dt>비밀번호 확인</dt>
                            <dd>
                                <input
                                    name='userRePw'
                                    value={signupInfo.userRePw}
                                    type='password'
                                    onChange={e => handleChange(e)}
                                    placeholder='비밀번호 확인' />
                                {CheckClass(signupInfo.rePwCheck)}
                            </dd>
                        </dl>
                        <dl className="input-ui otpType">
                            <dt>2차 인증</dt>
                            <dd>
                                <div className="select-radio">
                                    <input name='userSendOtpType'
                                        value='2'
                                        type='radio'
                                        onChange={e => handleChange(e)}
                                        checked={signupInfo.userSendOtpType == '2'} id="otpTypeKakao" />
                                    <label htmlFor="otpTypeKakao">카카오톡</label>
                                    <input name='userSendOtpType'
                                        value='1'
                                        type='radio'
                                        onChange={e => handleChange(e)}
                                        checked={signupInfo.userSendOtpType == '1'} id="otpTypeEmail" />
                                    <label htmlFor="otpTypeEmail">이메일</label>
                                    <input
                                        name='userSendOtpType'
                                        value='0'
                                        type='radio'
                                        onChange={e => handleChange(e)}
                                        checked={signupInfo.userSendOtpType == '0'} id="otpTypeNo" />
                                    <label htmlFor="otpTypeNo">사용안함</label>
                                </div>
                            </dd>
                        </dl>
                        {OtpTypePhoneNbClass()}
                        <div className="btn-wrap">
                            {SignUpClass()}
                        </div>
                    </div>

                    <div className="info-box-kstudio">
                        K Developers의 계정으로 영림원소프트랩의 개발 툴인 K-Studio를 사용할 수 있습니다.
                        <br /><br/><ol>
                            <li>K-Studio는 라이선스를 구매한 고객사에게만 제공되며, 라이선스를 보유하지 않은 사용자는 사용할 수 없습니다.</li>
                            <li>K-Studio로 생성된 모든 산출물은 라이선스를 구매한 고객사 내부에서만 사용이 허용됩니다.</li>
                            <li> K-Studio로 생성한 산출물을 불법적으로 복제, 배포, 판매하는 것은 엄격히 금지됩니다.</li>
                        </ol>


                    </div>
                </div>
                );
        }
    };

    return (
        <div className="signup-wrapper">
            <div className="signup-wrap">
                <div className="signup-step step01" id="step01">
                    <div className={`signup-cpn ${signupInfo.signupCpnClass ?? ''}`}>
                        <h2>소속을 선택해 주세요.</h2>
                        <div className="signup-select-ui">
                            <ul>
                                <li className="sel-cpn-cs">
                                    <input type="radio" id="selCpnCs" name="companyType" value="0" checked={signupInfo.companyType == '0'} onChange={e => handleChange(e)} />
                                    <label htmlFor="selCpnCs">
                                        <div className="checkbox"><i className="i-check-white"></i></div>
                                        <h3>고객사</h3>
                                        <p className="txt">영림원소프트랩의 고객사<br />소속입니다.</p>
                                        <i className="i-signup-cpn-cs"></i>
                                    </label>
                                </li>
                                <li className="sel-cpn-ptn">
                                    <input type="radio" id="selCpnPtn" name="companyType" value="7" checked={signupInfo.companyType == '7'} onChange={e => handleChange(e)} />
                                    <label htmlFor="selCpnPtn">
                                        <div className="checkbox"><i className="i-check-white"></i></div>
                                        <h3>협력사</h3>
                                        <p className="txt">영림원소프트랩과 함께<br />사업을 하는 협력사 소속입니다.</p>
                                        <i className="i-signup-cpn-ptn"></i>
                                    </label>
                                </li>
                                <li className="sel-cpn-ylw">
                                    <input type="radio" id="selCpnYlw" name="companyType" value="9" checked={signupInfo.companyType == '9'} onChange={e => handleChange(e)} />
                                    <label htmlFor="selCpnYlw">
                                        <div className="checkbox"><i className="i-check-white"></i></div>
                                        <h3>영림원소프트랩</h3>
                                        <p className="txt">영림원소프트랩 소속 또는<br />교육생, 외주 계약직입니다.</p>
                                        <i className="i-signup-cpn-ylw"></i>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {SignupQlfClass()}
                    {SignupInputClass()}
                </div>
                {Step02Class()}
            </div>
        </div>
    );
}

export default SignUpPanel;