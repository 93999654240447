import React, { Component } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import * as contentActions from '../../store/modules/contentActions';
import * as openApiActions from '../../store/modules/openApiActions';
import { bindActionCreators } from 'redux';

const MenuFilter = (props) => {
    const ContentStates = useSelector(state => state.contentActions);
    // OpenApiStates: state.openApiActions
    
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    // OpenApiActions: bindActionCreators(openApiActions, dispatch)

    const transformJSON = (data, value) => {
        let newObj = [];
        (function filterJSON(obj, searchValue, module) {            
           let path = '';
            if (module != undefined) {
                path = module;
                if (obj.module != undefined) {
                    path = module != "" ? module + '/' + obj.module : obj.module;
                }
            }                            
            if ((obj.module && (obj.module.toLowerCase().indexOf(searchValue.toLowerCase()) > -1))
                || (obj.tags && obj.tags.filter(w => w.toLowerCase().indexOf(searchValue.toLowerCase()) > -1))
            ) {               
                obj.filterPath = path + '/';
                newObj.push(obj);
                return;
            }
            for (var key in obj) {
                if (typeof obj[key] == "object" && obj[key] != null) {
                    filterJSON(obj[key], searchValue, path);
                }
            }
        })(data, value, '');
        return newObj;
    };

    const menuFiltering = (e) => {
        let menuData = ContentStates.get('menuDatas');
        let menuView = ContentStates.get('menuViews');
       
        // if (this.props.placeholder != undefined && this.props.placeholder.indexOf("API") > -1) {
        //     menuData = OpenApiStates.get('serviceListData');
        //     menuView = OpenApiStates.get('serviceListView');       
        // }        
        menuView.children = transformJSON(menuData.children, e.target.value);          
        // if (this.props.placeholder != undefined && this.props.placeholder.indexOf("API") > -1)
        //     OpenApiActions.setList({ treeData: menuData, treeView: menuView });    
        // else
        ContentActions.setMenu({ treeData: menuData, treeView: menuView });        
    };

    let placeholder = props.placeholder != undefined ? props.placeholder : "제목 필터"
    return (
        <div className="search-filter">
            <input type="text" placeholder={placeholder} onChange={menuFiltering}/>
            <div className="btn-search"><i className="i-search-s"></i></div>
        </div>
    );
}
export default MenuFilter;