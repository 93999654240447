import React, { useEffect,useState } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import { FavoriteContent } from './HomePanels/FavoriteContent';
import DOMPurify from 'dompurify';

import { useSelector } from "react-redux";

const Home = (props) => {
    //page
    const history = useNavigate();

    const LoginStates = useSelector(state => state.loginActions);
    const [searchword,setSearchWord] = useState('');
    const [postData, setPostData] = useState({});

    useEffect(()=>{
        const input = {
            userId: '',
            userName: '',
            workingTag: 'homePostList',
            pageNo: 1,
            postInfo: {
                contentType: '',
                postSeq: 0,
                attachSeq: 0,
                commentSeq: 0,
                postData: {
                    title: '',
                    content: '',
                    attachment: [],
                    comment: ''
                }
            }
        };
        fetch('api/Document/NewsManage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(input)
        }).then((response) => response.json())
            .then((data) => {
                setPostData(JSON.parse(data.postData));
            });
        },[]);
        const searchText = (e) => {
            setSearchWord(e.target.value);
        }
        const executeSearch = () => {
            if (searchword != '') {
                //const method = this.props.replace ? this.props.history.replace : this.props.history.push;
                history("/Search", {
                    state: searchword
                });
            }
        }
        const handleKeyPress = (e) => {
            if (e.key == 'Enter') {
                //const method = this.props.replace ? this.props.history.replace : this.props.history.push;
                history("/Search", {
                    state: e.target.value
                });
            }
        }
        const newlineMaker = (contents) => {
            let strcontent = '';
            contents.split('<br>').forEach(line => {
                strcontent += '<span>' + line + '<br /></span>';
            });
            return (
                <p className="txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(strcontent) }} />
            
            );
        }
        const showNewsList = () => {
            let postNodes = [], index = 0;
            const postList = postData.postDatas;

            if (postList != undefined) {
                for (let i = 0; i < postList.length; i++) {
                    postNodes.push(
                        <Link key={index++} to={{
                            pathname: '/News',
                            state: {
                                postSeq: postList[i].postdata.postSeq,
                                groupNo: postList[i].postdata.groupNo
                            }
                        }}>
                            <li>
                                <div className="news-item">
                                    <p className="date">{postList[i].postdata.timestamp}</p>
                                    <h3>{postList[i].postdata.posttitle}</h3>
                                    <div className="preview">{newlineMaker(postList[i].postdata.postcontent)}</div>
                                </div>
                            </li>
                        </Link>
                    );
                }
            }
            return postNodes;
        }
        const openKDCSetupWindow = () => {        
            window.open('https://kdc.proas.co.kr', "KDC Setup");
        }
        return (
            <div className="container">
                <div className="home">
                    <section className="top">
                        <div className="sec-wrap">
                            <div className="hello">
                                <p className="highlight">Hello, K-Developer {LoginStates.get('userName') != '' ? LoginStates.get('userName') + '님!' : LoginStates.get('userName') } </p>
                                <p className="sub">K Developers는 영림원 서비스를 개발하는 개발자들을 위한 공간입니다.</p>
                            </div>
                            <div className="search">
                                <div className="home-search-input">
                                    <input type="text" placeholder='검색어를 입력하세요' onChange={e => searchText(e)} onKeyDown={e => handleKeyPress(e)} value={searchword} />
                                    <div className="btn-search" ><i className="i-search"></i></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="main">
                        <div className="sec-wrap">
                            <ul className="info">
                                <li className="menu01">
                                    <Link to='/Tutorial'>
                                        <div className="icon-wrap"><i className="i-ctgr-01"></i></div>
                                        <h3>시작하기</h3>
                                        <p className="sub">K-Studio 설치에서 사용법까지 단계별로 쉽게 따라할 수 있습니다</p>
                                    </Link>
                                </li>                                
                                <li className="menu02">
                                    <Link to='/WebDev'>
                                        <div className="icon-wrap"><i className="i-ctgr-02"></i></div>
                                        <h3>K-System Ace</h3>
                                        <p className="sub">K-System Ace는 Web 기반의 ERP 프로그램입니다.</p>
                                    </Link>
                                </li>
                                <li className="menu03">
                                    <Link to="/Documents">
                                        <div className="icon-wrap"><i className="i-ctgr-03"></i></div>
                                        <h3>K-System Genuine</h3>
                                        <p className="sub">CS기반의 K-System Genuine에서 활용가능한 Lua Script 사용법을 안내합니다</p>
                                    </Link>
                                </li>
                                <li className="menu04">
                                    <Link to='/KsystemAPI'>
                                        <div className="icon-wrap"><i className="i-ctgr-05"></i></div>
                                        <h3>K-System API</h3>
                                        <p className="sub">K-System API에 대한 소개 및 사용법을 안내합니다</p>
                                    </Link>
                                </li>
                            </ul>
                            <div className="news-update">
                                <div className="title-wrap">
                                    <h3>새소식</h3>
                                    <Link to="/News" className="more">더보기</Link>
                                </div>
                                <ul className="news-list">
                                    {showNewsList()}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="latest">
                        <FavoriteContent history={history}/>
                    </section>
                    <section className="bnr">
                        <div className="sec-wrap">
                            <div className="left">
                                <p className="title"><span className="highlight">K-STUDIO</span> 쉽게 시작하기</p>
                                <ul className="tutorial">
                                    <Link to={'/Tutorial'}
                                        state={{
                                            index: 0
                                        }}
                                    >
                                        <li>1강 K-System</li>
                                    </Link>
                                    <Link to={'/Tutorial'}
                                        state={{
                                            index: 1
                                        }}
                                    >
                                        <li>2강 개발준비</li>
                                    </Link>
                                </ul>
                            </div>
                            <div className="right">
                                <p className="title" style={{ cursor: 'pointer' }} onClick={openKDCSetupWindow} ><span className="highli1ght">KDC</span>  설치하기</p>
                                <ul className="tutorial">
                                    <li>KDC를 통해 통합 개발 환경을 구성해보세요.</li>                                    
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
                <footer>
                    <p>© 2021 Younglimwon Team Platform All Rights Reserved.</p>
                </footer>
            </div>
        );
    }
export default Home;