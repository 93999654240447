import React, { useState, useEffect} from 'react'
import Filter from '../../Common/Filter';
import PopUp from '../../Common/PopUp';
import { useSelector } from 'react-redux';
import Table from '../../Common/Table';

const AuthRequestList = (props) => {
    //redux
    // let isMount = true;
    const loginInfo = useSelector(state => state.loginActions);
    const userId = loginInfo.get('userId');
    const userName = loginInfo.get('userName');
    const userCompany = loginInfo.get('userCompany');
    const userCompanySeq = loginInfo.get('companySeq');    

    const [authKind, setAuthKind] = useState("auth-acc");
    const [selectedPopup, setSelectedPopup] = useState();
    const [devAuthData, setDevAuthData] = useState([]);
    const [devAuthListView, setDevAuthListView] = useState([]);
    const [accData, setAccData] = useState([]);
    const [accListView, setAccListView] = useState([]);
    const [popupContent, setPopupContent] = useState(null);
    const [isMount, setIsMount] = useState(true);

    let options = [
        { key: "145001", value: "요청중", class: "waiting color-t5" },
        { key: "145002", value: "승인", class: "appr color-primary" },
        { key: "145003", value: "반려", class: "unappr color-t3" },
        { key: "1", value: "요청중", class: "waiting color-t5" }
    ]

    useEffect(() => {
        setIsMount(true);

        getAuthAccUserList();
        getAuthGroupMemberList();
       
        return () => {
            setIsMount(false);
        }
    }, [])

    useEffect(() => {
        setAccListView(accData);
    }, [accData])

    useEffect(() => {
        if (selectedPopup != null)
            setPopupContent(authReqInfoPopup());
    }, [selectedPopup])

    useEffect(() => {
        if (popupContent == null)
            setSelectedPopup()
    }, [popupContent])

    //fetch 접속권한 신청내역 
    const getAuthGroupMemberList = () => {
        let UserInfo = {
            companyName: userCompany,
            userId: userId,
            userName: userName,
            companySeq: userCompanySeq
        };

        fetch('api/User/GroupMemberAccReqList', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(UserInfo)
        }).then((response) => response.json())
            .then((data) => {
                if (isMount) {
                    setAccData(data.table);
                    setAccListView(data.table);
                }
            });
    }

    //fetch 개발권한 신청내역 
    const getAuthAccUserList = () => {
        let UserInfo = {
            companyName: userCompany,
            userId: userId,
            userName: userName,
            companySeq: userCompanySeq
        };

        fetch('api/User/DevUserAccReqList', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(UserInfo)
        }).then((response) => response.json())
            .then((data) => {
                /*
                for (let i = 0; i < data.table.length; i++) {
                    data.table[i].addDevGroupName = devAuthOptions[data.table[i].addDevGroupSeq];
                }*/
                if (isMount) {
                    setDevAuthData(data.table);
                    setDevAuthListView(data.table);
                }
            });
    }

    //필터
    const setFilteracc = () => {
        if (authKind == "auth-acc") {
            return (
                <Filter
                    listData={accData}
                    setListView={setAccListView}
                    comboBox={[{ name: "approverType", title: "상태", options: [{ key: "", name: "전체" }, { key: "145001", name: "요청중" }, { key: "145002", name: "승인" }, { key: "145003", name: "반려" }] }]}
                    //searchKeyword={{ name: ["devUserName"], title: authKind == "auth-acc" ? "이름, 그룹명으로 검색" : "이름, 권한명으로 검색" }}
                    searchKeyword={{ name: ["devUserName", "devGroupName"], title:  "이름, 그룹명으로 검색" }}
                    multiKeywords={[{ name: "companyName", title: "소속" }]}
                    dateFromTo={[{ name: "reqDateTime", title: "기간", options: [{ key: "", name: "기간 전체" }, { key: "90", name: "최근 3개월" }, { key: "180", name: "최근 6개월" }, { key: "365", name: "최근 1년" }] }]}
                />
            );
        }else{
            return null;
        }
    }
    const setFilterdev = () => {
        if (authKind == "auth-dev") {
            return (
                <Filter
                    listData={devAuthData}
                    setListView={setDevAuthListView}
                    comboBox={[{ name: "approverType", title: "상태", options: [{ key: "", name: "전체" }, { key: "145001", name: "요청중" }, { key: "145002", name: "승인" }, { key: "145003", name: "반려" }] }]}
                    //searchKeyword={{ name: ["devUserName"], title: authKind == "auth-acc" ? "이름, 그룹명으로 검색" : "이름, 권한명으로 검색" }}
                    searchKeyword={{ name: ["devUserName","addDevGroupName"], title:  "이름, 권한명으로 검색" }}
                    multiKeywords={[{ name: "companyName", title: "소속" }]}
                    dateFromTo={[{ name: "reqDateTime", title: "기간", options: [{ key: "", name: "기간 전체" }, { key: "90", name: "최근 3개월" }, { key: "180", name: "최근 6개월" }, { key: "365", name: "최근 1년" }] }]}
                />
            );
        }else{
            return null;
        }
    }



    //디테일 데이터 set
    const setDetailData = () => {
        const index = selectedPopup;
        if ((authKind == "auth-dev" ? devAuthListView.length > 0 : accListView.length > 0) && index != null) {
            const Index = options.findIndex(element => element.key == (authKind == "auth-dev" ? devAuthListView[index].approverType : accListView[index].approverType));
            let classtype = options[Index].class;
            let innerstr = options[Index].value;

            let reqDateTime = authKind == "auth-dev" ? devAuthListView[index].reqDateTime : accListView[index].reqDateTime;
            let reqUserName = authKind == "auth-dev" ? devAuthListView[index].reqUserName : accListView[index].reqUserName;
            let companyName = authKind == "auth-dev" ? devAuthListView[index].companyName : accListView[index].companyName;
            let devUserName = authKind == "auth-dev" ? devAuthListView[index].devUserName : accListView[index].devUserName;
            let reqUserId = authKind == "auth-dev" ? devAuthListView[index].devUserId : accListView[index].devUserId;
            let addDevGroupName = authKind == "auth-dev" ? devAuthListView[index].addDevGroupName : null;
            let devGroupName = authKind == "auth-dev" ? devAuthListView[index].devGroupName : accListView[index].devGroupName;
            let fromDate = authKind == "auth-dev" ? devAuthListView[index].reqDateTime : accListView[index].fromDate;
            let toDate = authKind == "auth-dev" ? devAuthListView[index].addDevExpDate : accListView[index].toDate;
            let reqMessage = authKind == "auth-dev" ? devAuthListView[index].reqMessage : accListView[index].reqMessage;
            let approverMessage = authKind == "auth-dev" ? devAuthListView[index].approverMessage : accListView[index].approverMessage;

            return (
                <form className="form-wrap" >
                    <div className="user-info-list">
                        <dl className="">
                            <dt>신청일</dt>
                            {reqDateTime}
                        </dl>
                        <dl className="">
                            <dt>신청자</dt>
                            {reqUserName}
                        </dl>
                        <dl className="target">
                            <dt>권한 대상자</dt>
                            <dd>{devUserName}<p className="user-info">
                                <span className="info">{companyName}</span>
                                <span className="info">{reqUserId}</span></p></dd>
                        </dl>
                        {
                            authKind == "auth-dev" ? 
                                <dl className="">
                                    <dt>신청권한</dt>
                                    <dd>{addDevGroupName}</dd>
                                </dl>
                                :
                                <dl className="acc-groups">
                                    <dt>신청권한그룹</dt>
                                    <dd>
                                        {devGroupName}
                                    </dd>
                                </dl>
                        }
                        <dl className="">
                            <dt>권한적용기간</dt>
                            <dd>{fromDate}
                                ~ {toDate}</dd>
                        </dl>
                        <dl className="">
                            <dt>신청사유</dt>
                            <dd>{reqMessage}</dd>
                        </dl>
                        <dl className="">
                            <dt>상태</dt>
                            <dd>
                                <span className={classtype}>{innerstr}</span>
                            </dd>
                        </dl>
                        <dl>
                            <dt>{innerstr == "승인" ? "승인사유" : "반려사유"}</dt>
                            <dd>{approverMessage}</dd>
                        </dl>

                    </div>
                    <div className="btn-wrap">
                        <button type="button" className="btn-primary" onClick={() => {setSelectedPopup(); }}>확인</button>
                    </div>
                </form >
            )
        }
    }

    //권한 데이터 set
    const setAuthData = () => {
        let result = [];

        for (let i = 0; i < (authKind == "auth-dev" ? devAuthListView.length : accListView.length); i++) {
            let authData = [];

            const Index = options.findIndex(element => element.key == (authKind == "auth-dev" ? devAuthListView[i].approverType : accListView[i].approverType));
            let classtype = options[Index].class;
            let innerstr = options[Index].value;

            let reqDateTime = authKind == "auth-dev" ? devAuthListView[i].reqDateTime : accListView[i].reqDateTime;
            let companyName = authKind == "auth-dev" ? devAuthListView[i].companyName : accListView[i].companyName;
            let authName = authKind == "auth-dev" ? devAuthListView[i].addDevGroupName : accListView[i].devGroupName;
            let fromDate = authKind == "auth-dev" ? devAuthListView[i].reqDateTime : accListView[i].fromDate;
            let toDate = authKind == "auth-dev" ? devAuthListView[i].addDevExpDate : accListView[i].toDate;

            let devUserName = authKind == "auth-dev" ? devAuthListView[i].devUserName : accListView[i].devUserName;

            authData.push(<td key={"reqDateTime" + i} > {reqDateTime} </td>);
            authData.push(<td key={"devUserName" + i}> {devUserName} </td>);
            authData.push(<td key={"companyName" + i}> {companyName} </td>);
            authData.push(<td key={"authName" + i}> {authName} </td>);
            authData.push(<td key={"fromDate" + i}> {fromDate} </td>);
            authData.push(<td key={"toDate" + i}> {toDate} </td>);
            authData.push(<td key={"className" + i} className={classtype}> {innerstr} </td>);

            result.push(<tr key={i} onClick={(e) => { setSelectedPopup(i); }}>{authData}</tr>);
        }

        return result;
    }

    const authReqInfoPopup = () => {
        if (selectedPopup != -1)
            // eslint-disable-next-line no-unused-expressions
            return (<div className="msg-popup-wrap dim" onClick={(e) => { e.target.className == "msg-popup-wrap" ? setSelectedPopup() : null }}>
                <div className="msg-popup admin-user-info">
                    <div className="box">
                        <div className="title-wrap">
                            {authKind == "auth-dev" ? <p className="title">개발권한 신청정보</p> : <p className="title">접속권한 신청정보</p>}
                            <button className="btn-close-popup" onClick={() => { setSelectedPopup(); }}><i className="i-close-popup"></i></button>
                        </div>
                        {setDetailData()}
                    </div>
                </div>
            </div>)
        else
            return null;
    }

    const setHeaders = () => {
        return [
            "신청일",
            "대상자",
            "소속",
            authKind == "auth-dev" ? "신청 권한" : "신청 그룹",
            "권한적용일",
            "권한만료일",
            "상태",
        ];
    };

    return (
        <div className="content">
            <div className="auth-apply">
                <section className="my-auth-history">
                    <PopUp content={popupContent} setContent={setPopupContent}/>
                    <h3>신청내역</h3>
                    <div className="list-tab">
                        <div className="item">
                            <input name="apply-tab" type="radio" checked={ authKind == "auth-acc"} readOnly />
                            <label htmlFor="auth-acc" onClick={() => { setAuthKind("auth-acc"); }}>접속권한</label>
                        </div>
                        <div className="item">
                            <input name="apply-tab" type="radio" checked={authKind == "auth-dev"} readOnly />
                            <label htmlFor="auth-dev" onClick={() => { setAuthKind("auth-dev"); } } >개발권한</label>
                        </div>
                    </div>
                    <div className="list-tools">
                        {setFilteracc()}
                        {setFilterdev()}
                    </div>
                    <Table className={"apply"} headers={setHeaders()} list={setAuthData()} />
                </section>
            </div>
        </div>
    )
}
export default AuthRequestList;