// html 데이터를 Delta 형식으로 변환
export const htmlToDelta = (html) => {
    const delta = { ops: [] };
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const parseNode = (node, indentLevel = 0) => {
        switch (node.nodeType) {
            // text 데이터
            case Node.TEXT_NODE:
                if (node.textContent.trim() !== '') {
                    delta.ops.push({ insert: node.textContent+"\n" });
                }
                break;
            // html element 데이터
            case Node.ELEMENT_NODE:
                const tagName = node.tagName.toLowerCase();
                let attributes = {};
                let insertText = '';

                switch (tagName) {
                    // case 'br':
                    //     delta.ops.push({ insert: "\n" });
                    //     break;
                    case 'strong':
                        parseChildren(node, { bold: true });
                        return;
                    case 'em':
                        parseChildren(node, { italic: true });
                        return;
                    case 'u':
                        parseChildren(node, { underline: true });
                        return;
                    case 's':
                        parseChildren(node, { strike: true });
                        return;
                    case 'h1':
                        // parseChildren(node, { header: 1 });
                        delta.ops.push({ insert: `${node.textContent}` });
                        delta.ops.push({ attributes: { header: 1 }, insert: '\n' });
                        return;
                    case 'h2':
                        // parseChildren(node, { header: 2 });
                        delta.ops.push({ insert: `${node.textContent}` });
                        delta.ops.push({ attributes: { header: 2 }, insert: '\n' });
                        return;
                    case 'blockquote':
                        // parseChildren(node, { blockquote: true });
                        delta.ops.push({ insert: `${node.textContent}` });
                        delta.ops.push({ attributes: { blockquote: true }, insert: '\n' });
                        return;
                    case 'pre':
                        // parseChildren(node, { 'code-block': true });
                        delta.ops.push({ insert: `${node.textContent}` });
                        delta.ops.push({ attributes: { 'code-block': true }, insert: '\n' });
                        return;
                    case 'a':
                        attributes.link = node.getAttribute('href');
                        parseChildren(node, attributes);
                        return;
                    case 'ul':
                        Array.from(node.children).forEach(child => {
                            parseNode(child, indentLevel);
                        });
                        return;
                    case 'ol':
                        Array.from(node.children).forEach(child => {
                            parseNode(child, indentLevel);
                        });
                        return;
                    case 'li':
                        insertText = node.childNodes[0] ? node.childNodes[0].textContent : '';
                        attributes.list = node.parentElement.tagName.toLowerCase() === 'ul' ? 'unordered' : 'ordered';
                        if (node.hasAttribute('data-checked')) {
                            attributes.list = node.getAttribute('data-checked') === 'true' ? 'checked' : 'unchecked';
                        }
                        if (indentLevel > 0) {
                            attributes.indent = indentLevel;
                        }
                        delta.ops.push({ insert: insertText });
                        if (Object.keys(attributes).length > 0) {
                            delta.ops.push({ attributes, insert: '\n' });
                        } else {
                            delta.ops.push({ insert: '\n' });
                        }
                        Array.from(node.childNodes).slice(1).forEach(child => {
                            parseNode(child, indentLevel + 1);
                        });
                        return;
                    case 'img':
                        delta.ops.push({ insert: { image: node.getAttribute('src') } });
                        return;
                    case 'iframe':
                        delta.ops.push({ insert: { video: node.getAttribute('src') } });
                        return;
                    default:
                        Array.from(node.childNodes).forEach(child => {
                            parseNode(child, indentLevel);
                        });
                        return;
                }
            default:
                break;
        }
      }

    const parseChildren = (node, attributes) => {
        Array.from(node.childNodes).forEach(child => {
            if (child.nodeType === Node.TEXT_NODE) {
                delta.ops.push({ attributes, insert: child.textContent });
            } else {
                parseNode(child);
            }
        });
        delta.ops.push({ insert: '\n' });
    }

    Array.from(doc.body.childNodes).forEach(node => parseNode(node));
    // console.log(delta);
    return delta;
};

export const deltaToHtml = (delta) => {

    const container = document.createElement('div');
    let currentElement = null;
    let listStack = [];
    let listType = null;
    let text = '';

    delta.ops.forEach((op, index) => {
        let element;
        let attributes = op.attributes || {};

        if (typeof op.insert === 'string') {
            if (op.insert !== '\n')
                text = op.insert;
            else if (attributes && Object.keys(attributes).length === 0 && attributes.constructor === Object) {
                text = '';
                container.appendChild(document.createElement('br'));
                return;
            }

            if (attributes.header) {
                element = document.createElement('h' + attributes.header);
                element.textContent = text.trim();
                container.appendChild(element);
                currentElement = null;
                return;
            } else if (attributes.blockquote) {
                element = document.createElement('blockquote');
                element.textContent = text.trim();
                container.appendChild(element);
                currentElement = null;
                return;
            } else if (attributes['code-block']) {
                element = document.createElement('pre');
                element.textContent = text.trim();
                container.appendChild(element);
                currentElement = null;
                return;
            } else if (attributes.list) {
                const attrType = (attributes.list === 'checked' || attributes.list === 'unchecked') ? 'check' : attributes.list === 'ordered' ? 'ol' : 'ul';
                let indentLevel = attributes.indent || 0;

                // If current list type or indent level changes, close the previous list
                while (listStack.length > 0 && (listType !== attrType || listStack[listStack.length - 1].indentLevel > indentLevel)) {
                    listStack.pop();
                }

                if (listStack.length === 0 || listType !== attrType || listStack[listStack.length - 1].indentLevel !== indentLevel) {
                    const newListElement = document.createElement(attrType === 'check' ? 'ul' : attrType);

                    if (listStack.length > 0 && indentLevel > listStack[listStack.length - 1].indentLevel) {
                        const parentLi = listStack[listStack.length - 1].element.lastElementChild;
                        if (parentLi) {
                            parentLi.appendChild(newListElement);
                        }
                    } else {
                        container.appendChild(newListElement);
                    }

                    listStack.push({ element: newListElement, indentLevel });
                    listType = attrType;
                }

                const listElement = listStack[listStack.length - 1].element;
                let li = document.createElement('li');
                if (attributes.list === 'checked' || attributes.list === 'unchecked') {
                    li.setAttribute('data-checked', attributes.list === 'checked');
                }
                li.textContent = text.trim();
                listElement.appendChild(li);

                currentElement = null;
                return;
            } else {
                if (!currentElement || currentElement.tagName !== 'P') {
                    if (index + 1 < delta.ops.length && delta.ops[index + 1].attributes && delta.ops[index + 1].insert === '\n') {
                        element = document.createElement('p');
                        currentElement = element;
                    } else {
                        if (attributes.bold) {
                            const strong = document.createElement('strong');
                            strong.textContent = text;
                            container.appendChild(strong);
                            return;
                        } else if (attributes.italic) {
                            const em = document.createElement('em');
                            em.textContent = text;
                            container.appendChild(em);
                            return;
                        } else if (attributes.underline) {
                            const u = document.createElement('u');
                            u.textContent = text;
                            container.appendChild(u);
                            return;
                        } else if (attributes.strike) {
                            const s = document.createElement('s');
                            s.textContent = text;
                            container.appendChild(s);
                            return;
                        } else if (attributes.link) {
                            const a = document.createElement('a');
                            a.href = attributes.link;
                            a.target = '_blank';
                            a.textContent = text;
                            container.appendChild(a);
                            return;
                        } else {
                            const p = document.createElement('p');
                            text.split('\n').forEach((part, idx) => {
                                if (idx > 0) p.appendChild(document.createElement('br'));
                                if (part && part !== '') {
                                    p.appendChild(document.createTextNode(part));
                                }
                            });
                            container.appendChild(p);
                            return;
                        }
                    }
                } else {
                    element = currentElement;
                }
            }
        } else if (op.insert.image) {
            element = document.createElement('img');
            element.src = op.insert.image;
            element.className = 'ql-image';
            container.appendChild(element);
            currentElement = null;
            return;
        } else if (op.insert.video) {
            element = document.createElement('iframe');
            element.src = op.insert.video;
            element.className = 'ql-video';
            element.frameBorder = "0";
            element.allowFullscreen = true;
            container.appendChild(element);
            currentElement = null;
            return;
        }
    });

    if (currentElement) {
        container.appendChild(currentElement);
    }

    return container.innerHTML;
};