import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import * as contentActions from '../../../store/modules/contentActions';
import { bindActionCreators } from 'redux';
import Editor from '../../libs/QuillEditor';
import HtmlViewer from '../../Common/HtmlViewer';
import { htmlToDelta, deltaToHtml } from '../../libs/DeltaConverter';

const TopicView = (props) => {
    const editorRef = React.useRef(null);
    const LoginStates = useSelector(state => state.loginActions);
    const [topicView, setTopicView] = useState([]);
    const [subTopic, setSubTopic] = useState([]);
    const [topicSeq, setTopicSeq] = useState(props.state.topicSeq);
    const [writeReply, setWriteReply] = useState(null);
    const [workingTag, setWorkingTag] = useState(props.state.workingTag);
    const history = useNavigate();
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    const [menuData, setMenuData] = useState(null);

    useEffect(() => {
        getTopMenu();
    }, []);

    useEffect(() => {
        if ((JSON.stringify(topicView) != JSON.stringify(props.state.topicView) && props.state.topicView != undefined)
            || (JSON.stringify(subTopic) != JSON.stringify(props.state.subTopic) && props.state.subTopic != undefined)) {
            setTopicView(props.state.topicView);
            setSubTopic(props.state.subTopic);
        }
    });
    const btnClickReply = (workingTag, subTopicSeq) => {
        if (workingTag == 'D') {
            if (window.confirm('삭제하시겠습니까?')) {
                props.fnSubTopicDelete(subTopicSeq);
            }
        } else if (workingTag == 'C') {
            setWriteReply(null);
            setWorkingTag(workingTag);
        } else {
            setWriteReply(renderReply());
            setWorkingTag(workingTag);
        } 
    }
    const dateFormat = (date) => {
        let month = date.getMonth() + 1, day = date.getDate(), hour = date.getHours(), minute = date.getMinutes();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;

        return date.getFullYear() + '.' + month + '.' + day + ' ' + hour + ':' + minute;
    }
    const subTopicSave = () => {
        // const converter = new QuillDeltaToHtmlConverter(editorRef.current.getContents().ops, {});
        // const content = converter.convert();
        const content = deltaToHtml(editorRef.current.getContents());
        const subTopicInfo = {
            content: content,
            contentText: editorRef.current.getText(),
            workingTag: workingTag
        };
        props.fnSubTopicSave(subTopicInfo);
        setWorkingTag('A');
        setWriteReply(null);
    }
    const RenderEditor = (props) => {
        return (
            <Editor
                ref={editorRef}
                readOnly={false}
                defaultValue={props.content} 
            />
        );
    }
    const getTopMenu = () => {
        fetch('datas/menu.json', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Cache-Control': 'no-cache'
            }
        }).then((response) => response.json())
            .then(menuData => {
                setMenuData(menuData);
            });
    };
    const getCategory = (category) => {
        let path = "";
        for (let i = 0; i < menuData.length; i++) {
            if(menuData[i].menuName === category) {
                path = menuData[i].path;
            }
        }
        return path;
    };
    const goToLink = (docPath) => {
        const docPaths = docPath.split(" / ");
        const category = getCategory(docPaths[0]);
        const content = docPaths.filter((item, id) => id > 0);
        const contentName = content[content.length - 1];
        let folderName = "";
        for (let i = 0; i < content.length - 1; i++) {
            folderName += content[i]
            if(i < content.length - 2) 
                folderName += "/";
        }
        const path = folderName !== "" ? folderName + "/" + contentName : contentName;
        const info = {
            contentType: category, 
            contentName: contentName, 
            type: '', 
            folderName: folderName || '',
            path: path
        }
        ContentActions.setContentInfo({
            contentType: category, contentName: contentName, type: '', folderName: folderName || ''
        });
        ContentActions.setPath(path);
        history('/' + category, {
            state: 'qna'
        });
    };
    const TopicItem = (props) => {
        let item = props.item, isAdmin = false, userId = LoginStates.get('userId'), topicContent = [], topicBottom = [];
        if (!props.isAdmin && item.authGrade != '' && userId != item.writerUserId) {
            isAdmin = true;
        }
        if (props.isLastData) {
            if (workingTag == 'U') {
                topicContent.push(
                    <RenderEditor content={htmlToDelta(item.content)} />
                );
                topicBottom.push(
                    <div className="bottom">
                        <button className="btn-primary" onClick={subTopicSave}>등록</button>
                        <button className="btn-secondary" onClick={() => btnClickReply('C')}>취소</button>
                    </div>
                );
            } else {
                topicContent.push(
                    <HtmlViewer data={item.content} />
                );
                if (item.subTopicSeq != undefined && item.writerUserId == userId) {
                    topicBottom.push(
                        <div key={'bottom'} className="bottom">
                            <button className="btn-edit" onClick={() => btnClickReply('U')}>수정</button>
                            <button className="btn-delete" onClick={() => btnClickReply('D', item.subTopicSeq)}>삭제</button>
                        </div>
                    );
                } else if (topicView[0].writerUserId == userId && subTopic.length == 0) {
                    topicBottom.push(
                        <div key={'bottom'} className="bottom">
                            <button className="btn-new-topic btn-secondary" onClick={() => props.fnOnChangeMode('topicUpdate', props.currentPage, topicSeq, topicView)}>수정</button>
                            <button className="btn-new-topic btn-secondary" onClick={() => props.fnOnChangeMode('topicDelete')}>삭제</button>
                        </div>
                    );
                }
            }
        } else {
            topicContent.push(
                <HtmlViewer data={item.content} />
            );
        }
        return (
            <Fragment>
                <div className={workingTag == 'U' ? 'item write-reply' : 'item'}>
                    <div className="top">
                        <div onClick={(e) => props.fnBtnOnPopup(item, e)} className="author">
                            <div className="pf" style={{ backgroundColor: item.colorCode }}>
                                {isAdmin ? 'P' : item.writerFirstInitial}
                            </div>
                            <a className="name" href={()=>{}}>
                                {isAdmin ? '관리자' : item.writer}
                            </a>
                        </div>
                        <span className="date">{dateFormat(new Date(item.writeDate))}</span>
                    </div>
                    {item.docPath
                        ? <div className="page-link-wrap">
                            <span className="page-link">
                                <a href={()=>{}} onClick={() => goToLink(item.docPath)}>
                                    관련페이지 : {item.docPath}
                                </a>
                            </span>
                        </div> : null
                    }
                    <div className="content">
                        {topicContent}
                    </div>
                    {topicBottom}
                </div>
            </Fragment>
        );
    }
    const Topic = () => {
        let view = [];
        if (topicView.length != 0) {
            view.push(
                <Fragment key={'View1'}>
                    <div className="topic-title">
                        <div className="ctgr">{topicView[0].catagoryName}</div>
                        <div className="title">
                            <p>{topicView[0].title}</p>
                            {topicView[0].subTopicCount != 0
                                ? <div className="topic-tag reply">
                                    피드 {topicView[0].subTopicCount}
                                </div> : null}
                        </div>
                    </div>
                    <TopicItem item={topicView[0]}
                        isLastData={subTopic.length == 0 ? true : false}
                        fnBtnOnPopup={props.fnBtnOnPopup}
                        isAdmin={props.isAdmin}
                        fnOnChangeMode={props.fnOnChangeMode}
                        currentPage={props.state.currentPage} />
                </Fragment>
            );
        }
        for (let i = 0; i < subTopic.length; i++) {
            view.push(
                <TopicItem key={i} item={subTopic[i]}
                    isLastData={i == subTopic.length - 1 ? true : false}
                    fnBtnOnPopup={props.fnBtnOnPopup}
                    isAdmin={props.isAdmin}
                    fnOnChangeMode={props.fnOnChangeMode}
                    currentPage={props.state.currentPage} />
            );
        }
        return view;
    }
    const renderReply = () => {
        return (
            <div className="item write-reply">
                <div className="top">
                    <div className="author">
                        <div className="pf" style={{ backgroundColor: '' }}>
                            {LoginStates.get('userId').substr(0, 1).toUpperCase()}
                        </div>
                        <a className="name" href={()=>{}}>
                            {LoginStates.get('userName')}
                        </a>
                    </div>
                </div>
                <div className="content edior">
                    <div className="editor-wrap">
                        <div className="editor">
                            <RenderEditor content={''} />
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <button className="btn-primary" onClick={subTopicSave}>등록</button>
                    <button className="btn-secondary" onClick={btnClickReply('C')}>취소</button>
                </div>
            </div>
        );
    }
    let lastWriterUserId = '', isVisibleReplybtn = false;
    if (topicView.length != 0) {
        if (writeReply == null) {
            if (subTopic.length == 0) {
                lastWriterUserId = topicView[0].writerUserId;
            } else {
                lastWriterUserId = subTopic[subTopic.length - 1].writerUserId;
            }
            if (lastWriterUserId != LoginStates.get('userId')) {
                isVisibleReplybtn = true;
            }
        }
    }
    return (
        <div className="qna-wrap">
            {isVisibleReplybtn
                ? <button className="btn-reply-admin btn-primary"
                    onClick={() => btnClickReply('A')}>답변하기</button> : null}
            <div className="top-wrap">
                <button className="btn-back-list"
                    onClick={() => props.fnOnChangeMode('topicList', props.state.currentPage)}>
                    <i className="i-back"></i>
                    <h2>{props.topicViewTitle}</h2>
                </button>
            </div>
            <section className="qna-view">
                <div className="topic-wrap">
                    <Topic key={'topic'} />
                    {workingTag == 'A' ? writeReply : null}
                    <button className="btn-back-list" onClick={() => props.fnOnChangeMode('topicList', props.state.currentPage)}>목록 보기</button>
                </div>
            </section>
        </div>
    );
}
export default TopicView;