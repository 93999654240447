import { Map, List } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const initialState = Map({
    stepType: '',
    stepName: '',
    stepIndex: 0,
    menuDatas: null,   
    menuIndex: 0, 
    currentStates: [],
    path: ''
});

const SETMENU = 'tutorialActions/SETMENU';
const SETCURRENTSTATE = 'tutorialActions/SETCURRENTSTATE';
const SETCURRENTSTEP = 'tutorialActions/SETCURRENTSTEP';
const SETCURRENTMENU = 'tutorialActions/SETCURRENTMENU';
const SETTUTORIALINFO = 'tutorialActions/SETTUTORIALINFO';
const SETPATH = 'tutorialActions/SETPATH';
const SETINIT = 'tutorialActions/SETINIT';
const SETALL = 'tutorialActions/SETALL';
const SETCURRENT = 'tutorialActions/SETCURRENT';
const SETCOMPLETE = 'tutorialActions/SETCOMPLETE'

export const setMenu = createAction(SETMENU);
export const setCurrentState = createAction(SETCURRENTSTATE);
export const setCurrentStep = createAction(SETCURRENTSTEP);
export const setCurrentMenu = createAction(SETCURRENTMENU);
export const setTutorialInfo = createAction(SETTUTORIALINFO);
export const setPath = createAction(SETPATH);
export const setInit = createAction(SETINIT);
export const setAll = createAction(SETALL);
export const setCurrent = createAction(SETCURRENT);
export const setComplete = createAction(SETCOMPLETE);

export default handleActions({
    [SETCURRENT]: (state, action) => {
        return state.set('stepIndex', action.payload.stepIndex)
            .set('stepName', action.payload.stepName)
            .set('stepType', action.payload.stepType)
            .set('menuIndex', action.payload.menuIndex)
            .set('path', action.payload.path);
    },
    //메뉴 데이터 세팅
    [SETMENU]: (state, action) => {
        return state.set('menuDatas', action.payload.menuDatas);
    },
    //목차별 진행상태(배열)
    [SETCURRENTSTATE]: (state, action) => {
        return state.set('currentStates', action.payload.currentStates);
    },
    //현재 목차의 스텝
    [SETCURRENTSTEP]: (state, action) => {
        return state.set('stepIndex', action.payload.stepIndex)
            .set('stepName', action.payload.stepName)
            .set('stepType', action.payload.stepType);
    },
    //현재 목차
    [SETCURRENTMENU]: (state, action) => {
        return state.set('menuIndex', action.payload.menuIndex);
    },
    [SETTUTORIALINFO]: (state, action) => {
        return state.merge(action.payload);
    },
    //경로 설정
    [SETPATH]: (state, action) => {
        const path = action.payload;        
        const menuIndex = parseInt(path.split('/')[0]);
        const stepIndex = parseInt(path.split('/')[1]);
        return state.set('path', action.payload)
            .set('menuIndex', menuIndex)
            .set('stepIndex', stepIndex);
           
    },
    [SETINIT]: () => {
        return initialState;
    },
    [SETALL]: (state, action) => {
        return state.set('currentStates', action.payload.currentStates)
            .set('menuDatas', action.payload.menuDatas)
            .set('menuIndex', action.payload.menuIndex)
            .set('stepIndex', action.payload.stepIndex)
            .set('stepName', action.payload.stepName)
            .set('stepType', action.payload.stepType);
    },
    [SETCOMPLETE]: (state, action) => {
        const index = action.payload.menuIndex;
        const isComplete = action.payload.complete;
        const datas = state.get('menuDatas');
        if(datas.children[index]) {
            datas.children[index] = {
                ...datas.children[index],
                complete: isComplete
            }
        }
        return state.set('menuDatas', datas);
    }
}, initialState);