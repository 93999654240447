import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import * as loginActions from '../../store/modules/loginActions';
import * as messageActions from '../../store/modules/messageActions';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

const LoginPanel = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    const MessageActions = bindActionCreators(messageActions, dispatch);

    const [userInfo, setUserInfo] = useState({
        userId: props.userId,
        userPassword: '',
        showAlert: ''
    });

    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);

    };

    const handleChange = (e) => {
        //이메일, 비밀번호 칸에 입력한 값으로 저장, but 리렌더링은 제일 마지막에
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });
    };

    const onSubmitSignUp = (e) => {
        //회원가입 패널로 넘어가기위한 panel타입을 설정해줌
        props.setPanelType('signUp');
    };

    // const hideNotice = (e) => {
    //     e.preventDefault();
    //     MessageActions.hideToast({ visible: false });
    // };

    const onSubmit = async (e) => {
        //로그인 버튼을 눌렀을 때 발생하는 이벤트
        e.preventDefault();
        let userId = userInfo.userId;
        let userPassword = userInfo.userPassword;
        let emailExpression = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        let notice = '';
        if (userId == undefined || userId == null || userId == "") {
            userId = document.getElementsByName('userId')[0].value;
        }
        if (userPassword == undefined || userPassword == null || userPassword == "") {
            userPassword = document.getElementsByName('userPassword')[0].value;
        }
        
        if (userId == "" || userPassword == "") {
            notice = "이메일과 비밀번호를 입력해주세요.";
            showToast('warning', notice);
        }
        else if (emailExpression.test(userId) == false) {
            notice = "잘못된 형식의 이메일 주소입니다. 이메일을 다시 입력해주세요.";
            showToast('warning', notice);
        }
        else {
            const userInfo = {
                userId: encodeURI(userId),
                userPassword: encodeURI(userPassword)  //Http 통신을 통해 입력된 문자열을 전달할 때는 이스케이프 처리를 해주는 것이 좋다.
            };
            setUserInfo({ ...userInfo, showAlert: "" });
            doLogin(userInfo);
        }
    };
    const doLogin = (userInfo) => {
        fetch('api/Login/Login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(userInfo)
        }).then((response) => response.json())
            .then((data) => {
                const info = data.userInformation;
                if (data.status != 'Success Login' && data.status != 'Already Session Exists' && data.status != 'Change Password') {
                    showToast('danger', data.message, 3000);
                } else {                    
                    const result = {                       
                        userId: info.userId ?? '',
                        userName: info.userName ?? '',
                        message: data.message,
                        result: data.status,
                        sessionID: info.sessionID ?? '',
                        companyName: info.companyName ?? '',
                        companySeq: info.companySeq ?? '',
                        companyType: info.companyType ?? '',
                        //devType: info.devType ?? '',
                        logindate: new Date().toISOString().slice(0, 10),
                        admin: info.admin ?? '',
                        userPhoneNum: info.userPhoneNum ?? '',
                        userSendOtpType: info.userSendOtpType ?? '',
                        deptName: info.deptName ?? '',
                        kindName: info.kindName ?? ''
                    };
                    if (data.status == 'Already Session Exists') {
                        if (window.confirm("다른 브라우저에 로그인된 계정입니다. 로그인할 경우 기존 연결은 끊깁니다. 계속 하시겠습니까?") == true) {
                            userInfo["delSession"] = true;
                            doLogin(userInfo);
                            return;
                        } else {
                            return;
                        }
                    } else {
                        props.setPanelType('');
                        LoginActions.setResultLogin(result);
                    }
                }
            });
    }
    // const ChangePassword = () => {
    //     LoginActions.panelLoad('changePassword');
    // };

    const FindPassword = () => {
        props.setPanelType('findPassword');
    };

    return (
        <div className="login-wrap">
            <form className="login-box">
                <h2>로그인</h2>
                <div className="input-ui">
                    <input type='text' name='userId' placeholder='아이디' value={userInfo.userId ?? ''} onChange={e => handleChange(e)} />
                </div>
                <div className="input-ui">
                    <input type='password' name='userPassword' placeholder='비밀번호' value={userInfo.userPassword ?? ''} onChange={e => handleChange(e)} />
                </div>
                <div className="btn-wrap">
                    <button onClick={(e) => onSubmit(e)} className='btn-primary' name='Login'>로그인</button>
                    <a onClick={FindPassword} className="btn-find-info">비밀번호 찾기</a>
                    <p className="no-id">아이디가 없다면,</p>
                    <button onClick={(e) => onSubmitSignUp(e)} className='btn-primary-t2'>K Developers에 가입하세요</button>
                </div>
            </form>
        </div>
    );
};

export default LoginPanel;