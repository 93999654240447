import React, { useState, useEffect } from 'react';

const MarkDownEditor = (props) => {
    const [mdCode, setMdCode] = useState('');
    const [mdCodeHistory, setMdCodeHistory] = useState([{ "text": "", "selectionStart": "", "selectionEnd": "" }]);
    const [mdPreview, setMdPreView] = useState('')
    const [contentInfo, setContentInfo] = useState({ contentType: '', contentName: '', folderName: '' });
    const [isViewMode, setIsViewMode] = useState(true);
    useEffect(() => { //렌더링 될 때마다 실행
        if (contentInfo != props.ContentInfo) {
            setContentInfo(props.ContentInfo);
            setMdCodeHistory([{ "text": "", "selectionStart": "", "selectionEnd":"" }]);
        }
        if (isViewMode != props.isViewMode)
            setIsViewMode(props.isViewMode);
    });
    useEffect(() => { //mdCode가 업데이트 될 때마다 실행
        //md가 편집되거나 새로 불러질 때 마다 호출.
        getPreViewContent();
    }, [mdCode])
    useEffect(() => {
        let body = JSON.stringify({
            ContentType: contentInfo.contentType,
            ContentName: contentInfo.contentName,
            FolderName: contentInfo.folderName
        });
        fetch('api/Document/PlainMdManual', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8' 
            },
            body: body
        }).then(response => response.json())
            .then(data => {
                if (contentInfo.type == 'pdf') {
                   
                } else {
                    setMdCode(data);                    
                }
            });
    }, [contentInfo])
    const getMarkDownText = () => {
        
    }
    const getPreViewContent = () => {       
        const srcPath = "doc/" + contentInfo.contentType + "/" + contentInfo.folderName + "/";
        const body = JSON.stringify({
            ContentType: contentInfo.contentType,
            ContentName: contentInfo.contentName,
            FolderName: contentInfo.folderName,
            PlainText: mdCode.replace(/\\/gi, '\\\\').replace(/"/gi, '\\\"'),
            ContentFilePath: srcPath
        });
        fetch('api/Document/GetHtml', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: body
        }).then(response => response.json())
            .then(data => {
                setMdPreView(data)                
            });
    }
    const handleEdit = ({ target, ctrlKey, key }) => {
        if (ctrlKey && key == 'z') { }
        else {
            let history = mdCodeHistory;
            history.push({ "text": mdCode, "selectionStart": target.selectionStart, "selectionEnd": target.selectionEnd });
            setMdCodeHistory(history);
        }
        setMdCode(target.value);
    }
    const onClickHelp = () => {
        alert("도움말 준비중!!");
    }
    const onTabKeyDown = (e) => {
        let textarea = e.target;
        let start = textarea.selectionStart;
        let end = textarea.selectionEnd;
        if (e.key == 'Tab') {
            
            let value = textarea.value;
            
            e.preventDefault();
            e.target.value = value.substring(0, start) + "\t" + value.substring(end);
            textarea.selectionStart = textarea.selectionEnd = start + 1;
            handleEdit(e);
            return false;
        }
        else if (e.ctrlKey && e.key == 'z') {
            e.preventDefault();
            if (mdCodeHistory.length > 0) {
                e.target.value = mdCodeHistory[mdCodeHistory.length - 1].text;
                textarea.selectionStart = mdCodeHistory[mdCodeHistory.length - 1].selectionStart - 1;
                textarea.selectionEnd = mdCodeHistory[mdCodeHistory.length - 1].selectionEnd - 1;
                mdCodeHistory.pop();
                setMdCodeHistory(mdCodeHistory);
                handleEdit(e);
                return false;
            }
        }
    }
    return (
        <>
            <div className="md-editor">
                <header>MD<a onClick={onClickHelp} className="help">도움말</a></header>
                <textarea className="editor" onKeyDown={(e) => onTabKeyDown(e)} value={mdCode} onChange={handleEdit} disabled={isViewMode}></textarea>
            </div>
            <div className="md-preview">
                <header>미리보기</header>
                <div className="preview" dangerouslySetInnerHTML={{ __html: mdPreview }}></div>
            </div>
        </>
    );
}
export default MarkDownEditor;