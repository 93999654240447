import { combineReducers } from 'redux';
import loginActions from './loginActions';
import contentActions from './contentActions';
import openApiActions from './openApiActions';
import messageActions from './messageActions';
import tutorialActions from './tutorialActions';
import simpleViewActions from './simpleViewActions';
import newsActions from './newsActions';

export default combineReducers({
    loginActions,
    contentActions,
    openApiActions,
    messageActions,
    tutorialActions,
    simpleViewActions,
    newsActions
});