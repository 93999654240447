import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as contentActions from '../../store/modules/contentActions';
import * as tutorialActions from '../../store/modules/tutorialActions';
import { bindActionCreators } from 'redux';
import { useNavigate } from "react-router-dom";

import storage from '../libs/storage';

export const FavoriteContent = (props) => {
    const SimpleViewStates = useSelector(state => state.simpleViewActions);
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);
    const TutorialActions = bindActionCreators(tutorialActions, dispatch);
    const history = useNavigate();

    const listClick = list => {
        // console.log(list);
        if (list.views.category == 'Lua') {
            ContentActions.setContentInfo({
                contentType: list.views.category, contentName: list.views.title, type: list.views.type || ''
            });
            ContentActions.setPath(list.views.path);
            history('/Lua', {
                state: 'Favorite'
            });
        } else if (list.views.category == 'Tutorial') {
            TutorialActions.setPath(
                list.views.path
            );
            history('/Tutorial', {
                state: 'Favorite'
            });
            
        } else if (list.views.category == 'OpenApi') {
            history(list.views.path);
        } else {
            ContentActions.setContentInfo({
                contentType: list.views.category, contentName: list.views.title, type: list.views.type || '', folderName: list.views.folderName || ''
            });
            ContentActions.setPath(list.views.path);
            history('/' + list.views.category, {
                state: 'Favorite'
            });            
        }
    };

    const showContents = () => {        
        let list = [], index = 0;
        if (SimpleViewStates.get('latestViews').length > 0) {            
            const latestViews = SimpleViewStates.get('latestViews');
            for (let i = 0; i < latestViews.length; i++) {
                list.push(
                    <Favorite key={index++} views={latestViews[i]} listClick={listClick} />
                );
            }
        }
        return list;
    }
    return (
        <div className="sec-wrap">
            <h3>{storage.get('loginStates') != null ? '최근 본 문서' : '간편 조회'}</h3>
            <ul className="latest-list">
                {showContents()}
            </ul>
        </div>
        );
}

const Favorite = (props) => {
    const handleClick = list => {
        props.listClick(list);
    };

    return (
        <li onClick={() => handleClick(props)}>
            <div className="category">{props.views.categoryName}</div>
            <p className="title">{props.views.title}</p>
        </li>
    );
}