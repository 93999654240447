import React, { useState, useEffect } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as contentActions from '../../store/modules/contentActions';
import * as tutorialActions from '../../store/modules/tutorialActions';
import { GetLink } from '../Common/DocumentLink';

const SearchItem = (props) => {
    const [data, setData] = useState([]);
    const [ifilter, setFilter] = useState([]);
    const [currPage, setPage] = useState(1);
    const history = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        let uniqueContentType = [];
        if (props.result != null) {
            for (let i = 0; i < props.result.length; i++) {
                if (uniqueContentType.filter(x => x.contentType == props.result[i].contentType).length == 0) {
                    uniqueContentType.push({ "contentType": props.result[i].contentType, "menuName": props.result[i].menuName, "checked": true });
                }
            }
            for (let j = 0; j < uniqueContentType.length; j++) {
                uniqueContentType[j]["count"] = props.result.filter(x => x.contentType == uniqueContentType[j].contentType).length;
            }
            setFilter(uniqueContentType);
            setData(props.result);
        }        
    }, [props.result])
    useDeepCompareEffect(() => {
        setPage(1);
    }, [ifilter])
    const moveToContent = (e, data) => {
        if (e.ctrlKey == true && e.shiftKey == true) {
            const url = GetLink(data);
            var newTab = window.open(url,"_blank");
            newTab.focus();
        }
        else {
            if (data.menuType == 'Tutorial') {
                const { setPath } = tutorialActions;
                dispatch(setPath(data.path.split('|')[1]));
                history(data.contentType, {
                    state: 'Favorite'
                });
            } else {
                const { setContentInfo, setPath } = contentActions;
                dispatch(setContentInfo({
                    contentType: data.contentType, contentName: data.contentName, type: data.documentType || '', folderName: data.folderName || ''
                }));
                dispatch(setPath(data.path));
                history('/' + data.contentType, {
                    state: 'Favorite'
                });
            }
        }        
    }
    const makePreview = (content) => {
        var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        if (content.documentType != 'pdf') {
            let reg = new RegExp('(' + props.keyword.replace(regExp, "") + ')', 'gi')
            let parts = content.contentBody.split(reg);
            for (let i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span className='keyword' key={i}>
                        {parts[i]}
                    </span>
                );
            }
            return (
                <p className="preview">{parts}</p >
            );
        } else return null;
    }
    const getList = () => {        
        let returnData = [];
        if (data != null) {
            const checkedTypes = ifilter.filter(x => x.checked == true);
            let filteredData = data.filter(d => checkedTypes.findIndex(x => x.contentType == d.contentType) > -1);
            const startIndex = currPage > 1 ? (currPage - 1) * 10 : 0;
            const endIndex = filteredData.length > startIndex + 10 ? startIndex + 10 : filteredData.length;
            
            for (let i = startIndex; i < endIndex; i++) {
                returnData.push(
                    <div className="item">
                        <a className="title" onClick={(e) => moveToContent(e, filteredData[i])}>{filteredData[i].contentName}</a>
                        <p className="path">
                            {filteredData[i].menuType == "Tutorial" ? filteredData[i].path.split('|')[0] : filteredData[i].path}
                        </p>
                        {makePreview(filteredData[i])}
                    </div>
                );
            }
        }
        if (returnData.length == 0) {
            returnData.push(
                <div className="no-result">
                    <i className="i-no-result"></i>
                    <p className="txt">검색결과가 없습니다.</p>
                </div>
            );
        }
        return returnData;
    }
    const clickPage = (e, page) => {
        setPage(page);
    }
    const getPagenation = () => {
        const checkedTypes = ifilter.filter(x => x.checked == true);
        let resultCount = 0;
        if (checkedTypes.length > 0)           
            resultCount = checkedTypes.filter(x => x.checked == true).reduce((x, y) => x + y.count, 0);
        let pagenation = [];
       
        if (resultCount > 0) {
            const pageCount = Math.ceil(resultCount/10);
            for (let i = 0; i < pageCount; i++) {
                pagenation.push(
                    <li><a className={currPage == i+1 ? "on" : ""} onClick={e => clickPage(e, i + 1)}>{i + 1}</a></li>
                );
            }
        }
        return (resultCount > 0 ?
            <div className="pagination">
                <button className="btn prev"><i className="i-paging-arrow"></i></button>
                <ol>
                    {pagenation}
                </ol>
                <button className="btn next"><i className="i-paging-arrow"></i></button>
            </div>
            : null
        );
    }
    const checkHandler = (e, contentType) => {
        setFilter(
            ifilter.map(filter =>
                filter.contentType == contentType ? { ...filter, checked: e.target.checked } : filter
            )
        );
    }
    const makeFilter = () => {        
        let returnValue = [];
        returnValue.push(
            ifilter.map(c =>
                <li className="filter-check">
                    <input type="checkbox" id={c.contentType} checked={c.checked} onChange={(e) => checkHandler(e, c.contentType)} />
                    <label for={c.contentType} className="check"></label>
                    <label for={c.contentType} className="txt">{c.menuName}<span className="count">({c.count})</span></label>
                </li>
            )
        );
        return (
            <ul>
                {returnValue}
            </ul>
        );
    }
    return (
        <div className="result-wrap">
            <div className="filter">
                <p className="title">필터</p>
                {makeFilter()}
            </div>
            <div className="result-list">
                {getList()}
                {getPagenation()}
            </div>
        </div>
    );
}
export default SearchItem;