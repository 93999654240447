import React, { Component, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const MenuTree = (props) => {
    const [state, setState] = useState({
        selectedNode: ''
    });
    
    useEffect(() => {
        document.body.classList.add('doc-type');

        return () => {
            document.body.classList.remove('doc-type');
        }
    }, []);
    
    const onClickNode = node => {        
        setState({
            selectedNode : node
        });        
        props.onClickNode(node);
    }

    let menudata = [];
    if (props.data != null && props.data.children != undefined) {            
        if (props.data.children.length > 0)
            menudata = props.data.children;
    }
    return (
        <SubTreeContainer data={menudata} depth={0} treeview={true} onClickNode={onClickNode} activeNode={state.selectedNode} nodepath={props.nodepath} docNo={''} />
    );
}

export default MenuTree;

const Node = (props) => {
    const li = React.createRef();
    // console.log(props);
    
    const nodeClick = (e, node) => {
        // e.preventDefault();
        if (node.item.location != undefined) {
            var newTab = window.open(node.item.location.replace(".", window.location.origin), "_blank");
            newTab.focus();
        }
        else
            props.onClickNode(node);
    }
    
    useEffect(() => {
        if (props.activate) {
            li.current.scrollIntoView();
        }
    }, []);

    let icoOutlink = [];

    if (props.item.location != undefined || props.item.opentab != undefined) {
        icoOutlink.push(
            <span className="icoOutlink">
                <i className="i-outlink"></i>
            </span>
        );
    }
    var activateClass = props.activate ? ' on' : ''; 
    return (
        <li ref={li}>
            <a
                className={"depth-" + props.depth + activateClass}
                onClick={e => nodeClick(e,props)}                    
            >
                <span className="txt file">{props.name}{icoOutlink}</span>
            </a>
        </li>
    );
}

const SubTree = (props) => {
    const [state, setState] = useState({
        expandClass: props.collapsed ? "btn-expand collapse" : "btn-expand",
        collapsed: props.collapsed
    });
    
    const onClickNode = node => {
        props.onClickNode(node);
    };

    const changeCollapse = (e, node) => {
        if (state.collapsed) {
            setState({ expandClass: "btn-expand", collapsed: false });
        } else {
            setState({ expandClass: "btn-expand collapse", collapsed: true });
        }
        if (node.item.default != undefined) {
            props.onClickNode(node);
        }
    };

    let path = props.path != props.name + '/' ? props.path : "";
    return (
        <li>
            <a className={"depth-" + props.depth} href={()=>{}} onClick={e => changeCollapse(e, props)}>
                <div className={state.expandClass} >
                    <i className="i-expand-arrow"></i>
                </div>
                <span className="txt">{props.name}</span>
            </a>
            <SubTreeContainer
                key={props.index + 1}
                data={props.data}
                depth={props.depth}
                path={props.path}
                treeview={state.collapsed ? false : true}
                onClickNode={onClickNode}
                item={props.item}
                activeNode={props.activeNode}
                nodepath={props.nodepath}
                docNo={props.docNo}
            />
        </li>
    );
};

const SubTreeContainer = (props) => {

    const onClickNode = node => {
        props.onClickNode(node);
    };

    const isHasItemFunc = (data, path) => {
        for (var i = 0; i < data.length; i++) {
            if (path == data[i].module) {
                return true;
            }
            if (path.substring(0, path.indexOf('/')) == data[i].module) {
                return isHasItemFunc(data[i].children, path.substring(path.indexOf('/')));
            }
        }
        return false;
    };

    const items = props.data;
    let style = {
        display: props.treeview ? "block" : "none",
        animation: "scale - display--reversed .3s"
    };
    let treeItems = [];
    let index = 0;
    let depth = props.depth + 1;
    let path = props.path != undefined ? props.path + '/' : "";
    if (props?.item?.filterPath != undefined)
        path = props.item.filterPath;
    let nodepath = props.nodepath;
    for (let i = 0; i < items.length; i++) {
        if (items[i].hasOwnProperty("children")) {
            let isCollapsed = true, isHasItem = false, itemArr = new Array([]);
            if (items[i].collapsed == undefined || items[i].collapsed == null) {
                isCollapsed = false;
            } else {
                isCollapsed = items[i].collapsed;
            }
            if (nodepath != '' && nodepath != undefined && itemArr != undefined) {
                itemArr.push(items[i]);
                isHasItem = isHasItemFunc(itemArr, nodepath);
                if (isHasItem) {
                    nodepath = nodepath.substring(nodepath.indexOf('/') + 1)
                    isCollapsed = false;
                }
            }
            treeItems.push(
                <SubTree
                    key={index++}
                    index={index + 1}
                    name={items[i].module}
                    data={items[i].children}
                    depth={depth}
                    path={path + items[i].module}
                    collapsed={isCollapsed}
                    onClickNode={onClickNode}
                    item={items[i]}
                    activeNode={props.activeNode}
                    nodepath={nodepath}
                    docNo={props.docNo + '-' + (index + 1)}
            />);
        } else {
            var isactive = false;
            if (nodepath == items[i].module) {
                isactive = true;
            }
            if (path + items[i].module == props.activeNode.path) {
                isactive = true;
            }
            treeItems.push(
                <Node key={index++} name={items[i].module} path={path + items[i].module} onClickNode={onClickNode} depth={depth} item={items[i]} activate={isactive} docNo={props.docNo + '-' + (index + 1)} />
            );
        }
    }
    return (
        <ul className={props.depth == 0 ? "tree" : ""} style={style}>
            {treeItems}
        </ul>
    );
};