import React, { useState, useEffect } from 'react'
import storage from '../../libs/storage';


const TopicList = (props) => {
    const [currentPage, setCurrentPage] = useState(props.state.currentPage);
    const [pageCount, setPageCount] = useState(props.state.pageCount);
    const [topicList, setTopicList] = useState(props.state.topicList);
    const loginStates = storage.get('loginStates');
    const adminType = loginStates.get("admin").split(",");

    useEffect(() => {
        setTopicList(props.state.topicList);
        setCurrentPage(props.state.currentPage);
    });

    const handleClick = (id) => {
        if (document.getElementById('searchKeyword').value != '') {
            props.fnSearchTopic(id);
        } else {
            props.fnOnChangeMode('topicList', id);
        }
    }
    const onKeyPress = (e) => {
        if (e.key == 'Enter') {
            props.fnSearchTopic(1);
        }
    }
    const dateFormat = (date) => {
        let month = date.getMonth() + 1, day = date.getDate(), hour = date.getHours(), minute = date.getMinutes();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;

        return date.getFullYear() + '.' + month + '.' + day + ' ' + hour + ':' + minute;
    }
    const renderTopicList = () =>{
        let list = [];
        for (let i = 0; i < topicList.length; i++) {
            list.push(
                <tr key={i}>
                    <td>
                        <div className="ctgr">{topicList[i].catagoryName}</div>
                    </td>
                    <td>
                        <div className="topic-title-wrap">
                            <a onClick={e => props.fnOnChangeMode('topicView', props.state.currentPage, topicList[i].topicSeq)} className="topic-title">{topicList[i].title}</a>
                            {topicList[i].subTopicCount != 0
                                ? <div className="topic-tag reply">피드 {topicList[i].subTopicCount}</div> : null}
                            {topicList[i].isNewSubTopic == '1' && Math.ceil((new Date().getTime() - new Date(topicList[i].writeDate).getTime()) / (1000 * 3600 * 24)) < 30
                                ? <div className="topic-tag new">NEW</div> : null}
                        </div>
                    </td>
                    <td>
                        <div onClick={e => props.fnBtnOnPopup(topicList[i], e)} className="author">
                            <div className="pf" style={{ backgroundColor: topicList[i].colorCode }}>
                                {topicList[i].writerFirstInitial}
                            </div>
                            <a className="name" href={()=>{}}>{topicList[i].writer}</a>
                        </div>
                    </td>
                    <td>
                        <span className="date">{dateFormat(new Date(topicList[i].writeDate))}</span>
                    </td>
                </tr>
            );
        }
        return list;
    }
    const setPaging = (type) => {
        if (type == 'prev') {
            if (document.getElementById('searchKeyword').value != '') {
                props.fnSearchTopic(currentPage - 1);
            } else {
                props.fnOnChangeMode('topicList', currentPage - 1);
            }
        } else {
            if (document.getElementById('searchKeyword').value != '') {
                props.fnSearchTopic(currentPage + 1);
            } else {
                props.fnOnChangeMode('topicList', currentPage + 1);
            }
        }
    }
    const renderPagination = () => {
        let list = [];
        if (topicList == undefined || topicList.length == 0) return;
        let paginations = Math.ceil(topicList[0].totalCount / pageCount);

        for (let i = 0; i < paginations; i++) {
            // 1. 페이지 그려주는 경우
            // 2. 생략버튼 그려주는 경우(맨 앞만 ... 인 경우 현재페이지 중간이라 앞뒤로 ...인경우 맨 뒤만 ...인경우)
            let renderPage = i + 1;
            if (renderPage == 1 || renderPage == paginations
                || (currentPage < 4 && renderPage < 5)
                || (currentPage > 3 && currentPage <= renderPage && renderPage < currentPage + 3)
                || (currentPage > paginations - 3 && renderPage > paginations - 4)) {
                list.push(
                    <li key={i}><a className={currentPage == renderPage ? 'on' : ''} onClick={e => handleClick(renderPage)}>{renderPage}</a></li>
                );
            } else if ((renderPage == 2 && currentPage > paginations - 3)
                || (renderPage == currentPage - 1 && renderPage < currentPage)
                || renderPage == paginations - 1) {
                list.push(
                    <li key={i}><a className="ellipsis" href={()=>{}}><i className="i-ellipsis"></i></a></li>
                );
            }
        }
        return (
            <div className="pagination">
                <button className="btn prev"
                    style={{ visibility: currentPage != 1 ? 'visible' : 'hidden' }}
                    onClick={e => setPaging('prev')}>
                    <i className="i-paging-arrow"></i>
                </button>
                <ol>
                    {list}
                </ol>
                <button className="btn next"
                    style={{ visibility: currentPage != paginations ? 'visible' : 'hidden' }}
                    onClick={e => setPaging('next')}>
                    <i className="i-paging-arrow"></i>
                </button>
            </div>
        );
    }
    return (
        <div className="qna-wrap">
            <h2>{props.topicListTitle}</h2>
            <section className="qna-list">
                <div className="ctgr-wrap">
                    <div className="select-wrap">
                        <select className="ctgr-select" id="ctgrSelect">
                            <option value="1">전체</option>
                            <option value="2">제목</option>
                            <option value="3">내용</option>
                        </select>
                    </div>
                    <div className="search-wrap">
                        <input id="searchKeyword" type="text" placeholder="검색" onKeyDown={e => onKeyPress(e)} />
                        <button className="btn-search" onClick={e => props.fnSearchTopic(1)}><i className="i-search"></i></button>
                    </div>
                    {!props.isAdmin && props.companyType != '0'
                        ? (
                            <div style={{ display: "flex", width: "30%", justifyContent: "space-evenly" }}>
                            <button className="btn-new-topic btn-primary" onClick={e => props.fnOnChangeMode('topicWrite')}>{props.btnTopicWriteName}</button>
                            </div>
                        )
                        : null}
                </div>
                {topicList.length != 0
                    ? <div className="board-wrap">
                        <table className="board">
                            <colgroup>
                                <col width="110px" ></col>
                                <col width="*" ></col>
                                <col width="100px" ></col>
                                <col width="120px" ></col>
                            </colgroup>
                            <tbody>
                                {renderTopicList()}
                            </tbody>
                        </table>
                    </div>
                    : <div className="no-item">
                        <i className="i-no-qna"></i>
                        <p className="txt">{props.state.current == 'topicSearch' ? '검색된 내용이 없습니다' : '작성된 1:1문의가 없습니다'}</p>
                    </div>}
            </section>
            {renderPagination()}
        </div>
    );
}
export default TopicList;