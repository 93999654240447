import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as loginActions from '../../store/modules/loginActions';
import * as messageActions from '../../store/modules/messageActions';
import { bindActionCreators } from 'redux';
import EmailVerificationPanel from './EmailVerificationPanel';

const FindIdPasswordPanel = (props) => {
    const LoginStates = useSelector((state) => state.loginActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);
    const MessageActions = bindActionCreators(messageActions, dispatch);
    const [userInfo, setUserInfo] = useState({
            userName: '',
            userId: '',
            noticed: '0',
            btnState: '비밀번호 발송',
            isVerify: false
        });

    const handleChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });
    };

    const AfterCheckAuth = () => {
        setUserInfo({
            ...userInfo,
            isVerify: true
        });
    };

    const AfterRequestAuth = () => {
        setUserInfo({
            ...userInfo,
            isDisabled: 'disable'
        });
    };

    const CheckNameId = (e) => {
        e.preventDefault();

        const userNameId = {
            userName: userInfo.userName,
            userId: encodeURI(userInfo.userId)
        };

        if (userNameId.userName == '' || userNameId.userId == '') {
            showToast('warning', '입력하지 않은 정보가 있습니다.');
        }
        else {
            setUserInfo({ ...userInfo, btnState: '발송 중...' });
            fetch('api/Login/FindPassword', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(userNameId)
            }).then(async (response) => response.json())
                .then(async (responseData) => {
                    if (responseData.status == "Success Finding") {
                        setUserInfo({ ...userInfo, noticed: '0' });
                        const result = {                                                     
                            userId: userNameId.userId,                           
                            message: responseData.message,
                            result: responseData.status
                        };
                        await LoginActions.setResultLogin(result);
                        props.setPanelType('');
                    }
                    else if (responseData.status == "Fail Sending") {
                        showToast('danger', responseData.message);
                    }
                    else if (responseData.status == "Fail Finding") {
                        showToast('warning', '입력하신 정보가 일치하지 않습니다.');
                    }
                    setUserInfo({ ...userInfo, btnState: '비밀번호 발송' });
                });
        }
    };

    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    };

    return (
        <div className="login-wrap">
            <form className="login-box">
                <h2>비밀번호 찾기</h2>
                {!userInfo.isVerify ? <p className="pw-change-txt">먼저, 본인확인을 위해 이메일 인증을 해주세요.</p> : null}
                <div className="input-ui">
                    <input
                        name='userName'
                        value={userInfo.userName}
                        onChange={e => handleChange(e)}
                        placeholder='이름'
                        onFocus={(e) => e.target.placeholder = '이름'}
                    />
                </div>
                <div className="input-ui">
                    <input
                        name='userId'
                        value={userInfo.userId}
                        onChange={e => handleChange(e)}
                        placeholder='이메일'
                        onFocus={(e) => e.target.placeholder = '아이디'}
                        disabled={userInfo.isDisabled}
                    />
                </div>
                <EmailVerificationPanel buttonTitle={'인증번호 요청'} state={userInfo} fnAfterCheckAuth={AfterCheckAuth} fnAfterRequestAuth={AfterRequestAuth} />
                {userInfo.isVerify ?
                    <div className="btn-wrap">
                        <button onClick={(e) => CheckNameId(e)} className='btn-primary'>{userInfo.btnState}</button>
                    </div>
                    : <div className="btn-wrap">
                        <button className='btn-primary btn-dis'>{userInfo.btnState}</button>
                    </div>}
            </form>
        </div>
    );
}

export default FindIdPasswordPanel;