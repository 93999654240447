import React, { useState, useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import ImageTool from '@editorjs/image';
import MonacoBlock from '@editorjs/monaco';
import "./Editor.css";

const Editor = ({ 
    editorData,
    setEditor
}) => {
    const editorInstance = useRef(null);
    const editorContainer = useRef(null);

    useEffect(() => {      
        if (editorInstance.current) {
            if (typeof editorInstance.current.destroy === "function") {
                editorInstance.current.destroy();
            }
            editorInstance.current = null;
        }
        if(editorContainer.current) {
            const editor = new EditorJS({
                readOnly: true,
                holder: editorContainer.current,
                placeholder: '',
                autofocus: false,
            
                tools: {
                    image: {
                        class: ImageTool,
                    },
                    code: {
                        class: MonacoBlock,
                        config:{
                            initLanguage:'sql'
                        },
                    },
                },
    
                data: {},
                onReady: () => {
                    editorInstance.current = editor;
                    try {
                        if(editorData.data)
                            editor.render(convertDataType(editorData.data));
                    } catch (e) {

                    }
                }
            });
            editorInstance.current = editor;
            setEditor(editor);
        }

        return () => {
            if (editorInstance.current) {
                if(typeof editorInstance.current.destroy === "function") {
                    editorInstance.current.destroy();
                }
                editorInstance.current = null;
            }
        }
    }, [editorData]);
    
    const convertDataType = (data) => {
        if (data && data.blocks && Array.isArray(data.blocks)) {
            data.blocks = data.blocks.flatMap(block => {
                // 사용하지 않는 type이 존재할 경우, 새로운 type으로 변경
                if (block.type === 'header') {
                    block.type = 'h1';
                }
                if (block.type === 'h4') {
                    block.type = 'paragraph';
                }  
                if (block.type === 'list') {
                    block.type = 'ul';
                }
                if(block.type === "paragraph" && block.tunes && block.tunes.indentTune) {
                    if(block.tunes.indentTune.indentLevel > 0) {
                        block.data.depth = block.tunes.indentTune.indentLevel;
                    }
                    if(!block.data.depth && block.data.level) {
                        block.data.depth = block.data.level;
                    }
                }
                if(block.type === "ul" || block.type === "ol") {
                    if(block.data.items) {
                        return convertToNewFormat(block);
                    } else {
                        if(block.type === "ul") block.type = "unordered";
                        else block.type = "ordered";
                    }
                }
                if(block.type === "unordered" || block.type === "ordered") {
                    if(!block.data.depth && block.data.level) {
                        block.data.depth = block.data.level;
                    }
                }
                return [block];
            });
        }
        return data;
    };

    const convertToNewFormat = (block, indent = 0) => {
        let result = [];
        
        const traverseItems = (type, items, indentLevel) => {
          let number = 1;
          
          items.forEach((item) => {
            let newItem = {
              type: type === "ul" ? "unordered" : "ordered",
              data: {
                text: item.content,
                depth: indentLevel,
                number: number,
                indent: indentLevel,
                style: type === "ul" ? "unordered" : "ordered"
              }
            };
      
            result.push(newItem);
      
            if (item.items && item.items.length > 0) {
              traverseItems(type, item.items, indentLevel + 1);
            }
            
            number++;
          });
        }
        
        traverseItems(block.type, block.data.items, indent);
        return result;
    }

    return (
        <div id="editorjs" className='editor' ref={editorContainer}></div>
    )
}
export default Editor;