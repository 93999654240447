import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const initialState = Map({
    display: 'none',
    groupno: 1
});

const SETDISPLAY = 'newsActions/SETDISPLAY';
const SETGROUPNO = 'newsActions/SETGROUPNO';

export const setDisplay = createAction(SETDISPLAY);
export const setGroupNo = createAction(SETGROUPNO);

export default handleActions({
    [SETDISPLAY]: (state, action) => {
        return state.set('display', action.payload.display);
    },
    [SETGROUPNO]: (state, action) => {
        return state.set('groupno', action.payload.groupno);
    }
}, initialState);