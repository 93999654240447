import React, { Component, useEffect, useState } from 'react';
import SearchItems from './SearchPanels/SearchItems';
import { useLocation, useNavigate } from 'react-router-dom';

const Search = (props) => {
    const history = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        searchword: location.state ?? '',
        keyword:'',
        result: null,
        filterList:[]
    });
    
    useEffect(() => {
        doSearching();
    }, []);
    
    const searchText = (e) => {
        setState({
            ...state,
            searchword: e.target.value
        });
    };
    
    const handleKeyPress = (e) => {
        if (e.key == 'Enter') {
            doSearching();
        }
    };

    const doSearching = () => {
        if (state.searchword != '') {
            history('/Search',{
                state: state.searchword
            });
            fetch(`api/Search/Searching?keyword=${state.searchword}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            }).then((response) => response.json())
                .then((data) => {
                    if (data.result == 'OK') {
                        setState({
                            ...state,
                            result: data.data,
                            keyword: state.searchword
                        });

                    }
                })
        }
    };    
    
    const executeSearch = (e) => {
        alert('click');
        e.preventDefault();
        
        doSearching();
    };
        
    return (
        <div className="search-result">
            <div className="header">
                <div className="headerWrap">
                    <div className="search-input">
                        <input type="text" placeholder="검색어를 입력하세요." onChange={e => searchText(e)} onKeyPress={e => handleKeyPress(e)} value={state.searchword} />  
                        {/* value="setText" */}
                        <button className="btn-search" onClick={doSearching}><i className="i-search"></i></button>
                    </div>
                </div>
            </div>
            <div className="result">
                <SearchItems result={state.result} keyword={state.keyword} />
            </div>
        </div>
    );
}
export default Search;