import React, { Component, Fragment, useState, useEffect } from 'react'
import Editor from '../../libs/QuillEditor';
import { htmlToDelta, deltaToHtml } from '../../libs/DeltaConverter';

const TopicWrite = (props) => {
    const editorRef = React.useRef(null);
    const [selectedCatagorySeq, SetSelectedCatagorySeq] = useState(props.catagorySeq != undefined ? props.catagorySeq : 0);
    const [catagoryList, setCatagoryList] = useState(props.state.catagoryList);
    const [content, setContent] = useState(props.state.content != undefined ? props.state.content : '');
    const [docPath, setDocPath] = useState(props.state.docPath != undefined ? props.state.docPath : '');
    const [title, setTitle] = useState(props.state.topicView.length != 0 && props.state.workingTag == 'U' ? props.state.topicView[0].title : '');
    const [isReceiveMail, setIsReceiveMail] = useState(props.state.isReceiveMail != undefined ? props.state.isReceiveMail : '0');
    const [topicSeq, setTopicSeq] = useState(props.state.topicSeq != undefined ? props.state.topicSeq : 0);

    const handleChange = (e) => {
        if (e.target.id == 'topicTitle') {
            setTitle(e.target.value);
        } else if (e.target.id == 'catagory') {
            SetSelectedCatagorySeq(e.target.value);
        } else if (e.target.id == 'replyToEmail') {
            setIsReceiveMail(e.target.checked ? '1' : '0');
        }
    }
    const renderCatagoryList = (catagorySeq) => {
        let list = [], selected = '';
        for (let i = 0; i < catagoryList.length; i++) {
            selected = '';
            if (catagoryList[i].catagorySeq == catagorySeq || (props.catagoryName != undefined && catagoryList[i].catagoryName.toLowerCase() == props.catagoryName.toLowerCase())) {
                selected = 'selected';
            }
            list.push(
                <option key={'catagory' + i} selected={selected} value={catagoryList[i].catagorySeq}>{catagoryList[i].catagoryName}</option>
            );
        }
        return (
            <select className="ctgr-select" id="catagory" onChange={handleChange}>
                <option value={0}>카테고리를 선택하세요</option>
                {list}
            </select>
        );
    }
    const topicSave = () => {
        // const converter = new QuillDeltaToHtmlConverter(editorRef.current.getContents().ops, {});
        // const content = converter.convert();
        // const content = editorRef.current.root.innerHTML;
        const content = deltaToHtml(editorRef.current.getContents());
        const topicInfo = {
            title: title,
            selectedCatagorySeq: selectedCatagorySeq,
            content: content,
            contentText: editorRef.current.getText(),
            docPath: docPath,
            isReceiveMail: isReceiveMail,
            topicSeq: topicSeq,
        };
        props.fnTopicSave(topicInfo);
    }
    return (
        <div className="qna-wrap">
            <div className="top-wrap">
                <button className="btn-back-list" onClick={e => props.fnOnChangeMode('topicList')}>
                    <i className="i-back"></i>
                    <h2>{props.topicWriteTitle}</h2>
                </button>
            </div>
            <section className="qna-write">
                <div className="select-wrap">
                    {renderCatagoryList(selectedCatagorySeq)}
                </div>
                {docPath
                    ? <div className="page-link-wrap">
                        <span className="page-link"><a>관련페이지 : {docPath}</a></span>
                    </div> : null
                }
                <div className="title-wrap">
                    <input type="text" id="topicTitle" value={title} onChange={handleChange} placeholder="제목을 입력하세요" />
                </div>
                {props.topicMessageInfo}
                <div className="editor-wrap">
                    <div className="editor">
                        <Editor
                            ref={editorRef}
                            readOnly={false}
                            defaultValue={htmlToDelta(content)}
                        />
                    </div>
                </div>
                <div className="bottom">
                    <div className="check-wrap">
                        <input type="checkbox" id="replyToEmail" onChange={handleChange} checked={isReceiveMail == '1' ? 'checked' : null} />
                        <label htmlFor="replyToEmail" className="toggle"></label>
                        <label htmlFor="replyToEmail" className="txt">메일로 답변 받기</label>
                    </div>
                    <button className="btn-primary" onClick={e => topicSave()}>등록</button>
                    <button className="btn-secondary" onClick={e => props.fnOnChangeMode('topicList')}>취소</button>
                </div>
            </section>
            <button className="btn-back-list" onClick={e => props.fnOnChangeMode('topicList')}>목록 보기</button>
        </div>
    );
}
export default TopicWrite;