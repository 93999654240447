import React, { useEffect, useState, Component } from 'react'

const ContentList = (props) => {
    const[selectedNode, setSelectedNode] = useState('');
    const[tree, setTree] = useState([]);
    //useEffect(()=>{
    //    if(props.data !== null)
    //        setTree(props.data);
    //},[]);

    useEffect(()=>{
        if(props.data !== null)
            setTree(props.data);
    }, [props]);

    const onClickNode = node => {
        setSelectedNode(node);
        props.onClickNode(node);
    }
    const addSubClick = node => { 
        props.addSubClick(node);
    }
    return (
        <SubTreeContainer
            data={tree}
            activeNode={selectedNode}
            depth={0}
            treeView={true}
            nodepath={props.nodepath}
            onClickNode={onClickNode}
            addSubClick={addSubClick}
        />
    );
}
const SubTreeContainer = (props) => {

    const onClickNode = node => {
        props.onClickNode(node);
    };
    const addSubClick = node =>{
        props.addSubClick(node);
    };
    const isHasItem = (data, path) => {
        for (var i = 0; i < data.length; i++) {
            if (path == data[i].module) {
                return true;
            }
            if (path.substring(0, path.indexOf('/')) == data[i].module) {
                return isHasItem(data[i].children, path.substring(path.indexOf('/') + 1));
            }
        }
        return false;
    };

    const items = props.data;
    // console.log(items);
    let style = {
        display: props.treeView ? "block" : "none",
        animation: "scale - display--reversed .3s"
    };
    let treeItems = [];
    let index = 0;
    let path = props.path != undefined ? props.path + '/' : "";
    let numPath = props.numPath != undefined ? props.numPath + '_' : "";
    let depth = props.depth + 1;
    let nodepath = props.nodepath;

    for (let i = 0; i < items.length; i++) {            
        if (items[i].hasOwnProperty("children")) {
            let isCollapsed = true, isHasItem = false, itemArr = new Array();
            var isactive = false;
            if (path != "") {
                if (path + items[i].module == props.activeNode.path) {
                    isactive = true;
                }
            }
            if (items[i].collapsed == undefined || items[i].collapsed == null) {
                isCollapsed = false;
            } else {
                isCollapsed = items[i].collapsed;
            }
            if (nodepath != '' && nodepath != undefined) {
                itemArr.push(items[i]);
                isHasItem = isHasItem(itemArr, nodepath);
                if (isHasItem) {
                    nodepath = nodepath.substring(nodepath.indexOf('/') + 1)
                    isCollapsed = false;
                }
            }
            treeItems.push(
                <SubTree
                    index={index++}
                    item={items[i]}
                    data={items[i].children}
                    depth={depth}
                    path={path + items[i].module}
                    collapsed={isCollapsed}
                    name={items[i].module}
                    numPath={numPath + i.toString()}
                    activeNode={props.activeNode}
                    nodepath={nodepath}
                    onClickNode={onClickNode}
                    addSubClick={addSubClick}
                />
            )
        } else {
            var isactive = false;
            if (nodepath == items[i].module) {
                isactive = true;
            }
            if (path + items[i].module == props.activeNode.path) {
                isactive = true;
            }
            treeItems.push(
                <Node
                    key={index++}
                    item={items[i]}
                    depth={depth}
                    path={path + items[i].module}
                    name={items[i].module}
                    numPath={numPath + i.toString()}
                    activate={isactive}
                    onClickNode={onClickNode}
                    addSubClick={addSubClick}
                />
            );
        }
    }
    return (
        <ul className={props.depth == 0 ? "tree" : ""} style={style}>
            {treeItems}
        </ul>
    );
};
const SubTree = (props) => {
    const [state, setState] = useState({
        expandClass: props.collapsed ? "btn-expand collapse" : "btn-expand",
        collapsed: props.collapsed
    });
    // useEffect(() => {
    //     setState({
    //         expandClass: props.collapsed ? "btn-expand collapse" : "btn-expand",
    //         collapsed: props.collapsed
    //     });
    // }, []);

    const onClickNode = node => {
        props.onClickNode(node);        
    };

    const changeCollapse = (e) => {
        if (state.collapsed) {
            setState({ expandClass: "btn-expand", collapsed: false });
        } else {
            setState({ expandClass: "btn-expand collapse", collapsed: true });
        }
    };

    const addSubClick = node =>{
        props.addSubClick(node);
    };

    const { item, numPath } = props;
    let path = props.path != props.name + '/' ? props.path : "";
    var isactive = false;
    if (path + item.module == props.activeNode.path) {
        isactive = true;
    }
    return (
        <li>
            <Node                    
                item={item}
                depth={props.depth}
                path={path}
                numPath={numPath}
                name={props.name}
                activate={isactive}
                onClickNode={onClickNode}
                addSubClick={addSubClick}
                expandClass={state.expandClass}
                changeCollapse={changeCollapse}
            />
            <SubTreeContainer
                index={props.index + 1}
                data={props.data}
                depth={props.depth}
                item={props.item}
                path={path}
                treeView={state.collapsed ? false : true}
                numPath={props.numPath}
                activeNode={props.activeNode}
                nodepath={props.nodepath}
                onClickNode={onClickNode}
                addSubClick={addSubClick}
            />
        </li>
    );
}
const Node = (props) => {
    const li = React.createRef();

    const nodeClick = (e, node) => {
        e.preventDefault();
        props.onClickNode(node);
    };

    const changeCollapse = (e, node) => {
        e.preventDefault();
        props.changeCollapse(e);
        props.onClickNode(node);
    };

    const addSubClick =(e, node) =>{
        e.preventDefault();
        props.addSubClick(node);
    };
    
    let activateClass = props.activate ? ' on' : '';       
    if (props.item.children != undefined)
        return (
            <a className={"depth-" + props.depth + activateClass} onClick={e => nodeClick(e, props)}>
                <div className={props.expandClass} onClick={e => changeCollapse(e, props)}>
                    <i className="i-manual-folder-open"></i>
                </div>
                <span className="txt folder">{props.name}</span>
                <div className="btn-add-folder" title="하위추가" onClick={e => addSubClick(e, props)}><i className="i-manual-add-folder"></i></div>
            </a>
        );
    else
        return (
            <li ref={li}>
                <a
                    className={"depth-" + props.depth + activateClass}
                    href={()=>{}}
                    onClick={e => nodeClick(e, props)}>
                    <i className="i-manual-file"></i>
                    <span className="txt file">{props.name}</span>
                    <div className="btn-add-folder" title="하위추가" onClick={e => addSubClick(e, props)}><i className="i-manual-add-folder"></i></div>
                    {/*누군가 편집중일때 editing-user 보이게 / 본인이 편집중일때 editing-user에 me 추가 yzyoo*/}
                    {/*<div className="editing-user">
                        <span className="user-pf me">Y</span>
                        <i className="i-pf-editing"></i>
                    </div>*/}
                </a>
            </li>
        );
}
export default ContentList;