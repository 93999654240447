import React, { Component, useState, useEffect } from 'react'
import ContentList from './ContentEditor/ContentList';

import { Intro, EditProperty, ListHeader } from './ContentEditor/EditorUtils';
import EditContent from './ContentEditor/EditContent';
import { useNavigate } from 'react-router-dom';

const ContentEditor = () => {
    
    const history = useNavigate();
    const [visibleIntro, setVisibleIntro] = useState(true);
    const [visibleEditor, setVisibleEditor] = useState(false);
    const [menuName, setMenuName] = useState('');
    const [menuData, setMenuData] = useState([]);
    const [tree, setTree]= useState([]);
    const [isPopUp, setIsPopUp] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('');
    const [currentItem, setCurrentItem] = useState(null);
    const [contentInfo, setContentInfo] = useState({contentType: '',contentName: '',folderName: '',type: ''});
    const [state, setState] = useState({
        tree: null,
        menuData: null,
        navMenuList: null,
        contentName: '',
        isViewMode: true,
        isExpanded: false,
        isPopUp:''
    });
    useEffect(()=>{
        getMenuContents();
    }, [menuName]);
    
    useEffect(()=>{
        if(tree.length > 0){
            setVisibleEditor(true);
            setVisibleIntro(false);
            setIsPopUp('popup');
        }
    },[tree])

    const getMenuContents = () => {
        let filename = menuName;
        if(state.menuData !== null){
            const currentMenu = state.menuData.find(n => n.path.toLowerCase() === filename)

            if (currentMenu !== undefined && currentMenu.disable) {
                filename = '';
            }
        }
        if (filename === '') {
            
        } else {
            fetch('datas/' + filename + '.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Cache-Control': 'no-cache'
                }
            }).then((response) => response.json())
                .then((content) => {
                    var array = [];
                    array.push(content !== '' ? content : '{}');
                    setTree(array);
                });
        }
    };
    const CategoryPopUp = (e) => {
        e.preventDefault();
        setVisibleIntro(!visibleIntro);
    };
    const outEditor = (e) => {
        history(-1);
    };

    const onClickNode = node => {
        let type = 'pdf';
        if (node.item.type === undefined || node.item.type !== 'pdf')
            type = 'markdown';
        let contentName = node.item.module;
        let folderName = "";
        let path = node.path;
        if (node.item.hasOwnProperty('directory')) {
            let directory = node.item.directory;

            if (directory === 'path') {
                folderName = path.replace("/" + contentName, "").replace(tree[0].module, "").replace("/", "");
            }
            else
                folderName = directory;
        }
        setContentInfo({
            contentType: menuName,
            contentName: contentName,
            folderName: folderName,
            type: type,
            numPath: node.item.numPath
        });
        setCurrentItem(node);
    };

    const addSubClick = node => {
        //문서 제목 입력 받기 혹은 파일 올리기 선택 팝업 창 띄워야 함.
        
        if(!node.item.hasOwnProperty('children')){
            node.item.children = [{ module:'새 문서 #1', directory:'path'}];
        }else{
            let Idx = (node.item.children.length + 1).toString();
            node.item.children.push({ module:'새 문서 #' + Idx, directory:'path'});
        }
    }
    const addSubItem = (node, name) => {
        if(!node.item.hasOwnProperty('children')){
            node.item.children = [{ module:'새 문서 #1', directory:'path'}];
        }else{
            let Idx = (node.item.children.length + 1).toString();
            node.item.children.push({ module:'새 문서 #' + Idx, directory:'path'});
        }
    }
    const EditMode = () => {
        setState({
            ...state,
            isViewMode: !state.isViewMode
        })
    };

    const EditorExpand = (isExpanded) => {
        if (state.isExpanded !== isExpanded)
            setState({
                ...state,
                isExpanded: isExpanded
            })
    };

    const [files, setFiles] = useState(null);

    const { isViewMode, isExpanded} = state;
    
    let displayEditButtons = { display: '' };
    if (isViewMode)
        displayEditButtons.display = 'none'; 
    return (
        <div className="container manual">
            <div className="manual-editor">
                <header className="gnb">
                <p className="title"><i className="i-edit-manual"></i>매뉴얼 관리</p>
                    <button className="btn-close" onClick={ (e) => outEditor(e)}><i className="i-close-fullpage"></i></button>
            </header>
                <Intro setMenuName={setMenuName} setMenuData={setMenuData} visible={visibleIntro} isPopUp={isPopUp} menuData={menuData} setCurrentCategory={setCurrentCategory} />
                {visibleEditor &&
                    <div className={`edit-content ${isViewMode ? "view-mode" : ""}`}>
                        <div className="left-ctgr">
                            <ListHeader ClickCategoryView={CategoryPopUp} isViewMode={isViewMode} currentCategory={currentCategory} />
                            <div className="ctgr-tree">
                                <ContentList data={tree} onClickNode={onClickNode} addSubClick={addSubClick} nodepath={''} />
                            </div>
                            {/*<div className="btn-wrap">*/}
                            {/*    <div className="btn-new-doc">*/}
                            {/*        <button className="new-doc">새 문서 만들기 </button>*/}
                            {/*        <a className="select-my-doc" href={()=>{}}><i className="i-select-doc"></i></a>*/}
                            {/*    </div >*/}
                            {/*</div>*/}
                        </div>
                        <div className={`edit-area ${isExpanded ? "expand-editor" : ""}`}>
                            <EditProperty ViewMode={isViewMode} contentInfo={contentInfo} currentItem={currentItem} EditMode={EditMode} setContentInfo={setContentInfo} setCurrentItem={setCurrentItem} files={files} data={tree} path={menuName} />
                            <EditContent ViewMode={isViewMode} contentInfo={contentInfo} isExpanded={isExpanded} setIsExpanded={EditorExpand} currentItem={currentItem} setFiles={setFiles} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ContentEditor;



