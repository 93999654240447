import React, { Component, useEffect, useState } from 'react';
// import TopicViewer from '../ManagerPanels/TopicPanels/TopicViewer';

const Post = (props) => {
    const [state, setState] = useState({
        activate: props.activate,
        viewCount: props.postdata.viewcount,
        originalViewCount: props.postdata.viewcount
    });
    
    useEffect(() => {
        if (props.isAccessFromHome) {
            postViewAdd();
        }
    }, []);

    const postViewAdd = () => {
        const input = {
            userId: '',
            userName: '',
            workingTag: 'postViewAdd',
            pageNo: 1,
            postInfo: {
                contentType: '',
                postSeq: props.postdata.postSeq,
                attachSeq: 0,
                commentSeq: 0,
                postData: {
                    title: '',
                    content: '',
                    attachment: [],
                    comment: ''
                }
            }
        };
        fetch('api/Document/NewsManage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(input)
        }).then((response) => response.json())
            .then((data) => {
                setState(prev => { return { ...prev, viewCount: data.postData }});
            });
        
    };

    const clickPostNode = e => {
        props.clickPostNode(e, state.activate);
        if (props.activate == false) {
            postViewAdd();
        }
    };

    const newlineMaker = (contents) => {
        let strcontent = '';
        contents.split('<br>').map(line => {
            strcontent += '<span>' + line + '<br /></span>';
        });
        return (
            <p className="txt" dangerouslySetInnerHTML={{ __html: strcontent }}>
            </p>
        );
    };

    const attechFile = () => {
        let filenode = [], index = 0;
        const filelist = props.postdata.attachfiles;
        for (var i = 0; i < filelist.length; i++) {            
            filenode.push(
                <li key={index++}>
                    <a href={'/FileServer/UploadFiles/' + filelist[i].attachfilename} target="_blank" rel="noreferrer" download>
                        <p className="filename">{filelist[i].attachfilename}</p>
                        <p className="filesize">{filelist[i].attachfilesize}</p>
                    </a>
                </li>
            );
        }
        return (
            <ul className="attached-files">
                {filenode}
            </ul>
        );
    };
    if (state.originalViewCount != props.postdata.viewcount) {
        setState(prev => { return {
            ...prev,
            originalViewCount: props.postdata.viewcount,
            viewCount: props.postdata.viewcount
        }});
    }
    return (
        <div className="post-wrap">
            <div className="info">
                <span className="writer">{props.postdata.writer}</span>
                <span className="timestamp">{props.postdata.timestamp}</span>
            </div>
            <div className="stats">
                <span className="views">조회수 {state.originalViewCount != props.postdata.viewcount ? props.postdata.viewcount : state.viewCount}</span>
                <span className="comment">댓글 {props.postdata.commentcount}</span>
            </div>
            <a className="title" onClick={clickPostNode}>{props.postdata.posttitle}</a>
            <div className="post-content">
                {newlineMaker(props.postdata.postcontent)}
                {/* <TopicViewer data={props.postdata.postcontent} className={"txt"} /> */}
                {attechFile()}
            </div>
        </div>
    );
}
export default Post;