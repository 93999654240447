import React, { useState, useEffect, Fragment } from 'react'
import PopUp from '../Common/PopUp';
import Filter from '../Common/Filter';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as messageActions from '../../store/modules/messageActions';
import Table from '../Common/Table';

const ApplicantList = (props) => {
    let changeUserApplyStatus = 'E';
    let reasonDisplay = 'none';
    let viewData = [];

    //redux
    const loginInfo = useSelector(state => state.loginActions);
    const userId = loginInfo.get('userId');
    const dispatch = useDispatch();
    const MessageActions = bindActionCreators(messageActions, dispatch);

    // State
    const [applyListType, setApplyListType] = useState('progress');
    const [data, setData] = useState([]);
    const [listView, setListView] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [popupContent, setPopUpContent] = useState(null);
    const [isCheckedAll, setIsCheckedAll] = useState(false);
    // Event
    const changeListType = (type) => {
        setApplyListType(type);
    }
    const handleChange = (e, item, index, type) => {
        if (e.target.name == 'applyStatus') {
            changeUserApplyStatus = e.target.value;
            if (changeUserApplyStatus == 'D') {
                reasonDisplay = '';
            } else {
                reasonDisplay = 'none';
            }
            btnOnPopup(e, item, index, type);
        } else if (e.target.name == 'remark') {
            viewData = JSON.parse(JSON.stringify(item));
            viewData.remark = e.target.value;
            btnOnPopup(e, viewData, index, type);
        }
    }
    // Method
    const applySave = (e, indexList, isDel, type) => {
        if (type == 'deny') {
            changeUserApplyStatus = 'D';
        }
        if (changeUserApplyStatus == 'D' && (viewData.remark == '' || viewData.remark == undefined)) {
            btnOnPopup(e, viewData, checkedItems, type);
            return;
        }
        let userInfo = [];
        for (let i = 0; i < listView.length; i++) {
            if (indexList.indexOf(i) != -1) {
                userInfo.push({
                    userId: listView[i].userId,
                    userName: listView[i].userName,
                    userEmail: listView[i].userId,
                    applyStatus: changeUserApplyStatus,
                    isDel: isDel,
                    remark: viewData.remark ?? '',
                    userCompany: listView[i].companyName,
                    lastUserId: userId
                });
            }
        }
        if (userInfo.length != 0) {
            fetch('api/Login/SignupApproval', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(userInfo)
            }).then(async (response) => response.json())
                .then(async (responseData) => {
                    getList();
                    viewData = [];
                    if (isDel != 'Y') {
                        showToast('success', '저장이 완료되었습니다.', 3000);
                    } else {
                        showToast('success', '삭제가 완료되었습니다.', 3000);
                    }
                    setCheckedItems([]);
                    setIsCheckedAll(false);
                    setPopUpContent(null);
                });
        }
    }
    const showToast = (level, message, time) => {
        MessageActions.showToast({
            visible: true,
            level: level,
            message: message
        });
        setTimeout(() =>
            MessageActions.hideToast({ visible: false })
            , time == undefined ? 1500 : time);
    }

    const getList = () => {
        fetch('api/Login/GetApplicantDataList', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then((response) => response.json())
            .then((data) => {
                setData(data.table);
            });
    }
    const getHistoryList = () => {
        fetch('api/Login/GetApplicantHistoryDataList', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then((response) => response.json())
            .then((data) => {
                setData(data.table);
            });
    }
    const renderBatchWrap = () => {
        let batchWrapArr = [];
        batchWrapArr.push(
            <div key="batch" className="batch-wrap">
                <p className="count"><span className="selected">{checkedItems.length}</span>/{data.length} 선택됨</p>
                <div className="btn-wrap">
                    <button className="btn-primary" onClick={(e) => btnOnPopup(e, '', checkedItems, 'approval')}>승인</button>
                    <button className="btn-primary-t3" onClick={(e) => btnOnPopup(e, viewData, checkedItems, 'deny')}>거부</button>
                    <button className="btn-secondary-t3" onClick={(e) => btnOnPopup(e, '', checkedItems, 'delete')}>삭제</button>
                </div>
            </div>
        );
        return batchWrapArr;
    }
    const btnOnPopup = (e, item, index, type) => {
        if (e != undefined) {
            e.stopPropagation();
        }
        if (applyListType != 'progress') {
            return;
        }
        if (item != null && index != undefined) {
            if (changeUserApplyStatus == 'D') {
                reasonDisplay = '';
            } else {
                reasonDisplay = 'none';
            }
            if (type == 'more') {
                setPopUpContent(
                    <div className="layer-list admin-more-menu" style={{ top: e.clientY + 'px', left: e.clientX + 'px' }}>
                        <div className="box">
                            <ul>
                                <li><a onClick={() => btnOnPopup(e, item, index, 'info')}>가입신청정보</a></li>
                                {/* <li><a onClick={this.popupApplyDelete.bind(this, index)}>삭제</a></li> */}
                            </ul>
                        </div>
                    </div>
                );
            } else if (type == 'info') {
                setPopUpContent(
                    <div className="msg-popup admin-user-info">
                        <div className="box">
                            <div className="title-wrap">
                                <p className="title">가입신청정보</p>
                                <button className="btn-close-popup" onClick={() => btnOnPopup()}><i className="i-close-popup"></i></button>
                            </div>
                            <form className="form-wrap">
                                <div className="user-info-list">
                                    <dl className="" id="">
                                        <dt>신청일</dt>
                                        <dd>{item.applyDate}</dd>
                                    </dl>
                                    <dl className="" id="">
                                        <dt>이름</dt>
                                        <dd>{item.userName}</dd>
                                    </dl>
                                    <dl className="" id="">
                                        <dt>이메일 아이디</dt>
                                        <dd>{item.userId}</dd>
                                    </dl>
                                    <dl className="" id="">
                                        <dt>소속</dt>
                                        <dd>{item.companyName}</dd>
                                    </dl>
                                    <dl className="" id="">
                                        <dt>소속유효계정</dt>
                                        <dd>{item.count}</dd>
                                    </dl>
                                    {item.deptName != null && item.deptName != '' ?
                                        <dl className="">
                                            <dt>부서</dt>
                                            <dd>{item.deptName}</dd>
                                        </dl> : null}
                                    {item.kindName != null && item.kindName != '' ?
                                        <dl className="">
                                            <dt>직무</dt>
                                            <dd>{item.kindName}</dd>
                                        </dl> : null}
                                    <dl className="" id="">
                                        <dt>2차인증</dt>
                                        <dd>{getSendOtpName(item.sendOtpType)}</dd>
                                    </dl>
                                    <dl className="" id="">
                                        <dt>상태</dt>
                                        <dd>
                                            <select name="applyStatus" defaultValue={item.applyStatus ?? 'E'} onChange={e => handleChange(e, item, index, 'info')}>
                                                <option value="E">대기</option>
                                                <option value="A">승인</option>
                                                <option value="D">거부</option>
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl className="reason" style={{ display: reasonDisplay }}>
                                        <dt>승인거부사유</dt>
                                        <dd>
                                            <input name="remark" placeholder="승인거부 사유를 입력하세요 (해당 사용자 로그인 시 보여집니다)" onChange={e => handleChange(e, item, index, 'info')} value={item.remark ?? ''} />
                                            <p className="required" style={{ display: item.remark != undefined && item.remark != '' ? 'none' : '' }}>사유는 필수로 입력하세요.</p>
                                        </dd>
                                    </dl>

                                </div>
                                <div className="btn-wrap">
                                    <button className="btn-secondary-t3" onClick={(e) => applySave(e, index, 'Y')}>삭제</button>
                                    <button className="btn-primary" onClick={(e) => applySave(e, index, 'N', 'info')} >처리완료</button>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            } else if (type == 'approval') {
                changeUserApplyStatus = 'A';
                setPopUpContent(
                    <div className="msg-popup-wrap dim">
                        <div className="msg-popup admin-approve-all">
                            <div className="box">
                                <p className="title">가입 승인</p>
                                <p className="">선택한 신청을 모두 승인하시겠습니까?</p>
                                <div className="btn-wrap">
                                    <button className="btn-secondary-t5" onClick={() => btnOnPopup()}>취소</button>
                                    <button className="btn-primary" onClick={(e) => applySave(e, index, 'N')}>승인</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else if (type == 'deny') {
                changeUserApplyStatus = 'D';
                setPopUpContent(
                    <div className="msg-popup-wrap dim">
                        <div className="msg-popup admin-approve-all">
                            <div className="box">
                                <p className="title color-t3">가입 승인거부</p>
                                <p className="">선택한 신청을 모두 승인거부하시겠습니까?</p>
                                <dl id="" className="reason">
                                    <dt>승인거부사유<span className="info">모든 신청에 적용됩니다.</span></dt>
                                    <dd>
                                        <input name="remark" placeholder="승인거부 사유를 입력하세요 (해당 사용자 로그인 시 보여집니다)" onChange={e => handleChange(e, item, index, 'deny')} value={item.remark ?? ''} />
                                        <p className="required" style={{ display: item.remark != undefined && item.remark != '' ? 'none' : '' }}>사유는 필수로 입력하세요.</p>
                                    </dd>
                                </dl>
                                <div className="btn-wrap">
                                    <button className="btn-secondary-t5" onClick={() => btnOnPopup()}>취소</button>
                                    <button className="btn-primary-t3" onClick={(e) => applySave(e, index, 'N', 'deny')}>승인거부</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else if (type == 'delete') {
                changeUserApplyStatus = 'X';
                setPopUpContent(
                    <div className="msg-popup-wrap dim">
                        <div className="msg-popup admin-approve-all">
                            <div className="box">
                                <p className="title color-t3">삭제</p>
                                <p className="">선택한 건을 모두 삭제하시겠습니까?</p>
                                <div className="btn-wrap">
                                    <button className="btn-secondary-t5" onClick={() => btnOnPopup()}>취소</button>
                                    <button className="btn-secondary-t3" onClick={(e) => applySave(e, index, 'Y')}>삭제</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            setPopUpContent(null);
        }
    }
    const getSendOtpName = (value) => {
        let Text = '사용안함';
        if (value == 1) {
            Text = '메일';
        }
        if (value == 2) {
            Text = '카카오톡';
        }
        return Text;
    }

    const renderStatus = (value) => {
        let statusArr = [];
        switch (value) {
            case 'A':
                statusArr.push(
                    <td key="status">
                        <span className="appr color-primary">승인</span>
                    </td>
                );
                break;
            case 'D':
                statusArr.push(
                    <td key="status">
                        <span className="unappr color-t3">거부</span>
                    </td>
                );
                break;
            case 'X':
                statusArr.push(
                    <td key="status">
                        <span className="unappr color-t5">삭제</span>
                    </td>
                );
                break;
            default:
                statusArr.push(
                    <td key="status">
                        <span className="waiting color-t5">대기</span>
                    </td>
                );
        }
        return statusArr;
    }
    const registerList = () => {
        let list = [];
        for (let i = 0; i < listView.length; i++) {
            let display = listView[i].applyStatus == 'D' ? { display: '' } : { display: 'none' };
            list.push(
                <Fragment key={i}>
                    <tr onClick={(e) => btnOnPopup(e, listView[i], [i], 'info')}>
                        {applyListType == 'progress' ? <td onClick={(e) => e.stopPropagation()}><div className="list-checkbox"><input type="checkbox" id={"chk" + i} value={i} checked={checkedItems.includes(i)} onChange={(e) => checkHandler(e)} /><label htmlFor={"chk" + i}></label></div></td> : null}
                        <td>{listView[i].applyDate}</td>
                        <td>{listView[i].userName}</td>
                        <td>{listView[i].companyName}</td>
                        <td>{listView[i].userId}</td>
                        {applyListType != 'progress' ? null : <td>{listView[i].kindName}</td>}
                        <td>{getSendOtpName(listView[i].sendOtpType)}</td>
                        {renderStatus(listView[i].applyStatus)}
                        {applyListType != 'progress' ?
                            <td>{listView[i].devUserName}</td> : null
                        }
                    </tr>
                    <tr className="added" style={display}>
                        <td colSpan="7">
                            {/*<i className="i-remark-row"></i>*/}
                            <div className="remark-input">
                                <span className="remark">승인거부 사유</span>
                                <input name=""
                                    placeholder="승인거부 사유를 입력하세요 (해당 사용자 로그인 시 보여집니다)"
                                    value={listView[i].remark}
                                    disabled="disabled" />
                            </div>
                        </td>
                    </tr>
                </Fragment>
            );
        }
        return list;
    }
    const checkHandler = (e) => {
        checkedItemHandler(parseInt(e.target.value), e.target.checked);
    }
    const checkedItemHandler = (value, isChecked) => {
        if (isChecked) {
            if (checkedItems.length == listView.length - 1) {
                setCheckedItems([...checkedItems, value]);
                setIsCheckedAll(true);

            } else {
                setCheckedItems([...checkedItems, value]);
            }
        } else if (!isChecked && checkedItems.indexOf(value) != -1) {
            setCheckedItems(checkedItems.filter(item => item != value));
            setIsCheckedAll(false);
        }
    }
    const allCheckedHandler = (e) => {
        if (e.target.checked) {
            var arr = [];
            for (let i = 0; i < listView.length; i++) {
                arr.push(i);
            }
            setCheckedItems(arr);
        } else {
            setCheckedItems([]);
        }
        setIsCheckedAll(!isCheckedAll);
    };
    //Life Cycle
    useEffect(() => {
        setData([]);
        if (applyListType == 'progress') {
            getList();
        }
        else {
            setCheckedItems([]);
            setIsCheckedAll(false);
            getHistoryList();
        }
    }, [applyListType])

    const setHeaders = () => {
        let headers = [];
        if(applyListType == 'progress') {
            headers = [
                <div className="list-checkbox"><input type="checkbox" id="chkAll" checked={isCheckedAll} onChange={(e) => allCheckedHandler(e)} /><label htmlFor="chkAll"></label></div>,
                "신청일",
                "이름",
                "소속",
                "이메일 아이디",
                "직무",
                "2차인증",
                "상태",
                ""
            ];
        } else {
            headers = [
                "처리일",
                "이름",
                "소속",
                "이메일 아이디",
                "2차인증",
                "상태",
                "처리자"
            ];
        }
        return headers;
    };

    return (
        <div className="content">
            <h2>가입신청관리</h2>
            <div className="list-tab">
                <div className="item">
                    <input id="progress" name="register-tab" type="radio" defaultChecked onClick={() => changeListType('progress')} />
                    <label htmlFor="progress">진행 중</label>
                </div>
                <div className="item">
                    <input id="done" name="register-tab" type="radio" onClick={() => changeListType('done')} />
                    <label htmlFor="done">내역 보기</label>
                </div>
            </div>
            <div className="list-tools">
                {checkedItems.length > 0 ? renderBatchWrap() : null}
                {applyListType == 'progress' ?
                    <Filter
                        listData={data}
                        setListView={setListView}
                        comboBox={[{ name: "applyStatus", title: "상태", options: [{ key: "", name: "전체" }, { key: "E", name: "대기" }, { key: "D", name: "거부" }], current: "E" }]}
                        multiKeywords={[{ name: "companyName", title: "소속" }, { name: "kindName", title: "직무" }]}
                        searchKeyword={{ name: ["userId", "userName"], title: "아이디나 이름으로 검색" }}
                        dateFromTo={[{ name: "applyDate", title: "기간", options: [{ key: "", name: "기간 전체" }, { key: "90", name: "최근 3개월" }, { key: "180", name: "최근 6개월" }, { key: "365", name: "최근 1년" }] }]}
                    />
                    : null}
                {applyListType == 'done' ?
                    <Filter
                        listData={data}
                        setListView={setListView}
                        comboBox={[{ name: "applyStatus", title: "상태", options: [{ key: "", name: "전체" }, { key: "A", name: "승인" }, { key: "D", name: "거부" }], current: "" }]}
                        multiKeywords={[{ name: "companyName", title: "소속" }, { name: "kindName", title: "직무" }]}
                        searchKeyword={{ name: ["userId", "userName"], title: "아이디나 이름으로 검색" }}
                        dateFromTo={[{ name: "applyDate", title: "기간", options: [{ key: "", name: "기간 전체" }, { key: "90", name: "최근 3개월" }, { key: "180", name: "최근 6개월" }, { key: "365", name: "최근 1년" }] }]}
                    />
                    : null}
            </div>
            <Table className={applyListType == 'progress' ? "progress" : "done"} headers={setHeaders()} list={registerList()}/>
            <PopUp content={popupContent} setContent={setPopUpContent} />
        </div>
    );
}

export default ApplicantList;