import React, { useState, useEffect } from 'react'
import MarkDownEditor from './MarkDownEditor';
import { UploadArea } from './EditorUtils';

export const EditContent = (props) => {
    const [mdCode, setMdCode] = useState('');
    const setIsExpanded = () => {
        props.setIsExpanded(!props.isExpanded)
    }
    const getMdText = () => {
        let body = JSON.stringify({
            ContentType: props.contentInfo.contentType,
            ContentName: props.contentInfo.contentName,
            FolderName: props.contentInfo.folderName
        });
        fetch('api/Document/PlainMdManual', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: body
        }).then(response => response.json())
            .then(data => {
                if (props.contentInfo.type === 'pdf') {

                } else {
                    setMdCode(data);
                }
            });
    }
    return (
        <div className="content">
            <div className="title-wrap">
                <button className="btn-expand-editor" onClick={setIsExpanded}>
                    <i className="i-expand"></i>{`편집창 ${props.isExpanded ? "축소" : "확대"}`}
                </button>
                <p className="info-autosaved">{/*10초 전에 자동저장되었습니다.*/}</p>
            </div>
            {props.contentInfo.type !== 'pdf' ?
                <div className="edit-md">
                    <UploadArea ContentInfo={props.contentInfo} isViewMode={props.ViewMode} setFiles={props.setFiles} />
                    <MarkDownEditor ContentInfo={props.contentInfo} isViewMode={props.ViewMode} />
                </div> :
                <div className="upload-pdf">
                    <div className="upload-wrap">
                        <div className="upload">
                            <p className="txt"> PDF파일을 업로드하세요</p>
                            <a className="btn-select-file" href={()=>{}}>파일 선택</a>
                        </div>
                        <div className="uploaded-file">
                            <div className="icon-wrap"><i className="i-filetype-doc"></i></div>
                            <span className="filename" title="파일명">업로드pdf명.pdf</span>
                            <div className="btn-wrap">
                                <button className="btn-delete-file" title="파일 삭제하기"><i className="i-delete-file"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="pdf-preview">
                        <header>미리보기</header>
                        <div className="preview"></div>
                    </div>
                </div>
            }
        </div>
    );
}

export default EditContent;
