import React from 'react';
import jQuery from "jquery";

import { useSelector } from 'react-redux';

window.$ = window.jQuery = jQuery;
const ScrollIntoView = () => {
    const ContentStates = useSelector(state => state.contentActions);
    
    const ClickIndex = (index, text) => {
        var offsetTop = 0;
        var contents = window.$(".content").find("[ref=" + (index-1) + "]");        
        if (contents.length > 0 && index > 0) {
            offsetTop = window.$(contents).offset().top;
        }
        window.$("html, body").stop().animate({
            scrollTop: offsetTop - 60
        }, 'offsetTop', 'swing');
    };

    const findSubJects = () => {
        let returnArray = [];
        var elements = window.$("<div>" + ContentStates.get('htmlText') + "</div>").find("h1,h3");
        for (var key = 0; key < elements.length; key++) {
            var text = { index: key, innerText: window.$(elements[key]).html() };
            returnArray.push(text);
        }        
        return returnArray;
    };
    
    var style = {
    };
    
    var listStyle = {
        "display": "list - item",
        "textAlign": "- webkit - match - parent"
    };
    let ankor = findSubJects();
    let index = 0;
    return (
        <div className="page-index">
            <nav style={style}>
                <h5>이 문서의 내용</h5>
                <ol>
                    {
                        ankor.map(
                            (subject, i) => {
                                return (<li key={index++} style={listStyle} onClick={() => ClickIndex(subject.index, subject.innerText)}> {subject.innerText} </li>);
                            }
                        )
                    }
                </ol>
            </nav>
        </div>
    );
}

export default ScrollIntoView;