import React, { Component, useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector, useDispatch } from 'react-redux';
import * as contentActions from '../../store/modules/contentActions';
import { bindActionCreators } from 'redux';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `./pdf.worker.js`;
const PDFDocViewer = (props) => {
    const ContentStates = useSelector((state) => state.contentActions);
    const dispatch = useDispatch();
    const ContentActions = bindActionCreators(contentActions, dispatch);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const folder = props.ContentInfo.folderName != '' ? props.ContentInfo.folderName + '/' : props.ContentInfo.folderName;
    const filePath = `./doc/${props.ContentInfo.contentType}/${folder}${props.ContentInfo.contentName}.pdf`;      
    return (
        <div style={{ alignItems: "center", display:"inline-block" }}>
            <Document
                file={filePath}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas"
            >
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={document.getElementsByClassName("doc-nav")[0].offsetWidth - document.getElementsByClassName("btn-download")[0].offsetWidth}
                            />
                        ),
                    )
                }
            </Document>
            
        </div>
    );
};

export default PDFDocViewer;
