import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as loginActions from '../../store/modules/loginActions';
import { bindActionCreators } from 'redux';

const MessagePanel = (props) => {
    const LoginStates = useSelector((state) => state.loginActions);
    const dispatch = useDispatch();
    const LoginActions = bindActionCreators(loginActions, dispatch);

    const GoBackLogin = () => {
        props.setPanelType('loginAgain');
    };

    const ChangeMessage = () => {
        const result = LoginStates.get('result');
        if (result == 'Success Finding') {
            return (
                <form className="login-box">
                    <h2>임시 비밀번호를 이메일로 발송하였습니다.</h2>
                    <p className="msg">이메일로 발송 된 임시 비밀번호로 다시 로그인 해 주세요.</p>
                    <div className="btn-wrap">
                        <button onClick={this.GoBackLogin} className='btn-primary'>로그인화면으로 돌아가기</button>
					</div>
                </form>
            );
        }
        else if (result == 'Over Failed Count') {
            return (
                <form className="login-box">
                    <h2>임시 비밀번호를 이메일로 발송하였습니다.</h2>
                    <p className="msg">로그인 실패 횟수 초과로 인해 현재 비밀번호로 로그인 할 수 없습니다.<br />
						이메일로 발송 된 임시 비밀번호로 다시 로그인 해 주세요.</p>

                    <div className="btn-wrap">
                        <button onClick={GoBackLogin} className='btn-primary'>로그인화면으로 돌아가기</button>
					</div>
				</form>
            );
        }
        else if (result == 'Fail Sending') {
            return (
                <form className="login-box">
                    <h2>임시 비밀번호를 이메일로 발송이 실패하였습니다.</h2>
                    <p className="msg">관리자에게 문의 요청 바랍니다.</p>

                    <div className="btn-wrap">
                        <button onClick={GoBackLogin} className='btn-primary'>로그인화면으로 돌아가기</button>
                    </div>
                </form>
            );
        }
        else if (result == 'Server Error') {
            return (
                <form className="login-box">
                    <h2>로그인 과정에서 오류가 발생하였습니다.</h2>
                    <p className="msg">관리자에게 문의 요청 바랍니다.</p>

                    <div className="btn-wrap">
                        <button onClick={GoBackLogin} className='btn-primary'>로그인화면으로 돌아가기</button>
                    </div>
                </form>
            );
        }
        else {
            return (
                <form className="login-box">
                    <h2>가입신청이 완료되었습니다.</h2>
                    <p className="msg">심사과정을 거쳐 승인 결과를 이메일로 발송합니다.<br />승인은 신청 후 1~3일 소요됩니다.</p>
                    <div className="btn-wrap">
                        <button onClick={GoBackLogin} className='btn-primary'>로그인화면으로 돌아가기</button>
					</div>
				</form>
             );
        }
    };

    return (
        <div className="login-wrap">
            {ChangeMessage()}
        </div>
    );
}

export default MessagePanel;