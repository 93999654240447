
import React, {  Fragment,useLayoutEffect, useState, useRef, useEffect} from 'react';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginActions from '../store/modules/loginActions';
import AuthRequest from './AccountPanels/AuthAccountPanels/AuthRequest'
import AuthRequestList from './AccountPanels/AuthAccountPanels/AuthRequestList'
import storage from './libs/storage';

export function usePrevState(state) {
    const ref = useRef(state);
    useEffect(() => {
        ref.current = state;
    }, [state]);
    return ref.current;
}


const KdcOpen = ({ location }) => {
    
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

 //Type으로 화면호출 분기
 const [loginstatus , setloginstatus] = useState(false);
 const [Type , setType] = useState("");

 //로그인스토리지
 const dispatch = useDispatch();
 const LoginAction = bindActionCreators(loginActions, dispatch);

useLayoutEffect(() => {
    SetSsoLogin();
}, []);  


 const SetSsoLogin = () =>{
        try{

            //Ex) http://121.78.149.120:8099/KdcOpen?Type=Request&&Key=241022144933687270
            // http://localhost:44404/KdcOpen?Type=Reqlist&&Key=301122092824630193
            //KdcOpen뒤에 Location값 셋팅 (Type,Key) 두가지  
            
            const loginInfo = storage.get('loginStates');



            let sessionid = '';
            if(!(loginInfo === null || loginInfo === undefined)){
                sessionid = loginInfo.get('sessionID')
            }
       
            let ssostr = query.Key.toString(); 
            setType(query.Type.toString());
            const userInfo = {
                WorkingTag: query.Type.toString(),
                SsoKey: ssostr ,
                Sessionid : sessionid
            };
        
            fetch('api/Login/SsoLogin', {
                method: 'Post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(userInfo)
            }).then((response) => response.json())
            .then(async(data) => {
                    const info = data.userInformation;
                    if (data.status == 'IsSession'){
                        setloginstatus(true);
                    }
                    else if (data.status != 'Success Login' && data.status != 'Already Session Exists' && data.status != 'Change Password') {
                        //showToast('danger', data.message, 3000);
                        alert(data.message);
                        setloginstatus(false);
                    } else {
                        const result = {                            
                            userId: info.userId ?? '',
                            userName: info.userName ?? '',
                            message: data.message,
                            result: data.status,
                            sessionID: info.sessionID ?? '',
                            companyName: info.companyName ?? '',
                            companySeq: info.companySeq ?? '',
                            companyType: info.companyType ?? '',
                            devType: info.devType ?? '',
                            logindate: new Date().toISOString().slice(0, 10),
                            admin: info.admin ?? '',
                            userPhoneNum: info.userPhoneNum ?? '',
                            userSendOtpType: info.userSendOtpType ?? '',
                            deptName: info.deptName ?? '',
                            kindName: info.kindName ?? ''
                        };
                        /* 이미있는 세션과 새로 Sp탄 세션은 비교해봤자 의미가 없다 */
                        await LoginAction.setLoginInfo(result);
                        setloginstatus(true);       
                    }
            });
        } catch(e){
            console.log("SsoLogin Err");
            setloginstatus(false);      
        }
    }

    const setPopPage = () =>{
        if (Type == "Request" && loginstatus ){
            return (<AuthRequest handleRequestPopup={() => handleRequestPopup()} hadleCheckReq={() => handleRequestPopup()}/>);
        }else if (Type == "Reqlist" && loginstatus ){
            return (
                    <div className="container">
                        <div className="setting-wrap">
                            <AuthRequestList/>
                        </div>
                    </div>
                   );
        }
        else {
            return null ;
        }
    }


    //권한 신청 팝업
    const handleRequestPopup = () =>{
        //console.log("chrome.webview.postMessage");
        //window.chrome.webview.postMessage("MSGID_FormClosed");
        // chrome.webview.postMessage("MSGID_FormClosed");
    }


    return (
            <Fragment>
                {setPopPage()}
            </Fragment>
        )
};

export default KdcOpen;
