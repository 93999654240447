import { Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const initialState = Map({
    contentType: '',
    contentName: '',
    menuViews: null,
    subjects: '',
    htmlText: '',
    menuDatas: null,
    nodepath: '',
    docType: '',
    video: '',
    folderName: '',
    tabName: '',
    url: ''
});


const SETCONTENTINFO = 'contentActions/SETCONTENTINFO';
const MENULOAD = 'contentActions/MENULOAD';
const MENUSET = 'contentActions/MENUSET';
const CONTENTLOAD = 'contentActions/CONTENTLOAD';
const PATHSET = 'openApiActions/PATHSET';
const INIT_STATES = 'contentActions/INIT_STATES';

export const setContentInfo = createAction(SETCONTENTINFO);
export const initMenu = createAction(MENULOAD);
export const setMenu = createAction(MENUSET);
export const setContent = createAction(CONTENTLOAD);
export const setPath = createAction(PATHSET);
export const init = createAction(INIT_STATES);

export default handleActions({
    // 메뉴 클릭시 데이터 전달
    [SETCONTENTINFO]: (state, action) => {
        return state.set('contentType', action.payload.contentType)
            .set('contentName', action.payload.contentName)
            .set('docType', action.payload.type)
            .set('video', action.payload.video)
            .set('folderName', action.payload.folderName)
            .set('tabName', action.payload.tabName)
            .set('url', action.payload.url);
    },
    //메뉴 초기상태 로드
    [MENULOAD]: (state, action) => {
        return state.set('menuDatas', null)
            .set('menuViews', null);
    },
    [CONTENTLOAD]: (state, action) => {
        return state.set('htmlText', action.payload);
    },
    //메뉴 세팅
    [MENUSET]: (state, action) => {
        const strJson = JSON.stringify(action.payload.treeData);

        //return state.updateIn('menuDatas', arr => arr.push(action.payload));
        return state.set('menuDatas', JSON.parse(strJson))
            .set('menuViews', action.payload.treeView );
    },
    [PATHSET]: (state, action) => {
        return state.set('nodepath', action.payload);
    },
    [INIT_STATES]: (state, action) => {
        //state = action.payload;
        return state.set(initialState);
    }
}, initialState);