import React, { useEffect, useState } from 'react';

const LeftContent = (props) => {

    // const [currentIndex, setCurrentIndex] = useState(props.menuIndex);

    useEffect(() => {
        document.body.classList.add('doc-type');
        return () => {
            document.body.classList.remove('doc-type');
        }
    }, []);
    
    const contentMenu = () => {
        const menuData = props.data; 
        const currentStates = menuData.children;
        let className = '';
        let list = [], index = 0;

        for (let i = 0; i < currentStates.length; i++) {
            if (i == props.menuIndex) {
                className = 'current';
            }
            else if (currentStates[i].complete == 'yes') {
                className = 'checked';
            }
            else {
                className = '';
            }
            list.push(
                <li key={index++} className={className} onClick={(e) => clickSubject(e, i)}>
                    <div className="nb-item">
                        <i className="i-input-ok"></i>
                    </div>
                    <a className="title" href={()=>{}}>{currentStates[i].module}</a>
                </li>
            );
        }
        return list;
    };

    const clickSubject = (e, index) => {
        e.preventDefault();        
        // setCurrentIndex(index);
        props.onClickSubject(index);
    };    

    let menu = null;
    if (props.data.module != undefined) {
        menu = contentMenu();
    } 
    return (
        <div className="left-menu">
            <h3 className="tree-title"><a>K-Studio 시작하기</a></h3>
            <ol className="tutorial">
                {menu}
            </ol>
        </div>
    );
}

export default LeftContent;